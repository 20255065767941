import { Dispatch, SetStateAction, useCallback, useReducer, useRef } from "react";
import { isFunction } from "../utils";

/**
 * Call the `useControlledState` hook at the top level of your component to declare
 * a {@link https://react.dev/learn/state-a-components-memory | state variable}
 * that can take updates from a {@link https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components | controlled component}
 * property value.
 * 
 * @param controlState
 * The initial and controlled value you want the state to be. It can be a value of
 * any type, but there is a special behavior for functions.
 * Unlike `useState` this argument continues to be used after the initial render
 * and may update the state variable when it has changed according to the `equals`
 * equality comparison.
 * 
 * If you pass a function as `controlState`, it will be treated as a factory function
 * to produce the control state.
 * It should be pure, should take no arguments, and should return a value of any type.
 * The function is called to create the control state on each render before it determines
 * if the value has changed according to the `equals` comparison.
 * 
 * **⚠️ CAUTION:**  
 * When you return an _object_ from a factory function its identity will be different on
 * each render. As the default implementation of `equals` is `Object.is`, which compares
 * objects by reference equality, each render would result in a state update.  
 * _Be sure to provide a custom implementation of `equals` for these cases._
 * 
 * @param equals
 * Determines whether the `controlState` value is equal to the last `controlState`.  
 * When `controlState` changes it results in the state variable being updated.
 * @returns
 * `useControlledState` returns an array tuple with exactly two values:
 * 1. The current state. During the first render, it will match the `controlState` you
 *    have passed. When the `controlState` value changes across renders, it will be
 *    updated to match the new `controlState`.
 * 2. The `set` function that lets you update the state to a different value and
 *    trigger a re-render.
 * 
 * @example
 * The following shows how `useControlledState` is used to wire together an
 * initial `expanded` component property with an `expanded` state value, where changes
 * to the component property update the property while also allowing the component itself
 * to internally update the state value through the `setExpanded` set state action.
 * 
 * ```tsx
 * type DisclosureProps = PropsWithChildren<{
 *   expanded?: boolean;
 * }>
 * 
 * const Disclosure : FC<DisclosureProps> = ({
 *   expanded: initial = false,
 *   children
 * }) => {
 *   const [expanded, setExpanded] = useControlledState(initial);
 * 
 *   const toggle = useCallback(() => {
 *     setExpanded(prevState => !prevState);
 *   }, [setExpanded]);
 * 
 *   const contentId = useId();
 * 
 *   return (
 *     <>
 *       <button
 *         type="button"
 *         aria-controls={contentId}
 *         aria-expanded={expanded}
 *         onClick={toggle}
 *       >
 *         {expanded ? "Collapse" : "Expand"}
 *       </button>
 *       <div id={contentId} aria-hidden={!expanded}>
 *         {children}
 *       </div>
 *     </>
 *   );
 * }
 * ```
 */
export const useControlledState = <S>(
	controlState: S | (() => S),
	equals: ((current: S, previous: S) => boolean) = Object.is
): [S, Dispatch<SetStateAction<S>>] => {
	// We rely on `useRef` as the backing storage for controlled state, as refs
	// are safe to update during render and can avoid having to rely on useEffect
	// and scheduling for a second pass by using a `useState` setter.
	// This means we need an alternate means to have the set callback trigger
	// a re-render when the state is updated locally, for which we use a dummy
	// reducer.
	const [, update] = useReducer(o => !o, false);
	const ref = useRef<{ initial: S, state: S } | null>(null);

	const updated = isFunction(controlState) ? controlState() : controlState;
	if (ref.current == null || !equals(updated, ref.current.initial)) {
		ref.current = { initial: updated, state: updated };
	}

	const state = ref.current!.state;

	const setState = useCallback((action: SetStateAction<S>) => {
		const state = ref.current!.state;
		ref.current = {
			...ref.current!,
			state: isFunction(action) ? action(state) : action
		};
		update();
	}, []);

	return [state, setState];
};