import { createSlice } from "@reduxjs/toolkit";
import { NotificationsState } from "../types";

const initialState: NotificationsState = {
	show: false
};

export const notificationSlice = createSlice({
	name: "notification",
	initialState,
	reducers: {
		toggleNotifications: state => {
			state.show = !state.show;
		},
		hideNotifications: state => {
			state.show = false;
		},
	}
});

export const { toggleNotifications, hideNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;