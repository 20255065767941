// A utility type that constrains T to be a (union of) literal strings.
type LiteralUnion<T extends string> = string extends T ? never : T;

// By exporting both the class and the interface under the same name
// *and* having the class implement the same-named interface, TypeScript
// unifies both types and we get the overridden, wider type for the
// `has` method.
export interface LiteralUnionSet<T extends string> extends ReadonlySet<T> {
	/**
	 * @returns A boolean indicating whether an element with
	 * the specified value exists in the `LiteralUnionSet` or not.
	 */
	has(value: T | string): value is T;
}

/**
 * A read-only set that represents a string literal union and
 * can be used with the `LiteralUnionSet.Literal` helper type
 * to extract the union type.
 * 
 * This type is meant as a helper for those cases where not
 * only compile-time safety of enum literal unions is needed,
 * but also runtime checks or type guards that narrow down
 * from a plain string type.
 */
export class LiteralUnionSet<T extends string> implements LiteralUnionSet<T> {
	/**
	 * Creates a set representing a string literal union.
	 * @param args The string literal values of the union.
	 * @returns The created set.
	 */
	constructor(...args: LiteralUnion<T>[]) {
		// This is compatible. It just casts the boolean
		// return value of `has` as a type guard, and widens
		// its parameter type from `T` to `T | string`.
		return new Set(args) as unknown as LiteralUnionSet<T>;
	}
}

export namespace LiteralUnionSet {
	/**
	 * Extracts the string literal union type from a `LiteralUnionSet`.
	 */
	export type Literal<T extends LiteralUnionSet<any>>
		= T extends LiteralUnionSet<infer U> ? U : never;
}