import { GetDocumentResponse } from "src/apis/document";
import { DocumentModel, DocumentTypeModel, PermissionEnumModel } from "src/open-api/documentService";

export const getFilenameWithoutExtension = (fileName: string): string => {
	return fileName.substring(0, fileName.lastIndexOf("."));
}

export const getFilenameExtension = (fileName: string): string => {
	const extensionIndex = fileName.lastIndexOf(".");
	if (extensionIndex === -1) {
		return "";
	}
	return fileName.substring(extensionIndex).toLowerCase();
}

export const getFilenameExtensionType = (fileName: string): string => {
	const extension = getFilenameExtension(fileName);

	// exclude the first dot
	const fileExtensionType = extension.replace(".", "");
	return fileExtensionType;
}

const icons = new Map<DocumentTypeModel, string>([
	["Excel", "png:file-xls"],
	["Jpeg", "png:file-jpg"],
	["Pdf", "png:file-pdf"],
	["Png", "png:file-png"],
	["Text", "png:file-txt"],
	["Word", "png:file-doc"],
	["PowerPoint", "png:file-ppt"]
]) as ReadonlyMap<DocumentTypeModel, string>;

export const getTypedIconForFileType = (fileType: DocumentTypeModel): string =>
	icons.get(fileType) ?? "file2";

/** @deprecated Use `getTypedIconForFileType` instead. */
export const getFileTypeIcon = (fileType: DocumentTypeModel): string => {
	const otherIcon = "file2";

	const supportedIcons = new Map<DocumentTypeModel, string>([
		["Excel", "file-xls"],
		["Jpeg", "file-jpg"],
		["Other", otherIcon],
		["Pdf", "file-pdf"],
		["Png", "file-png"],
		["Text", "file-txt"],
		["Word", "file-doc"],
		["PowerPoint", "file-ppt"]
	]);

	return supportedIcons.get(fileType) ?? otherIcon;
}

export const getFileTypeFromExtension = (filename: string): DocumentTypeModel => {
	const fileExtension = getFilenameExtension(filename);
	// .doc, .docx,, .xlsx,, .xls, .txt, .ppt, .pptx, .pdf, .png, .jpeg,
	const supportedFileTypes = new Map<string, DocumentTypeModel>([
		[".doc", "Word"],
		[".docx", "Word"],
		[".xlsx", "Excel"],
		[".xls", "Excel"],
		[".txt", "Text"],
		[".ppt", "PowerPoint"],
		[".pptx", "PowerPoint"],
		[".pdf", "Pdf"],
		[".png", "Png"],
		[".jpeg", "Jpeg"],
		[".jpg", "Jpeg"],
	]);

	return supportedFileTypes.get(fileExtension) ?? "Other";
}

type CollaboraDiscoveryResponse = {
	[fileType: string]: string;
}

export const parseCollaboraDiscoveryXml = (xml: string): CollaboraDiscoveryResponse => {
	const xmlDocument = new DOMParser().parseFromString(xml, "text/xml");

	const result: CollaboraDiscoveryResponse = {};

	for (const fileType of ["doc", "docx", "txt"]) {
		const fileTypeNode = xmlDocument.querySelector(`action[ext='${fileType}']`);
		if (!fileTypeNode) continue;

		const urlsrc = fileTypeNode.getAttribute("urlsrc");
		if (!urlsrc) continue;

		result[fileType] = urlsrc;
	}

	return result;
}

const supportedPreviewTypes = new Set(["Text", "Word"]);
export const isDocumentPreviewSupported = (document: DocumentModel) => {
	const type = document.documentType;
	return !!type && supportedPreviewTypes.has(type);
}

export const documentHasPermission = (doc: Pick<DocumentModel | GetDocumentResponse, "permissions">, permission: PermissionEnumModel) => {
	return doc.permissions?.some(docPermission => docPermission === permission);
}