/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActionResult } from '../models/ActionResult';
import type { CreateCalendarEventRequestModel } from '../models/CreateCalendarEventRequestModel';
import type { CreateCalendarEventResponseModel } from '../models/CreateCalendarEventResponseModel';
import type { DeleteCalendarEventResponseModel } from '../models/DeleteCalendarEventResponseModel';
import type { GetCalendarEventResponseModel } from '../models/GetCalendarEventResponseModel';
import type { GetCalendarEventsResponseModel } from '../models/GetCalendarEventsResponseModel';
import type { UpdateCalendarEventRequestModel } from '../models/UpdateCalendarEventRequestModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CalendarEventService {

    /**
     * Returns the list of events based on its calendar identifier and date time range filter.
     * @param calendarId Calendar identifier
     * @param fromDate Lower limit of date time range filter. Should be in ISO 8601 format (YYYY-MM-DD).
     * @param toDate Upper limit of date time range filter. Should be in ISO 8601 format (YYYY-MM-DD).
     * @param acceptLanguage The culture code used for the request.
     * @returns GetCalendarEventsResponseModel Success
     * @throws ApiError
     */
    public static getCalendarEventByFilter(
        calendarId: string,
        fromDate: string,
        toDate: string,
        acceptLanguage: string,
    ): CancelablePromise<GetCalendarEventsResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/calendars/{calendarId}/events',
            path: {
                'calendarId': calendarId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'fromDate': fromDate,
                'toDate': toDate,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Creates an event.
     * @param calendarId Calendar identifier
     * @param acceptLanguage The culture code used for the request.
     * @param requestBody Event details
     * @returns CreateCalendarEventResponseModel Created
     * @throws ApiError
     */
    public static createCalendarEvent(
        calendarId: string,
        acceptLanguage: string,
        requestBody: CreateCalendarEventRequestModel,
    ): CancelablePromise<CreateCalendarEventResponseModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/calendars/{calendarId}/events',
            path: {
                'calendarId': calendarId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Returns an event based on its calendar identifier and event identifier.
     * @param calendarId Calendar identifier
     * @param eventId Event identifier
     * @param acceptLanguage The culture code used for the request.
     * @returns GetCalendarEventResponseModel Success
     * @throws ApiError
     */
    public static getByEventId(
        calendarId: string,
        eventId: string,
        acceptLanguage: string,
    ): CancelablePromise<GetCalendarEventResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/calendars/{calendarId}/events/{eventId}',
            path: {
                'calendarId': calendarId,
                'eventId': eventId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates an event. This method does not support patch semantics and always updates the entire event resource.
     * @param calendarId Calendar identifier
     * @param eventId Event identifier
     * @param acceptLanguage The culture code used for the request.
     * @param requestBody Event details
     * @returns any Created
     * @throws ApiError
     */
    public static updateCalendarEvent(
        calendarId: string,
        eventId: string,
        acceptLanguage: string,
        requestBody?: UpdateCalendarEventRequestModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/calendars/{calendarId}/events/{eventId}',
            path: {
                'calendarId': calendarId,
                'eventId': eventId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                412: `Client Error`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes an event.
     * @param calendarId Calendar identifier
     * @param eventId Event identifier
     * @param acceptLanguage The culture code used for the request.
     * @returns DeleteCalendarEventResponseModel Accepted
     * @throws ApiError
     */
    public static deleteCalendarEvent(
        calendarId: string,
        eventId: string,
        acceptLanguage: string,
    ): CancelablePromise<DeleteCalendarEventResponseModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/calendars/{calendarId}/events/{eventId}',
            path: {
                'calendarId': calendarId,
                'eventId': eventId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Accepts an invitation to a calendar event
     * @param calendarId
     * @param eventId
     * @param acceptLanguage The culture code used for the request.
     * @returns ActionResult Accepted
     * @throws ApiError
     */
    public static accept(
        calendarId: string,
        eventId: string,
        acceptLanguage: string,
    ): CancelablePromise<ActionResult> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/calendars/{calendarId}/events/{eventId}/accept',
            path: {
                'calendarId': calendarId,
                'eventId': eventId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Declines an invitation to a calendar event
     * @param calendarId
     * @param eventId
     * @param acceptLanguage The culture code used for the request.
     * @returns ActionResult Accepted
     * @throws ApiError
     */
    public static decline(
        calendarId: string,
        eventId: string,
        acceptLanguage: string,
    ): CancelablePromise<ActionResult> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/calendars/{calendarId}/events/{eventId}/decline',
            path: {
                'calendarId': calendarId,
                'eventId': eventId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
