import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    create: build.mutation<CreateApiResponse, CreateArgs>({
      query: (queryArg) => ({
        url: `/v1/notifications/publisher`,
        method: "POST",
        body: queryArg.createNotificationRequestModel,
      }),
    }),
    markAsRead: build.mutation<MarkAsReadApiResponse, MarkAsReadArgs>({
      query: (queryArg) => ({
        url: `/v1/notifications/publisher`,
        method: "PATCH",
        body: queryArg.markNotificationAsReadRequestModel,
      }),
    }),
    registerChoice: build.mutation<
      RegisterChoiceApiResponse,
      RegisterChoiceArgs
    >({
      query: (queryArg) => ({
        url: `/v1/notifications/publisher/actions`,
        method: "PATCH",
        body: queryArg.registerChoiceRequestModel,
      }),
    }),
    getAll: build.query<GetAllApiResponse, GetAllArgs>({
      query: (queryArg) => ({
        url: `/v1/notifications/receiver`,
        params: { fromDate: queryArg.fromDate },
      }),
    }),
    getV1NotificationsReceiverByNotificationId: build.query<
      GetV1NotificationsReceiverByNotificationIdApiResponse,
      GetV1NotificationsReceiverByNotificationIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/notifications/receiver/${queryArg.notificationId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type CreateApiResponse =
  /** status 201 Created */ CreateNotificationResponseModel;
export type CreateArgs = {
  /** The request model containing the notification data. */
  createNotificationRequestModel: CreateNotificationRequestModel;
};
export type MarkAsReadApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type MarkAsReadArgs = {
  /** The request model containing the recipient account ID and the list of notification IDs. */
  markNotificationAsReadRequestModel: MarkNotificationAsReadRequestModel;
};
export type RegisterChoiceApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type RegisterChoiceArgs = {
  registerChoiceRequestModel: RegisterChoiceRequestModel;
};
export type GetAllApiResponse =
  /** status 200 Success */ GetAllNotificationsResponseModel;
export type GetAllArgs = {
  fromDate?: string;
};
export type GetV1NotificationsReceiverByNotificationIdApiResponse =
  /** status 200 Success */ NotificationResponseModel;
export type GetV1NotificationsReceiverByNotificationIdArgs = {
  /** The ID of the notification. */
  notificationId: string;
};
export type CreateNotificationResponseModel = {
  id: string;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type CreateNotificationActionRequestModel = {
  kind: string;
  relativePath: string;
  verb: string;
};
export type NotificationRelationModel = {
  objectId: string;
  objectKind: string;
};
export type CreateNotificationRequestModel = {
  kind: string;
  payload: any;
  recipientAccountId: string;
  recipientEmailAddress?: string | null;
  actions?: CreateNotificationActionRequestModel[] | null;
  relations?: NotificationRelationModel[] | null;
  idempotencyKey: string;
};
export type MarkNotificationAsReadRequestModel = {
  notificationIds: string[];
};
export type RegisterChoiceRequestModel = {
  recipientAccountId: string;
  notificationKinds: string[];
  objectKind: string;
  objectId: string;
  notificationActionKind: string;
};
export type NotificationActionModel = {
  kind: string;
  relativePath: string;
  verb: string;
  isUsed?: boolean;
};
export type NotificationResponseModel = {
  id: string;
  kind: string;
  isRead: boolean;
  payload: any;
  recipientAccountId: string;
  recipientEmailAddress?: string | null;
  actions?: NotificationActionModel[] | null;
  createdAt: string;
};
export type GetAllNotificationsResponseModel = {
  items: NotificationResponseModel[];
};
export const {
  useCreateMutation,
  useMarkAsReadMutation,
  useRegisterChoiceMutation,
  useGetAllQuery,
  useGetV1NotificationsReceiverByNotificationIdQuery,
} = injectedRtkApi;
