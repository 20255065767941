import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { instance as msal, getAccessToken } from "src/common/auth";
import { env } from "src/env";

export const api = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: env.REACT_APP_SEARCH_SERVICE_API_URL,
		prepareHeaders: async (headers) => {
			const token = await getAccessToken(msal);
			if (!token) return headers;

			headers.set("authorization", `Bearer ${token}`);
			return headers;
		},
	}),
	endpoints: () => ({}),
	reducerPath: "api::search"
});