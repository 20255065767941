import { ChatMessage } from "@azure/communication-react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { FC, useCallback, useMemo, useState } from "react";
import { CacaoModal } from "src/modules/shared/components/CacaoModal";
import { useAppSelector } from "src/common/redux";
import { useGetChatByIdQuery, useGetCommunicationAccessTokenQuery, useGetCommunicationUserIdentityQuery } from "src/apis/chat";
import { useMediaBreakpoint, useMediaQuery } from "src/common/hooks";
import { reactions } from "../utils/reactions";
import { AccountIdReaction } from "../types";
import { ChatAccountReaction } from "./ChatAccountReaction";

interface MessageReactionsProps {
	message: ChatMessage
}
export const MessageReactions: FC<MessageReactionsProps> = ({ message }: MessageReactionsProps) => {

	const [showReactionsPanel, setShowReactionsPanel] = useState<boolean>(false);
	const focusedThreadId = useAppSelector(state => state.chat.focusedThreadId);

	const breakpoint = useMediaBreakpoint("tablet");
	const isDesktop = useMediaQuery(`(min-width :  ${breakpoint})`);

	const { data: userIdentityResponse } = useGetCommunicationUserIdentityQuery();
	const { data: chatAccessTokenResponse } = useGetCommunicationAccessTokenQuery(userIdentityResponse?.communicationUserIdentity ? {
		userIdentity: userIdentityResponse.communicationUserIdentity
	} : skipToken);
	const { data: chatThreadResponse } = useGetChatByIdQuery(focusedThreadId && chatAccessTokenResponse?.communicationAccessToken ? {
		chatId: focusedThreadId,
		"X-Chat-Access-Token": chatAccessTokenResponse?.communicationAccessToken
	} : skipToken);

	const messageReactions = useMemo(() => {
		if (!message?.metadata) {
			return [];
		}
		const availableReactions = reactions.map(r => r.command);
		return Object.values(message.metadata).filter(m => availableReactions.includes(m));
	}, [message?.metadata]);

	const reactionsCount = useMemo(() => {
		const counts: Record<string, number> = {};
		messageReactions.forEach((r) => { counts[r] = (counts[r] || 0) + 1; });
		return counts;
	}, [messageReactions]);

	const onMouseEnter = useCallback(() => {
		if (isDesktop) {
			// unfocus focused elements in order to prevent scrolling to focused element
			const el = document.querySelector(":focus");
			if (el && el instanceof HTMLElement) {
				el.blur();
			}
			setShowReactionsPanel(true);
		}
	}, [isDesktop, setShowReactionsPanel]);

	const onMouseLeave = useCallback(() => {
		isDesktop && setShowReactionsPanel(false);
	}, [isDesktop, setShowReactionsPanel]);

	const onClick = useCallback(() => {
		!isDesktop && setShowReactionsPanel(true);
	}, [isDesktop, setShowReactionsPanel]);

	const reactionsElement = useMemo(() => {
		const uniqueReactions = [...new Set(messageReactions)];
		return !!uniqueReactions?.length &&
			<ul
				className="kko-chat-message__reactions"
				onClick={onClick}
				onMouseLeave={onMouseLeave}
				onMouseEnter={onMouseEnter}
			>
				{uniqueReactions.map(r => (
					<li
						role="none"
					>
						<span>{r}</span>
						<strong>{reactionsCount[r]}</strong>
					</li>
				))}
			</ul>
	}, [messageReactions, reactionsCount, onClick, onMouseLeave, onMouseEnter]);

	const accountIdByAcsId = useCallback((acsId: string) => {
		if (!chatThreadResponse?.chatThread?.participants?.length) {
			return undefined;
		}
		return chatThreadResponse.chatThread.participants.find(p => p.chatParticipantId === acsId)?.accountId;
	}, [chatThreadResponse?.chatThread?.participants]);

	const accountIdsPerReactionsEntry = useMemo(() => {
		if (!message?.metadata) {
			return undefined;
		}
		const accountIdReactions: AccountIdReaction[] = [];
		for (const [key, value] of Object.entries(message.metadata ?? {})) {
			if (!key.includes("reaction-")) continue;

			accountIdReactions.push({
				accountId: accountIdByAcsId(key.replace("reaction-", "")),
				reaction: value
			});
		}
		return accountIdReactions.map(accountIdReaction => (
			<ChatAccountReaction accountIdReaction={accountIdReaction} />
		));
	}, [accountIdByAcsId, message?.metadata]);

	return <>
		{reactionsElement}
		<CacaoModal
			show={showReactionsPanel}
			title={undefined}
			className="kko-reactions-underlay"
			backdropClassName="kko-reactions-underlay"
			contentClassName={isDesktop ? "kko-reactions-underlay" : undefined}
			showCloseButton={!isDesktop}
			onHide={() => { !isDesktop && setShowReactionsPanel(false) }}
		>
			{accountIdsPerReactionsEntry}
		</CacaoModal>
	</>
}