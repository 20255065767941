import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { i18n } from "src/common/i18n";
import { createAppAsyncThunk } from "src/common/redux";
import {
	CalendarEventService,
	GetCalendarResponseModel,
	GetCalendarEventResponseModel,
	CreateCalendarEventRequestModel,
	UpdateCalendarEventRequestModel,
} from "src/open-api/calendarService";
import { CalendarGranularity } from "src/modules/calendar/utils/types";
import { toDateRangeForEvents } from "src/modules/calendar/utils/date-range-for-events";
import { CalendarState, RequestForEventsRefresh } from "../types";
import { showLoading as showGlobalLoading, hideLoading as hideGlobalLoading, addToast, ToastType } from "./feedbackSlice";

const initialState: CalendarState = {
	events: [],
	isLoading: false,
	calendars: undefined,
	eventsStartDate: undefined,
	eventsEndDate: undefined,
	requestForEventsRefresh: undefined
}

type FetchEventsArgs = {
	calendarId: string;
	selectedDate: Date;
	granularity: CalendarGranularity;
	forceReload?: boolean;
};

export const fetchEvents = createAppAsyncThunk(
	"calendar/fetchEvents",
	async ({ calendarId, selectedDate, granularity, forceReload }: FetchEventsArgs, { dispatch, getState }) => {
		const locale = i18n.language;

		try {
			dispatch(showGlobalLoading());

			const state = getState();
			const { startDate, endDate } = toDateRangeForEvents(selectedDate, granularity);

			if (false
				|| !state.calendar.eventsStartDate
				|| !state.calendar.eventsEndDate
				|| startDate < new Date(state.calendar.eventsStartDate)
				|| endDate > new Date(state.calendar.eventsEndDate)
				|| forceReload
			) {
				const events = await CalendarEventService.getCalendarEventByFilter(
					calendarId,
					startDate.toISOString(),
					endDate.toISOString(),
					locale
				);

				dispatch(setEvents(events.items || []));
				dispatch(setEventsStartDate(startDate.toISOString()));
				dispatch(setEventsEndDate(endDate.toISOString()));
			}
		} finally {
			dispatch(hideGlobalLoading());
		}
	});

type SaveEventArgs = {
	calendarId: string,
	event: CreateCalendarEventRequestModel & UpdateCalendarEventRequestModel,
	eventId?: string,
}

export const saveEvent = createAppAsyncThunk("calendar/save",
	async ({ calendarId, event, eventId }: SaveEventArgs, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showLoading());

			if (eventId) {
				const updateEvent: UpdateCalendarEventRequestModel = { ...event };
				await CalendarEventService.updateCalendarEvent(
					calendarId,
					eventId,
					locale,
					updateEvent
				);
			} else {
				const createEvent: CreateCalendarEventRequestModel = { ...event };
				await CalendarEventService.createCalendarEvent(
					calendarId,
					locale,
					createEvent
				);
			}
			dispatch(addToast({
				titleKey: "kko:general.feedback.save-calendarevent-title",
				contentKey: "kko:general.feedback.save-calendarevent-success",
				type: ToastType.Success,
				icon: "check"
			}));

		} catch (error) {
			dispatch(addToast({
				titleKey: "kko:general.feedback.save-calendarevent-title",
				contentKey: "kko:general.feedback.save-calendarevent-error",
				type: ToastType.Error
			}));

		} finally {
			dispatch(hideLoading());
		}
	});

type FetchEventByIdArgs = {
	calendarId: string,
	eventId: string
};

export const fetchEventById = createAppAsyncThunk(
	"calendar/fetchEvents/{id}",
	async ({ calendarId, eventId }: FetchEventByIdArgs, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showGlobalLoading());

			const event = await CalendarEventService.getByEventId(
				calendarId,
				eventId,
				locale
			);

			return event;

		} finally {
			dispatch(hideGlobalLoading());
		}
	}
);

export const calendarSlice = createSlice({
	name: "calendar",
	initialState,
	reducers: {
		setEvents: (state, action: PayloadAction<GetCalendarEventResponseModel[]>) => {
			state.events = action.payload;
		},
		setCalendars: (state, action: PayloadAction<GetCalendarResponseModel[]>) => {
			state.calendars = action.payload;
		},
		showLoading: state => {
			state.isLoading = true;
		},
		hideLoading: state => {
			state.isLoading = false;
		},
		setEventsStartDate: (state: CalendarState, action: PayloadAction<string | undefined>) => {
			state.eventsStartDate = action.payload;
		},
		setEventsEndDate: (state: CalendarState, action: PayloadAction<string | undefined>) => {
			state.eventsEndDate = action.payload;
		},
		setRequestForEventsRefresh: (state: CalendarState, action: PayloadAction<RequestForEventsRefresh | undefined>) => {
			state.requestForEventsRefresh = action.payload;
		},
	}
});

export const { setEvents, showLoading, hideLoading, setCalendars, setEventsStartDate, setEventsEndDate, setRequestForEventsRefresh } = calendarSlice.actions;
export default calendarSlice.reducer;