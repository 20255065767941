import { CalendarGranularity } from "src/modules/calendar/utils/types";
import { toDateRangeForEvents } from "src/modules/calendar/utils/date-range-for-events";
import { api, GetCalendarEventResponseModel, GetCalendarResponseModel } from "./api.generated";

export type GetCalendarsEventsArgs = {
	"Accept-Language": string;
	calendarIds: string[];
	granularity: CalendarGranularity;
	selectedDate: string;
};

export type GetCalendarsArgs = {
	"Accept-Language": string;
	calendarIds: string[];
};

const apiWithTags = api.enhanceEndpoints({ addTagTypes: ["CalendarEvent"] })

const updatedApi = apiWithTags.injectEndpoints({
	endpoints: (build) => ({
		getCalendarsEvents: build.query<GetCalendarsEventsApiResponse, GetCalendarsEventsArgs>({
			queryFn: () => ({ data: [] }),
			async onQueryStarted(arg, { updateCachedData, queryFulfilled, dispatch }) {
				await queryFulfilled;

				const calendarIds = arg.calendarIds;
				if (calendarIds.length === 0) return;

				const { startDate, endDate } = toDateRangeForEvents(
					new Date(arg.selectedDate),
					arg.granularity
				);

				const queries = calendarIds.map(calendarId => {
					const thunk = updatedApi.endpoints.getCalendarEventByFilter.initiate({
						"Accept-Language": arg["Accept-Language"],
						fromDate: startDate.toISOString(),
						toDate: endDate.toISOString(),
						calendarId
					}, { forceRefetch: true });

					const result = dispatch(thunk);
					return { result: result.unwrap(), unsubscribe: result.unsubscribe };
				});

				queries.forEach(async query => {
					const response = await query.result;

					updateCachedData((draft) => {
						draft.push(...response?.items);
					});
				});

				queries.forEach(query => query.unsubscribe());
			},
			providesTags: ["CalendarEvent"],
		}),
		getCalendars: build.query<GetCalendarsApiResponse, GetCalendarsArgs>({
			queryFn: () => ({ data: [] }),
			async onQueryStarted(arg, { updateCachedData, queryFulfilled, dispatch }) {
				await queryFulfilled;

				const calendarIds = arg.calendarIds;
				if (calendarIds.length === 0) return;

				const queries = calendarIds.map(calendarId => {
					const thunk = updatedApi.endpoints.getByCalendarId.initiate({
						"Accept-Language": arg["Accept-Language"],
						calendarId
					}, { forceRefetch: true });

					const result = dispatch(thunk);
					return { result: result.unwrap(), unsubscribe: result.unsubscribe };
				});

				queries.forEach(async query => {
					const response = await query.result;

					updateCachedData((draft) => {
						draft.push(response);
					});
				});

				queries.forEach(query => query.unsubscribe());
			},
		}),
	})
});


export type GetCalendarsEventsApiResponse =
  /** status 200 Success */ GetCalendarEventResponseModel[];

export type GetCalendarsApiResponse =
  /** status 200 Success */ GetCalendarResponseModel[];

export * from "./api.generated";
export const { useGetCalendarsEventsQuery, useGetCalendarsQuery } = updatedApi;
export { updatedApi as api };