import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    hideTimelineEvent: build.mutation<
      HideTimelineEventApiResponse,
      HideTimelineEventArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/timeline/objects/${queryArg.objectId}/events/${queryArg.eventId}/hide`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    unhideTimelineEvent: build.mutation<
      UnhideTimelineEventApiResponse,
      UnhideTimelineEventArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/timeline/objects/${queryArg.objectId}/events/${queryArg.eventId}/unhide`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    createCustomTimelineEvent: build.mutation<
      CreateCustomTimelineEventApiResponse,
      CreateCustomTimelineEventArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/timeline/custom-events`,
        method: "POST",
        body: queryArg.createCustomTimelineEventRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    deleteTimelineEvent: build.mutation<
      DeleteTimelineEventApiResponse,
      DeleteTimelineEventArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/timeline/custom-events/${queryArg.eventId}`,
        method: "DELETE",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getTimeline: build.query<GetTimelineApiResponse, GetTimelineArgs>({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/timeline`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type HideTimelineEventApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type HideTimelineEventArgs = {
  /** The ID of the episode */
  episodeId: string;
  /** The ID of the object that the event belongs to (e.g. episode, document, task). */
  objectId: string;
  /** The ID of the event to hide. */
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type UnhideTimelineEventApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type UnhideTimelineEventArgs = {
  /** The ID of the episode */
  episodeId: string;
  /** The ID of the object that the event belongs to (e.g. episode, document, task). */
  objectId: string;
  /** The ID of the event to hide. */
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type CreateCustomTimelineEventApiResponse =
  /** status 201 Created */ void;
export type CreateCustomTimelineEventArgs = {
  /** The ID of the episode. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The request model for creating a custom timeline event. */
  createCustomTimelineEventRequestModel: CreateCustomTimelineEventRequestModel;
};
export type DeleteTimelineEventApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type DeleteTimelineEventArgs = {
  /** The ID of the episode. */
  episodeId: string;
  /** The ID of the event to delete. */
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetTimelineApiResponse =
  /** status 200 Success */ GetEpisodeTimelineResponseModel;
export type GetTimelineArgs = {
  /** The ID of the episode. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type CreateCustomTimelineEventRequestModel = {
  /** The title of the custom timeline event. */
  title: string;
  /** The description of the timeline event. */
  description?: string | null;
  /** The timestamp of the timeline event. */
  timestamp: string;
};
export type TimelineEventResponseModelBase = {
  $type: string;
  /** The ID of the timeline event. */
  id: string;
  /** The ID of the object that the event belongs to (e.g. episode, document, task). */
  objectId: string;
  /** The title of the timeline event. */
  title: string;
  /** Indicates whether the timeline event is hidden from the user. */
  isHidden: boolean;
  /** The timestamp of the timeline event. In simple terms, it is the date and time when the event occurred. */
  timestamp: string;
  /** The source of the timeline event (i.e., Episode, Document, Task, etc.). */
  source?: string | null;
};
export type DocumentCreatedTimelineEventResponseModel = {
  $type: "DocumentCreatedTimelineEventResponseModel";
} & TimelineEventResponseModelBase & {
    /** The ID of the creator of the document. */
    creatorAccountId: string;
    /** The source of the timeline event. */
    source?: string | null;
  };
export type DocumentUpdatedTimelineEventResponseModel = {
  $type: "DocumentUpdatedTimelineEventResponseModel";
} & TimelineEventResponseModelBase & {
    /** The ID of the updater of the document. */
    updaterAccountId: string;
    /** The source of the timeline event. */
    source?: string | null;
  };
export type TaskMarkAsCompletedTimelineEventResponseModel = {
  $type: "TaskMarkAsCompletedTimelineEventResponseModel";
} & TimelineEventResponseModelBase & {
    /** The ID of the completer of the task. */
    completerAccountId: string;
    /** The source of the timeline event. */
    source?: string | null;
  };
export type TaskDueDateTimeSetTimelineEventResponseModel = {
  $type: "TaskDueDateTimeSetTimelineEventResponseModel";
} & TimelineEventResponseModelBase & {
    /** The ID of the setter of the task due date time. */
    source?: string | null;
  };
export type AppointmentScheduledTimelineEventResponseModel = {
  $type: "AppointmentScheduledTimelineEventResponseModel";
} & TimelineEventResponseModelBase & {
    /** The source of the timeline event. */
    source?: string | null;
  };
export type EpisodeMemberAddedTimelineEventResponseModel = {
  $type: "EpisodeMemberAddedTimelineEventResponseModel";
} & TimelineEventResponseModelBase & {
    /** The ID of invitee account. */
    inviteeAccountId: string;
    /** The source of the timeline event. */
    source?: string | null;
  };
export type EpisodeSubjectProfileInformationUpdatedTimelineEventResponseModel =
  {
    $type: "EpisodeSubjectProfileInformationUpdatedTimelineEventResponseModel";
  } & TimelineEventResponseModelBase & {
      /** The ID of the episode. */
      updaterAccountId: string;
      /** The source of the timeline event. */
      source?: string | null;
    };
export type CustomTimelineEventResponseModel = {
  $type: "CustomTimelineEventResponseModel";
} & TimelineEventResponseModelBase & {
    /** The source of the timeline event. */
    source?: string | null;
    /** The description of the timeline event. */
    description?: string | null;
    /** Indicates whether the timeline event can be deleted by the current user. */
    canDelete: boolean;
  };
export type GetEpisodeTimelineResponseModel = {
  /** The timeline events of the episode. */
  items: (
    | DocumentCreatedTimelineEventResponseModel
    | DocumentUpdatedTimelineEventResponseModel
    | TaskMarkAsCompletedTimelineEventResponseModel
    | TaskDueDateTimeSetTimelineEventResponseModel
    | AppointmentScheduledTimelineEventResponseModel
    | EpisodeMemberAddedTimelineEventResponseModel
    | EpisodeSubjectProfileInformationUpdatedTimelineEventResponseModel
    | CustomTimelineEventResponseModel
  )[];
};
export const {
  useHideTimelineEventMutation,
  useUnhideTimelineEventMutation,
  useCreateCustomTimelineEventMutation,
  useDeleteTimelineEventMutation,
  useGetTimelineQuery,
} = injectedRtkApi;
