import { useCallback, useSyncExternalStore } from "react";
import { useTranslation } from "react-i18next";

/**
 * Call the `useLocale` hook at the top level of your component to
 * retrieve the currently active i18next language as per the
 * `i18n.language` property and trigger a re-render when it changes.
 * @returns
 * The active language, as an IETF BCP 47 language tag, using
 * the `<language>[-<script>][-<region>]` format.
 */
export const useLocale = (): string => {
	const { i18n } = useTranslation();

	const subscribe = useCallback((onStoreChange: () => void): () => void => {
		const listener = () => onStoreChange();
		i18n.on("languageChanged", listener);
		return () => i18n.off("languageChanged", listener);

	}, [i18n]);

	return useSyncExternalStore(subscribe, () => i18n.language);
};