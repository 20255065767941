import type { TypedUseSelectorHook } from "react-redux";
import type { RootState, AppDispatch } from "./store";

import { createAsyncThunk } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

// Strongly typed versions of `useDispatch` and `useSelector` that should
// be used by the application.
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
	dispatch: AppDispatch,
	state: RootState
}>();