import { useEffect } from "react";
import { useEvent } from "./use-event";

/**
 * Call `useTeardown` at the top level of your component to register
 * a teardown function that executes on unmount of the component.  
 * Note that only the latest function passed to the hook, which
 * correponds to a component's last state, is executed.
 * @param teardown
 * The teardown function to execute.
 */
export const useTeardown = (teardown: () => void): void => {
	teardown = useEvent(teardown);

	useEffect(() => teardown, [teardown]);
};