import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum ToastType {
	Success,
	Error
}

export type ToastItemData = {
	key?: string;
	titleKey: string;
	contentKey: string;
	contentFormatData?: any;
	type: ToastType;
	icon?: string;
	actions?: ToastAction[];
}

export type ToastAction = {
	name: string;
	callback?: Function;
}

export type LoadingState = {
	isLoading: boolean;
}

export type FeedbackState = LoadingState & {
	readonly isLoading: boolean;
	readonly isLoadingRequestCount: number;
	readonly toasts: ToastItemData[];
}

export const initialState: FeedbackState = {
	isLoading: false,
	isLoadingRequestCount: 0,
	toasts: []
}

export const feedbackSlice = createSlice({
	name: "feedback",
	initialState,
	reducers: {
		showLoading: (state) => {
			state.isLoadingRequestCount++;
			state.isLoading = true;
		},
		hideLoading: (state) => {
			state.isLoadingRequestCount--;
			if (state.isLoadingRequestCount <= 0) {
				state.isLoadingRequestCount = 0;
				state.isLoading = false;
			}
		},
		addToast: (state, payload: PayloadAction<ToastItemData>) => {
			payload.payload.key = payload.payload.key || `t-${new Date().getTime()}`;
			state.toasts.push(payload.payload);
		},
		removeToast: (state, payload: PayloadAction<ToastItemData>) => {

			state.toasts = [...state.toasts.filter(t => t.key !== payload.payload.key)]
		}
	}
});

export const { showLoading, hideLoading, addToast, removeToast } = feedbackSlice.actions;
export default feedbackSlice.reducer;