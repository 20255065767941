import { useInsertionEffect, useRef } from "react";

/**
 * Call `useEvent` at the top level of your component to create a stable reference
 * to a function that will execute within the scope of the latest render of the component,
 * using its latest state.
 * 
 * @param callback
 * The function value for which you want to obtain a stable reference. It can take any arguments
 * and return any values.  
 * On initial render React will create and return a surrogate function to you that will always
 * delegate to the original function within the scope of the latest render of the component,
 * using its latest state. On next renders, React will continue to return that same surrogate
 * function, with stable identity.
 * @returns
 * On the initial render, `useEvent` returns a surrogate function that will always delegate
 * to the original function within the scope of the latest render. On next renders, it will
 * continue returning that same surrogate function, with stable identity.
 * 
 * **⚠️CAUTION:**  
 * The returned function **MUST NOT** be called during render!  
 * Doing so is unsafe and may give incorrect results using mismatched component state.  
 * There is no way to enforce this programmatically. Care must be taken to only call this
 * function from side effects such as `useEffect` or call it as/inside of an event handler.
 * 
 * 
 * @description
 * The `useEvent` hook can help solve performance problems when binding to events.
 * Binding a bare function creates a different function identity on each render and
 * causes events to rebind on each render.
 * 
 * ```tsx
 * const Chat: FC = () => {
 *   const [text, setText] = useState("");
 *
 *   // 🟡 Always a different function
 *   const onClick = () => {
 *     sendMessage(text);
 *   };
 *
 *   // 🟡 Rebinds `onClick` every render
 *   return <SendButton onClick={onClick} />;
 * }
 * ```
 * 
 * `useCallback` can be used to mitigate this issue and memoize a callback function, but
 * it will still return a different function identity whenever any of its dependencies
 * change, and will still cause events to rebind when they do not have to.
 * 
 * ```tsx
 * const Chat: FC = () => {
 *   const [text, setText] = useState("");
 *
 *   // 🟡 A different function whenever `text` changes
 *   const onClick = useCallback(() => {
 *     sendMessage(text);
 *   }, [text]);
 * 
 *   // 🟡 Rebinds `onClick` when `text` changes.
 *   return <SendButton onClick={onClick} />;
 * }
 * ```
 * 
 * By comparison, `useEvent` has no dependency list and returns a stable function that delegates
 * to the original function within the scope of the component's latest render, always using the
 * latest state when executing.
 * This avoids events being rebound and also allows components with events that can be
 * {@link https://react.dev/reference/react/memo | memoized} with greater efficiency, because
 * their props will no longer be subject to change whenever dependencies of callbacks change.
 * 
 * ```tsx
 * function Chat() {
 *   const [text, setText] = useState('');
 *
 *   // ✅ Always the same function, even if `text` changes; yet still the latest value is used.
 *   const onClick = useEvent(() => {
 *     sendMessage(text);
 *   });
 *
 *   // ✅ Binds `onClick` once and does not rebind.
 *   return <SendButton onClick={onClick} />;
 * }
 * ```
 */
export const useEvent = <Args extends unknown[], Return>(callback: (...args: Args) => Return) => {
	const ref = useRef(callback);

	// Avoids problems with concurrent mode.
	// Yes- useInsertionEffect states in its documentation that it 'doesn't have access to refs.'
	// However, this is a reference to DOM element refs. That is: useInsertionEffect runs before
	// things like <div ref={myRef}> have had a chance to run.
	useInsertionEffect(() => { ref.current = callback });

	const mem = useRef((...args: Args) => ref.current(...args));
	return mem.current;
}