import { FC, useCallback, useId, useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, useMatch, useSearchParams } from "react-router-dom";
import { useMediaBreakpoint, useMediaQuery } from "src/common/hooks";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { toggleSearchbox } from "src/redux/slices/core";
import { Button, PillButton } from "src/modules/shared/components/buttons";
import { Icon } from "src/modules/shared/components/Icon";


type SearchBoxProps = {};

export const SearchBox: FC<SearchBoxProps> = () => {
	const { t } = useTranslation();
	const groupId = useId();

	const dispatch = useAppDispatch();
	const expanded = useAppSelector(state => state.core.searchbox.expanded);

	// The tablet breakpoint is defined as exclusive, i.e. the breakpoint
	// value itself does not belong with tablet, but with desktop.
	// The phone breakpoint is defined as inclusive, i.e. the breakpoint
	// value itself belongs with phone, not with tablet.
	//
	// ```
	//          break                  break
	// [ phone    | ] [    tablet    ] [ |    desktop ]
	// ```

	const desktopMin = useMediaBreakpoint("tablet");
	const phoneMax = useMediaBreakpoint("phone");

	const isPhone = useMediaQuery(`(max-width : ${phoneMax})`);
	const isDesktop = useMediaQuery(`(min-width : ${desktopMin})`);

	const initialTerm = useSearchTerm();

	useLayoutEffect(() => {
		if (isDesktop) {
			dispatch(toggleSearchbox(false));
		}
	}, [dispatch, isDesktop]);

	const onToggleHandler = useCallback(() => {
		dispatch(toggleSearchbox());
	}, [dispatch]);

	const onSubmitHandler = useCallback(() => {
		// When on a constrained phone resolution, collapse the
		// search form and regain the main menu and other pill
		// buttons when a search query is submitted.
		if (!isPhone) return;
		dispatch(toggleSearchbox(false));
	}, [dispatch, isPhone]);

	return (
		<div className="kko-header__search kko-header-search">
			{!isDesktop && (
				<PillButton
					className="kko-header-search__trigger"
					label="search"
					icon="search"
					size="xlarge"
					aria-controls={groupId}
					aria-expanded={expanded}
					onClick={onToggleHandler}
				/>
			)}
			<div
				id={groupId}
				role="search"
				className="kko-header-search__form"
				hidden={(expanded || isDesktop) ? undefined : true}
			>
				<Form
					className="kko-field kko-field--justified"
					method="get"
					action="/search"
					onSubmit={onSubmitHandler}
				>
					<Icon icon="search" />
					<input
						aria-label={t("kko:general.header.search.placeholder")}
						className="kko-field__input"
						name="search"
						role="searchbox"
						inputMode="search"
						maxLength={100}
						defaultValue={initialTerm}
						placeholder={t("kko:general.header.search.placeholder")}
					/>
					<Button
						priority="tertiary"
						label="confirm"
						type="submit"
						icon={{ only: "arrow-right" }}
					/>
				</Form>
			</div>
		</div>
	);
}


const useSearchTerm = () => {
	const [params] = useSearchParams();
	const match = useMatch("/search");
	if (!match) return undefined;

	return params.get("search") || undefined;
}