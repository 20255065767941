import { FC, PropsWithChildren, ReactNode } from "react";
import { Link, To } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RelativeTime } from "src/modules/shared/components/RelativeTime";
import { Notification, NotificationBadge } from "../types";
import { NotificationActions } from "./NotificationActions";
import { NotificationAside } from "./NotificationAside";

type NotificationShellProps = PropsWithChildren<{
	notification: Notification;
	badge?: NotificationBadge;
	title?: ReactNode;
	to?: To;
}>;

export const NotificationShell: FC<NotificationShellProps> = ({ notification, title, badge, to, children }) => {
	const { t } = useTranslation();
	const { payload, actions, createdAt } = notification;

	return (
		<article
			className="kko-newnotification"
			data-unread={!notification.isRead}
		>
			<NotificationAside
				module={payload.module}
				sender={payload.sender}
				badge={badge}
			/>
			<div className="kko-newnotification__content">
				<div>
					<div className="kko-newnotification__title">{title}</div>
					<div>
						<div className="mb-8 kko-text--lower-contrast">
							{children}
						</div>
						{!!to && (
							<Link
								className="kko-newnotification__default-action"
								to={to}
							>{t("kko:pages.notifications.view")}</Link>
						)}
						{actions && (
							<div className="kko-newnotification__actions">
								<NotificationActions actions={actions} />
							</div>
						)}
					</div>
				</div>
				<RelativeTime
					className="kko-newnotification__age kko-text--lower-contrast"
					dateTime={createdAt}
					timeStyle="long"
				/>
			</div>
		</article>
	);
};
