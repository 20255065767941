import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
	if (!isObject(error)) return false;

	if (!("status" in error)) return false;

	if (typeof error.status === "number" && "data" in error) return true;
	if (typeof error.status !== "string") return false;

	switch (error.status) {
		case "CUSTOM_ERROR":
		case "FETCH_ERROR":
		case "TIMEOUT_ERROR":
			return "error" in error && typeof error.error === "string";
		case "PARSING_ERROR":
			return "originalStatus" in error
				&& "data" in error
				&& "error" in error
				&& typeof error.originalStatus === "number"
				&& typeof error.data === "string"
				&& typeof error.error === "string";
		default:
			return false;
	}
}



/**
 * An ASP.NET Web API `ProblemDetails` instance.
 */
export type ProblemDetails = {
	type?: string | null;
	title?: string | null;
	status?: number | null;
	detail?: string | null;
	instance?: string | null;
	[key: string]: any;
};

const props = {
	"type": "string",
	"title": "string",
	"status": "number",
	"detail": "string",
	"instance": "string"
} as const;

export const isProblemDetails = (data: unknown): data is ProblemDetails => {
	if (!isObject(data)) return false;

	for (const [prop, type] of Object.entries(props)) {
		if (prop in data && data[prop] != null && typeof data[prop] !== type) return false;
	}

	return true;
}

const isObject = (data: unknown): data is Record<string, unknown> => {
	return data != null && typeof data === "object";
}