import type { RouteObject } from "react-router-dom";
import { AppLayout } from "src/modules/core/components/AppLayout";
import { routes as calendarRoutes } from "src/modules/calendar/routes";
import { routes as episodeRoutes } from "src/modules/episodes/routes";
import { routes as networkRoutes } from "src/modules/network/routes";
import { routes as profileRoutes } from "src/modules/profile/routes";
import { routes as searchRoutes } from "src/modules/search/routes";
import { routes as tasksRoutes } from "src/modules/tasks/routes";
import { routes as termsRoutes } from "src/modules/terms/routes";
import { routes as styleguideRoutes } from "src/modules/styleguide/routes";

export const routes: RouteObject[] = [
	{
		path: "/",
		Component: AppLayout,
		children: [
			{
				index: true,
				lazy: async () => ({ Component: (await import("../modules/home/pages")).Home })
			},
			...calendarRoutes,
			...episodeRoutes,
			...networkRoutes,
			...profileRoutes,
			...searchRoutes,
			...tasksRoutes,
			{
				path: "*",
				lazy: async () => ({ Component: (await import("../modules/errors/pages")).NotFound })
			}],
	}, {
		path: "welcome",
		lazy: async () => ({ Component: (await import("../modules/login/Welcome")).Welcome })
	},
	...termsRoutes,
	...styleguideRoutes,
];
