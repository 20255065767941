import type { Configuration } from "@azure/msal-browser";
import { env } from "src/env";

export type Policies<Names extends Record<string, string>> = {
	[K in keyof Names]: Policy
}

export type Policy = {
	name: string,
	authority: string
};

const createPolicies = <T extends Record<string, string>>(baseAuthority: string, names: T): Policies<T> => {
	const policies = Object
		.entries(names)
		.map(([prop, name]) => [prop, { name, authority: `${baseAuthority}${name}` }]);

	return Object.fromEntries(policies);
}

export const policies = createPolicies(env.REACT_APP_MSAL_URL, {
	redeemInvite: "B2C_1A_INV_redeem",
	resetEmail: "B2C_1A_EMAILADDRESSRESET",
	resetPassword: "B2C_1A_PASSWORDRESET",
	resetPhone: "B2C_1A_PHONENUMBERRESET",
	signIn: "B2C_1_SignIn"
});

export const scopes = [
	"openid",
	`https://${env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/cacao-api/cacao.read`,
	`https://${env.REACT_APP_MSAL_TENANT_NAME}.onmicrosoft.com/cacao-api/cacao.write`
];

export const findAuthorityForPolicy = (
	policyName: string | null | undefined,
	validPolicies?: Policy[]
): string | undefined => {
	if (!policyName) return;

	policyName = policyName.toUpperCase();

	validPolicies ??= Object.values(policies);

	for (const policy of validPolicies) {
		if (policy.name.toUpperCase() === policyName)
			return policy.authority;
	}

	return undefined;
}

export const msalBaseUrl = env.REACT_APP_MSAL_URL;

export const baseMsalConfig = {
	auth: {
		clientId: env.REACT_APP_MSAL_CLIENT_ID as string,
		knownAuthorities: [env.REACT_APP_MSAL_KNOWN_AUTHORITIES as string],
		navigateToLoginRequestUrl: true,
		redirectUri: `${window.location.origin}/`
	},
	cache: {
		cacheLocation: "sessionStorage"
	},
} satisfies Configuration;
