/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateDocumentResponse } from '../models/CreateDocumentResponse';
import type { DeleteDocumentReponse } from '../models/DeleteDocumentReponse';
import type { DocumentExistsResponse } from '../models/DocumentExistsResponse';
import type { DocumentStatusModel } from '../models/DocumentStatusModel';
import type { GetAccessRightsResponseModel } from '../models/GetAccessRightsResponseModel';
import type { GetDocumentResponse } from '../models/GetDocumentResponse';
import type { GetDocumentsResponse } from '../models/GetDocumentsResponse';
import type { GetVersionsResponseModel } from '../models/GetVersionsResponseModel';
import type { InitialPermissionModel } from '../models/InitialPermissionModel';
import type { ObjectTypeModel } from '../models/ObjectTypeModel';
import type { RenameDocumentRequestModel } from '../models/RenameDocumentRequestModel';
import type { RestoreDocumentFromTrashRequestModel } from '../models/RestoreDocumentFromTrashRequestModel';
import type { RestoreDocumentToVersionRequestModel } from '../models/RestoreDocumentToVersionRequestModel';
import type { TogglePrivateStatusResponse } from '../models/TogglePrivateStatusResponse';
import type { UpdateAccessRightsForDocumentModel } from '../models/UpdateAccessRightsForDocumentModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentsService {

    /**
     * The action that retrieves information about the document for the specified id.
     * @param documentId The identifier of the document.
     * @param objectId The object id to which the document belongs to.
     * @param objectType The object type to which the document belongs to.
     * @returns GetDocumentResponse Success
     * @throws ApiError
     */
    public static getV1Documents(
        documentId: string,
        objectId: string,
        objectType: ObjectTypeModel,
    ): CancelablePromise<GetDocumentResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/documents/{documentId}',
            path: {
                'documentId': documentId,
            },
            query: {
                'objectId': objectId,
                'objectType': objectType,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that soft deletes a document
     * @param documentId The id of the document that is being deleted.
     * @param objectId The id of the object for which the documents should be retrieved.
     * @param objectType The type of the object for which the documents should be retrieved.
     * @returns DeleteDocumentReponse Accepted
     * @throws ApiError
     */
    public static deleteV1Documents(
        documentId: string,
        objectId: string,
        objectType?: ObjectTypeModel,
    ): CancelablePromise<DeleteDocumentReponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/documents/{documentId}',
            path: {
                'documentId': documentId,
            },
            query: {
                'objectId': objectId,
                'objectType': objectType,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that retrieves the content of the document with the specified id. If the version is not specified, the latest version will be retrieved.
     * @param documentId The identifier of the document.
     * @param objectId The object id to which the document belongs to.
     * @param objectType The object type to which the document belongs to.
     * @param version The version of the document that needs to be retrieved.
     * @returns binary Success
     * @throws ApiError
     */
    public static getV1DocumentsContent(
        documentId: string,
        objectId: string,
        objectType: ObjectTypeModel,
        version?: number,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/documents/{documentId}/content',
            path: {
                'documentId': documentId,
            },
            query: {
                'objectId': objectId,
                'objectType': objectType,
                'version': version,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that retrieves information about the documents for the specified object id and type.
     * @param objectId The id of the object for which the documents should be retrieved.
     * @param objectType The type of the object for which the documents should be retrieved.
     * @param documentStatus The status of the documents that should be retrieved
     * @returns GetDocumentsResponse Success
     * @throws ApiError
     */
    public static getV1Documents1(
        objectId: string,
        objectType: ObjectTypeModel,
        documentStatus?: DocumentStatusModel,
    ): CancelablePromise<GetDocumentsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/documents',
            query: {
                'objectId': objectId,
                'objectType': objectType,
                'documentStatus': documentStatus,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that creates a new document.
     * @param objectId The id of the object for which the document is created.
     * @param objectType The object type for which the document is created.
     * @param title The title of the document.
     * @param permission The initial permissions that will be assigned to the document.
     * @param formData
     * @returns CreateDocumentResponse Created
     * @throws ApiError
     */
    public static postV1Documents(
        objectId: string,
        objectType: ObjectTypeModel,
        title: string,
        permission?: InitialPermissionModel,
        formData?: {
            file?: Blob;
        },
    ): CancelablePromise<CreateDocumentResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/documents',
            query: {
                'objectId': objectId,
                'objectType': objectType,
                'title': title,
                'permission': permission,
            },
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Retrieves a collection of document versions for a specific document.
     * @param documentId The ID of the document for which versions are retrieved.
     * @param objectId The ID of the related object associated with the document.
     * @param objectType The type of the related object.
     * @returns GetVersionsResponseModel Success
     * @throws ApiError
     */
    public static getV1DocumentsVersions(
        documentId: string,
        objectId: string,
        objectType: ObjectTypeModel,
    ): CancelablePromise<GetVersionsResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/documents/{documentId}/versions',
            path: {
                'documentId': documentId,
            },
            query: {
                'objectId': objectId,
                'objectType': objectType,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Restores a document from the trash with the specified parameters.
     * @param documentId The ID of the document to be restored from the trash.
     * @param requestBody The request model containing the restoration details.
     * @returns any Created
     * @throws ApiError
     */
    public static restoreFromTrash(
        documentId: string,
        requestBody?: RestoreDocumentFromTrashRequestModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/documents/{documentId}/restore',
            path: {
                'documentId': documentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Restores a document to a specific version with the specified parameters.
     * @param documentId The ID of the document to be restored to a specific version.
     * @param version The version number to which the document should be restored.
     * @param requestBody The request model containing the restoration details.
     * @returns any Created
     * @throws ApiError
     */
    public static restoreToVersion(
        documentId: string,
        version: number,
        requestBody: RestoreDocumentToVersionRequestModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/documents/{documentId}/versions/{version}/restore',
            path: {
                'documentId': documentId,
                'version': version,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that renames a document.
     * @param documentId
     * @param requestBody The object that provides information about the document that needs to be renamed.
     * @returns any Accepted
     * @throws ApiError
     */
    public static patchV1DocumentsName(
        documentId: string,
        requestBody?: RenameDocumentRequestModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/documents/{documentId}/name',
            path: {
                'documentId': documentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that retrieves information about if a document already exists or not.
     * @param title The title of the document that is being checked.
     * @param objectId The id of the object for which the documents should be retrieved.
     * @param objectType The type of the object for which the documents should be retrieved.
     * @returns DocumentExistsResponse Success
     * @throws ApiError
     */
    public static getV1DocumentsExists(
        title: string,
        objectId: string,
        objectType: ObjectTypeModel,
    ): CancelablePromise<DocumentExistsResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/documents/exists',
            query: {
                'title': title,
                'objectId': objectId,
                'objectType': objectType,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that retrieves the access rights for a document. Only the owner of a document has the rights to access this action
     * @param documentId The id of the document for which the access rights are retrieved.
     * @param objectId The id of the object to which the document belongs to.
     * @param objectType The type of the object to which the document belongs to.
     * @returns GetAccessRightsResponseModel Success
     * @throws ApiError
     */
    public static getV1DocumentsAccessrights(
        documentId: string,
        objectId: string,
        objectType?: ObjectTypeModel,
    ): CancelablePromise<GetAccessRightsResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/documents/{documentId}/accessrights',
            path: {
                'documentId': documentId,
            },
            query: {
                'objectId': objectId,
                'objectType': objectType,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that updates the access rights for a document. Only the owner of a document has the rights to access this action
     * @param documentId The id of the document for which the access rights are updated.
     * @param requestBody The object that provides details about the access rights that need to be updated.
     * @returns any Accepted
     * @throws ApiError
     */
    public static patchV1DocumentsAccessrights(
        documentId: string,
        requestBody?: UpdateAccessRightsForDocumentModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/documents/{documentId}/accessrights',
            path: {
                'documentId': documentId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * The action that toggles the private status of a document.
     * @param documentId The id of the document for which private flag is being toggled.
     * @param objectId The id of the object to which the document belongs to.
     * @param objectType The type of the object to which the document belongs to.
     * @returns TogglePrivateStatusResponse Accepted
     * @throws ApiError
     */
    public static patchV1DocumentsPrivate(
        documentId: string,
        objectId: string,
        objectType: ObjectTypeModel,
    ): CancelablePromise<TogglePrivateStatusResponse> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/v1/documents/{documentId}/private',
            path: {
                'documentId': documentId,
            },
            query: {
                'objectId': objectId,
                'objectType': objectType,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
