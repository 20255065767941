import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ProblemDetails, isFetchBaseQueryError, isProblemDetails } from "src/common/redux";

// Will be used to selectively suppress runtime errors from
// being reported in the Webpack error overlay.
const suppress = Symbol.for("webpack::suppress");

export class RouteError extends Error {
	[suppress] = true;

	/**
	 * Converts error data into a RouteError.  
	 * RTK `FetchBaseQueryError` receives subclasses with well-typed
	 * data, in particular where the `data` represents .NET `ProblemDetails`.
	 * These errors carry a `webpack::suppress` symbol that can be used to
	 * filter them out of being shown in the development error overlay.
	 * @param error The error data to interpret as a `RouteError`.
	 */
	static from(error: unknown) {
		if (!isFetchBaseQueryError(error))
			return new RouteError(error?.toString());

		if (isProblemDetails(error.data))
			return new ProblemDetailsError(error.data);

		return new RtkQueryError(error);
	}
}

export class RtkQueryError extends RouteError {
	readonly data?: unknown;
	readonly status: FetchBaseQueryError["status"];

	constructor(error: FetchBaseQueryError) {
		super(typeof error.status !== "number" ? error.error : undefined);

		this.data = error.data;
		this.status = error.status;
	}
}

export class ProblemDetailsError extends RouteError {
	readonly problem: ProblemDetails;

	constructor(problem: ProblemDetails) {
		super(problem.title ?? undefined);
		this.problem = problem;
	}
}