import { IPublicClientApplication, PublicClientApplication } from "@azure/msal-browser";
import { baseMsalConfig } from "./config";

// Webpack doesn't guarantee singleton uniqueness of an imported module.
// To make sure everything synchronizes on the same PublicClientApplication
// instance, we have to publish it on the global context behind a symbol.
export const instance: IPublicClientApplication = singleton({
	symbol: Symbol.for("@cacao/msal"),
	guard: (value) => value instanceof PublicClientApplication,
	create: () => new PublicClientApplication(baseMsalConfig)
});


type Options<T> = {
	symbol: symbol,
	guard: (value: unknown) => boolean
	create: () => T
}

function singleton<T>({ symbol, guard, create }: Options<T>) {
	const value = (globalThis as any)[symbol];
	return guard(value)
		? value as T
		: (globalThis as any)[symbol] = create();
}

