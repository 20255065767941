export const i18nLanguageCodes = {
	English: "en-US",
	Dutch: "nl-NL"
}

export const defaultCalendarColor = "#313F87";

export const ohhIntegrationSettings = {
	storageKey: "ohhFillFormIframeUrl",
	defaultFormIFrameUrl: "https://formfiller.openhealthhub.com/user/b6a180db-3f8d-434c-8dd8-1e0df4d9a33f/program/fe59b285-5cd3-4cee-a11e-ccbf2ba726aa-16886/module?signature=ulaogRrj9Fi6m634XMYLI2rPtaoXgjtTNc5m4DJ%2f5Z8%3D&key=8nvASNUSrwPDdvZo60CugMetzBmpkJM9",
	defaultViewerImageName: "ohh-fihr-viewer.svg"
}

export const aidenIntegrationSettings = {
	storageKey: "aidenIframeUrl",
	defaultIFrameUrl: "https://aiden-pzp-demo.cacao.agorasys.nl/link/pzpdemo"
}
