import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { i18n } from "src/common/i18n";
import { createAppAsyncThunk } from "src/common/redux";
import { ProfilePhotosService, ProfilesService } from "src/open-api/networkService";
import { EpisodeResponseModel, CreateEpisodeRequestModel, EpisodesService, EpisodeMembershipResponseModel, EpisodeMembershipsService } from "src/open-api/episodeService";
import { EpisodeMemberItem, EpisodesState } from "../../modules/shared/types";
import { addToast, ToastType, showLoading as showGlobalLoading, hideLoading as hideGlobalLoading } from "./feedbackSlice";

const initialState: EpisodesState = {
	isLoading: false,
	episodes: [],
	episode: null,
	episodeMembers: []
}

type GetEpisodeMembersArgs = {
	episodeId: string
};

export const getEpisodeMembers = createAppAsyncThunk(
	"episodes/getEpisodeMembers",
	async ({ episodeId }: GetEpisodeMembersArgs, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showGlobalLoading());

			const episodeMembers: EpisodeMemberItem[] = await EpisodeMembershipsService
				.getV1EpisodeMemberships(episodeId, locale)
				.then(response => response.items ?? []);

			if (episodeMembers.length > 0) {
				await Promise.all(episodeMembers.map(async member => {
					if (!member.accountId) return;

					const profile = await ProfilesService.getProfileByAccountId(member.accountId, locale);
					if (!profile) return;

					member.firstName = profile.name.firstName;
					member.lastName = profile.name?.lastName;
					member.infix = profile.name.infix ?? "";

					if (!profile.photoInfo) return;

					try {
						const blob = await ProfilePhotosService.getV1AccountsProfilePhoto(profile.accountId, locale, "Small");
						const imageUrl = window.URL.createObjectURL(blob);
						dispatch(setEpisodeMemberImage({ accountId: profile.accountId!, imageUrl }));
					} catch (error) {
						console.error("Error for image fetch", error);
					}
				}));
			}

			dispatch(setEpisodeMembers(episodeMembers));
			return episodeMembers;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});

export const getEpisodes = createAppAsyncThunk(
	"episodes/getEpisodes",
	async (_, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showGlobalLoading());

			const result = await EpisodesService.getAllEpisodes(locale);
			const episodes = result.items ?? [];

			dispatch(setEpisodes(episodes));
			return episodes;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});

type GetEpisodesForAccountArgs = {
	accountId: string
};

export const getEpisodesForAccount = createAppAsyncThunk(
	"episodes/getEpisodesForAccount",
	async (args: GetEpisodesForAccountArgs, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showGlobalLoading());

			const result = await EpisodeMembershipsService.getV1EpisodeMembershipsAccount(
				args.accountId,
				locale
			);

			return result.episodeIds ?? [];;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});

type GetEpisodeArgs = {
	episodeId: string
};

export const getEpisode = createAppAsyncThunk(
	"episodes/getEpisode",
	async ({ episodeId }: GetEpisodeArgs, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showGlobalLoading());

			const episode = await EpisodesService.getEpisode(episodeId, locale);

			dispatch(setEpisode(episode));
			return episode;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});



export const saveEpisode = createAppAsyncThunk(
	"episodes/save",
	async (arg: CreateEpisodeRequestModel, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showLoading());

			await EpisodesService.createEpisode(locale, arg);

			dispatch(addToast({
				titleKey: "kko:general.feedback.save-episode-title",
				contentKey: "kko:general.feedback.save-episode-success",
				type: ToastType.Success,
				icon: "check"
			}));

		} catch {
			dispatch(addToast({
				titleKey: "kko:general.feedback.save-episode-title",
				contentKey: "kko:general.feedback.save-episode-error",
				type: ToastType.Error
			}));

		} finally {
			dispatch(hideLoading());
		}
	});

export const episodeSlice = createSlice({
	name: "episode",
	initialState,
	reducers: {
		showLoading: state => {
			state.isLoading = true;
		},
		hideLoading: state => {
			state.isLoading = false;
		},
		setEpisodes: (state, payload: PayloadAction<EpisodeResponseModel[]>) => {
			state.episodes = payload.payload;
		},
		setEpisode: (state, payload: PayloadAction<EpisodeResponseModel>) => {
			state.episode = payload.payload;
		},
		setEpisodeMembers: (state, payload: PayloadAction<EpisodeMembershipResponseModel[]>) => {
			state.episodeMembers = payload.payload;
		},
		setEpisodeMemberImage: (state, payload: PayloadAction<{ accountId: string, imageUrl: string }>) => {
			const matchedEpisodeMemberIndex = state.episodeMembers
				.findIndex(em => em.accountId === payload.payload.accountId);

			if (matchedEpisodeMemberIndex >= 0) {
				state.episodeMembers[matchedEpisodeMemberIndex].imageUrl = payload.payload.imageUrl;
			}
		}
	}
});

export const { showLoading, hideLoading, setEpisodes, setEpisode, setEpisodeMembers, setEpisodeMemberImage } = episodeSlice.actions;
export default episodeSlice.reducer;