import { configureStore } from "@reduxjs/toolkit";

import menuSlice from "src/redux/slices/menuSlice";
import profileSlice from "src/redux/slices/profileSlice";
import feedbackSlice from "src/redux/slices/feedbackSlice";
import preferencesSlice from "src/redux/slices/preferencesSlice";
import episodeSlice from "src/redux/slices/episodeSlice";
import calendarSlice from "src/redux/slices/calendarSlice";
import notificationSlice from "src/redux/slices/notificationsSlice";
import documentsSlice from "src/redux/slices/documentsSlice";
import chatSlice from "src/redux/slices/chatsSlice";

import { slice as core } from "src/redux/slices/core";

import { api as accountApi } from "src/apis/account";
import { api as calendarApi } from "src/apis/calendar";
import { api as documentApi } from "src/apis/document";
import { api as emailApi } from "src/apis/email";
import { api as episodeApi } from "src/apis/episode";
import { api as localizationApi } from "src/apis/localization";
import { api as networkApi } from "src/apis/network";
import { api as notificationApi } from "src/apis/notification";
import { api as preferencesApi } from "src/apis/preferences";
import { api as chatApi } from "src/apis/chat";
import { api as tasksApi } from "src/apis/tasks";
import { api as timelineApi } from "src/apis/timeline";
import { api as searchApi } from "src/apis/search";

export const store = configureStore({
	reducer: {
		// `api::`-prefixed API slice reducers.
		[accountApi.reducerPath]: accountApi.reducer,
		[calendarApi.reducerPath]: calendarApi.reducer,
		[documentApi.reducerPath]: documentApi.reducer,
		[emailApi.reducerPath]: emailApi.reducer,
		[episodeApi.reducerPath]: episodeApi.reducer,
		[localizationApi.reducerPath]: localizationApi.reducer,
		[networkApi.reducerPath]: networkApi.reducer,
		[notificationApi.reducerPath]: notificationApi.reducer,
		[preferencesApi.reducerPath]: preferencesApi.reducer,
		[chatApi.reducerPath]: chatApi.reducer,
		[tasksApi.reducerPath]: tasksApi.reducer,
		[timelineApi.reducerPath]: timelineApi.reducer,
		[searchApi.reducerPath]: searchApi.reducer,

		// regular slice reducers
		[core.name]: core.reducer,
		menu: menuSlice,
		profile: profileSlice,
		feedback: feedbackSlice,
		preferences: preferencesSlice,
		episode: episodeSlice,
		calendar: calendarSlice,
		notification: notificationSlice,
		document: documentsSlice,
		chat: chatSlice
	},

	middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
		accountApi.middleware,
		calendarApi.middleware,
		documentApi.middleware,
		emailApi.middleware,
		episodeApi.middleware,
		localizationApi.middleware,
		networkApi.middleware,
		notificationApi.middleware,
		preferencesApi.middleware,
		chatApi.middleware,
		tasksApi.middleware,
		timelineApi.middleware,
		searchApi.middleware
	)
});

// Infer the `RootState` and `AppDispatch` types from the store itself.
// Remove the api:: slices from the type definition. We treat them as
// private. They should _not_ be accessed via the state.
export type RootState = Omit<ReturnType<typeof store.getState>, `api::${string}`>;
export type AppDispatch = typeof store.dispatch;