import classNames from "classnames";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "src/common/redux";
import { focusChat } from "src/redux/slices/chatsSlice";
import { useUnreadMessages } from "../hooks/use-unread-messages";

type NavTabBarProps = {
	className?: string;
	selected: "one-on-one" | "episode";
	onChangeTab: (tab: "one-on-one" | "episode") => void;
}

export const NavTabBar: FC<NavTabBarProps> = ({ className, onChangeTab, selected }) => {

	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const { unreadOneOnOneMessages, unreadChannelMessages } = useUnreadMessages();

	const changeTab = (tab: "one-on-one" | "episode") => {
		onChangeTab(tab);
		dispatch(focusChat(undefined));
	}

	return (
		<div className={classNames("kko-tab-navbar", className)}>
			<div className="kko-tab-navbar__navigation">
				<ul>
					<li>
						<a
							{...selected === "one-on-one" && { "aria-current": "page" }}
							onClick={() => { changeTab("one-on-one") }}
						>
							{t("kko:pages.chats.navigation.tabs.one-on-one")}
							{!!unreadOneOnOneMessages && (
								<div className="kko-number-badge">
									<i aria-hidden="true">{unreadOneOnOneMessages}</i>
								</div>
							)}
						</a>
					</li>
					<li>
						<a
							{...selected === "episode" && { "aria-current": "page" }}
							onClick={() => { changeTab("episode") }}
						>
							{t("kko:pages.chats.navigation.tabs.episode")}
							{!!unreadChannelMessages && (
								<div className="kko-number-badge">
									<i aria-hidden="true">{unreadChannelMessages}</i>
								</div>
							)}
						</a>
					</li>
				</ul>
			</div>
		</div >
	);
};