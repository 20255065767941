import { Dispatch, Reducer, ReducerAction, ReducerState, useEffect, useReducer, useRef } from "react";

type ScanState<S, A> = [state: S, prevState: S | undefined, action: A | undefined];

type ScanReducer<R extends Reducer<any, any>>
	= Reducer<ScanState<ReducerState<R>, ReducerAction<R>>, ReducerAction<R>>;

function withScan<R extends Reducer<any, any>>(reducer: R): Reducer<
	ScanState<ReducerState<R>, ReducerAction<R>>, ReducerAction<R>
> {
	return (prevScan, action) => {
		const [prevState] = prevScan;
		const state = reducer(prevState, action);

		return (Object.is(state, prevState))
			? prevScan
			: [state, prevState, action];
	}
}

/**
 * A listener function that receives the reducer's state, previous state, and action
 * which caused the change.
 * Can be used for side-effectful responses to state changes, such as deciding to
 * emit an 'event' from a component only on certain kinds of interaction.
 */
export type ReducerListener<R extends Reducer<any, any>> = (
	state: ReducerState<R>,
	prevState: ReducerState<R> | undefined,
	action: ReducerAction<R>
) => void;

/**
 * A function that lets you register a listener for when the reducer
 * state changes. Listeners receive the state, previous state and action that caused
 * the change.
 */
export type Listen<L> = (callback: L) => void;

// NOTE:
// The following interface replicates the overload type declarations
// for React's own `useReducer` to ensure type-ahead autocompletion
// actually functions as expected.
// These cannot be written as normal overloaded function signatures
// with the final one being the implementation signature, because
// the TypeScript compiler judges the typings to be incompatible.
// Presumably because it doesn't know how to generate the JavaScript
// code for the overload resolution.
// So instead, we create a private function with a wider signature
// and we pass it through the narrowed overload declarations shaped
// as a call interface.

interface UseListeningReducer {
	/**
	 * Manages the state of a component with a reducer, like React's own
	 * {@linkcode https://react.dev/reference/react/useReducer | useReducer}.
	 * But adds the ability to register listeners when state has changed.
	 * 
	 * Listeners can be used for side-effectful responses to state changes,
	 * such as deciding to emit an 'event' from a component only on
	 * certain kinds of interaction.
	 * 
	 * @param reducer
	 * The reducer function that specifies how the state gets updated.
	 * It must be pure, should take the state and action as arguments, and
	 * should return the next state. State and action can be of any types.
	 * @param initializerArg
	 * The value from which the initial state is calculated. It can be a
	 * value of any type. How the initial state is calculated from it
	 * depends on the next `initializer` argument.
	 * @param initializer
	 * The initializer function that should return the initial state.
	 * The initial state is set to the result of calling `initializer(initializerArg)`.
	 * 
	 * @returns
	 * An array tuple with three values:
	 * 1. The current state.
	 *    During the first render, it's set to `initializer(initializerArg)`.
	 * 2. The {@link https://react.dev/reference/react/useReducer#dispatch | dispatch function}
	 *    that lets you update the state to a different value and trigger a re-render.
	 * 3. A listen function that lets you register a listener for when the reducer
	 *    state changes. Listeners receive the state, previous state and action that caused
	 *    the change.
	 *    
	 *    **⚠️ IMPORTANT:**  
	 *    Listeners have to be re-added each time the functional component executes.
	 *    This is the most simple to understand model which guarantees they always execute
	 *    against the most recent closure scope and overall state of the component.
	 */
	<R extends Reducer<any, any>, I>(
		reducer: R,
		initializerArg: I & ReducerState<R>,
		initializer: (arg: I & ReducerState<R>) => ReducerState<R>
	): [ReducerState<R>, Dispatch<ReducerAction<R>>, Listen<ReducerListener<R>>];

	/**
	 * Manages the state of a component with a reducer, like React's own
	 * {@linkcode https://react.dev/reference/react/useReducer | useReducer}.
	 * But adds the ability to register listeners when state has changed.
	 * 
	 * Listeners can be used for side-effectful responses to state changes,
	 * such as deciding to emit an 'event' from a component only on
	 * certain kinds of interaction.
	 * 
	 * @param reducer
	 * The reducer function that specifies how the state gets updated.
	 * It must be pure, should take the state and action as arguments, and
	 * should return the next state. State and action can be of any types.
	 * @param initializerArg
	 * The value from which the initial state is calculated. It can be a
	 * value of any type. How the initial state is calculated from it
	 * depends on the next `initializer` argument.
	 * @param initializer
	 * The initializer function that should return the initial state.
	 * The initial state is set to the result of calling `initializer(initializerArg)`.
	 * 
	 * @returns
	 * An array tuple with three values:
	 * 1. The current state.
	 *    During the first render, it's set to `initializer(initializerArg)`.
	 * 2. The {@link https://react.dev/reference/react/useReducer#dispatch | dispatch function}
	 *    that lets you update the state to a different value and trigger a re-render.
	 * 3. A listen function that lets you register a listener for when the reducer
	 *    state changes. Listeners receive the state, previous state and action that caused
	 *    the change.
	 *    
	 *    **⚠️ IMPORTANT:**  
	 *    Listeners have to be re-added each time the functional component executes.
	 *    This is the most simple to understand model which guarantees they always execute
	 *    against the most recent closure scope and overall state of the component.
	 */
	<R extends Reducer<any, any>, I>(
		reducer: R,
		initializerArg: I,
		initializer: (arg: I) => ReducerState<R>
	): [ReducerState<R>, Dispatch<ReducerAction<R>>, Listen<ReducerListener<R>>];

	/**
	 * Manages the state of a component with a reducer, like React's own
	 * {@linkcode https://react.dev/reference/react/useReducer | useReducer}.
	 * But adds the ability to register listeners when state has changed.
	 * 
	 * Listeners can be used for side-effectful responses to state changes,
	 * such as deciding to emit an 'event' from a component only on
	 * certain kinds of interaction.
	 * 
	 * @param reducer
	 * The reducer function that specifies how the state gets updated.
	 * It must be pure, should take the state and action as arguments, and
	 * should return the next state. State and action can be of any types.
	 * @param initialState
	 * The initial state the reducer is set to.
	 * 
	 * @returns
	 * An array tuple with three values:
	 * 1. The current state.
	 *    During the first render, it's set to `initialState`.
	 * 2. The {@link https://react.dev/reference/react/useReducer#dispatch | dispatch function}
	 *    that lets you update the state to a different value and trigger a re-render.
	 * 3. A listen function that lets you register a listener for when the reducer
	 *    state changes. Listeners receive the state, previous state and action that caused
	 *    the change.
	 *    
	 *    **⚠️ IMPORTANT:**  
	 *    Listeners have to be re-added each time the functional component executes.
	 *    This is the most simple to understand model which guarantees they always execute
	 *    against the most recent closure scope and overall state of the component.
	 */
	<R extends Reducer<any, any>>(
		reducer: R,
		initialState: ReducerState<R>
	): [ReducerState<R>, Dispatch<ReducerAction<R>>, Listen<ReducerListener<R>>];
}

/**
 * Manages the state of a component with a reducer, like React's own
 * {@linkcode https://react.dev/reference/react/useReducer | useReducer}.
 * But adds the ability to register listeners when state has changed.
 * 
 * Listeners can be used for side-effectful responses to state changes,
 * such as deciding to emit an 'event' from a component only on
 * certain kinds of interaction.
 */
export const useListeningReducer = (<R extends Reducer<any, any>, I>(
	reducer: R,
	initializerArg: I,
	initializer?: (arg: I) => ReducerState<R>
): [ReducerState<R>, Dispatch<ReducerAction<R>>, Listen<ReducerListener<R>>] => {

	// NOTE:
	// The `as` coercion in the following is safe, because the only overload
	// defined in the `UseListeningReducer` call interface where `initialize`
	// can be undefined, is the one where `initializerArg` is in fact
	// of type `ReducerState<R>`.
	const [[state, prevState, action], dispatch] = useReducer<ScanReducer<R>, I>(
		withScan(reducer),
		initializerArg,
		arg => initializer
			? [initializer(arg), undefined, undefined]
			: [arg as ReducerState<R>, undefined, undefined]
	);

	// Listeners are stored in a ref object so we can keep connected to it
	// under `useEffect`.
	// Just as hooks have to be re-attached each pass, we do the same with
	// the listeners. So we clear this ref-ed set each turn.
	const ref = useRef(new Set<ReducerListener<R>>());
	ref.current.clear();

	const listen = (callback: ReducerListener<R>) => {
		ref.current.add(callback);
		return () => ref.current.delete(callback);
	};

	useEffect(() => {
		if (action === undefined || Object.is(state, prevState)) return;
		for (const listener of ref.current) {
			listener(state, prevState, action);
		}
	}, [state, prevState, action]);

	return [state, dispatch, listen];

}) as UseListeningReducer;