import { DependencyList, Key, RefCallback, useMemo, useCallback, useRef, EffectCallback } from "react";

export interface KeyRef<T> {
	/**
	 * Creates a keyed ref callback that will associate a
	 * {@link https://react.dev/learn/manipulating-the-dom-with-refs | ref}
	 * with the map returned by a `useKeyRefs` hook.
	 * @param key
	 * The key with which the created ref callback will be registered
	 * to the map returned by the `useKeyRefs` hook.
	 * @returns
	 * The ref callback.
	 */
	(key: Key): RefCallback<T>;
}

/**
 * A readonly map of values associated with a `useKeyRefs` hook.
 */
export type RefMap<T> = ReadonlyMap<Key, T>;

/**
 * Call `useKeyRefs` at the top level of your component to declare a map
 * of {@link https://react.dev/learn/manipulating-the-dom-with-refs | refs}
 * by key.  
 * This hook is meant to be used to obtain refs to a list of rendered
 * DOM elements where the size of the list is not predefined and thus
 * a deterministic number of individual `useRef` hooks cannot be used.
 *
 * @returns
 * `useKeyRefs` returns an array tuple with exactly two values:
 * 1. The readonly map of refs. Initially this will be empty.
 * 2. A factory function that takes a {@linkcode Key} and produces a
 *    {@link https://react.dev/reference/react-dom/components/common#ref-callback ref callback}
 *    function. If you pass the ref callback to React as a `ref` attribute
 *    to a JSX node, React will add the passed value to the map returned
 *    by the hook.
 *
 * @example
 * ```tsx
 * type Item = { id: string, value: string };
 * type Props = { items : readonly Item[] };
 *
 * const MyComponent : FC<Props> = ({ items }) => {
 *   const [refs, keyRef] = useKeyRefs();
 * 
 *   useEffect(() => {
 *     for (const item of items) {
 *       const element = refs.get(item.id);
 *       // ... use the DOM element for something ...
 *     }
 *   }, [items]);
 * 
 *   return (
 *     <ul>
 *       {items.map(item => (
 *         <li key={item.id} ref={keyRef(item.id)}>{item.value}</li>
 *       ))}
 *     </ul>
 *   );
 * };
 * ```
 */
export const useKeyRefs = <T = Element>() => {
	const refs = useRef(new Map<Key, T>());

	const keyRef = useCallback<KeyRef<T>>((key: Key) => {
		const { current: map } = refs;

		return (element: T | null) => {
			if (element == null) {
				map.delete(key);
			} else {
				map.set(key, element);
			}
		}
	}, []);

	return [refs.current as RefMap<T>, keyRef] as const;
};

export type KeyRefEffectCallback<T> =
	(instance: T, key: Key) => ReturnType<EffectCallback>;

export const useKeyRefsEffect = <T = Element>(
	effect: KeyRefEffectCallback<T>,
	deps: DependencyList
) => {
	const refs = useRef(new Map<Key, RefCallback<T>>());

	const keyRef = useMemo<KeyRef<T>>(() => {
		// When deps have changed and we return a new keyRef callback
		// all registered callbacks should tear down and reinitialize.
		const { current: map } = refs;

		if (map.size !== 0) {
			for (const callback of map.values()) {
				callback(null);
			}
			map.clear();
		}

		return (key: Key) => {
			const found = map.get(key);
			if (found) return found;

			let cleanup: ReturnType<KeyRefEffectCallback<T>> = undefined;
			const callback = (element: T) => {
				cleanup?.();

				// If the element is null, we delete the callback
				// from the map. It may have gone away to not return
				// and we do not want to leak memory.
				if (element == null) {
					cleanup = undefined;
					map.delete(key);
					return;
				}

				cleanup = effect(element, key);
			};

			map.set(key, callback);
			return callback;
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);

	return keyRef;
}