import Modal from "react-bootstrap/Modal";
import classNames from "classnames";
import { FC, PropsWithChildren, ReactNode, useCallback } from "react";
import { Button } from "./buttons/Button";

type ModalSize = "sm" | "lg" | "xl";

export type CacaoModalProps = PropsWithChildren<{
	show: boolean;
	size?: ModalSize;
	action?: CacaoModalAction;
	title: ReactNode;
	footer?: ReactNode;
	backdrop?: boolean;
	enforceFocus?: boolean;
	showCloseButton?: boolean;
	className?: string;
	backdropClassName?: string;
	contentClassName?: string;
	onHide?: () => void;
}>;

export type CacaoModalAction = {
	text: string;
	isDisabled?: boolean;
	isLoading?: boolean;
	onClick?: () => void;
}

export const CacaoModal: FC<CacaoModalProps> = ({
	show,
	size,
	action,
	title,
	footer,
	children,
	backdrop = true,
	enforceFocus = true,
	showCloseButton = true,
	className,
	backdropClassName,
	contentClassName,
	onHide,
}: CacaoModalProps) => {

	// Don't close the modal if we're hitting escape inside a Mobiscroll picker.
	// This event does not belong to react-bootstrap's Modal.
	const onEscapeHandler = useCallback((event: KeyboardEvent) => {
		const { target } = event;
		if (target instanceof Element && target.closest(".mbsc-picker") != null) {
			event.preventDefault();
		}
	}, []);

	if (!show) return null;

	return (
		<Modal
			centered
			show={show}
			size={size}
			backdrop={backdrop}
			backdropClassName={backdropClassName}
			contentClassName={classNames("kko-modal__content", contentClassName)}
			dialogClassName={classNames("kko-modal", className)}
			enforceFocus={enforceFocus}
			onEscapeKeyDown={onEscapeHandler}
			onHide={onHide}
		>
			<Modal.Header closeButton={showCloseButton}>
				<Modal.Title>{title}</Modal.Title>
				{action && (
					<Button
						priority="primary"
						disabled={action?.isDisabled}
						label={action.text}
						data-loading={action.isLoading}
						onClick={action.onClick}
					/>
				)}
			</Modal.Header>
			<Modal.Body>{children}</Modal.Body>
			<Modal.Footer>{footer}</Modal.Footer>
		</Modal>
	);
}