import { hideLoading, showLoading } from "src/redux/slices/feedbackSlice";
import { api } from "./api.generated";

const enhancedApi = api.enhanceEndpoints({
	addTagTypes: ["Task"],
	endpoints: {
		getAllTasks: {
			providesTags: [{ type: "Task", id: "LIST" }],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				dispatch(showLoading());
				try {
					await queryFulfilled;
				} finally {
					dispatch(hideLoading());
				}
			}
		},
		getTaskDetailsById: {
			providesTags: (response, error, args) => [{ type: "Task", id: args.taskId }]
		},
		createTask: {
			invalidatesTags: [{ type: "Task", id: "LIST" }]
		},
		// Some of the invalidations are performed manually in CreateOrEditTask and TaskDetails, 
		// because RTK Query does not handle multiple
		// invalidations in rapid succession where a previous request is still mid-flight.
		// See: https://github.com/reduxjs/redux-toolkit/issues/2444
		deleteTask: {
			invalidatesTags: (response, error, args) => [
				{ type: "Task", id: args.taskId },
				{ type: "Task", id: "LIST" }
			]
		}
	}
});

export * from "./api.generated";
export { enhancedApi as api };
