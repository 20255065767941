import { useMsal } from "@azure/msal-react"

type PlatformAccount = {
	/** The logged in user's account name. */
	accountName?: string;

	/** The logged in user's Cacao platform account ID. */
	accountId?: string;
}

/**
 * Returns the logged in user's account name and Cacao platform
 * account ID from Msal.
 * @returns An object containing the account name and account ID.
 */
export const usePlatformAccount = (): PlatformAccount => {
	const { instance: msal } = useMsal();

	const accountInfo = msal.getActiveAccount();
	if (!accountInfo) return {};

	const claim = accountInfo.idTokenClaims?.["extension_CacaoAccountId"];

	return {
		accountName: accountInfo.name,
		accountId: typeof claim === "string" ? claim : undefined
	};
}

