import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import qs from "qs";
import { instance as msal, getAccessToken } from "src/common/auth";
import { env } from "src/env";

export const api = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: env.REACT_APP_TASKS_SERVICE_API_URL,
		paramsSerializer: (params) => qs.stringify(params, { arrayFormat: "repeat" }),
		prepareHeaders: async (headers) => {
			const token = await getAccessToken(msal);
			if (!token) return headers;

			headers.set("authorization", `Bearer ${token}`);
			return headers;
		},
	}),
	endpoints: () => ({}),
	reducerPath: "api::tasks"
});