import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    invitationEmail: build.mutation<
      InvitationEmailApiResponse,
      InvitationEmailArgs
    >({
      query: (queryArg) => ({
        url: `/v1/InvitationEmails`,
        method: "POST",
        body: queryArg.invitationEmailRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type InvitationEmailApiResponse =
  /** status 200 Success */ InvitationEmailResponseModel;
export type InvitationEmailArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The object that provides details about the invitation email that needs to be sent. */
  invitationEmailRequestModel: InvitationEmailRequestModel;
};
export type InvitationEmailResponseModel = {
  /** The flag that specifies if the email was sent. */
  isSuccess?: boolean;
};
export type BadRequestResult = {
  statusCode?: number;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type InvitationEmailRequestModel = {
  /** Gets the invitation link that should be used for redeeming the invite. */
  redeemLink: string;
  /** Gets the email address where the invitation should be sent. */
  emailAddress: string;
};
export const { useInvitationEmailMutation } = injectedRtkApi;
