import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { ChatsPillIndicator } from "src/modules/chat/components/ChatsPillIndicator";
import { ChatsOverview } from "src/modules/chat/pages/ChatsOverview";
import { toggleSidemenu } from "src/redux/slices/core";
import { NotificationsOverview } from "src/modules/notification/pages/NotificationsOverview";
import { NotificationsPillIndicator } from "src/modules/notification/components/NotificationsPillIndicator";
import { ActionButton } from "src/modules/shared/components/buttons";
import { hideNotifications } from "src/redux/slices/notificationsSlice";
import { useLightDismiss } from "src/common/hooks/use-light-dismiss";
import { hideChats } from "src/redux/slices/chatsSlice";
import { AccountMenu } from "./AccountMenu";
import { SearchBox } from "./Searchbox";

const Header: FC = () => {
	const { t } = useTranslation();

	const dispatch = useAppDispatch();
	const isMenuExpanded = useAppSelector(state => state.core.sidemenu.expanded);

	const onToggleMenuHandler = useCallback(() => {
		dispatch(toggleSidemenu());
	}, [dispatch]);

	const headerNotificationsPopoverActive = useAppSelector(state => state.notification.show);
	const headerChatsPopoverActive = useAppSelector(state => state.chat.show);

	const excludeNotificationsRef = useLightDismiss(() => dispatch(hideNotifications()));
	const excludeChatsRef = useLightDismiss(() => dispatch(hideChats()));

	return (
		<div className="kko-header">
			<div className="kko-header__mobile-nav">
				{/*
					TODO:
					This is missing aria-controls and it has no way of properly
					forwarding keyboard focus to the expanded menu.
					Needs to be redone.
				*/}
				<ActionButton
					priority="tertiary"
					icon={{ only: isMenuExpanded ? "close" : "menu-hamburger" }}
					label={t("kko:general.header.show-menu")}
					aria-expanded={isMenuExpanded}
					aria-haspopup="true"
					onClick={onToggleMenuHandler}
				/>
			</div>
			<SearchBox />
			<div className="kko-header__notifications">
				<ChatsPillIndicator ref={excludeChatsRef("chats-opener")} />
				<NotificationsPillIndicator ref={excludeNotificationsRef("notifications-opener")} />
			</div>
			{headerNotificationsPopoverActive && (
				<div className="kko-header__popover">
					<div ref={excludeNotificationsRef("notifications-panel")} className="kko-notifications-popover">
						<NotificationsOverview />
					</div>
				</div>
			)}
			{headerChatsPopoverActive && (
				<div className="kko-header__popover">
					<ChatsOverview ref={excludeChatsRef("chats-panel")} />
				</div>
			)}
			<AccountMenu />
		</div>
	)
}

export default Header;
