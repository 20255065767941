import { useMsal } from "@azure/msal-react";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { policies, scopes } from "src/common/auth";
import Logo from "../../Assets/images/logo-login.svg";

import "../../Assets/styles/cacao/modules/login.scss";
import { Button } from "../shared/components/buttons";

export const Login: FC = () => {
	const { t } = useTranslation()
	const { instance: msalInstance } = useMsal();

	const signIn = useCallback(() => {
		msalInstance.loginRedirect({
			authority: policies.signIn.authority,
			prompt: "login",
			scopes,
		})
	}, [msalInstance]);

	return (
		<div className="kko-page kko-page--login">
			<div className="kko-login-header">
				<div className="kko-login-header__logo">
					<img src={Logo} alt="CACAO logo"></img>
				</div>
				<div className="kko-login-header__page-title">
					{t("kko:pages.home.title")}
				</div>
			</div>
			<div className="kko-login">
				<div className="kko-login__content">
					<div className="kko-login__inside-content">
						<div className="kko-login-welcome">
							<p className="">{t("kko:pages.home.disclaimer")}</p>
							<p className="">{t("kko:pages.home.description")}</p>
						</div>
					</div>
				</div>
				<div className="kko-login__footer">
					<div className="kko-login__actions">
						<Button
							priority="primary"
							size="large"
							label={t("kko:pages.home.buttons.sign-in")}
							onClick={signIn}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}