import type { RouteObject } from "react-router-dom";

export const routes: RouteObject[] = [{
	path: "account",
	lazy: async () => ({ Component: (await import("./pages")).Layout }),
	children: [{
		path: "profile",
		children: [{
			index: true,
			lazy: async () => ({ Component: (await import("./pages")).ViewProfile }),
		}, {
			path: "edit",
			lazy: async () => ({ Component: (await import("./pages")).EditProfile }),
		}]
	}, {
		path: "security",
		lazy: async () => ({ Component: (await import("./pages")).EditSecurity }),
	}, {
		path: "settings",
		lazy: async () => ({ Component: (await import("./pages")).Settings })
	}]
}];
