import { skipToken } from "@reduxjs/toolkit/query/react";
import { useMemo } from "react";
import {
	useGetDisplayNameByAccountIdQuery,
	useGetProfileByAccountIdQuery,
	useGetProfileByCurrentUserAccountIdQuery,
	useGetV1AccountsByAccountIdProfilePhotoQuery
} from "src/apis/network";
import { useLocale } from "src/common/hooks/use-locale";
import { getFullName } from "../utils/getFullName";

type ProfileDetailsArgs = {
	accountId: string | null | undefined;
}

/**
 * @deprecated
 * Use the individual RTK Query hooks directly.
 * - `useGetProfileByAccountIdQuery` to obtain the full profile.
 * - `useGetDisplayNameByAccountIdQuery` to obtain just the profile display name.
 * - `useGetV1AccountIdProfilePhotoQuery` to obtain the profile photo.
 */
export function useProfileDetails({ accountId }: ProfileDetailsArgs) {
	const locale = useLocale();

	const { data: profile } = useGetProfileByAccountIdQuery((accountId ? {
		"Accept-Language": locale,
		accountId: accountId
	} : skipToken));

	const { currentData: imageUrl } = useGetV1AccountsByAccountIdProfilePhotoQuery((accountId ? {
		"Accept-Language": locale,
		accountId: accountId
	} : skipToken));

	let displayName = useMemo(() => {
		const name = profile?.name;
		return name ? getFullName(name) : "";
	}, [profile]);

	let avatarName = useMemo(() => {
		const name = profile?.name;
		return name ? getFullName({ firstName: name.firstName, lastName: name.lastName }) : "";
	}, [profile]);

	const { data: backendDisplayName } = useGetDisplayNameByAccountIdQuery(accountId && !(displayName && avatarName) ? {
		"Accept-Language": locale,
		accountId: accountId
	} : skipToken);

	displayName = displayName || backendDisplayName?.displayName || "";
	avatarName = avatarName || backendDisplayName?.displayName || "";

	return [profile, imageUrl, displayName, avatarName] as const;
}

/**
 * @deprecated
 * Use the individual RTK Query hooks directly.
 * - `useGetProfileByAccountIdQuery` to obtain the full profile.
 * - `useGetDisplayNameByAccountIdQuery` to obtain just the profile display name.
 * - `useGetV1AccountIdProfilePhotoQuery` to obtain the profile photo.
 * 
 * The current user's account ID can be obtained with the `usePlatformAccount` hook.
 */
export function useCurrentProfileDetails() {
	const locale = useLocale();

	const { data: profile } = useGetProfileByCurrentUserAccountIdQuery({
		"Accept-Language": locale
	});

	const { currentData: imageUrl } = useGetV1AccountsByAccountIdProfilePhotoQuery(!!profile && !!profile.accountId
		? {
			"Accept-Language": locale,
			accountId: profile?.accountId
		}
		: skipToken
	);

	const displayName = useMemo(() => {
		const name = profile?.name;
		return name ? getFullName(name) : "";
	}, [profile]);

	const avatarName = useMemo(() => {
		const name = profile?.name;
		return name ? getFullName({ firstName: name.firstName, lastName: name.lastName }) : "";
	}, [profile]);

	return [profile, imageUrl, displayName, avatarName] as const;
}