import type { RouteObject } from "react-router-dom";
import { RouteErrorBoundary } from "../core/components/errors";

export const routes: RouteObject[] = [{
	path: "network/contacts",
	lazy: async () => ({
		Component: (await import("./pages")).Contacts,
	}),
	children: [{
		path: ":id",
		children: [{
			index: true,
			errorElement: <RouteErrorBoundary type="motivator" />,
			lazy: async () => ({ Component: (await import("./pages")).ViewContact })
		}, {
			path: "edit",
			errorElement: <RouteErrorBoundary type="motivator" />,
			lazy: async () => ({ Component: (await import("./pages")).EditContact })
		}]
	}, {
		path: "add",
		lazy: async () => ({ Component: (await import("./pages")).AddContact })
	}]
}, {
	path: "network/invites-sent",
	lazy: async () => ({ Component: (await import("./pages")).InvitationsSent }),
}, {
	path: "network/invites-received",
	lazy: async () => ({ Component: (await import("./pages")).InvitationsReceived }),
}];
