/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvitationStatusResponseModel } from '../models/InvitationStatusResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvitationsService {

    /**
     * The method that establishes if the invitation with the specified identifier is active.
     * @param acceptLanguage The culture code used for the request.
     * @param identifier The identifier of the invitation that is being checked.
     * @returns InvitationStatusResponseModel Success
     * @throws ApiError
     */
    public static isActive(
        acceptLanguage: string,
        identifier?: string,
    ): CancelablePromise<InvitationStatusResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/Invitations/IsActive',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'identifier': identifier,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
