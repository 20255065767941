import { hideLoading, showLoading } from "src/redux/slices/feedbackSlice";
import { api } from "./api.generated";
export * from "./api.generated";

api.enhanceEndpoints({
	addTagTypes: ["TimelineEvent"],
	endpoints: {
		getTimeline: {
			providesTags: [{ type: "TimelineEvent", id: "LIST" }],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				dispatch(showLoading());
				await queryFulfilled.catch(() => { });
				dispatch(hideLoading());
			}
		},
		createCustomTimelineEvent: {
			invalidatesTags: [{ type: "TimelineEvent", id: "LIST" }]
		},
		deleteTimelineEvent: {
			invalidatesTags: [{ type: "TimelineEvent", id: "LIST" }]
		},
		hideTimelineEvent: {
			invalidatesTags: [{ type: "TimelineEvent", id: "LIST" }]
		},
		unhideTimelineEvent: {
			invalidatesTags: [{ type: "TimelineEvent", id: "LIST" }]
		}
	}
});
