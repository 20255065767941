import { skipToken } from "@reduxjs/toolkit/query/react";
import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { To, generatePath } from "react-router-dom";
import { useGetMultipleDisplayNamesByAccountIdsQuery } from "src/apis/network";
import { useLocale } from "src/common/hooks";
import { NotificationBadge, TaskRelatedNotification } from "../types"
import { NotificationShell } from "./NotificationShell";

type TaskNotificationProps = {
	notification: TaskRelatedNotification
}

export const TaskNotification: FC<TaskNotificationProps> = ({ notification }) => {
	const { t } = useTranslation();
	const locale = useLocale();

	const { payload, kind } = notification;

	const { currentData: assigneeNames } = useGetMultipleDisplayNamesByAccountIdsQuery((
		payload.assignees?.length
			? {
				"Accept-Language": locale,
				accountIds: payload.assignees
			}
			: skipToken
	), {
		refetchOnMountOrArgChange: true,
		selectFromResult: ({ currentData }) => ({ currentData: currentData?.items ?? [] })
	});

	const title = useMemo<ReactNode>(() => {
		switch (kind) {
			case "TaskCreated":
				return t("kko:pages.notifications.new-task-created.text", {
					taskName: payload.title
				});

			case "TaskAssigneesUpdated":
				return t("kko:pages.notifications.task-assignees-updated.text", {
					taskName: payload.title,
					assignees: assigneeNames.map(name => name.displayName)
				});

			case "TaskAssigneeAdded":
				return t("kko:pages.notifications.task-assigned.text", {
					taskName: payload.title
				});

			case "TaskInformationUpdated":
				return t("kko:pages.notifications.task-information-updated.text", {
					taskName: payload.title
				});

			case "TaskMarkedAsCompleted":
				return t("kko:pages.notifications.task-completed.text", {
					taskName: payload.title
				});

			case "TaskReminder":
				return t("kko:pages.notifications.task-reminder.text", {
					taskName: payload.title
				});

			default:
				return null;
		}
	}, [assigneeNames, kind, payload.title, t]);

	const badge = useMemo<NotificationBadge | undefined>(() => {
		switch (kind) {
			case "TaskReminder":
				return { kind: "danger", icon: "bell-fill" };
			case "TaskMarkedAsCompleted":
				return "success";
			default:
				return undefined;
		}
	}, [kind]);

	const to = useMemo<To>(() => {
		return payload.taskId
			? generatePath("/tasks/:taskId", { taskId: payload.taskId })
			: "/calendar"
	}, [payload.taskId]);

	return (
		<NotificationShell
			notification={notification}
			badge={badge}
			title={title}
			to={to}
		>
			{payload.sender?.displayName}
			{!!payload.episodeTitle && (
				<>
					{!!payload.sender?.displayName && <span aria-hidden="true"> · </span>}
					{payload.episodeTitle}
					{!!payload.episodeSubjectDisplayName && ` (${payload.episodeSubjectDisplayName})`}
				</>
			)}
		</NotificationShell>
	)
};
