import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getByCalendarId: build.query<
      GetByCalendarIdApiResponse,
      GetByCalendarIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    updateCalendar: build.mutation<
      UpdateCalendarApiResponse,
      UpdateCalendarArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}`,
        method: "PUT",
        body: queryArg.updateCalendarRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    deleteCalendar: build.mutation<
      DeleteCalendarApiResponse,
      DeleteCalendarArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}`,
        method: "DELETE",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getCalendarByFilter: build.query<
      GetCalendarByFilterApiResponse,
      GetCalendarByFilterArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/account/${queryArg.accountId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    createCalendar: build.mutation<
      CreateCalendarApiResponse,
      CreateCalendarArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars`,
        method: "POST",
        body: queryArg.createCalendarRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getCalendarEventByFilter: build.query<
      GetCalendarEventByFilterApiResponse,
      GetCalendarEventByFilterArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/events`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { fromDate: queryArg.fromDate, toDate: queryArg.toDate },
      }),
    }),
    createCalendarEvent: build.mutation<
      CreateCalendarEventApiResponse,
      CreateCalendarEventArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/events`,
        method: "POST",
        body: queryArg.createCalendarEventRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getByEventId: build.query<GetByEventIdApiResponse, GetByEventIdArgs>({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/events/${queryArg.eventId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    updateCalendarEvent: build.mutation<
      UpdateCalendarEventApiResponse,
      UpdateCalendarEventArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/events/${queryArg.eventId}`,
        method: "PUT",
        body: queryArg.updateCalendarEventRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    deleteCalendarEvent: build.mutation<
      DeleteCalendarEventApiResponse,
      DeleteCalendarEventArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/events/${queryArg.eventId}`,
        method: "DELETE",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    accept: build.mutation<AcceptApiResponse, AcceptArgs>({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/events/${queryArg.eventId}/accept`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    decline: build.mutation<DeclineApiResponse, DeclineArgs>({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/events/${queryArg.eventId}/decline`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getExternalCalendarByFilter: build.query<
      GetExternalCalendarByFilterApiResponse,
      GetExternalCalendarByFilterArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/settings/${queryArg.accountId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    bulkExternalCalendar: build.mutation<
      BulkExternalCalendarApiResponse,
      BulkExternalCalendarArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/settings/${queryArg.accountId}`,
        method: "POST",
        body: queryArg.updateBulkExternalCalendarsRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getCurrentInternetCalendar: build.query<
      GetCurrentInternetCalendarApiResponse,
      GetCurrentInternetCalendarArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/internet-calendars/key`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    resetCurrentInternetCalendar: build.mutation<
      ResetCurrentInternetCalendarApiResponse,
      ResetCurrentInternetCalendarArgs
    >({
      query: (queryArg) => ({
        url: `/v1/calendars/${queryArg.calendarId}/internet-calendars/key`,
        method: "DELETE",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetByCalendarIdApiResponse =
  /** status 200 Success */ GetCalendarResponseModel;
export type GetByCalendarIdArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type UpdateCalendarApiResponse = /** status 201 Created */
  | UpdateCalendarResponseModel
  | /** status 204 No Content */ UpdateCalendarResponseModel;
export type UpdateCalendarArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  updateCalendarRequestModel: UpdateCalendarRequestModel;
};
export type DeleteCalendarApiResponse =
  /** status 202 Accepted */ DeleteCalendarResponseModel | /** status 204 No Content */ void;
export type DeleteCalendarArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetCalendarByFilterApiResponse =
  /** status 200 Success */ GetCalendarsResponseModel;
export type GetCalendarByFilterArgs = {
  /** Account identifier */
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type CreateCalendarApiResponse =
  /** status 201 Created */ CreateCalendarResponseModel;
export type CreateCalendarArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  createCalendarRequestModel: CreateCalendarRequestModel;
};
export type GetCalendarEventByFilterApiResponse =
  /** status 200 Success */ GetCalendarEventsResponseModel;
export type GetCalendarEventByFilterArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** Lower limit of date time range filter. Should be in ISO 8601 format (YYYY-MM-DD). */
  fromDate: string;
  /** Upper limit of date time range filter. Should be in ISO 8601 format (YYYY-MM-DD). */
  toDate: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type CreateCalendarEventApiResponse =
  /** status 201 Created */ CreateCalendarEventResponseModel;
export type CreateCalendarEventArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** Event details */
  createCalendarEventRequestModel: CreateCalendarEventRequestModel;
};
export type GetByEventIdApiResponse =
  /** status 200 Success */ GetCalendarEventResponseModel;
export type GetByEventIdArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** Event identifier */
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type UpdateCalendarEventApiResponse =
  /** status 201 Created */ void | /** status 204 No Content */ ActionResult;
export type UpdateCalendarEventArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** Event identifier */
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** Event details */
  updateCalendarEventRequestModel: UpdateCalendarEventRequestModel;
};
export type DeleteCalendarEventApiResponse =
  /** status 202 Accepted */ DeleteCalendarEventResponseModel | /** status 204 No Content */ void;
export type DeleteCalendarEventArgs = {
  /** Calendar identifier */
  calendarId: string;
  /** Event identifier */
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type AcceptApiResponse = /** status 202 Accepted */
  | ActionResult
  | /** status 204 No Content */ ActionResult;
export type AcceptArgs = {
  calendarId: string;
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type DeclineApiResponse = /** status 202 Accepted */
  | ActionResult
  | /** status 204 No Content */ ActionResult;
export type DeclineArgs = {
  calendarId: string;
  eventId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetExternalCalendarByFilterApiResponse =
  /** status 200 Success */ GetCalendarsResponseModel;
export type GetExternalCalendarByFilterArgs = {
  /** Account identifier */
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type BulkExternalCalendarApiResponse =
  /** status 201 Created */ BulkCalendarResponseModel;
export type BulkExternalCalendarArgs = {
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  updateBulkExternalCalendarsRequestModel: UpdateBulkExternalCalendarsRequestModel;
};
export type GetCurrentInternetCalendarApiResponse =
  /** status 200 Success */ GetCurrentInternetCalendarResponse;
export type GetCurrentInternetCalendarArgs = {
  /** The calendar ID. */
  calendarId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type ResetCurrentInternetCalendarApiResponse =
  /** status 202 Accepted */ IActionResult | /** status 204 No Content */ void;
export type ResetCurrentInternetCalendarArgs = {
  /** The calendar ID. */
  calendarId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetCalendarResponseModel = {
  /** Identifier of the calendar. */
  id?: string | null;
  /** The account id linked to the calendar. */
  accountId: string;
  /** Title of the calendar. */
  title: string;
  /** Description of the calendar. */
  description?: string | null;
  /** Geographic location of the calendar as free-form text. */
  location?: string | null;
  /** The time zone of the calendar. (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) */
  timeZone?: string | null;
  /** The color with which the calendar can be presented in the UI */
  color?: string | null;
  /** True if the calendar represents an external calendar */
  isExternalCalendar?: boolean;
  /** The location of an external calendar file */
  externalCalendarLocation?: string | null;
  /** The tag of the entity. */
  etag?: string | null;
  /** The permissions which apply on this calendar for the requesting account */
  permissions?: string[] | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type UpdateCalendarResponseModel = {
  /** Identifier of the calendar. */
  id?: string | null;
  /** The account id linked to the calendar. */
  accountId: string;
  /** Title of the calendar. */
  title: string;
  /** Description of the calendar. */
  description?: string | null;
  /** Geographic location of the calendar as free-form text. */
  location?: string | null;
  /** The time zone of the calendar. (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) */
  timeZone?: string | null;
  /** The color with which the calendar can be presented in the UI */
  color?: string | null;
  /** True if the calendar represents an external calendar */
  isExternalCalendar?: boolean;
  /** The location of an external calendar file */
  externalCalendarLocation?: string | null;
  /** The tag of the entity. */
  etag?: string | null;
};
export type UpdateCalendarRequestModel = {
  /** Title of the calendar. */
  title: string;
  /** Description of the calendar. */
  description?: string | null;
  /** Geographic location of the calendar as free-form text. */
  location?: string | null;
  /** The time zone of the calendar. (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) */
  timeZone?: string | null;
  /** The color with which the calendar can be presented in the UI */
  color?: string | null;
  /** The location of an external calendar file */
  externalCalendarLocation?: string | null;
  /** The tag of the entity. */
  etag?: string | null;
};
export type DeleteCalendarResponseModel = {
  /** The flag that specifies if the operation was a success. */
  isSuccess?: boolean;
};
export type GetCalendarsResponseModel = {
  /** The list of items in the response. */
  items?: GetCalendarResponseModel[] | null;
};
export type CreateCalendarResponseModel = {
  /** The ID of the calendar */
  id: string;
};
export type CreateCalendarRequestModel = {
  /** Title of the calendar. */
  title: string;
  /** Description of the calendar. */
  description?: string | null;
  /** Geographic location of the calendar as free-form text. */
  location?: string | null;
  /** The time zone of the calendar. (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) */
  timeZone?: string | null;
  /** The color with which the calendar can be presented in the UI */
  color?: string | null;
  /** The location of an external calendar file */
  externalCalendarLocation?: string | null;
  /** The account id linked to the calendar. */
  accountId: string;
};
export type OrganizerModel = {
  /** The account identifier of the organizer. */
  accountId?: string | null;
  /** The common name which is set for a non-cacao organizer */
  commonName?: string | null;
};
export type EpisodeModel = {
  /** The id of the episode related */
  id?: string;
  /** The name of the episode related */
  name?: string | null;
  /** The id of the episode subject related */
  subjectId?: string | null;
};
export type ResponseStatusResponseModel =
  | "NeedsAction"
  | "Declined"
  | "Tentative"
  | "Accepted"
  | "Delegated";
export type AttendeeResponseModel = {
  /** The account identifier of the attendee. */
  accountId?: string | null;
  /** The common name which is set for non-cacao attendees */
  commonName?: string | null;
  responseStatus?: ResponseStatusResponseModel;
};
export type EventTypeModel =
  | "Default"
  | "OutOfOffice"
  | "FocusTime"
  | "WorkingLocation";
export type EventStatusModel =
  | "Default"
  | "Accepted"
  | "Declined"
  | "Tentative";
export type EventVisibilityModel = "Default" | "Public" | "Private";
export type GetCalendarEventResponseModel = {
  /** Identifier of the Event */
  id?: string;
  /** Identifier of the Calendar */
  calendarId: string;
  /** Identifier which can be used to link multiple instances of the same appointment over calendars */
  uniqueMeetingId: string;
  /** Creation time of the event. */
  created?: string | null;
  /** The (inclusive) start time of the event. For a recurring event, this is the start time of the first instance. */
  start: string;
  /** The (exclusive) end time of the event. For a recurring event, this is the end time of the first instance. */
  end: string;
  /** Indication if the calendar event is an all day event */
  isAllDay?: boolean;
  /** Title of the event. */
  title?: string | null;
  /** Description of the event. Can contain HTML */
  description?: string | null;
  /** Geographic location of the event as free-form text */
  location?: string | null;
  organizer?: OrganizerModel;
  episode?: EpisodeModel;
  /** The attendees of the event. */
  attendees?: AttendeeResponseModel[] | null;
  /** List of RRULE, EXRULE, RDATE and EXDATE lines for a recurring event, as specified in RFC5545.
    Note that DTSTART and DTEND lines are not allowed in this field; event start and end times are specified in the start and end fields.
    This field is omitted for single events or instances of recurring events. */
  recurrence?: string[] | null;
  /** List of lines for a recurrence exceptions */
  recurrenceExceptions?: string[] | null;
  /** List of date times for recurrence exceptions */
  recurrenceExceptionDates?: string[] | null;
  eventType: EventTypeModel;
  status?: EventStatusModel;
  visibility?: EventVisibilityModel;
  /** The tag of the entity. */
  etag?: string | null;
  /** The account id the events belong to */
  accountId: string;
  /** The permissions which apply on this calendar event for the requesting account */
  permissions: string[];
};
export type GetCalendarEventsResponseModel = {
  /** The list of items in the response. */
  items: GetCalendarEventResponseModel[];
};
export type CreateCalendarEventResponseModel = {
  /** The ID of the calendar */
  calendarId: string;
  /** The ID of the calendar event */
  id: string;
};
export type AttendeeRequestModel = {
  /** The account identifier of the attendee. */
  accountId: string;
};
export type CreateCalendarEventRequestModel = {
  /** The (inclusive) start time of the event. For a recurring event, this is the start time of the first instance. */
  start: string;
  /** The (exclusive) end time of the event. For a recurring event, this is the end time of the first instance. */
  end: string;
  /** Title of the event. */
  title: string;
  /** Description of the event. Can contain HTML */
  description?: string | null;
  /** Geographic location of the event as free-form text */
  location?: string | null;
  episode?: EpisodeModel;
  /** The attendees of the event. */
  attendees?: AttendeeRequestModel[] | null;
  /** List of RRULE, EXRULE, RDATE and EXDATE lines for a recurring event, as specified in RFC5545.
    Note that DTSTART and DTEND lines are not allowed in this field; event start and end times are specified in the start and end fields.
    This field is omitted for single events or instances of recurring events. */
  recurrence?: string[] | null;
  eventType: EventTypeModel;
  visibility?: EventVisibilityModel;
  /** The organizer of the event. */
  organizerAccountId: string;
};
export type ActionResult = object;
export type UpdateCalendarEventRequestModel = {
  /** The (inclusive) start time of the event. For a recurring event, this is the start time of the first instance. */
  start: string;
  /** The (exclusive) end time of the event. For a recurring event, this is the end time of the first instance. */
  end: string;
  /** Title of the event. */
  title: string;
  /** Description of the event. Can contain HTML */
  description?: string | null;
  /** Geographic location of the event as free-form text */
  location?: string | null;
  episode?: EpisodeModel;
  /** The attendees of the event. */
  attendees?: AttendeeRequestModel[] | null;
  /** List of RRULE, EXRULE, RDATE and EXDATE lines for a recurring event, as specified in RFC5545.
    Note that DTSTART and DTEND lines are not allowed in this field; event start and end times are specified in the start and end fields.
    This field is omitted for single events or instances of recurring events. */
  recurrence?: string[] | null;
  eventType: EventTypeModel;
  visibility?: EventVisibilityModel;
  /** The tag of the entity. */
  etag: string;
};
export type DeleteCalendarEventResponseModel = {
  /** The flag that specifies if the operation was a success. */
  isSuccess?: boolean;
};
export type BulkCalendarResponseModel = {
  /** The newly created calendar ids */
  createdCalendarIds?: string[] | null;
  /** The updated calendar ids */
  updatedCalendarIds?: string[] | null;
  /** The deleted calendar ids */
  deletedCalendarIds?: string[] | null;
  /** The exceptions which occurred during the bulk processing */
  exceptions?: string[] | null;
};
export type BulkCalendarRequestModel = {
  /** Title of the calendar. */
  title: string;
  /** Description of the calendar. */
  description?: string | null;
  /** Geographic location of the calendar as free-form text. */
  location?: string | null;
  /** The time zone of the calendar. (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) */
  timeZone?: string | null;
  /** The color with which the calendar can be presented in the UI */
  color?: string | null;
  /** The location of an external calendar file */
  externalCalendarLocation?: string | null;
  /** The id of the calendar. Needed for updating a calendar */
  id?: string | null;
  /** The account id linked to the calendar. Needed for creating a calendar */
  accountId: string;
  /** The tag of the entity. Needed for updating a calendar. */
  etag?: string | null;
};
export type UpdateBulkExternalCalendarsRequestModel = {
  /** Collection of calendars to create, update or delete */
  items: BulkCalendarRequestModel[];
};
export type GetCurrentInternetCalendarResponse = {
  /** Gets or sets the identifier of the iCalendar. */
  key: string;
};
export type IActionResult = object;
export const {
  useGetByCalendarIdQuery,
  useUpdateCalendarMutation,
  useDeleteCalendarMutation,
  useGetCalendarByFilterQuery,
  useCreateCalendarMutation,
  useGetCalendarEventByFilterQuery,
  useCreateCalendarEventMutation,
  useGetByEventIdQuery,
  useUpdateCalendarEventMutation,
  useDeleteCalendarEventMutation,
  useAcceptMutation,
  useDeclineMutation,
  useGetExternalCalendarByFilterQuery,
  useBulkExternalCalendarMutation,
  useGetCurrentInternetCalendarQuery,
  useResetCurrentInternetCalendarMutation,
} = injectedRtkApi;
