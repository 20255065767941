import { useMsal } from "@azure/msal-react";
import { getClaim } from "../claims";

export type AuthenticatedUserInfo = {
	accountId?: string;
}

const UserClaims = {
	ACCOUNT_ID: "extension_CacaoAccountId",
	FALLBACK_ACCOUNT_ID: "sub"
}

/**
 * @deprecated Use the `usePlatformAccount` hook instead.
 */
export const useAuthenticatedUserInfo = (): AuthenticatedUserInfo => {
	const { instance } = useMsal();
	const activeAccount = instance.getActiveAccount();

	return {
		accountId: undefined
			|| getClaim<string>(activeAccount, UserClaims.ACCOUNT_ID)
			|| getClaim<string>(activeAccount, UserClaims.FALLBACK_ACCOUNT_ID)
	};
}

