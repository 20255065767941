import { isFetchBaseQueryError } from "src/common/redux";
import { hideLoading, showLoading } from "src/redux/slices/feedbackSlice";
import { GetEpisodeIdsForAccountResponseModel, api } from "./api.generated";

const updatedApi = api.injectEndpoints({
	endpoints: (build) => ({
		getEpisodeMembershipAccountByAccountIds: build.query<
			GetV1EpisodeMembershipAccountByAccountIdsApiResponse,
			GetV1EpisodeMembershipAccountByAccountIdsArgs
		>({
			queryFn: async (arg, { dispatch, signal, forced }) => {
				const results = arg.accountIds.map(accountId => {
					const result = dispatch(api.endpoints.getV1EpisodeMembershipsAccountByAccountId.initiate({
						"Accept-Language": arg["Accept-Language"],
						accountId
					}, { forceRefetch: forced }));

					signal.addEventListener("abort", () => {
						result.abort();
					})

					return result;
				});

				const unsubscribe = () => {
					for (const result of results) {
						result.unsubscribe();
					}
				}

				try {
					const responses = await Promise.all(results.map(result => result.unwrap()));
					const episodeIds = responses.reduce((previous, current) => {
						return previous == null
							? new Set(current.episodeIds ?? [])
							: new Set(current.episodeIds?.filter(id => previous.has(id)) ?? []);
					}, null as Set<string> | null);

					return {
						data: { episodeIds: episodeIds?.size ? [...episodeIds] : [] },
						meta: { unsubscribe }
					};

				} catch (error) {
					return isFetchBaseQueryError(error)
						? { error, meta: { unsubscribe } }
						: {
							error: { status: "CUSTOM_ERROR", data: error, error: "Composite query error" },
							meta: { unsubscribe }
						};
				}
			},

			onCacheEntryAdded: async (arg, { cacheEntryRemoved, cacheDataLoaded }) => {
				const { meta } = await cacheDataLoaded;
				await cacheEntryRemoved;

				(meta as any).unsubscribe();
			}
		})
	}),
});

export type GetV1EpisodeMembershipAccountByAccountIdsArgs = {
	"Accept-Language": string;
	accountIds: string[];
};
export type GetV1EpisodeMembershipAccountByAccountIdsApiResponse =
/** status 200 Success */ GetEpisodeIdsForAccountResponseModel;

export * from "./api.generated";
export const {
	useGetEpisodeMembershipAccountByAccountIdsQuery,
	useLazyGetEpisodeMembershipAccountByAccountIdsQuery
} = updatedApi;


const enhancedApi = updatedApi.enhanceEndpoints({
	addTagTypes: [
		"Episode",
		"PendingInvites",
		"RequestedInvites",
		"ReceivedInvites",
		"SentInvites"
	],
	endpoints: {
		getAllEpisodes: {
			providesTags: [{ type: "Episode", id: "LIST" }],
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				dispatch(showLoading());
				await queryFulfilled.catch(() => { });
				dispatch(hideLoading());
			}
		},

		getEpisode: {
			providesTags: (response, error, args) => [{ type: "Episode", id: args.episodeId }]
		},

		createEpisode: {
			invalidatesTags: [{ type: "Episode", id: "LIST" }]
		},

		updateEpisode: {
			invalidatesTags: (response, error, args) => [
				{ type: "Episode", id: args.episodeId },
				{ type: "Episode", id: "LIST" }
			]
		},

		getReceivedEpisodeInvitations: {
			providesTags: [{ type: "ReceivedInvites", id: "LIST" }]
		},
		getSentEpisodeInvitations: {
			providesTags: [{ type: "SentInvites", id: "LIST" }]
		},
		getPendingEpisodeInvitationsByEpisodeId: {
			providesTags: (response, error, args) => [
				{ type: "PendingInvites", id: "LIST" },
				{ type: "PendingInvites", id: args.episodeId },
			]
		},
		createEpisodeInvitationForKnownUser: {
			invalidatesTags: [
				{ type: "SentInvites", id: "LIST" },
				{ type: "PendingInvites", id: "LIST" }
			]
		},
		createEpisodeInvitationForUnknownUser: {
			invalidatesTags: [
				{ type: "SentInvites", id: "LIST" },
				{ type: "PendingInvites", id: "LIST" }
			]
		},
		acceptEpisodeInvitation: {
			invalidatesTags: [
				{ type: "ReceivedInvites", id: "LIST" },
				{ type: "PendingInvites", id: "LIST" }
			]
		},
		declineEpisodeInvitation: {
			invalidatesTags: [
				{ type: "ReceivedInvites", id: "LIST" },
				{ type: "PendingInvites", id: "LIST" }
			]
		},
		revokeEpisodeInvitation: {
			invalidatesTags: [
				{ type: "SentInvites", id: "LIST" },
				{ type: "PendingInvites", id: "LIST" }
			]
		},

		getPendingInvitationRequests: {
			providesTags: (response, error, args) => [{ type: "RequestedInvites", id: args.episodeId }]
		},
		createEpisodeInvitationRequest: {
			invalidatesTags: (response, error, args) => [{ type: "RequestedInvites", id: args.episodeId }]
		},
		acceptEpisodeInvitationRequest: {
			invalidatesTags: (response, error, args) => [{ type: "RequestedInvites", id: args.episodeId }]
		},
		declineEpisodeInvitationRequest: {
			invalidatesTags: (response, error, args) => [{ type: "RequestedInvites", id: args.episodeId }]
		}
	}
});

export { enhancedApi as api };