import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getV1ResourcesAll: build.query<
      GetV1ResourcesAllApiResponse,
      GetV1ResourcesAllArgs
    >({
      query: (queryArg) => ({
        url: `/v1/Resources/All`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetV1ResourcesAllApiResponse = /** status 200 Success */ {
  [key: string]: string;
};
export type GetV1ResourcesAllArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export const { useGetV1ResourcesAllQuery } = injectedRtkApi;
