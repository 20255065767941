
export const env = {
	// Base
	REACT_APP_BASE_PATH: process.env.REACT_APP_BASE_PATH,
	REACT_APP_ENV: process.env.REACT_APP_ENV,

	// MSAL
	REACT_APP_MSAL_CLIENT_ID: process.env.REACT_APP_MSAL_CLIENT_ID,
	REACT_APP_MSAL_URL: process.env.REACT_APP_MSAL_URL,
	REACT_APP_MSAL_AUTHORITY_SIGNIN: process.env.REACT_APP_MSAL_AUTHORITY_SIGNIN,
	REACT_APP_MSAL_AUTHORITY_SIGNUP: process.env.REACT_APP_MSAL_AUTHORITY_SIGNUP,
	REACT_APP_MSAL_KNOWN_AUTHORITIES: process.env.REACT_APP_MSAL_KNOWN_AUTHORITIES,
	REACT_APP_MSAL_TENANT_NAME: process.env.REACT_APP_MSAL_TENANT_NAME,

	// Application Insights
	REACT_APP_AI_CLOUD_ROLE_NAME: process.env.REACT_APP_AI_CLOUD_ROLE_NAME,
	REACT_APP_AI_INSTRUMENTATION_KEY: process.env.REACT_APP_AI_INSTRUMENTATION_KEY,

	// OpenAPI Cacao Services
	REACT_APP_EMAIL_SERVICE_API_URL: process.env.REACT_APP_EMAIL_SERVICE_API_URL,
	REACT_APP_ACCOUNT_SERVICE_API_URL: process.env.REACT_APP_ACCOUNT_SERVICE_API_URL,
	REACT_APP_LOCALIZATION_SERVICE_API_URL: process.env.REACT_APP_LOCALIZATION_SERVICE_API_URL,
	REACT_APP_NETWORK_SERVICE_API_URL: process.env.REACT_APP_NETWORK_SERVICE_API_URL,
	REACT_APP_PREFERENCES_SERVICE_API_URL: process.env.REACT_APP_PREFERENCES_SERVICE_API_URL,
	REACT_APP_EPISODE_SERVICE_API_URL: process.env.REACT_APP_EPISODE_SERVICE_API_URL,
	REACT_APP_NOTIFICATION_SERVICE_API_URL: process.env.REACT_APP_NOTIFICATION_SERVICE_API_URL,
	REACT_APP_CALENDAR_SERVICE_API_URL: process.env.REACT_APP_CALENDAR_SERVICE_API_URL,
	REACT_APP_DOCUMENT_SERVICE_API_URL: process.env.REACT_APP_DOCUMENT_SERVICE_API_URL,
	REACT_APP_CHAT_SERVICE_API_URL: process.env.REACT_APP_CHAT_SERVICE_API_URL,
	REACT_APP_SEARCH_SERVICE_API_URL: process.env.REACT_APP_SEARCH_SERVICE_API_URL,
	REACT_APP_TASKS_SERVICE_API_URL: process.env.REACT_APP_TASKS_SERVICE_API_URL,
	REACT_APP_TIMELINE_SERVICE_API_URL: process.env.REACT_APP_TIMELINE_SERVICE_API_URL,
	REACT_APP_DOCUMENT_WOPI_SERVICE_API_URL: process.env.REACT_APP_DOCUMENT_WOPI_SERVICE_API_URL,
	REACT_APP_COLLABORA_SERVER_URL: process.env.REACT_APP_COLLABORA_SERVER_URL,

	// Azure Communication Service
	REACT_APP_COMMUNICATION_SERVICE_ENDPOINT: process.env.REACT_APP_COMMUNICATION_SERVICE_ENDPOINT
} as const satisfies Record<string, string>;