import { useEffect } from "react";
import { useKeyRefs } from "./use-key-refs";
import { useEvent } from "./use-event";

const options = { capture: true, passive: true };

export const useLightDismiss = <T extends Element = Element>(callback: (event: MouseEvent) => void) => {
	callback = useEvent(callback);
	const [refs, keyRef] = useKeyRefs<T>();

	useEffect(() => {
		const listener = (event: MouseEvent) => {
			// Ignore any injected events that aren't setting a correct
			// Node instance that is contained in the document.
			if (!(event.target instanceof Node) || !document.contains(event.target)) return;

			// Ignore any events on modals
			const modalElements = document.querySelectorAll("[role=dialog]");
			for (const modal of modalElements) {
				if (modal.contains(event.target)) {
					return;
				}
			}

			for (const other of refs.values()) {
				if (other.contains(event.target)) return;
			}

			callback(event);
		};

		document.addEventListener("click", listener, options);
		return () => { document.removeEventListener("click", listener, options) };
	}, [callback, refs]);

	return keyRef;
};