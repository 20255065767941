import type { ComponentPropsWithoutRef, ForwardedRef, PropsWithChildren } from "react";
import { forwardRef } from "react";
import classNames from "classnames";
import { Icon } from "../Icon";

// NOTE:
// The following type definition uses some tricks with union
// types, intersection types, and the `never` type to create
// a type that requires either an `only` icon name, which may
// be paired with neither `left` nor `right; or _at least_
// one of `left` or `right`.
// The way the union works out is the properties themselves
// always exist on the unified type, meaning the null propagation
// operator can simply be used in code without needing the `in`
// operator to narrow the type.


/**
 * Configures the optional icon for a `<Button>` component.
 */
export type ButtonIcon = {
	/**
	 * Configures an icon for the button to show as
	 * an icon-only button. When specified the button
	 * will not display any further content or label.
	 * The label will instead be made accessible via
	 * an `aria-label` instead.
	 * 
	 * When specifying an `only` icon, you may not
	 * specify a `left` or `right` icon.
	 */
	only: string;

	/**
	 * Configures an icon for the button to show on the
	 * left-hand side of the button content or label.
	 * 
	 * When specifying a `left` icon, you may also
	 * specify a `right` icon but not an `only` icon.
	 */
	left?: never;

	/**
	 * Configures an icon for the button to show on the
	 * right-hand side of the button content or label.
	 * 
	 * When specifying a `right` icon, you may also
	 * specify a `left` icon but not an `only` icon.
	 */
	right?: never;
} | ({
	only?: never;
	left?: string;
	right?: string;
} & ({ left: string } | { right: string }));


export type ButtonPriority = "primary" | "secondary" | "tertiary";
export type ButtonSize = "medium" | "large" | "xlarge";

type Props = PropsWithChildren<{
	/**
	 * Configures the optional icon for a `<Button>` component.
	 * Can either be specified as an object with properties or
	 * as a plain string. In case of a plain string, the value
	 * is used as the icon name.
	 */
	icon?: string | ButtonIcon;

	/**
	 * Configures the visual priority level of the button.  
	 * Can be one of `"primary"`; `"secondary"`; or `"tertiary"`.  
	 * Defaults to `"secondary"` when not specified.
	 */
	priority?: ButtonPriority;

	/**
	 * Sets the label text for the button.
	 */
	label: string;

	/**
	 * Configures the size variant of the button.  
	 * Can be one of `"medium"`; `"large"; or `"xlarge"`.  
	 * Defaults to `"medium"` when not specified.
	 */
	size?: ButtonSize;
} & ({
	/**
	 * Configures whether the button should visually
	 * be floating and have a drop shadow.  
	 * May only be specified for buttons that have
	 * `priority` set to `"primary"`.  
	 * Defaults to `false` when not specified.
	 */
	floating?: never
} | {
	priority: "primary",
	floating?: boolean;
})>;

export type ButtonProps = Props
	& Omit<ComponentPropsWithoutRef<"button">, "children" | "slot">;

export type AnchorButtonProps = Props
	& Omit<ComponentPropsWithoutRef<"a">, "children" | "slot">
	& Pick<ComponentPropsWithoutRef<"button">, "disabled">;

const Button = ({
	children,
	className,
	icon,
	priority = "secondary",
	label,
	size = "medium",
	floating,
	...props
}: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	// Counter to regular HTML buttons it is more convenient
	// to default to the "button" type instead of the "submit"
	// type.
	props.type ??= "button";

	const iconObj = typeof icon === "string"
		? { left: icon } satisfies ButtonIcon
		: icon;

	if (iconObj?.only || children) {
		props["aria-label"] ??= label;
	}
	if (iconObj?.only) {
		props["title"] ??= label;
	}

	className = composeClassName({ className, priority, floating, size, icon: iconObj });

	return <button
		ref={ref}
		className={className}
		{...props}
	>
		{iconObj?.left && <Icon icon={iconObj.left} />}
		{iconObj?.only
			? <Icon icon={iconObj.only} />
			: <span className="kko-button__text">{children ? children : label}</span>
		}
		{iconObj?.right && <Icon icon={iconObj.right} />}
	</button>
};

const AnchorButton = ({
	children,
	className,
	disabled,
	icon,
	priority = "secondary",
	label,
	size = "medium",
	...props
}: AnchorButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
	// Anchor elements can't normally be disabled like buttons can.
	// But ARIA does allow them to be announced as disabled with the "aria-disabled" attribute.
	// To then actually disable their link behavior, we have to remove the "href" attribute.
	// However, doing so also removes the intrinsic "link" role and we have to add it explicitly.
	if (disabled) {
		props["aria-disabled"] = "true";
		props.href = undefined;
		props.role ??= "link";

		if (!/\blink\b/i.test(props.role)) {
			props.role = `${props.role} link`.trimStart();
		}
	}

	const iconObj = typeof icon === "string"
		? { left: icon } satisfies ButtonIcon
		: icon;

	if (iconObj?.only || children) {
		props["aria-label"] ??= label;
	}
	if (iconObj?.only) {
		props["title"] ??= label;
	}

	className = composeClassName({ className, priority, size, icon: iconObj });

	return <a
		ref={ref}
		className={className}
		{...props}
	>
		{iconObj?.left && <Icon icon={iconObj.left} />}
		{iconObj?.only
			? <Icon icon={iconObj.only} />
			: <span className="kko-button__text">{children ? children : label}</span>
		}
		{iconObj?.right && <Icon icon={iconObj.right} />}
	</a>
};

const Overload = forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	AnchorButtonProps | ButtonProps
>((props, ref) => isAnchor(props)
	? AnchorButton(props, ref as ForwardedRef<HTMLAnchorElement>)
	: Button(props, ref as ForwardedRef<HTMLButtonElement>)
);
Overload.displayName = "Button";

export { Overload as Button };

type ClassArgs = {
	className?: string;
	floating?: boolean;
	icon?: ButtonIcon;
	priority?: ButtonPriority;
	size?: ButtonSize
};

function composeClassName({ className, floating, icon, priority, size }: ClassArgs) {
	return classNames("kko-button", {
		"kko-button--primary": priority === "primary",
		"kko-button--secondary": priority === "secondary",
		"kko-button--tertiary": priority === "tertiary",
		"kko-button--large": size === "large",
		"kko-button--xlarge": size === "xlarge",
		"kko-button--icon-only": !!icon?.only,
		"kko-button--lifted": !!floating && priority === "primary"
	}, className);
}

function isAnchor(value: ButtonProps | AnchorButtonProps): value is AnchorButtonProps {
	return "href" in value && !!value.href;
};
