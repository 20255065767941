import { api } from "./api.generated";
export * from "./api.generated";

api.enhanceEndpoints({
	endpoints: {
		// The markAsRead endpoint should optimistically update the `isRead` state to
		// avoid any double-dispatches, e.g. when rapidly opening and closing the
		// notifications overlay.
		markAsRead: {
			async onQueryStarted(arg, { dispatch, queryFulfilled }) {
				const ids = new Set(arg.markNotificationAsReadRequestModel.notificationIds);

				const { undo } = dispatch(api.util.updateQueryData("getAll", {}, draft => {
					if (!draft.items) return;

					for (const item of draft.items) {
						if (!item.id || !ids.has(item.id)) return;

						item.isRead = true;
					}
				}));

				try {
					await queryFulfilled;
				} catch {
					undo();
				};
			}
		}
	}
});
