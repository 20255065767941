/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEpisodeRequestModel } from '../models/CreateEpisodeRequestModel';
import type { CreateEpisodeResponseModel } from '../models/CreateEpisodeResponseModel';
import type { GetAllEpisodesResponseModel } from '../models/GetAllEpisodesResponseModel';
import type { GetEpisodeDisplayInformationResponseModel } from '../models/GetEpisodeDisplayInformationResponseModel';
import type { GetEpisodeResponseModel } from '../models/GetEpisodeResponseModel';
import type { UpdateEpisodeRequestModel } from '../models/UpdateEpisodeRequestModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EpisodesService {

    /**
     * Gets all episodes the authenticated user is involved with
     * @param acceptLanguage The culture code used for the request.
     * @returns GetAllEpisodesResponseModel Success
     * @throws ApiError
     */
    public static getAllEpisodes(
        acceptLanguage: string,
    ): CancelablePromise<GetAllEpisodesResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/episodes',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Creates an episode
     * @param acceptLanguage The culture code used for the request.
     * @param requestBody The episode that needs to be created.
     * @returns CreateEpisodeResponseModel Created
     * @throws ApiError
     */
    public static createEpisode(
        acceptLanguage: string,
        requestBody?: CreateEpisodeRequestModel,
    ): CancelablePromise<CreateEpisodeResponseModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/episodes',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Returns back information about an episode
     * @param episodeId The id of the episode that needs to be retrieved.
     * @param acceptLanguage The culture code used for the request.
     * @returns GetEpisodeResponseModel Success
     * @throws ApiError
     */
    public static getEpisode(
        episodeId: string,
        acceptLanguage: string,
    ): CancelablePromise<GetEpisodeResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/episodes/{episodeId}',
            path: {
                'episodeId': episodeId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates an episode
     * @param episodeId The ID of the episode to update
     * @param acceptLanguage The culture code used for the request.
     * @param requestBody The episode that needs to be updated.
     * @returns any Created
     * @throws ApiError
     */
    public static updateEpisode(
        episodeId: string,
        acceptLanguage: string,
        requestBody?: UpdateEpisodeRequestModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/episodes/{episodeId}',
            path: {
                'episodeId': episodeId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                412: `Client Error`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Retrieves the display information of an episode. This endpoint bypasses the permission check in case you are invited
     * @param episodeId The id of the episode
     * @param acceptLanguage The culture code used for the request.
     * @returns GetEpisodeDisplayInformationResponseModel Success
     * @throws ApiError
     */
    public static getEpisodeDisplayInformation(
        episodeId: string,
        acceptLanguage: string,
    ): CancelablePromise<GetEpisodeDisplayInformationResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/episodes/{episodeId}/display-information',
            path: {
                'episodeId': episodeId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
