import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveLanguagePreference: build.mutation<
      SaveLanguagePreferenceApiResponse,
      SaveLanguagePreferenceArgs
    >({
      query: (queryArg) => ({
        url: `/v1/user-preferences/me/language`,
        method: "PUT",
        body: queryArg.saveLanguagePreferenceRequestModel,
      }),
    }),
    deleteLanguagePreference: build.mutation<
      DeleteLanguagePreferenceApiResponse,
      DeleteLanguagePreferenceArgs
    >({
      query: () => ({
        url: `/v1/user-preferences/me/language`,
        method: "DELETE",
      }),
    }),
    getPreference: build.query<GetPreferenceApiResponse, GetPreferenceArgs>({
      query: (queryArg) => ({
        url: `/v1/user-preferences/me/${queryArg.preferenceType}`,
      }),
    }),
    getAllPreferences: build.query<
      GetAllPreferencesApiResponse,
      GetAllPreferencesArgs
    >({
      query: () => ({ url: `/v1/user-preferences/me` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type SaveLanguagePreferenceApiResponse =
  /** status 200 Success */ SaveLanguagePreferenceResponseModel;
export type SaveLanguagePreferenceArgs = {
  /** The user preference to be saved. */
  saveLanguagePreferenceRequestModel: SaveLanguagePreferenceRequestModel;
};
export type DeleteLanguagePreferenceApiResponse =
  /** status 200 Success */ DeleteLanguagePreferenceResponseModel;
export type DeleteLanguagePreferenceArgs = void;
export type GetPreferenceApiResponse =
  /** status 200 Success */ GetPreferenceResponseModel;
export type GetPreferenceArgs = {
  preferenceType: PreferenceTypeModel;
};
export type GetAllPreferencesApiResponse =
  /** status 200 Success */ GetAllPreferencesResponseModel;
export type GetAllPreferencesArgs = void;
export type PreferenceTypeModel = "PreferredLanguage";
export type SaveLanguagePreferenceResponseModel = {
  type?: PreferenceTypeModel;
  /** The value for the current preference. */
  value?: any | null;
  /** The Etag used to make sure we don't overwrite changes to the current preference. */
  eTag?: string | null;
};
export type BadRequestResult = {
  statusCode?: number;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type NotFoundResult = {
  statusCode?: number;
};
export type IOutputFormatter = object;
export type MemberTypes =
  | "Constructor"
  | "Event"
  | "Field"
  | "Method"
  | "Property"
  | "TypeInfo"
  | "Custom"
  | "NestedType"
  | "All";
export type MethodAttributes =
  | "ReuseSlot"
  | "Private"
  | "FamANDAssem"
  | "Assembly"
  | "Family"
  | "FamORAssem"
  | "Public"
  | "MemberAccessMask"
  | "UnmanagedExport"
  | "Static"
  | "Final"
  | "Virtual"
  | "HideBySig"
  | "NewSlot"
  | "CheckAccessOnOverride"
  | "Abstract"
  | "SpecialName"
  | "RTSpecialName"
  | "PinvokeImpl"
  | "HasSecurity"
  | "RequireSecObject"
  | "ReservedMask";
export type MethodImplAttributes =
  | "IL"
  | "Native"
  | "OPTIL"
  | "Runtime"
  | "Unmanaged"
  | "NoInlining"
  | "ForwardRef"
  | "Synchronized"
  | "NoOptimization"
  | "PreserveSig"
  | "AggressiveInlining"
  | "AggressiveOptimization"
  | "InternalCall"
  | "MaxMethodImplVal";
export type CallingConventions =
  | "Standard"
  | "VarArgs"
  | "Any"
  | "HasThis"
  | "ExplicitThis";
export type IntPtr = object;
export type RuntimeMethodHandle = {
  value?: IntPtr;
};
export type MethodBase = {
  memberType?: MemberTypes;
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
};
export type GenericParameterAttributes =
  | "None"
  | "Covariant"
  | "Contravariant"
  | "VarianceMask"
  | "ReferenceTypeConstraint"
  | "NotNullableValueTypeConstraint"
  | "DefaultConstructorConstraint"
  | "SpecialConstraintMask";
export type TypeAttributes =
  | "NotPublic"
  | "Public"
  | "NestedPublic"
  | "NestedPrivate"
  | "NestedFamily"
  | "NestedAssembly"
  | "NestedFamANDAssem"
  | "NestedFamORAssem"
  | "SequentialLayout"
  | "ExplicitLayout"
  | "LayoutMask"
  | "Interface"
  | "Abstract"
  | "Sealed"
  | "SpecialName"
  | "RTSpecialName"
  | "Import"
  | "Serializable"
  | "WindowsRuntime"
  | "UnicodeClass"
  | "AutoClass"
  | "CustomFormatClass"
  | "HasSecurity"
  | "ReservedMask"
  | "BeforeFieldInit"
  | "CustomFormatMask";
export type LayoutKind = "Sequential" | "Explicit" | "Auto";
export type StructLayoutAttribute = {
  typeId?: any | null;
  value?: LayoutKind;
};
export type RuntimeTypeHandle = {
  value?: IntPtr;
};
export type EventAttributes = "None" | "SpecialName" | "RTSpecialName";
export type ParameterAttributes =
  | "None"
  | "In"
  | "Out"
  | "Lcid"
  | "Retval"
  | "Optional"
  | "HasDefault"
  | "HasFieldMarshal"
  | "Reserved3"
  | "Reserved4"
  | "ReservedMask";
export type MemberInfo = {
  memberType?: MemberTypes;
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
};
export type ParameterInfo = {
  attributes?: ParameterAttributes;
  member?: MemberInfo;
  name?: string | null;
  parameterType?: Type;
  position?: number;
  isIn?: boolean;
  isLcid?: boolean;
  isOptional?: boolean;
  isOut?: boolean;
  isRetval?: boolean;
  defaultValue?: any | null;
  rawDefaultValue?: any | null;
  hasDefaultValue?: boolean;
  customAttributes?: CustomAttributeData[] | null;
  metadataToken?: number;
};
export type ICustomAttributeProvider = object;
export type MethodInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
  returnParameter?: ParameterInfo;
  returnType?: Type;
  returnTypeCustomAttributes?: ICustomAttributeProvider;
};
export type EventInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: EventAttributes;
  isSpecialName?: boolean;
  addMethod?: MethodInfo;
  removeMethod?: MethodInfo;
  raiseMethod?: MethodInfo;
  isMulticast?: boolean;
  eventHandlerType?: Type;
};
export type FieldAttributes =
  | "PrivateScope"
  | "Private"
  | "FamANDAssem"
  | "Assembly"
  | "Family"
  | "FamORAssem"
  | "Public"
  | "FieldAccessMask"
  | "Static"
  | "InitOnly"
  | "Literal"
  | "NotSerialized"
  | "HasFieldRVA"
  | "SpecialName"
  | "RTSpecialName"
  | "HasFieldMarshal"
  | "PinvokeImpl"
  | "HasDefault"
  | "ReservedMask";
export type RuntimeFieldHandle = {
  value?: IntPtr;
};
export type FieldInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  memberType?: MemberTypes;
  attributes?: FieldAttributes;
  fieldType?: Type;
  isInitOnly?: boolean;
  isLiteral?: boolean;
  isNotSerialized?: boolean;
  isPinvokeImpl?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  fieldHandle?: RuntimeFieldHandle;
};
export type PropertyAttributes =
  | "None"
  | "SpecialName"
  | "RTSpecialName"
  | "HasDefault"
  | "Reserved2"
  | "Reserved3"
  | "Reserved4"
  | "ReservedMask";
export type PropertyInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  memberType?: MemberTypes;
  propertyType?: Type;
  attributes?: PropertyAttributes;
  isSpecialName?: boolean;
  canRead?: boolean;
  canWrite?: boolean;
  getMethod?: MethodInfo;
  setMethod?: MethodInfo;
};
export type TypeInfo = {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
  genericTypeParameters?: Type[] | null;
  declaredConstructors?: ConstructorInfo[] | null;
  declaredEvents?: EventInfo[] | null;
  declaredFields?: FieldInfo[] | null;
  declaredMembers?: MemberInfo[] | null;
  declaredMethods?: MethodInfo[] | null;
  declaredNestedTypes?: TypeInfo[] | null;
  declaredProperties?: PropertyInfo[] | null;
  implementedInterfaces?: Type[] | null;
};
export type SecurityRuleSet = "None" | "Level1" | "Level2";
export type Assembly = {
  definedTypes?: TypeInfo[] | null;
  exportedTypes?: Type[] | null;
  codeBase?: string | null;
  entryPoint?: MethodInfo;
  fullName?: string | null;
  imageRuntimeVersion?: string | null;
  isDynamic?: boolean;
  location?: string | null;
  reflectionOnly?: boolean;
  isCollectible?: boolean;
  isFullyTrusted?: boolean;
  customAttributes?: CustomAttributeData[] | null;
  escapedCodeBase?: string | null;
  manifestModule?: Module;
  modules?: Module[] | null;
  globalAssemblyCache?: boolean;
  hostContext?: number;
  securityRuleSet?: SecurityRuleSet;
};
export type ModuleHandle = {
  mdStreamVersion?: number;
};
export type Module = {
  assembly?: Assembly;
  fullyQualifiedName?: string | null;
  name?: string | null;
  mdStreamVersion?: number;
  moduleVersionId?: string;
  scopeName?: string | null;
  moduleHandle?: ModuleHandle;
  customAttributes?: CustomAttributeData[] | null;
  metadataToken?: number;
};
export type ConstructorInfo = {
  name?: string | null;
  declaringType?: Type;
  reflectedType?: Type;
  module?: Module;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  attributes?: MethodAttributes;
  methodImplementationFlags?: MethodImplAttributes;
  callingConvention?: CallingConventions;
  isAbstract?: boolean;
  isConstructor?: boolean;
  isFinal?: boolean;
  isHideBySig?: boolean;
  isSpecialName?: boolean;
  isStatic?: boolean;
  isVirtual?: boolean;
  isAssembly?: boolean;
  isFamily?: boolean;
  isFamilyAndAssembly?: boolean;
  isFamilyOrAssembly?: boolean;
  isPrivate?: boolean;
  isPublic?: boolean;
  isConstructedGenericMethod?: boolean;
  isGenericMethod?: boolean;
  isGenericMethodDefinition?: boolean;
  containsGenericParameters?: boolean;
  methodHandle?: RuntimeMethodHandle;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  memberType?: MemberTypes;
};
export type CustomAttributeTypedArgument = {
  argumentType?: Type;
  value?: any | null;
};
export type CustomAttributeNamedArgument = {
  memberInfo?: MemberInfo;
  typedValue?: CustomAttributeTypedArgument;
  memberName?: string | null;
  isField?: boolean;
};
export type CustomAttributeData = {
  attributeType?: Type;
  constructor?: ConstructorInfo;
  constructorArguments?: CustomAttributeTypedArgument[] | null;
  namedArguments?: CustomAttributeNamedArgument[] | null;
};
export type Type = {
  name?: string | null;
  customAttributes?: CustomAttributeData[] | null;
  isCollectible?: boolean;
  metadataToken?: number;
  isInterface?: boolean;
  memberType?: MemberTypes;
  namespace?: string | null;
  assemblyQualifiedName?: string | null;
  fullName?: string | null;
  assembly?: Assembly;
  module?: Module;
  isNested?: boolean;
  declaringType?: Type;
  declaringMethod?: MethodBase;
  reflectedType?: Type;
  underlyingSystemType?: Type;
  isTypeDefinition?: boolean;
  isArray?: boolean;
  isByRef?: boolean;
  isPointer?: boolean;
  isConstructedGenericType?: boolean;
  isGenericParameter?: boolean;
  isGenericTypeParameter?: boolean;
  isGenericMethodParameter?: boolean;
  isGenericType?: boolean;
  isGenericTypeDefinition?: boolean;
  isSZArray?: boolean;
  isVariableBoundArray?: boolean;
  isByRefLike?: boolean;
  hasElementType?: boolean;
  genericTypeArguments?: Type[] | null;
  genericParameterPosition?: number;
  genericParameterAttributes?: GenericParameterAttributes;
  attributes?: TypeAttributes;
  isAbstract?: boolean;
  isImport?: boolean;
  isSealed?: boolean;
  isSpecialName?: boolean;
  isClass?: boolean;
  isNestedAssembly?: boolean;
  isNestedFamANDAssem?: boolean;
  isNestedFamily?: boolean;
  isNestedFamORAssem?: boolean;
  isNestedPrivate?: boolean;
  isNestedPublic?: boolean;
  isNotPublic?: boolean;
  isPublic?: boolean;
  isAutoLayout?: boolean;
  isExplicitLayout?: boolean;
  isLayoutSequential?: boolean;
  isAnsiClass?: boolean;
  isAutoClass?: boolean;
  isUnicodeClass?: boolean;
  isCOMObject?: boolean;
  isContextful?: boolean;
  isEnum?: boolean;
  isMarshalByRef?: boolean;
  isPrimitive?: boolean;
  isValueType?: boolean;
  isSignatureType?: boolean;
  isSecurityCritical?: boolean;
  isSecuritySafeCritical?: boolean;
  isSecurityTransparent?: boolean;
  structLayoutAttribute?: StructLayoutAttribute;
  typeInitializer?: ConstructorInfo;
  typeHandle?: RuntimeTypeHandle;
  guid?: string;
  baseType?: Type;
  isSerializable?: boolean;
  containsGenericParameters?: boolean;
  isVisible?: boolean;
};
export type ObjectResult = {
  value?: any | null;
  formatters?: IOutputFormatter[] | null;
  contentTypes?: string[] | null;
  declaredType?: Type;
  statusCode?: number | null;
};
export type PreferredLanguageModel = "Automatic" | "En" | "Nl";
export type SaveLanguagePreferenceRequestModel = {
  value?: PreferredLanguageModel;
  /** The Etag used to make sure we don't overwrite changes to the current preference. */
  eTag?: string | null;
};
export type DeleteLanguagePreferenceResponseModel = {
  /** The flag that specifies if the operation was a success. */
  isSuccess?: boolean;
};
export type GetPreferenceResponseModel = {
  type?: PreferenceTypeModel;
  /** The value for the current preference. */
  value?: any | null;
  /** The Etag used to make sure we don't overwrite changes to the current preference. */
  eTag?: string | null;
};
export type PreferenceResponseModel = {
  type?: PreferenceTypeModel;
  /** The value for the current preference. */
  value?: any | null;
  /** The Etag used to make sure we don't overwrite changes to the current preference. */
  eTag?: string | null;
};
export type GetAllPreferencesResponseModel = {
  /** Gets the list of the episode response models. */
  items?: PreferenceResponseModel[] | null;
};
export const {
  useSaveLanguagePreferenceMutation,
  useDeleteLanguagePreferenceMutation,
  useGetPreferenceQuery,
  useGetAllPreferencesQuery,
} = injectedRtkApi;
