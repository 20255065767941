/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateEpisodeMembershipRequestModel } from '../models/CreateEpisodeMembershipRequestModel';
import type { CreateEpisodeMembershipResponseModel } from '../models/CreateEpisodeMembershipResponseModel';
import type { GetEpisodeIdsForAccountResponseModel } from '../models/GetEpisodeIdsForAccountResponseModel';
import type { GetEpisodeMembersResponse } from '../models/GetEpisodeMembersResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class EpisodeMembershipsService {

    /**
     * Handles HTTP POST requests to create an episode membership (Intended for backend-to-backend communication)
     * @param acceptLanguage The culture code used for the request.
     * @param requestBody The request model containing the necessary data to create the episode member.
     * @returns CreateEpisodeMembershipResponseModel Created
     * @throws ApiError
     */
    public static postV1EpisodeMemberships(
        acceptLanguage: string,
        requestBody?: CreateEpisodeMembershipRequestModel,
    ): CancelablePromise<CreateEpisodeMembershipResponseModel> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/v1/EpisodeMemberships',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
                412: `Client Error`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Gets the list of members for a given episode id
     * @param episodeId The id of the episode.
     * @param acceptLanguage The culture code used for the request.
     * @returns GetEpisodeMembersResponse Success
     * @throws ApiError
     */
    public static getV1EpisodeMemberships(
        episodeId: string,
        acceptLanguage: string,
    ): CancelablePromise<GetEpisodeMembersResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/EpisodeMemberships',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'episodeId': episodeId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Returns a list of episodes the account provided and the authenticated user are both members of
     * @param accountId The account for which the episodes are returned
     * @param acceptLanguage The culture code used for the request.
     * @returns GetEpisodeIdsForAccountResponseModel Success
     * @throws ApiError
     */
    public static getV1EpisodeMembershipsAccount(
        accountId: string,
        acceptLanguage: string,
    ): CancelablePromise<GetEpisodeIdsForAccountResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/EpisodeMemberships/account/{accountId}',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                500: `Server Error`,
            },
        });
    }

}
