/**
 * A function that can be used to remove an event listener previously
 * added with `bindEventListener`.
 */
export type Unbinder = () => void;

/**
 * Appends an event listener for events whose type attribute value is `type`
 * and returns a function that can be used to at some later point remove
 * the listener again.
 *
 * The event listener is appended to target's event listener list and is not
 * appended if it has the same `type`, `callback`, and `options.capture`.
 *
 * @param target
 * The target to which an event is being bound.
 * @param type 
 * The type of the event being bound.
 * @param callback
 * The callback to be invoked when the event is dispatched.
 * @param options
 * Sets listener-specific options.  
 * For compatibility this can be a `boolean`, in which case the method behaves
 * exactly as if the value was specified as the options's `capture` property.
 * @returns
 * A function that will remove the event listener.
 */
export function bindEventListener<T extends HTMLElement, K extends keyof HTMLElementEventMap>(
	target: T,
	type: K,
	listener: (this: T, event: HTMLElementEventMap[K]) => any,
	options?: AddEventListenerOptions | boolean
): Unbinder;

export function bindEventListener<T extends Element, K extends keyof ElementEventMap>(
	target: T,
	type: K,
	listener: (this: T, event: ElementEventMap[K]) => any,
	options?: AddEventListenerOptions | boolean
): Unbinder;

export function bindEventListener<T extends Document, K extends keyof DocumentEventMap>(
	target: T,
	type: K,
	listener: (this: T, event: DocumentEventMap[K]) => any,
	options?: AddEventListenerOptions | boolean
): Unbinder;

export function bindEventListener<T extends Window, K extends keyof WindowEventMap>(
	target: T,
	type: K,
	listener: (this: T, event: WindowEventMap[K]) => any,
	options?: AddEventListenerOptions | boolean
): Unbinder;

export function bindEventListener<T extends EventTarget, K extends Event>(
	target: T,
	type: string,
	listener: (this: T, event: K) => any,
	options?: AddEventListenerOptions | boolean
): Unbinder;

export function bindEventListener<T extends EventTarget>(
	target: T,
	type: string,
	callback: EventListenerOrEventListenerObject,
	options?: AddEventListenerOptions | boolean
): Unbinder;

export function bindEventListener(
	target: EventTarget,
	type: string,
	callback: EventListenerOrEventListenerObject,
	options?: AddEventListenerOptions | boolean
): Unbinder {
	target.addEventListener(type, callback, options);
	return () => target.removeEventListener(type, callback, options);
}

