import { SendMessageOptions, SendMessageRequest } from "@azure/communication-chat";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { usePropsFor, MessageThread, SendBox, MessageProps, MessageRenderer, ChatMessage, useChatThreadClient } from "@azure/communication-react";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetDisplayNameByAccountIdQuery } from "src/apis/network";
import { useLocalStorage, useLocale, useMediaBreakpoint, useMediaQuery } from "src/common/hooks";
import { usePlatformAccount } from "src/modules/shared/hooks";
import { ActionButton } from "src/modules/shared/components/buttons";
import { useGetChatByIdQuery, useGetCommunicationAccessTokenQuery, useGetCommunicationUserIdentityQuery, useRemoveEmoticonReactionMutation, useUpsertEmoticonReactionMutation, api as chatApi } from "src/apis/chat";
import { ContextMenu } from "src/modules/shared/components/ContextMenu";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { focusChat, setLastMessageDate } from "src/redux/slices/chatsSlice";
import { useGetEpisodeQuery } from "src/apis/episode";
import { Datetime } from "src/modules/shared/components/Datetime";
import { reactions } from "../utils/reactions";
import { ChatContextMenu } from "./ChatContextMenu";
import { MessageReactions } from "./MessageReactions";

export type ChatComponentsProps = {
	onFetchAvatar: (userId: string | undefined) => JSX.Element,
	currentUserIdentifier: string,
}

export const ChatComponents: FC<ChatComponentsProps> = ({ onFetchAvatar, currentUserIdentifier }: ChatComponentsProps) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation();
	const messageThreadProps = usePropsFor(MessageThread);
	const sendBoxProps = usePropsFor(SendBox);

	const chatThreadClient = useChatThreadClient();

	const { accountId } = usePlatformAccount();
	const locale = useLocale();
	const { data: { displayName } = {} } = useGetDisplayNameByAccountIdQuery(accountId ? { "Accept-Language": locale, accountId } : skipToken);

	const { data: userIdentityResponse } = useGetCommunicationUserIdentityQuery();

	const [pinnedChatIds, setPinnedChatIds] = useLocalStorage<string[]>("pinnedChatIds", [], "all");

	const focusedThreadId = useAppSelector(state => state.chat.focusedThreadId);

	const breakpoint = useMediaBreakpoint("tablet");
	const isDesktop = useMediaQuery(`(min-width : ${breakpoint})`);

	useEffect(() => {
		setMessageBeingReplied(undefined);
	}, [focusedThreadId]);

	const reactionOfCurrentUser = useCallback((message: ChatMessage) => {
		if (!userIdentityResponse?.communicationUserIdentity) {
			return undefined;
		}
		return (message?.metadata || {})[`reaction-${userIdentityResponse.communicationUserIdentity}`];
	}, [userIdentityResponse]);

	const messageContextMenuOptions = useCallback((message: ChatMessage) => {
		return [...reactions.map(r => ({ ...r, active: r.command === reactionOfCurrentUser(message) })), {
			icon: "arrow-left-curved",
			label: t("kko:pages:chats.reply-message-menu-item"),
			command: "reply",
			disabled: false,
		}];
	}, [reactionOfCurrentUser, t]);

	const [messageBeingReplied, setMessageBeingReplied] = useState<MessageProps | undefined>();
	const [reactWithEmoticon] = useUpsertEmoticonReactionMutation();
	const [deleteEmoticon] = useRemoveEmoticonReactionMutation();

	const { data: chatAccessTokenResponse } = useGetCommunicationAccessTokenQuery(currentUserIdentifier ? {
		userIdentity: currentUserIdentifier
	} : skipToken);

	const { data: { chatThread } = {} } = useGetChatByIdQuery(focusedThreadId && chatAccessTokenResponse?.communicationAccessToken ? {
		chatId: focusedThreadId,
		"X-Chat-Access-Token": chatAccessTokenResponse?.communicationAccessToken
	} : skipToken);

	const otherParticipant = useMemo(() => {
		return chatThread?.participants?.find(p => p.accountId !== accountId);
	}, [chatThread, accountId]);

	const { data: { displayName: otherParticipantDisplayName } = {} } = useGetDisplayNameByAccountIdQuery(otherParticipant?.accountId ? { "Accept-Language": locale, accountId: otherParticipant.accountId } : skipToken);

	const { data: episode } = useGetEpisodeQuery(chatThread?.episodeId ? {
		"Accept-Language": locale,
		episodeId: chatThread?.episodeId
	} : skipToken);

	const sendboxRef = useRef<HTMLDivElement>(null);

	const onMessageContextMenuCommandHandler = useCallback(async (command: string, messageProps: MessageProps) => {
		if (command === "reply") {
			setMessageBeingReplied(messageProps);
			const textArea = sendboxRef?.current?.querySelector("textarea");
			textArea?.focus();
		} else if (reactions.map(r => r.command).includes(command)) {
			// reaction logic
			if (chatAccessTokenResponse?.communicationAccessToken) {
				if (command === reactionOfCurrentUser(messageProps.message as ChatMessage)) {
					await deleteEmoticon({
						chatThreadId: chatThreadClient.threadId,
						chatMessageId: messageProps.message.messageId,
						"X-Chat-Access-Token": chatAccessTokenResponse?.communicationAccessToken
					}).unwrap();
				} else {
					await reactWithEmoticon({
						chatThreadId: chatThreadClient.threadId,
						chatMessageId: messageProps.message.messageId,
						emoticon: command,
						"X-Chat-Access-Token": chatAccessTokenResponse?.communicationAccessToken
					}).unwrap();
				}
			}
		}
	}, [chatThreadClient, chatAccessTokenResponse?.communicationAccessToken, reactWithEmoticon, setMessageBeingReplied, reactionOfCurrentUser, deleteEmoticon]);

	const onRenderMessage = useCallback((messageProps: MessageProps, defaultOnRender?: MessageRenderer) => {
		const { message } = messageProps;
		if (message.messageType !== "chat") {
			return defaultOnRender?.(messageProps) ?? <></>;
		}
		const isMyMessage = message.senderId === currentUserIdentifier;
		return (
			<div className={`kko-chat-message kko-chat-message--${isMyMessage ? "mine" : "theirs"}`}>
				<div className="kko-chat-message__content">
					{message.metadata?.repliedMessageContent &&
						<cite>
							{message.metadata?.repliedMessageDisplayName &&
								<strong>
									{message.metadata?.repliedMessageDisplayName}
								</strong>
							}
							<span>
								<i>
									{message.metadata?.repliedMessageContent}
								</i>
							</span>
						</cite>
					}
					<span>
						{message.content}
					</span>
				</div>
				{<MessageReactions message={message} />}
				<span className="kko-chat-message__time-stamp">
					<Datetime
						value={message.createdOn.toISOString()}
						culture={locale}
						timeStyle="regular"
					/>
				</span>
				<div className="kko-chat-message__menu">
					<ChatContextMenu
						icon={{ left: "menu-dots" }}
						label=""
						menuItems={messageContextMenuOptions(message)}
						onCommand={(command: string) => onMessageContextMenuCommandHandler(command, messageProps)}
					/>
				</div>
			</div >
		)
	}, [currentUserIdentifier, onMessageContextMenuCommandHandler, messageContextMenuOptions, locale]);

	const onSendMessage = async (content: string): Promise<void> => {
		const messageContent: SendMessageRequest = { content: content };
		const messageOptions: SendMessageOptions = { senderDisplayName: displayName };
		if (messageBeingReplied?.message?.messageType === "chat"
			&& messageBeingReplied.message.content
			&& messageBeingReplied.message.senderDisplayName
		) {
			messageOptions.metadata =
			{
				repliedMessageContent: messageBeingReplied.message.content,
				repliedMessageDisplayName: messageBeingReplied.message.senderDisplayName
			}
		}
		await chatThreadClient.sendMessage(
			messageContent,
			messageOptions
		);
		setMessageBeingReplied(undefined);
		dispatch(setLastMessageDate((new Date()).toString()));
		chatThread?.id && dispatch(chatApi.util.invalidateTags([
			{ type: "ChatThread", id: "LIST" },
			{ type: "ChatThread", id: chatThread.id }
		]));
	};

	const isPinned = useMemo(() => {
		if (!chatThreadClient?.threadId) {
			return false;
		}
		return pinnedChatIds.includes(chatThreadClient.threadId);
	}, [pinnedChatIds, chatThreadClient]);

	const onChatWindowContextMenuCommandHandler = useCallback(async (command: string) => {
		switch (command) {
			case "pin":
				setPinnedChatIds([...pinnedChatIds, chatThreadClient.threadId]);
				return;
			case "unpin":
				setPinnedChatIds(pinnedChatIds.filter(chatId => chatId !== chatThreadClient.threadId));
				return;
			default: return;
		}
	}, [setPinnedChatIds, chatThreadClient, pinnedChatIds]);

	const unfocus = useCallback(() => {
		dispatch(focusChat(undefined));
	}, [dispatch]);

	const headerContextMenu = useMemo(() => (
		isPinned ? <ChatContextMenu
			label={""}
			menuItems={[{
				icon: "pin",
				label: `${t("kko:pages:chats.unpin-menu-item")}`,
				command: "unpin",
				disabled: false
			}]}
			onCommand={(command: string) => onChatWindowContextMenuCommandHandler(command)}
		/> : <ContextMenu
			label={""}
			menuItems={[{
				icon: "pin",
				label: `${t("kko:pages:chats.pin-menu-item")}`,
				command: "pin",
				disabled: false
			}]}
			onCommand={(command: string) => onChatWindowContextMenuCommandHandler(command)}
		/>
	), [t, onChatWindowContextMenuCommandHandler, isPinned]);

	const header = useMemo(() => {
		return <div className="kko-chat-conversation__header">
			{!isDesktop && <ActionButton
				className="kko-button--dimmed"
				icon="arrow-back"
				label={""}
				priority="tertiary"
				onClick={() => {
					unfocus()
				}}
			/>}
			<div className="h3">{episode?.title ? episode.title : otherParticipantDisplayName}</div>
			{headerContextMenu}
		</div>
	}, [episode?.title, headerContextMenu, otherParticipantDisplayName, unfocus, isDesktop]);

	return (
		<>
			{header}
			<div className="kko-chat-conversation__list">
				{/*Props are updated asynchronously, so only render the component once props are populated.*/}
				{messageThreadProps && <MessageThread
					{...messageThreadProps}
					styles={{
						root: { fontFamily: "kko chatRoot" },

						// Styles for chat container.
						chatContainer: { fontFamily: "kko chatContainer" },

						// styles for chat items.
						chatItemMessageContainer: { fontFamily: "kko chatItemMessageContainer" },

						// Styles for chat message container.
						chatMessageContainer: { fontFamily: "kko chatMessageContainer" },

						// Styles for my chat message container in case of failure.
						failedMyChatMessageContainer: { fontFamily: "kko failedMyChatMessageContainer" },

						// Styles for load previous messages container.
						loadPreviousMessagesButtonContainer: { fontFamily: "kko loadPreviousMessagesButtonContainer" },

						// Styles for message status indicator container.
						// messageStatusContainer: { background: "#FFF000" },

						// styles for my chat items.
						myChatItemMessageContainer: { fontFamily: "kko myChatItemMessageContainer" },

						// Styles for my chat message container.
						myChatMessageContainer: { fontFamily: "kko myChatMessageContainer" },

						// Styles for new message container.
						newMessageButtonContainer: { fontFamily: "kko newMessageButtonContainer" },

						// Styles for system message container.
						systemMessageContainer: { fontFamily: "kko systemMessageContainer" }

					}}
					onRenderMessage={onRenderMessage}
					onRenderAvatar={onFetchAvatar}
				/>}
			</div>
			{messageBeingReplied?.message?.messageType === "chat" &&
				<div className="kko-chat-conversation__reply-container">
					<div>
						<small>
							{messageBeingReplied.message.senderDisplayName}
						</small>
						<cite>
							<span>
								{messageBeingReplied.message.content}
							</span>
						</cite>
					</div>
					<ActionButton
						className="kko-button--dimmed kko-button--close"
						icon="close"
						label=""
						priority="tertiary"
						onClick={() => {
							setMessageBeingReplied(undefined);
						}}
					/>
				</div>
			}
			{sendBoxProps &&
				<div ref={sendboxRef} className="kko-chat-conversation__post-new">
					<SendBox
						{...sendBoxProps}
						strings={{ placeholderText: t("kko:pages:chats.send-message-placeholder") }}
						onSendMessage={onSendMessage}
					/>
				</div>
			}
		</>
	);
}