import { instance as msalInstance, getAccessToken } from "src/common/auth";
import { env } from "src/env";

import { OpenAPI as OpenAPIEmailService } from "./emailService";
import { OpenAPI as OpenAPIAccountService } from "./accountService";
import { OpenAPI as OpenAPINetworkService } from "./networkService";
import { OpenAPI as OpenAPIPreferencesService } from "./preferencesService";
import { OpenAPI as OpenAPIEpisodeService } from "./episodeService";
import { OpenAPI as OpenAPINotificationService } from "./notificationService";
import { OpenAPI as OpenAPIDocumentService } from "./documentService";
import { OpenAPI as OpenAPICalendarService } from "./calendarService";

export const initAPIs = () => {
	OpenAPIAccountService.BASE = env.REACT_APP_ACCOUNT_SERVICE_API_URL || "";
	OpenAPIAccountService.TOKEN = () => getAccessToken(msalInstance);

	OpenAPICalendarService.BASE = env.REACT_APP_CALENDAR_SERVICE_API_URL || "";
	OpenAPICalendarService.TOKEN = () => getAccessToken(msalInstance);

	OpenAPIEmailService.BASE = env.REACT_APP_EMAIL_SERVICE_API_URL || "";
	OpenAPIEmailService.TOKEN = () => getAccessToken(msalInstance);

	OpenAPIDocumentService.BASE = env.REACT_APP_DOCUMENT_SERVICE_API_URL || "";
	OpenAPIDocumentService.TOKEN = () => getAccessToken(msalInstance);

	OpenAPIEpisodeService.BASE = env.REACT_APP_EPISODE_SERVICE_API_URL || "";
	OpenAPIEpisodeService.TOKEN = () => getAccessToken(msalInstance);

	OpenAPINetworkService.BASE = env.REACT_APP_NETWORK_SERVICE_API_URL || "";
	OpenAPINetworkService.TOKEN = () => getAccessToken(msalInstance);

	OpenAPINotificationService.BASE = env.REACT_APP_NOTIFICATION_SERVICE_API_URL || "";
	OpenAPINotificationService.TOKEN = () => getAccessToken(msalInstance);

	OpenAPIPreferencesService.BASE = env.REACT_APP_PREFERENCES_SERVICE_API_URL || "";
	OpenAPIPreferencesService.TOKEN = () => getAccessToken(msalInstance);
};