import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { FC, useMemo } from "react";
import { useRouteError } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ProblemDetails } from "src/common/redux";
import { ProblemDetailsError, RtkQueryError } from "./errors";
import { ProblemDetailsPanel } from "./ProblemDetailsPanel";
import { ProblemDetailsMotivator } from "./ProblemDetailsMotivator";

type FetchBaseQueryStatus = Exclude<FetchBaseQueryError["status"], number>;

const statusKeys = new Map<FetchBaseQueryStatus, string>([
	["FETCH_ERROR", "network"],
	["TIMEOUT_ERROR", "timeout"],
	["PARSING_ERROR", "parsing"],
	["CUSTOM_ERROR", "generic"]
]);

type RouteErrorBoundaryProps = {
	type?: "panel" | "motivator";
}

export const RouteErrorBoundary: FC<RouteErrorBoundaryProps> = ({ type = "panel" }) => {
	const { t } = useTranslation("kko", { keyPrefix: "core.errors" });
	const error = useRouteError();

	const problem = useMemo<ProblemDetails>(() => {
		if (error instanceof ProblemDetailsError) {
			return error.problem;
		}

		if (error instanceof RtkQueryError) {
			if (typeof error.status === "number") {
				return {
					title: t("network.title"),
					detail: t("network.detail", { status: error.status }),
					status: error.status
				}
			}

			return {
				title: t(`${statusKeys.get(error.status) ?? "generic"}.title`),
				detail: error.message || undefined
			};
		}

		return {
			title: t("generic.title"),
			detail: (error instanceof Error)
				? error.message || t("generic.detail")
				: t("generic.detail")
		}

	}, [error, t]);

	switch (type) {
		case "panel":
			return <ProblemDetailsPanel problem={problem} />;
		case "motivator":
			return <ProblemDetailsMotivator problem={problem} />;
	};
}
