import type { RouteObject } from "react-router-dom";
import { RouteErrorBoundary } from "../core/components/errors";

export const routes: RouteObject[] = [{
	path: "episodes/summary",
	lazy: async () => ({ Component: (await import("./pages")).Episodes }),
	children: [{
		path: "add",
		lazy: async () => ({ Component: (await import("./pages")).AddEpisode })
	}, {
		path: ":episodeId",
		errorElement: <RouteErrorBoundary type="motivator" />,
		lazy: async () => ({ Component: (await import("./pages")).PreviewEpisode })
	}]
}, {
	path: "episodes/invites-sent",
	lazy: async () => ({ Component: (await import("./pages")).InvitationsSent }),
},
{
	path: "episodes/invites-received",
	lazy: async () => ({ Component: (await import("./pages")).InvitationsReceived }),
},
{
	path: "episodes/details/:episodeId",
	errorElement: <RouteErrorBoundary type="panel" />,
	lazy: async () => ({ Component: (await import("./pages")).Episode }),
	children: [{
		path: "information",
		lazy: async () => ({ Component: (await import("./pages")).EpisodeGeneralInformation }),
	}, {
		path: "access-rights",
		lazy: async () => ({ Component: (await import("./pages")).EpisodeAccessRights }),
	}, {
		path: "tasks",
		lazy: async () => ({ Component: (await import("./pages")).Tasks }),
		children: [{
			path: ":id",
			children: [{
				index: true,
				errorElement: <RouteErrorBoundary type="motivator" />,
				lazy: async () => ({ Component: (await import("./pages")).ViewTask }),
			}, {
				path: "edit",
				errorElement: <RouteErrorBoundary type="motivator" />,
				lazy: async () => ({ Component: (await import("./pages")).EditTask }),
			}]
		}, {
			path: "add",
			lazy: async () => ({ Component: (await import("./pages")).AddTask })
		}]
	}, {
		path: "documents",
		children: [{
			index: true,
			lazy: async () => ({ Component: (await import("./pages")).EpisodeDocuments })
		}, {
			path: "deleted",
			lazy: async () => ({ Component: (await import("./pages")).EpisodeDeletedDocuments })
		}, {
			path: ":documentId/:documentName?",
			errorElement: <RouteErrorBoundary type="panel" />,
			lazy: async () => ({ Component: (await import("./pages")).EpisodeDocument }),
		}]
	}, {
		path: "ohh-form",
		lazy: async () => ({ Component: (await import("./pages")).EpisodeOhhFormFiller }),
	}, {
		path: "aiden",
		lazy: async () => ({ Component: (await import("./pages")).EpisodeAiden }),
	}, {
		path: "ohh-viewer",
		lazy: async () => ({ Component: (await import("./pages")).EpisodeOhhViewer }),
	}]
}];