import { InteractionRequiredAuthError, IPublicClientApplication } from "@azure/msal-browser";
import jwt_decode, { JwtPayload } from "jwt-decode";
import { getAccountFlow } from "./claims";
import { findAuthorityForPolicy, policies, scopes } from "./config";


export const getAccessToken = async (msalInstance: IPublicClientApplication): Promise<string> => {
	const activeAccount = msalInstance.getActiveAccount();
	const accounts = msalInstance.getAllAccounts();

	if (!activeAccount && accounts.length === 0) {
		// No logged in user
		return "";
	}

	// NOTE:
	//  Falling back to the signIn policy authority like this is invalid.
	//  An account needs to actually have that authority set or you will end up
	//  with authorization errors.
	//  However, falling back like this retains behavior that was already broken before.
	//
	//  This should be cleaned up in the context of
	//  PBI 7479: Improve managing of active accounts with MSAL library
	const userFlow = getAccountFlow(activeAccount);
	const authority = findAuthorityForPolicy(userFlow) ?? policies.signIn.authority;

	const msalRequestParams = {
		authority,
		scopes
	};

	const msalRequestWithPromptParams = {
		...msalRequestParams,
		prompt: "login"
	};

	const request = {
		...msalRequestParams,
		account: activeAccount || accounts[0]
	};

	try {
		const { accessToken } = await msalInstance.acquireTokenSilent(request);
		return accessToken;

	} catch (error) {
		if (error instanceof InteractionRequiredAuthError) {
			// fallback to interaction when silent call fails
			const acquireTokenRedirectRequest = {
				...msalRequestWithPromptParams,
				account: activeAccount || accounts[0],
				loginHint: (activeAccount || accounts[0]).username
			};

			msalInstance.acquireTokenRedirect(acquireTokenRedirectRequest);
		}

		console.error("Unexpected error occured for acquireTokenSilent in getToken callback", error);
		throw new Error("Unexpected error occured for acquireTokenSilent in getToken callback");
	}
}

export const isTokenExpired = (idToken: string): boolean => {
	const payload = jwt_decode<JwtPayload>(idToken);
	if (payload.exp == null) return true;

	return (payload.exp * 1e3) <= Date.now();
}

export const getInvitationIdFromToken = (idToken: string): string => {
	const decodedIdToken = jwt_decode(idToken) as any;
	return decodedIdToken["invitationIdentifier"];
}
