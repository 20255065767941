import { Dispatch, SetStateAction, useCallback, useRef, useSyncExternalStore } from "react";
import { Unbinder, bindEventListener } from "../dom";
import { isReducer, isThunk } from "../utils";

/**
 * Configures the level of storage item synchronization.  
 * Valid values are `"none"`; `"local"`; or `"all"`.
 * 
 * `"none"`  
 * No synchronization occurs.  
 * Any changes to the storage item by either the local browsing context
 * or any other browsing contexts on the same origin, are ignored.
 * The backing storage area is for all intents and purposes treated as
 * write-only, save for obtaining the initial state.
 * 
 * `"local"`:  
 * Synchronization occurs within the local browsing context.  
 * Changes to the storage item by the local browsing context are
 * responded to and the hook will update its value accordingly.
 * Changes to the storage item by other browsing contexts on the same
 * origin, are ignored.
 * 
 * `"all"`:  
 * Synchronization occurs within the local browsing context and
 * across any other browsing contexts on the same origin.
 * All changes to the storage item are responded to and the hook
 * will update its value accordingly.
 */
export type StorageHookSynchronization = "none" | "local" | "all";

/**
 * Configures a serializer used to serialize a state value to
 * a storable string representation.
 */
export type StorageSerializer<S> = {
	/**
	 * @param state
	 * The state value to serialize.
	 * @returns
	 * The serialized string representation of the state value.
	 */
	(state: S): string;
}

/**
 * Configures a parser used to deserialize a stored string
 * representation to its original state value.
 */
export type StorageParser<S> = {
	/**
	 * @param value
	 * The string representation of the state value to parse.
	 * @returns
	 * The deserialized state value.
	 */
	(value: string): S | undefined;
}

/**
 * Configures a `useStorage` hook.
 */
export type StorageHookOptions<S> = {
	/**
	 * Configures the key of the storage item the `useStorage` hook will use.
	 */
	readonly key: string,

	/**
	 * Configures the storage implementation the `useStorage` hook will use.  
	 * Usually should be `window.sessionStorage` or `window.locationStorage`.
	 */
	readonly storageArea: Storage;

	/**
	 * Configures whether any changes to the storage item that originate from
	 * the same browsing context or from other browsing contexts on the same
	 * origin, should be responded to.
	 */
	readonly synchronize?: StorageHookSynchronization;

	/**
	 * Configures the serializer used to serialize the state value to
	 * a storable string representation.
	 * 
	 * When omitted will use `JSON.stringify`.
	 */
	readonly serializer?: StorageSerializer<S>;

	/**
	 * Configures the parser used to deserialize the stored string
	 * representation to its original state value.
	 * 
	 * **⚠️ CAUTION:**  
	 * When omitted will use an untyped `JSON.parse` that is _assumed_
	 * to match the type definition of the state value.
	 */
	readonly parser?: StorageParser<S>;
};

// Create an event target off of a symbol attached to the globalThis.
// This gives a singleton symbol across the browsing context which is
// what we are after.
// This is necessary because with Webpack bundling modules are not
// necessarily singleton. Duplicates can exist in multiple chunks when
// it performs bundle-splitting.
const symbol = Symbol.for("@cacao/use-storage-hook");
const eventTarget = (((globalThis as any)[symbol] as EventTarget) ??= new EventTarget());

/**
 * Call the `useLocalStorage` hook at the top level of your component to declare a {@link https://react.dev/learn/state-a-components-memory | state variable}
 * that is stored in the `localStorage` {@link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API | Web Storage API} storage area.
 * 
 * This is a shorthand convenience form of the `useStorage` hook.
 * @param key 
 * The key of the storage item the hook will use.
 * @param initialState
 * The value you want the state to be initially. It can be a value of any type that is
 * serializable to be stored in Web Storage, but there is special behavior for functions.
 * 
 * If you pass a function as `initialState`, it will be treated as an _initializer function_.  
 * It should be pure, should take no arguments, and should return a value of any type that is
 * serializable to be stored in Web Storage. React will call your initializer function whenever
 * there is no valid value persisted in Web Storage and store its return value as initial
 * state locally, but will not persist it to Web Storage.
 *
 * @param synchronize 
 * Configures whether and how the hook should synchronize with changes made to Web Storage
 * by other sources. When those changes are synchronized, it will trigger a re-render with
 * the updated state value.  
 * The default behavior of this hook when no value is provided is to _**not**_ synchronize.
 * 
 * @returns
 * `useLocalStorage` returns an array tuple containing exactly two values:
 * 1. The current state.
 * 2. The `set` function that lets you update the state to a different
 *    value and trigger a re-render.
 */
export const useLocalStorage = <S>(
	key: string,
	initialState: S | (() => S),
	synchronize?: StorageHookSynchronization
) => useStorage(initialState, {
	key,
	storageArea: globalThis.localStorage,
	synchronize: synchronize || "none"
});

/**
 * Call the `useSessionStorage` hook at the top level of your component to declare a {@link https://react.dev/learn/state-a-components-memory | state variable}
 * that is stored in the `sessionStorage` {@link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API | Web Storage API} storage area.
 * 
 * This is a shorthand convenience form of the `useStorage` hook.
 * @param key 
 * The key of the storage item the hook will use.
 * @param initialState
 * The value you want the state to be initially. It can be a value of any type that is
 * serializable to be stored in Web Storage, but there is special behavior for functions.
 * 
 * If you pass a function as `initialState`, it will be treated as an _initializer function_.  
 * It should be pure, should take no arguments, and should return a value of any type that is
 * serializable to be stored in Web Storage. React will call your initializer function whenever
 * there is no valid value persisted in Web Storage and store its return value as initial
 * state locally, but will not persist it to Web Storage.
 *
 * @param synchronize 
 * Configures whether and how the hook should synchronize with changes made to Web Storage
 * by other sources. When those changes are synchronized, it will trigger a re-render with
 * the updated state value.  
 * The default behavior of this hook when no value is provided is to _**not**_ synchronize.
 * 
 * @returns
 * `useSessionStorage` returns an array tuple containing exactly two values:
 * 1. The current state.
 * 2. The `set` function that lets you update the state to a different
 *    value and trigger a re-render.
 */
export const useSessionStorage = <S>(
	key: string,
	initialState: S | (() => S),
	synchronize?: StorageHookSynchronization
) => useStorage(initialState, {
	key,
	storageArea: globalThis.sessionStorage,
	synchronize: synchronize ?? "none"
});

/**
 * Call the `useStorage` hook at the top level of your component to declare a {@link https://react.dev/learn/state-a-components-memory | state variable}
 * that is stored in a configured {@link https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API | Web Storage API} storage area - i.e.
 * `sessionStorage` or `localStorage`.
 * 
 * @param initialState
 * The value you want the state to be initially. It can be a value of any type that is
 * serializable to be stored in Web Storage, but there is special behavior for functions.
 * 
 * If you pass a function as `initialState`, it will be treated as an _initializer function_.  
 * It should be pure, should take no arguments, and should return a value of any type that is
 * serializable to be stored in Web Storage. React will call your initializer function whenever
 * there is no valid value persisted in Web Storage and store its return value as initial
 * state locally, but will not persist it to Web Storage.
 * 
 * @param options
 * Options that configure the hook.  
 * Beyond the basic configuration of setting the storage area and the storage key,
 * custom (de)serialization logic can be configured, e.g. to support cases where
 * strongly typed serialization is preferred when using TypeScript.
 * 
 * The hook can also be configured to synchronize with changes to Web Storage, made either from
 * the local browsing context - i.e. from the same browser tab - or from any browsing context
 * operating on the same {@link https://developer.mozilla.org/en-US/docs/Glossary/Origin | origin}.
 * When synchronizing and receiving a new value it will trigger a re-render.
 * @returns
 * `useStorage` returns an array tuple containing exactly two values:
 * 1. The current state.
 * 2. The `set` function that lets you update the state to a different
 *    value and trigger a re-render.
 */
export const useStorage = <S>(
	initialState: S | (() => S),
	options: StorageHookOptions<S>
): [S, Dispatch<SetStateAction<S>>] => {
	const {
		key,
		storageArea,
		synchronize = "none",
		parser = parseJSONUnsafe<S>,
		serializer = value => JSON.stringify(value)
	} = options;

	const storageItem = useRef<StorageItem<S>>({});

	const initialThunk = useRef(isThunk(initialState)
		? initialState
		: () => initialState
	).current;

	const subscribe = useCallback((onChange: () => void) => {
		const unbinds: Unbinder[] = [];

		const listener = (event: StorageEvent) => {
			if (event.storageArea !== storageArea) return;
			if (event.key !== key) return;

			if (storageItem.current.item !== event.newValue)
				onChange();
		}

		switch (synchronize) {
			case "none":
				storageItem.current.onChange = onChange;
				unbinds.push(() => { storageItem.current.onChange = undefined });
				break;

			case "local":
				unbinds.push(
					bindEventListener(eventTarget, "storage", listener)
				);
				break;

			case "all":
				unbinds.push(
					bindEventListener(globalThis, "storage", listener),
					bindEventListener(eventTarget, "storage", listener)
				);
				break;
		}

		return () => {
			for (const unbind of unbinds) unbind();
		};

	}, [key, storageArea, synchronize]);

	const getSnapshot = useCallback(() => {
		const { current } = storageItem;

		try {
			const item = storageArea.getItem(key) ?? undefined;
			if (current.item !== item) {
				current.item = item;
				current.value = item == null ? undefined : parser(item);
			}
		} catch {
			current.item = undefined;
			current.value = undefined;
		}

		return current.value;
	}, [key, parser, storageArea]);

	const storageValue = useSyncExternalStore(
		subscribe,
		getSnapshot,
		() => undefined
	) ?? initialThunk();

	const setStorageValue = useCallback((value: SetStateAction<S>) => {
		const { current } = storageItem;
		const nextState = isReducer(value)
			? value(current.value ?? initialThunk())
			: value;

		const oldItem = current.item;
		const newItem = serializer(nextState);

		try {
			storageArea.setItem(key, newItem);
		} catch {
			// Intentionally swallowed.
			// Setting items in storage may fail for numerous privacy
			// and quota related issues.

			// TODO:
			// Could we turn success status into the setter's
			// return value?
		}

		// Signal changes as the last operation.
		// After both the short-circuited values have been set *and*
		// the actual storage is updated. This way any calling code
		// gets to see the coherent end state.
		current.onChange?.();

		// Dispatch a storage event to the dedicated event target.
		// If anything is listening within the same tab, it will know
		// about the update.
		// Storage events raised on the window object are only for
		// *other* tabs and because we want to treat both separately
		// with different synchronization levels *and* don't want to
		// mess with how the DOM APIs are supposed to behave, we emit
		// this event over a separate EventTarget.
		eventTarget.dispatchEvent(new StorageEvent("storage", {
			key,
			storageArea,
			oldValue: oldItem,
			newValue: newItem,
			url: window.location.href
		}));
	}, [initialThunk, key, serializer, storageArea]);

	return [storageValue, setStorageValue];
}

function parseJSONUnsafe<S>(value: string): S | undefined {
	try {
		// Have to assume the type here. Dangerous, but no way to avoid other
		// than using something like JSON Schema to validate it.
		const parsed = JSON.parse(value);
		return parsed == null ? undefined : parsed as S;
	} catch {
		return undefined;
	}
}

type StorageItem<S> = {
	value?: S,
	item?: string;
	onChange?: () => void;
}
