import "./Assets/styles/master.scss";
import "src/env";

import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider as ReduxProvider } from "react-redux";
import { TelemetryProvider } from "./common/telemetry/TelemetryProvider";
import { i18n } from "./common/i18n";
import { store } from "./common/redux";
import { App } from "./App";

const element = document.getElementById("root");
if (element == null) throw new Error("Could not find root element.");

const root = createRoot(element, { identifierPrefix: "kko-" });

root.render(
	<TelemetryProvider>
		<ReduxProvider store={store}>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</ReduxProvider>
	</TelemetryProvider>
)

