/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetMinimalProfileByAccountIdResponseModel } from '../models/GetMinimalProfileByAccountIdResponseModel';
import type { GetMultipleDisplayNamesByAccountIdsResponseModel } from '../models/GetMultipleDisplayNamesByAccountIdsResponseModel';
import type { GetMultiProfileNameByAccountIdsResponseModel } from '../models/GetMultiProfileNameByAccountIdsResponseModel';
import type { GetProfileByAccountIdResponseModel } from '../models/GetProfileByAccountIdResponseModel';
import type { GetProfileByCurrentUserAccountIdResponseModel } from '../models/GetProfileByCurrentUserAccountIdResponseModel';
import type { GetProfileDisplayNameByAccountIdResponseModel } from '../models/GetProfileDisplayNameByAccountIdResponseModel';
import type { GetProfilesByEpisodeIdResponseModel } from '../models/GetProfilesByEpisodeIdResponseModel';
import type { UpdateProfileRequestModel } from '../models/UpdateProfileRequestModel';
import type { Visibility } from '../models/Visibility';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfilesService {

    /**
     * Returns back information about a profile.
     * @param acceptLanguage The culture code used for the request.
     * @param maxVisibility The maximum visibility to return.
     * @returns GetProfileByCurrentUserAccountIdResponseModel Success
     * @throws ApiError
     */
    public static getProfileByCurrentUserAccountId(
        acceptLanguage: string,
        maxVisibility?: Visibility,
    ): CancelablePromise<GetProfileByCurrentUserAccountIdResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/current/profile',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'maxVisibility': maxVisibility,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Returns back information about a profile.
     * @param accountId The account id of the profile that needs to be retrieved.
     * @param acceptLanguage The culture code used for the request.
     * @param maxVisibility The maximum visibility to return.
     * @returns GetProfileByAccountIdResponseModel Success
     * @throws ApiError
     */
    public static getProfileByAccountId(
        accountId: string,
        acceptLanguage: string,
        maxVisibility?: Visibility,
    ): CancelablePromise<GetProfileByAccountIdResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/{accountId}/profile',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'maxVisibility': maxVisibility,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Updates a profile.
     * @param accountId The account id of the profile that needs to be updated.
     * @param acceptLanguage The culture code used for the request.
     * @param requestBody The profile that needs to be updated.
     * @returns any Created
     * @throws ApiError
     */
    public static updateProfile(
        accountId: string,
        acceptLanguage: string,
        requestBody?: UpdateProfileRequestModel,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/accounts/{accountId}/profile',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                412: `Client Error`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get the minimal profile information for a profile by account id.
     * @param accountId The account id of the profile that needs to be retrieved.
     * @param acceptLanguage The culture code used for the request.
     * @param maxVisibility The maximum visibility to return.
     * @returns GetMinimalProfileByAccountIdResponseModel Success
     * @throws ApiError
     */
    public static getMinimalProfileByAccountId(
        accountId: string,
        acceptLanguage: string,
        maxVisibility?: Visibility,
    ): CancelablePromise<GetMinimalProfileByAccountIdResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/{accountId}/minimal-profile',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'maxVisibility': maxVisibility,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Retrieves the display name for a user's profile by their account ID asynchronously.
     * This endpoint bypasses the visibility check in case you are invited, or in existing network connection.
     * @param accountId The unique identifier of the account.
     * @param acceptLanguage The culture code used for the request.
     * @returns GetProfileDisplayNameByAccountIdResponseModel Success
     * @throws ApiError
     */
    public static getDisplayNameByAccountId(
        accountId: string,
        acceptLanguage: string,
    ): CancelablePromise<GetProfileDisplayNameByAccountIdResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/{accountId}/display-name',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Retrieves the profile names for multiple users by their account IDs asynchronously.
     * This endpoint bypasses the visibility check in case you are invited, or in existing network connection.
     * @param acceptLanguage The culture code used for the request.
     * @param accountIds The account ids of the users.
     * @returns GetMultiProfileNameByAccountIdsResponseModel Success
     * @throws ApiError
     */
    public static getMultiProfileNameByAccountIds(
        acceptLanguage: string,
        accountIds?: Array<string>,
    ): CancelablePromise<GetMultiProfileNameByAccountIdsResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/profiles/names',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'accountIds': accountIds,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Retrieves the display name for multiple user's profile by their account IDs.
     * @param acceptLanguage The culture code used for the request.
     * @param accountIds The unique identifiers of the accounts.
     * @returns GetMultipleDisplayNamesByAccountIdsResponseModel Success
     * @throws ApiError
     */
    public static getMultipleDisplayNamesByAccountIds(
        acceptLanguage: string,
        accountIds?: Array<string>,
    ): CancelablePromise<GetMultipleDisplayNamesByAccountIdsResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/display-names',
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'accountIds': accountIds,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Retrieves all profiles of the episode members of a given episode.
     * @param episodeId
     * @param acceptLanguage The culture code used for the request.
     * @returns GetProfilesByEpisodeIdResponseModel Success
     * @throws ApiError
     */
    public static getProfilesByEpisodeId(
        episodeId: string,
        acceptLanguage: string,
    ): CancelablePromise<GetProfilesByEpisodeIdResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/{episodeId}/profiles',
            path: {
                'episodeId': episodeId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                500: `Server Error`,
            },
        });
    }

}
