import { FC } from "react";
import { useTranslation } from "react-i18next";

export const Loader: FC = () => {
	const { t } = useTranslation();

	return <div className="kko-loader-container kko-loader-container--stretch" data-loader-active={true}>
		<div className="kko-loader">
			<span className="visually-hidden">{t("kko:general.loading")}</span>
		</div>
	</div>
}