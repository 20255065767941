import { FC } from "react"
import { useTranslation } from "react-i18next";
import { ProblemDetails } from "src/common/redux"
import { Icon } from "src/modules/shared/components/Icon";

type Props = {
	problem: ProblemDetails;
}

export const ProblemDetailsMotivator: FC<Props> = ({ problem }) => {
	const { t } = useTranslation("kko", { keyPrefix: "core.errors" });

	const errors = problem.errors && typeof problem.errors === "object"
		? (
			<dl className="kko-errors-kvp">
				{Object.entries(problem.errors).map(([name, value]) => (
					<>
						<dt>{name}</dt>
						{Array.isArray(value)
							? value.map(value => (<dd>{String(value)}</dd>))
							: <dd>{String(value)}</dd>
						}
					</>
				))}
			</dl>
		)
		: null;

	const { title, detail } = problem.title
		? {
			title: problem.title,
			detail: problem.detail
		}
		: {
			title: t("generic.title"),
			detail: problem.detail || (errors ? null : t("generic.detail", { defaultValue: "" }))
		};

	return (
		<article
			className="kko-motivator kko-motivator--compact"
			role="alert"
			data-appearance="error"
		>
			<Icon className="kko-motivator__icon kko-icon" icon="times-circle" />
			<div className="kko-motivator__message">
				<strong>{title}</strong>
				{detail && <p>{detail}</p>}
				{errors}
			</div>
		</article>
	);
};