import { useCallback, useId, useRef } from "react"

/**
 * Creates a unique ID associated with the passed in object based
 * on its object identity, using
 * {@linkcode https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/is | Object.is}.
 */
type IdFactory = (object: object) => string;

/**
 * Call `useMappedIds` at the top level of your component to create
 * a factory function capable of generating unique IDs for individual
 * objects.  
 * The returned function supports generating a dynamic number of IDs,
 * keyed to the object identity of the passed in item (using `Object.is`).
 * 
 * @description
 * The basic {@linkcode https://react.dev/reference/react/useId | useId} hook
 * only supports returning a single unassociated ID.
 * React's {@link https://react.dev/warnings/invalid-hook-call-warning | Rules of Hooks}
 * requires that hooks are called non-conditionally. This means the `useId` hook can not
 * be called from inside conditional blocks _or_ from loop constructs. It can only be
 * used for static pre-defined quantities and cannot handle the dynamic nature of a list
 * of items needing IDs.
 * The factory function returned by the `useMappedIds` hook is more computationally
 * heavy - i.e. should not be used where `useId` suffices - but _can_ support
 * a changing number of IDs.
 * 
 * @returns The ID factory function.  
 * It can be called with any object to produce a stable ID for that object.
 * As {@linkcode https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/WeakMap| WeakMap}
 * is used internally, it *must* be an object. Primitives are not supported.
 * 
 * @example
 * The following example uses generated IDs as keys for `li` elements of a list, in absence
 * of any property on the `Item` type that would qualify as a natural key.
 * 
 * ```tsx
 * type Props = { items : readonly Item[] };
 * 
 * const Component: FC<Props> = ({ items }) => {
 *   const mapId = useMappedIds();
 *
 *   return (
 *     <ul>
 *       {items.map(item => (
 *         <li key={mapId(item)}>{item.value}</li>
 *       ))}
 *     </ul>
 *   );
 * };
 * ```
 */
export function useMappedIds(): IdFactory {
	const root = useId();
	const ref = useRef({ map: new WeakMap<object, string>(), count: 0 });

	return useCallback((object: object): string => {
		const { current } = ref;

		let id = current.map.get(object);
		if (id) return id;

		// Avoid collisions with the paths down from the render root that
		// `useId` creates, by repeating the closing `:`.
		// E.g. for a path `:r1:r2:` we create `:r1:r2::0:`, `:r1:r2::1:`, etc. 
		id = `${root}:${++current.count}:`;
		current.map.set(object, id);

		return id;
	}, [root]);
}