import { StatefulChatClient, createStatefulChatClient, fromFlatCommunicationIdentifier } from "@azure/communication-react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { AzureCommunicationTokenCredential, CommunicationUserIdentifier } from "@azure/communication-common";
import { useIsAuthenticated } from "@azure/msal-react"
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ChatMessageReceivedEvent } from "@azure/communication-chat";
import { setLastMessageDate, setUnreadMessagesCount } from "src/redux/slices/chatsSlice";
import { useGetChatThreadsQuery, useGetCommunicationAccessTokenQuery, useGetCommunicationUserIdentityQuery } from "src/apis/chat";
import { useGetDisplayNameByAccountIdQuery } from "src/apis/network";
import { usePlatformAccount } from "src/modules/shared/hooks";
import { useLocale } from "src/common/hooks";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { ChatThreadResponseModel } from "src/open-api/chatService";

export function useChatClient() {

	const locale = useLocale();
	const dispatch = useAppDispatch();
	const isAuthenticated = useIsAuthenticated();

	const { data: userIdentityResponse } = useGetCommunicationUserIdentityQuery(isAuthenticated ? undefined : skipToken);
	const { data: chatAccessTokenResponse } = useGetCommunicationAccessTokenQuery(userIdentityResponse?.communicationUserIdentity ? {
		userIdentity: userIdentityResponse.communicationUserIdentity
	} : skipToken);

	const { accountId } = usePlatformAccount();
	const { data: { displayName } = {} } = useGetDisplayNameByAccountIdQuery(locale && accountId ? { "Accept-Language": locale, accountId } : skipToken);

	const { data: { items: chatThreads } = {}, refetch } = useGetChatThreadsQuery(chatAccessTokenResponse?.communicationAccessToken ? {
		"X-Chat-Access-Token": chatAccessTokenResponse.communicationAccessToken
	} : skipToken);

	// refs needed in order to pass up-to-date values to ACS callback
	const chatThreadsRef = useRef<ChatThreadResponseModel[] | undefined>();
	chatThreadsRef.current = chatThreads;
	const focusedThreadIdRef = useRef<string | undefined>();
	const focusedThreadId = useAppSelector(state => state.chat.focusedThreadId);
	focusedThreadIdRef.current = focusedThreadId;

	const userId = useMemo(
		() => {
			if (!userIdentityResponse?.communicationUserIdentity) {
				return undefined;
			}
			return fromFlatCommunicationIdentifier(userIdentityResponse.communicationUserIdentity) as CommunicationUserIdentifier
		},
		[userIdentityResponse?.communicationUserIdentity]
	);

	const credential = useMemo(() => {
		if (!chatAccessTokenResponse?.communicationAccessToken) {
			return undefined;
		}
		try {
			return new AzureCommunicationTokenCredential(chatAccessTokenResponse?.communicationAccessToken);
		} catch {
			console.error("Failed to construct token credential");
			return undefined;
		}
	}, [chatAccessTokenResponse?.communicationAccessToken]);

	const [statefulChatClient, setStatefulChatClient] = useState<StatefulChatClient>();

	const isMessageOnFocusedThread = useCallback((threadId: string) => {
		const focuesChatThread = (chatThreadsRef.current ?? []).find(ct => ct.id === focusedThreadIdRef?.current);
		return threadId === focuesChatThread?.externalChatThreadId;
	}, []);


	const refreshThreadState = useCallback(async (threadId: string, statefulChatClient: StatefulChatClient) => {
		if (!userIdentityResponse?.communicationUserIdentity || !statefulChatClient) {
			return;
		}
		if (isMessageOnFocusedThread(threadId)) {
			return;
		}
		const chatThreadClient = statefulChatClient.getChatThreadClient(threadId);
		let lastReadReceiptDate = undefined;
		let unreadMessages = 0;
		for await (const readReceipt of chatThreadClient.listReadReceipts({ maxPageSize: 1 })) {
			if (readReceipt?.readOn && readReceipt.sender.kind === "communicationUser" && readReceipt.sender.communicationUserId === userIdentityResponse.communicationUserIdentity) {
				lastReadReceiptDate = readReceipt.readOn;
				break;
			}
		}
		let messageLoopBreak = false;
		for await (const messages of chatThreadClient.listMessages({ startTime: lastReadReceiptDate }).byPage()) {
			for (const message of messages) {
				if (lastReadReceiptDate && message.createdOn <= lastReadReceiptDate) {
					messageLoopBreak = true;
					break;
				}
				if (message.sender?.kind === "communicationUser" && message.sender?.communicationUserId !== userIdentityResponse?.communicationUserIdentity) {
					unreadMessages++;
				}
			}
			if (messageLoopBreak) {
				break;
			}
		}
		dispatch(setUnreadMessagesCount({ unreadMessagesCount: unreadMessages, threadId: chatThreadClient.threadId }));
	}, [userIdentityResponse?.communicationUserIdentity, dispatch, isMessageOnFocusedThread]);

	const onMessageReceived = useCallback(async (message: ChatMessageReceivedEvent, statefulChatClient: StatefulChatClient) => {
		if (!userIdentityResponse?.communicationUserIdentity || !chatAccessTokenResponse?.communicationAccessToken) {
			return;
		}
		if (message?.sender?.kind === "communicationUser" &&
			message.sender.communicationUserId !== userIdentityResponse.communicationUserIdentity) {
			refreshThreadState(message.threadId, statefulChatClient);
			refetch();
			dispatch(setLastMessageDate((new Date()).toISOString()));
		}
	}, [userIdentityResponse?.communicationUserIdentity, chatAccessTokenResponse?.communicationAccessToken, refreshThreadState, refetch, dispatch]);

	useEffect(() => {
		if (statefulChatClient || !userId || !displayName || !credential || !chatAccessTokenResponse?.communicationAccessToken) {
			return;
		}
		const chatClient = createStatefulChatClient({
			userId: userId,
			displayName: displayName,
			endpoint: process.env.REACT_APP_COMMUNICATION_SERVICE_ENDPOINT,
			credential: credential
		});
		// Listen to notifications
		chatClient.startRealtimeNotifications();
		chatClient.on("chatMessageReceived", (message: ChatMessageReceivedEvent) => {
			onMessageReceived(message, chatClient);
		});
		setStatefulChatClient(chatClient);
	}, [credential, displayName, userId, onMessageReceived, statefulChatClient, chatAccessTokenResponse?.communicationAccessToken]);


	useEffect(() => {
		if (!statefulChatClient) {
			return;
		}
		if (chatThreads) {
			chatThreads.forEach(ct => {
				refreshThreadState(ct.externalChatThreadId, statefulChatClient);
			})
		}
	}, [statefulChatClient, chatThreads, refreshThreadState]);

	return { statefulChatClient } as const;
}

