import { ComponentProps, FocusEventHandler, KeyboardEventHandler, MouseEventHandler, useCallback, useId, useMemo } from "react";
import { Unbinder, bindEventListener } from "src/common/dom";
import { LiteralUnionSet } from "../../utils";
import { PropsAction, useControlledReducer } from "../use-controlled-reducer";
import { useMappedIds } from "../use-mappped-ids";
import { useRefEffect } from "../use-ref-effect";

const KeyboardKeys = new LiteralUnionSet("ArrowDown", "ArrowUp", "Home", "End", "Escape", "Enter", " ", "Tab");
type KeyboardKey = LiteralUnionSet.Literal<typeof KeyboardKeys>;

/**
 * An equality comparison function.
 * @template T The type to compare for equality.
 */
interface EqualityComparison<T> {
	/**
	 * Compares two values for equality.
	 * @param a The first value to compare.
	 * @param b The second value to compare.
	 * @returns `true` when `a` and `b` should be considered equal; otherwise, `false`.
	 */
	(a: T | undefined, b: T | undefined): boolean;
}

type UserKeyboardAction = {
	type: "Keyboard",
	key: KeyboardKey,
	alt?: boolean
};

type UserOptionAction<TOption extends object> = {
	type: "FocusOption" | "SelectOption",
	option: TOption
}

type UserComboAction = {
	type: "FocusOut" | "ToggleList"
}

type CompositeAction<TOption extends object> =
	| UserKeyboardAction
	| UserComboAction
	| UserOptionAction<TOption>
	| PropsAction<AriaComboboxControlledProps<TOption>>;

type AriaComboboxState<TOption extends object> = {
	active: TOption | undefined;
	disabled: boolean;
	expanded: boolean;
	multiple: boolean;
	options: readonly TOption[];
	selected: readonly TOption[];
};

export type AriaComboboxProps<TOption extends object> = Readonly<{
	disabled?: boolean;
	multiple?: boolean;
	options: readonly TOption[];
	selected?: TOption | readonly TOption[];

	/**
	 * An equality comparison function used to compare option
	 * elements. Replaces the use of `Object.is` when specified.
	 *
	 * This property binds on first render and is not changed with
	 * subsequent renders. The function used must either be pure or
	 * it must have dependencies which do not change.
	 */
	is?: EqualityComparison<TOption>;

	/**
	 * A callback function that is executed when the selected
	 * options change.
	 * @param options The selected options.
	 */
	onChange?: (options: readonly TOption[]) => void;
}>;

type AriaComboboxControlledProps<TOption extends object> =
	Omit<AriaComboboxProps<TOption>, "is" | "onChange">;

type ComboboxProps = Omit<Readonly<
	& Required<Pick<ComponentProps<"var">,
		| "role"
		| "onClick"
		| "onKeyDown"
		| "onBlur"
		| "aria-autocomplete"
		| "aria-controls"
		| "aria-expanded"
		| "aria-haspopup"
	>>
	& Pick<ComponentProps<"var">, "aria-activedescendant">
>, never>;

type ListboxProps = Omit<Readonly<
	& Required<Pick<ComponentProps<"var">,
		| "id"
		| "role"
		| "hidden"
		| "tabIndex"
	>>
	& Pick<ComponentProps<"var">,
		| "aria-multiselectable"
	>
	& {
		ref: <T extends HTMLElement>(element: T | null) => void
	}
>, never>;

type Booleanish = boolean | "true" | "false";
type OptionProps = Omit<Readonly<
	& Required<Pick<ComponentProps<"var">,
		| "id"
		| "key"
		| "role"
		| "onPointerDownCapture"
		| "onPointerEnter"
	>>
	& Pick<ComponentProps<"var">,
		| "aria-current"
		| "aria-selected"
	>
	& {
		"data-active"?: Booleanish
	}
>, never>;

type AriaComboboxReturn<TOption extends object> = Readonly<{
	state: Readonly<AriaComboboxState<TOption>>;
	comboboxProps: ComboboxProps;
	listboxProps: ListboxProps;
	getOptionProps: (option: TOption) => OptionProps;
	isActiveOption: (option: TOption) => boolean;
	isSelectedOption: (option: TOption) => boolean;
}>;

const updateSelection = <TOption>(
	current: readonly TOption[],
	operation: "set" | "toggle" | "add",
	value: TOption | undefined
): readonly TOption[] => {
	if (value === undefined) return current;

	switch (operation) {
		case "set":
			return [value];
		case "add":
			return [...new Set(current).add(value)];
		case "toggle":
			const set = new Set(current);
			if (set.has(value)) {
				set.delete(value);
			} else {
				set.add(value);
			}
			return [...set];
	}
}

// TypeScript does not narrow the type of readonly arrays correctly
// for the Array.isArray function.
// The following is the most conservative way to fix it, which is
// also used internally by the TypeScript team itself.
// See: https://github.com/microsoft/TypeScript/issues/17002#issuecomment-1477626624
const isArray = Array.isArray as (arg: any) => arg is readonly unknown[];

export function useAriaCombobox<
	TOption extends object,
>({
	is = Object.is,
	onChange,
	...props
}: AriaComboboxProps<TOption>): AriaComboboxReturn<TOption> {
	// The 'is' property can be set once at first render and
	// after this point is fixed. The function should be pure.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	is = useCallback(is, []);

	const propsReducer = useCallback((
		prevState: AriaComboboxState<TOption>,
		action: PropsAction<AriaComboboxControlledProps<TOption>>
	) => {
		let {
			active,
			disabled,
			expanded,
			multiple,
			options,
			selected
		} = prevState;

		if (false
			|| action.changed.has("multiple")
			|| action.changed.has("selected")
			|| action.changed.has("options")
		) {
			let shouldReselect = false;

			if (action.changed.has("multiple")) {
				multiple = action.props.multiple ?? false;
				shouldReselect = !multiple; // May need to clip selection.
			}

			if (action.changed.has("options")) {
				options = action.props.options;
				expanded = !!options.length && expanded;
				active = active && options.find(option => is(active, option));
			}

			if (action.changed.has("selected")) {
				selected = action.props.selected != null
					? isArray(action.props.selected)
						? action.props.selected
						: [action.props.selected]
					: [];

				shouldReselect = true;
			}

			if (shouldReselect) {
				selected = [...selected.reduce<Set<TOption>>((aggr, current) => {
					// If the props do not allow multiple selections and we've
					// remapped at least one valid selected item into the canonical
					// options, we should stop. This means the first (valid) option
					// is kept as selected when switching from multi-select down to
					// single-select.
					if (!multiple && aggr.size !== 0) return aggr;

					const found = options.find(option => is(current, option));
					if (found !== undefined) aggr.add(found);
					return aggr;
				}, new Set())];
			}
		}

		if (action.changed.has("disabled")) {
			disabled = action.props.disabled ?? false;
			if (disabled) {
				active = undefined;
				expanded = false;
			}
		}

		return {
			...prevState,
			expanded,
			disabled,
			options,
			active,
			selected
		};
	}, [is]);

	const keyboardReducer = useCallback((
		prevState: AriaComboboxState<TOption>,
		action: UserKeyboardAction
	): AriaComboboxState<TOption> => {
		const {
			options,
			disabled,
			expanded,
			multiple,
			selected,
			active = prevState.selected.at(-1)
		} = prevState;

		if (disabled) return prevState;

		// If we don't have any options, keyboard interaction shouldn't be able
		// to accomplish anything.
		if (!options.length) return prevState;

		switch (action.key) {
			case "ArrowDown": {
				if (!expanded)
					return { ...prevState, expanded: true, active: active ?? prevState.options[0] };

				if (action.alt)
					return prevState;

				const index = active
					? options.findIndex(item => is(item, active)) + 1
					: 0;

				return (index < options.length)
					? { ...prevState, active: options[index] }
					: prevState;
			}

			case "ArrowUp": {
				if (!expanded)
					return { ...prevState, expanded: true, active: active ?? prevState.options[0] };

				if (action.alt)
					return {
						...prevState,
						active: undefined,
						expanded: false,
						selected: updateSelection(
							selected,
							multiple ? "add" : "set",
							active
						)
					};

				const index = options.findIndex(item => is(item, active)) - 1;
				return (index !== -1)
					? { ...prevState, active: options[Math.max(0, index)] }
					: prevState;
			}

			case "Home":
				return { ...prevState, expanded: true, active: options[0] };

			case "End":
				return { ...prevState, expanded: true, active: options.at(-1) };

			case "Escape":
				return expanded
					? { ...prevState, expanded: false, active: undefined }
					: prevState;

			case "Enter":
			case " ":
				if (!expanded) {
					return {
						...prevState,
						active: active ?? prevState.options[0],
						expanded: true
					};
				}

				return {
					...prevState,
					active: undefined,
					expanded: false,
					selected: updateSelection(
						selected,
						multiple ? "toggle" : "set",
						active
					)
				};

			case "Tab":
				if (!expanded) return prevState;

				const updated = updateSelection(
					selected,
					multiple ? "add" : "set",
					active
				);

				return (updated === selected)
					? prevState
					: {
						...prevState,
						selected: updated
					};

			default:
				return prevState;
		}
	}, [is]);

	const focusOutReducer = useCallback((
		prevState: AriaComboboxState<TOption>
	): AriaComboboxState<TOption> => {
		const { expanded } = prevState;
		return expanded
			? { ...prevState, expanded: false, active: undefined }
			: prevState;
	}, []);

	const optionReducer = useCallback((
		prevState: AriaComboboxState<TOption>,
		action: UserOptionAction<TOption>
	): AriaComboboxState<TOption> => {
		const { disabled, multiple, options, selected } = prevState;
		if (disabled) return prevState;

		const option = options.find(option => is(option, action.option));

		switch (action.type) {
			case "FocusOption":
				return { ...prevState, active: option };
			case "SelectOption":
				return {
					...prevState,
					active: undefined,
					expanded: false,
					selected: updateSelection(
						selected,
						multiple ? "toggle" : "set",
						option
					)
				};
			default:
				return prevState;
		}
	}, [is]);

	const reducer = useCallback((
		prevState: AriaComboboxState<TOption>,
		action: CompositeAction<TOption>
	): AriaComboboxState<TOption> => {

		switch (action.type) {
			case "FocusOut":
				return focusOutReducer(prevState);
			case "Keyboard":
				return keyboardReducer(prevState, action);
			case "ToggleList":
				const { disabled, expanded, selected } = prevState;
				if (disabled) return prevState;

				return {
					...prevState,
					expanded: !expanded,
					active: expanded ? selected[0] : undefined
				};
			case "FocusOption":
			case "SelectOption":
				return optionReducer(prevState, action);
			case "PropsUpdate":
				return propsReducer(prevState, action);
			default:
				return prevState;
		}
	}, [focusOutReducer, keyboardReducer, optionReducer, propsReducer]);

	const [state, dispatch, listen] = useControlledReducer({
		// This type actually *is* correct, but TypeScript doesn't preserve
		// the compatibility correctly across the use of the spread operator
		// because it is typed as a generic `TProps` and we use type inference
		// to dig back out the TOption type and the IsMultiple flag.
		props,
		reducer,
		initializer: props => {
			let {
				disabled = false,
				multiple = false,
				selected = []
			} = props;

			if (!isArray(selected)) {
				selected = selected == null ? [] : [selected];
			}

			return {
				...props,
				disabled,
				multiple,
				active: undefined,
				expanded: false,
				selected: multiple
					? selected
					: selected.slice(0, 1)
			};
		}
	});

	listen((state, prevState, action) => {
		if (!prevState || !action || action.type === "PropsUpdate") return;
		if (prevState.selected === state.selected) return;

		onChange?.(state.selected);
	});

	const onKeyDown: KeyboardEventHandler<HTMLElement> = useCallback(event => {
		if (event.defaultPrevented) return;

		if (KeyboardKeys.has(event.key)) {
			if (event.key !== "Tab") { event.preventDefault(); }
			dispatch({ type: "Keyboard", key: event.key, alt: event.altKey });
		}
	}, [dispatch]);

	const onBlur: FocusEventHandler<HTMLElement> = useCallback(event => {
		dispatch({ type: "FocusOut" });
	}, [dispatch]);

	const onComboClick: MouseEventHandler<HTMLElement> = useCallback(() => {
		dispatch({ type: "ToggleList" });
	}, [dispatch]);

	const listboxId = useId();
	const createId = useMappedIds();

	const createOptionId = useCallback((option: TOption) => {
		// If we don't use `Object.is` object identity, then always
		// try to map back to the current state list for the authorative
		// option instance.
		if (is !== Object.is) {
			option = state.options.find(x => is(x, option)) ?? option;
		}

		return createId(option);
	}, [is, createId, state.options]);

	const listboxRef = useRefEffect(element => {
		const unbinders: Unbinder[] = [];

		unbinders.push(bindEventListener(element, "touchstart", event => {
			event.preventDefault();
			event.stopImmediatePropagation();
		}, { passive: false }));

		unbinders.push(bindEventListener(element, "pointerdown", event => {
			event.preventDefault();
			event.stopImmediatePropagation();
		}, { passive: false }));

		unbinders.push(bindEventListener(element, "mousedown", event => {
			event.preventDefault();
			event.stopImmediatePropagation();
		}, { passive: false }));

		return () => {
			for (const unbinder of unbinders) { unbinder() }
		}
	});

	const comboboxProps: ComboboxProps = useMemo(() => {
		return {
			role: "combobox",
			"aria-autocomplete": "none",
			"aria-controls": listboxId,
			"aria-expanded": state.expanded,
			"aria-haspopup": "listbox",
			"aria-activedescendant": state.active ? createOptionId(state.active) : undefined,
			onClick: onComboClick,
			onBlur,
			onKeyDown
		};
	}, [listboxId, state, createOptionId, onComboClick, onBlur, onKeyDown]);

	const listboxProps: ListboxProps = useMemo(() => ({
		id: listboxId,
		ref: listboxRef,
		role: "listbox",
		hidden: !state.expanded,
		tabIndex: -1,
		"aria-multiselectable": state.multiple || undefined
	}), [listboxId, listboxRef, state.expanded, state.multiple]);

	const isActiveOption = useCallback((option: TOption) => {
		return !!(state.active && is(state.active, option));
	}, [is, state]);

	const isSelectedOption = useCallback((option: TOption) => {
		return state.selected.some(selected => is(selected, option));
	}, [is, state]);

	const getOptionProps = useCallback((option: TOption): OptionProps => {
		const id = createOptionId(option);
		return {
			id,
			key: `option-${id}`,
			role: "option",
			"aria-selected": isSelectedOption(option) ? true : (state.multiple ? false : undefined),
			"aria-current": isActiveOption(option) ? "location" : undefined,
			onPointerEnter: () => dispatch({ type: "FocusOption", option }),
			onPointerDownCapture: () => {
				// Executes at least one event loop tick later, after the event has gone
				// through its bubbling phase, where it will be cancelled and stopped by
				// the native listeners that are registered with passive:false.
				setTimeout(() => {
					dispatch({ type: "SelectOption", option });
				}, 0);
			},
		};
	}, [state.multiple, createOptionId, isActiveOption, isSelectedOption, dispatch]);


	return {
		state,
		comboboxProps,
		listboxProps,
		getOptionProps,
		isActiveOption,
		isSelectedOption
	};
};
