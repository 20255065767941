import type { RouteObject } from "react-router-dom";

export const routes: RouteObject[] = [{
	path: "styleguide",
	lazy: async () => ({ Component: (await import("./pages")).Layout }),
	children: [{
		index: true,
		lazy: async () => ({ Component: (await import("./pages")).Main })
	}, {
		path: "buttons",
		lazy: async () => ({ Component: (await import("./pages")).Buttons })
	}, {
		path: "colors",
		lazy: async () => ({ Component: (await import("./pages")).Colors })
	}, {
		path: "icons",
		lazy: async () => ({ Component: (await import("./pages")).Icons })
	}, {
		path: "inputs",
		lazy: async () => ({ Component: (await import("./pages")).Inputs })
	}, {
		path: "layout",
		lazy: async () => ({ Component: (await import("./pages")).Grid })
	}, {
		path: "miscellaneous",
		lazy: async () => ({ Component: (await import("./pages")).Miscellaneous })
	}, {
		path: "mobiscroll",
		lazy: async () => ({ Component: (await import("./pages")).Mobiscroll })
	}, {
		path: "modules",
		lazy: async () => ({ Component: (await import("./pages")).Modules })
	}, {
		path: "notifications",
		lazy: async () => ({ Component: (await import("./pages")).Notifications })
	}, {
		path: "tickables",
		lazy: async () => ({ Component: (await import("./pages")).Tickables })
	}, {
		path: "typography",
		lazy: async () => ({ Component: (await import("./pages")).Typography })
	}]
}];