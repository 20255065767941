/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DeleteLanguagePreferenceResponseModel } from '../models/DeleteLanguagePreferenceResponseModel';
import type { GetAllPreferencesResponseModel } from '../models/GetAllPreferencesResponseModel';
import type { GetPreferenceResponseModel } from '../models/GetPreferenceResponseModel';
import type { PreferenceTypeModel } from '../models/PreferenceTypeModel';
import type { SaveLanguagePreferenceRequestModel } from '../models/SaveLanguagePreferenceRequestModel';
import type { SaveLanguagePreferenceResponseModel } from '../models/SaveLanguagePreferenceResponseModel';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PreferencesService {

    /**
     * Saves language preference for current logged in user
     * @param requestBody The user preference to be saved.
     * @returns SaveLanguagePreferenceResponseModel Success
     * @throws ApiError
     */
    public static saveLanguagePreference(
        requestBody?: SaveLanguagePreferenceRequestModel,
    ): CancelablePromise<SaveLanguagePreferenceResponseModel> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/user-preferences/me/language',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                412: `Client Error`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes language preference for current logged in user
     * @returns DeleteLanguagePreferenceResponseModel Success
     * @throws ApiError
     */
    public static deleteLanguagePreference(): CancelablePromise<DeleteLanguagePreferenceResponseModel> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/user-preferences/me/language',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get a preference for current logged in user
     * @param preferenceType
     * @returns GetPreferenceResponseModel Success
     * @throws ApiError
     */
    public static getPreference(
        preferenceType: PreferenceTypeModel,
    ): CancelablePromise<GetPreferenceResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user-preferences/me/{preferenceType}',
            path: {
                'preferenceType': preferenceType,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Get all preferences for current logged in user
     * @returns GetAllPreferencesResponseModel Success
     * @throws ApiError
     */
    public static getAllPreferences(): CancelablePromise<GetAllPreferencesResponseModel> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/user-preferences/me',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
