import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from "@azure/msal-react";
import { FC, useMemo } from "react";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { ChatClientProvider } from "@azure/communication-react";
import { i18n } from "src/common/i18n";
import { useAppDispatch } from "src/common/redux";
import { Login } from "src/modules/login/Login";
import { fetchProfile } from "src/redux/slices/profileSlice";
import { fetchAllPreferences } from "src/redux/slices/preferencesSlice";
import { useAsyncEffect } from "src/common/hooks";
import { useChatClient } from "src/modules/chat/hooks/use-chat-client";
import Header from "./navigation/header/Header";
import SideMenu from "./navigation/side-menu/SideMenu";

export const AppLayout: FC = () => {

	const isAuthenticated = useIsAuthenticated();

	const dispatch = useAppDispatch();

	useAsyncEffect(async (signal) => {
		if (isAuthenticated) {
			await dispatch(fetchAllPreferences());
			if (signal.aborted) return;

			await i18n.changeLanguage();
			document.documentElement.lang = i18n.resolvedLanguage ?? "en";

			await dispatch(fetchProfile({}));
		}
	}, [isAuthenticated, dispatch]);

	const { statefulChatClient } = useChatClient();

	const mainContent = useMemo(() => (
		<>
			<ScrollRestoration />
			<div className="kko-page">
				<div className="kko-page__header">
					<Header />
				</div>
				<div className="kko-page__sidebar">
					<SideMenu />
				</div>
				<main className="kko-page__main">
					<Outlet />
				</main>
			</div>
		</>
	), []);

	return (
		<>
			<AuthenticatedTemplate>
				{!statefulChatClient ? mainContent : (
					<ChatClientProvider chatClient={statefulChatClient}>
						{mainContent}
					</ChatClientProvider>
				)}
			</AuthenticatedTemplate>
			<UnauthenticatedTemplate>
				<Login />
			</UnauthenticatedTemplate>
		</>
	)
}


