import { SetStateAction } from "react";

/**
 * Determines whether a value is a function.
 * @param value The value to check.
 * @returns `true` if `value` is a function; otherwise, `false`.
 */
export function isFunction<T>(value: T): value is Extract<T, ((...args: any[]) => any)> {
	return typeof value === "function";
}

/**
 * Determines whether a value is a thunk.
 * @param value The value to check.
 * @returns `true` if `value` is a thunk; otherwise, `false`.
 */
export function isThunk<S>(value: S | (() => S)): value is (() => S) {
	return typeof value === "function";
}

/**
 * Determines whether a `SetStateAction` is in reducer form.
 * @param value The action to check.
 * @returns `true` if `value` is in reducer form; otherwise, `false`.
 */
export function isReducer<S>(value: SetStateAction<S>): value is ((prevState: S) => S) {
	return typeof value === "function";
}

type ValueOf<T> = T[keyof T];
type IncludesEvery<T, U extends T[]> = T extends ValueOf<U> ? true : false;
type WhenIncludesEvery<T, U extends T[]> = IncludesEvery<T, U> extends true ? U : never;

/**
 * Creates a type guard function that performs a runtime check
 * whether a given value is part of a literal type union.
 * 
 * Intermediate conditional types are used to enforce that all
 * values of the literal union are specified in the creation
 * of the guard, which makes this compile-time safe.
 * 
 * @description
 * Prefer `LiteralUnionSet` for literal union types defined in the
 * application code itself, as this avoids redeclaring the union
 * members.
 * 
 * @returns A type-guard function for `T`.
 * 
 * @example Generating a functioning type guard:
 * ```ts
 * type Example = "foo" | "bar";
 * const isExample = createUnionTypeGuard<Example>()("foo", "bar");
 * 
 * isExample("foo") // true
 * isExample("bar") // true
 * isExample("baz") // false
 * ```
 * 
 * @example Specifying an option that is not part of the union type:
 * ```ts
 * type Example = "foo" | "bar";
 * const isExample = createUnionTypeGuard<Example>()("foo", "baz");
 * // Compiler error: Argument of type '"baz"' is not assignable to parameter of type 'Example'.
 * ```
 * 
 * @example Forgetting to specify an option that is part of the union type:
 * ```ts
 * type Example = "foo" | "bar";
 * const isExample = createUnionTypeGuard<Example>()("foo");
 * // Compiler error: Argument of type 'string' is not assignable to parameter of type 'never'.
 * ```
 */
export const createUnionTypeGuard = <T extends string>() =>
	<U extends T[]>(...elements: WhenIncludesEvery<T, U>) => {
		const values = new Set<string>(elements);
		return (value: string) => values.has(value);
	}
