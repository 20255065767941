import type { RouteObject } from "react-router-dom";
import { RouteErrorBoundary } from "../core/components/errors";

export const routes: RouteObject[] = [{
	path: "tasks",
	lazy: async () => ({ Component: (await import("./pages")).Tasks }),
	children: [{
		path: ":id",
		children: [{
			index: true,
			errorElement: <RouteErrorBoundary type="motivator" />,
			lazy: async () => ({ Component: (await import("./pages")).ViewTask }),
		}, {
			path: "edit",
			errorElement: <RouteErrorBoundary type="motivator" />,
			lazy: async () => ({ Component: (await import("./pages")).EditTask }),
		}]
	}, {
		path: "add",
		lazy: async () => ({ Component: (await import("./pages")).AddTask })
	}]
}];
