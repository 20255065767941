import { MutableRefObject, Ref, RefCallback } from "react";

/**
 * Merges any number of type-compatible {@link https://react.dev/learn/referencing-values-with-refs | ref}
 * objects or callbacks into a single {@link https://react.dev/reference/react-dom/components/common#ref-callback | ref callback}.
 * 
 * This utility function can be used in cases where multiple pieces of functionality need
 * their own references to the same DOM elements.
 * 
 * @param refs The refs or array(s) of refs to merge.
 * @returns A callback-style ref that merges the passed in `refs`.
 * 
 * @example
 * The following example shows a custom hook returning a ref callback being
 * merged with a ref object created by the component with the `useRef` hook.

 * To avoid rebinding, the merged ref is memoized with the `useMemo` hook.
 * 
 * ```tsx
 * const Component : FC = () => {
 *   const { ref : theirRef } = useCustomHookWithRef();
 *   const ourRef = useRef<HTMLElement>(null);
 * 
 *   const mergedRef = useMemo(() => {
 *     return mergeRefs(theirRef, ourRef);
 *   }, [theirRef, ourRef]);
 * 
 *   return <div ref={mergedRef}></div>;
 * };
 * ```
 */
export const mergeRefs = <T = any>(...refs: (Ref<T>[] | Ref<T>)[]): RefCallback<T> => {
	const flatRefs = refs.flat(1);

	return value => {
		for (const ref of flatRefs) {
			if (ref == null) continue;

			switch (typeof ref) {
				case "function":
					ref(value);
					break;
				case "string":
					throw new TypeError("Not supported");
				default:
					(ref as MutableRefObject<T | null>).current = value;
					break;
			}
		};
	};
}