import { api } from "./api.generated";
export * from "./api.generated";

const enhancedApi = api.enhanceEndpoints({
	addTagTypes: ["ChatThread"],
	endpoints: {
		getChatThreads: {
			providesTags: [{ type: "ChatThread", id: "LIST" }]
		},
		getChatById: {
			providesTags: (response, error, args) => [{ type: "ChatThread", id: args.chatId }]
		},
	}
});

export * from "./api.generated";
export { enhancedApi as api };