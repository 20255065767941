import { FC, useRef } from "react";
import { useTranslation } from "react-i18next"
import { useGetAllQuery, useMarkAsReadMutation } from "src/apis/notification";
import { Button } from "src/modules/shared/components/buttons";
import { useIntersectionObserver, useTeardown } from "src/common/hooks";
import { GeneralNotification } from "../components/GeneralNotification";

export const NotificationsOverview: FC = () => {
	const { t } = useTranslation();
	const markedRef = useRef(new Set<string>());

	const { data: { items: notifications } = {}, isLoading } = useGetAllQuery({}, {
		// Refetches automatically when the notifications overview remounts.
		refetchOnMountOrArgChange: true,
		selectFromResult: ({ data, isLoading }) => ({
			isLoading,
			// Discard notifications that don't have IDs.
			// We can't handle them correctly.
			data: data?.items
				? { items: data.items.filter(item => !!item.id) }
				: data
		})
	});

	// The intersection observer will track those notifications that scrolled at least
	// 50% into view so that they can collectively be marked as read as soon as the
	// component unmounts.
	const [observe, root] = useIntersectionObserver(entries => {
		const { current: marked } = markedRef;

		for (const [key, entry] of entries) {
			if (!entry.isIntersecting || entry.intersectionRatio < 0.5) continue;

			const notification = notifications?.find(item => item.id === String(key));
			if (!notification || notification.isRead) continue;

			marked.add(String(key));
		}
	}, { useRoot: true, thresholds: 0.5 });

	// Marking notifications as read happens with the following teardown function
	// that executes on component unmount. We simply take all the notification IDs
	// that were collected prior by the intersection observer and we dispatch a
	// fire-and-forget mutation update that marks them as read.
	const [markAsRead] = useMarkAsReadMutation();

	useTeardown(() => {
		const { current: marked } = markedRef;

		if (marked.size === 0) return;

		// We really don't care to report to the end-user when it fails,
		// so we can run as a fire-and-forget and log any errors to the
		// console out-of-band.
		markAsRead({
			markNotificationAsReadRequestModel: {
				notificationIds: [...marked]
			}
		})
			.unwrap()
			.catch(error => console.error(error));
	});

	return (
		<div ref={root} className="kko-newnotification-container" >
			<div className="kko-newnotification-container__header">
				<div className="h3">
					{t("kko:pages.notifications.title")}
				</div>
				<Button
					href="/"
					priority="tertiary"
					label={t("kko:pages.notifications.commands.settings")}
					icon={{ right: "arrow-right" }}
				/>
			</div>
			<div className="kko-newnotification-container__list">
				{isLoading
					? <em>{t("kko:pages.notifications.list.is-loading")}</em>
					: !!notifications?.length
						? (
							<ul className="kko-list--plain">
								{notifications.map(notification => (
									<li key={notification.id!} ref={observe(notification.id!)} >
										<GeneralNotification notification={notification} />
									</li>
								))}
							</ul>
						)
						: <em>{t("kko:pages.notifications.list.empty")}</em>
				}
			</div>
		</div>
	)
}