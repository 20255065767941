import type { LanguageDetectorModule } from "i18next";

import { store } from "src/common/redux";
import { PreferredLanguageModel } from "src/open-api/preferencesService";
import { i18nLanguageCodes } from "src/modules/shared/utils/constants";

const browserLanguageCodes = {
	EnglishUS: "en-US",
	DutchNL: "nl-NL",
	English: "en",
	Dutch: "nl"
}

const browserToi18nLanguageCode = {
	[browserLanguageCodes.EnglishUS]: i18nLanguageCodes.English,
	[browserLanguageCodes.DutchNL]: i18nLanguageCodes.Dutch,
	[browserLanguageCodes.English]: i18nLanguageCodes.English,
	[browserLanguageCodes.Dutch]: i18nLanguageCodes.Dutch
}

const preferredLanguageSettingMappings: Omit<Record<PreferredLanguageModel, string>, "Automatic"> = {
	"En": i18nLanguageCodes.English,
	"Nl": i18nLanguageCodes.Dutch
}

const languageDetector: LanguageDetectorModule = {
	type: "languageDetector",
	detect: () => {
		// Detect the user's prefered language from the Redux store state.
		const preferredLanguage = store.getState().preferences.language.value;

		let knowni18nLanguageCode = (preferredLanguageSettingMappings as any)[preferredLanguage] as string;
		if (!knowni18nLanguageCode) {
			//fallback to automatic language detection from browser
			const knownBrowserLanguageCode = window.navigator.languages
				.find(language => Object.values(browserLanguageCodes).some(bl => bl === language)) || browserLanguageCodes.English;

			knowni18nLanguageCode = (browserToi18nLanguageCode as any)[knownBrowserLanguageCode] || i18nLanguageCodes.English;
		}

		return knowni18nLanguageCode;
	}
}

export default languageDetector;