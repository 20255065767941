import { skipToken } from "@reduxjs/toolkit/query/react";
import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { To, generatePath } from "react-router-dom";
import { useGetEpisodeQuery } from "src/apis/episode";
import { useLocale } from "src/common/hooks";
import { useStreamAllAddressBookEntriesQuery } from "src/apis/network";
import { NetworkRelatedNotification, NotificationBadge } from "../types"
import { NotificationShell } from "./NotificationShell";

type NetworkNotificationProps = {
	notification: NetworkRelatedNotification
}

export const NetworkNotification: FC<NetworkNotificationProps> = ({ notification }) => {
	const { t } = useTranslation();
	const locale = useLocale();

	const { payload, kind } = notification;

	const { currentData: episode } = useGetEpisodeQuery((payload.episodeId
		? { "Accept-Language": locale, episodeId: payload.episodeId }
		: skipToken
	), { refetchOnMountOrArgChange: true });

	const { currentData: contacts } = useStreamAllAddressBookEntriesQuery((
		kind === "network-invitation-accepted"
			? { "Accept-Language": locale, linkedAccountsOnly: true }
			: skipToken
	), {
		refetchOnMountOrArgChange: true,
		selectFromResult: ({ currentData }) => ({ currentData: currentData?.items ?? [] })
	})

	const title = useMemo<ReactNode>(() => {
		switch (kind) {
			case "episode-invitation-accepted":
				return t("kko:pages.notifications.episode-invite.accepted");

			case "episode-invitation-declined":
				return t("kko:pages.notifications.episode-invite.declined");

			case "network-invitation-accepted":
				return t("kko:pages.notifications.network-invite.accepted");

			case "network-invitation-declined":
				return t("kko:pages.notifications.network-invite.declined");

			default:
				return null;
		}
	}, [kind, t]);

	const badge = useMemo<NotificationBadge | undefined>(() => {
		switch (kind) {
			case "episode-invitation-accepted":
			case "network-invitation-accepted":
				return "success";

			case "episode-invitation-declined":
			case "network-invitation-declined":
				return "danger";
		}
	}, [kind]);

	const to = useMemo<To | undefined>(() => {
		switch (kind) {
			case "episode-invitation-accepted":
			case "episode-invitation-declined":
				return payload.episodeId
					? generatePath("/episodes/details/:episodeId/information", { episodeId: payload.episodeId })
					: undefined;

			case "network-invitation-accepted":
				const accountId = payload.sender?.accountId;
				const contactId = accountId && contacts
					.find(contact => contact.linkedProfile?.accountId === accountId)
					?.id;

				return contactId
					? generatePath("/network/contacts/:contactId", { contactId: contactId })
					: "/network/contacts";

			case "network-invitation-declined":
				return "/network/contacts";
		}
	}, [contacts, kind, payload.episodeId, payload.sender?.accountId]);

	return (
		<NotificationShell
			notification={notification}
			title={title}
			badge={badge}
			to={to}
		>
			{payload.sender?.displayName}
			{episode && (
				<>
					{!!payload.sender?.displayName && <span aria-hidden="true"> · </span>}
					{`${episode.title} (${episode.subjectDisplayName})`}
				</>
			)}
		</NotificationShell>
	)
};