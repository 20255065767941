import classNames from "classnames";
import { useMsal } from "@azure/msal-react";
import { useId, type FC, useCallback, useLayoutEffect, useEffect } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { sideMenu } from "src/modules/core/menu-items"
import { Icon } from "src/modules/shared/components/Icon";
import logo from "src/Assets/images/logo.svg";
import { toggleSidebar, toggleSidemenu } from "src/redux/slices/core";
import { useMediaBreakpoint, useMediaQuery } from "src/common/hooks";
import { Button } from "src/modules/shared/components/buttons";
import { AccountItems } from "./AccountItems";

type SideMenuProps = {}

const SideMenu: FC<SideMenuProps> = props => {
	const { t } = useTranslation();
	const sidebarId = useId();

	const dispatch = useAppDispatch();
	const breakpoint = useMediaBreakpoint("tablet");
	const isDesktop = useMediaQuery(`(min-width : ${breakpoint})`);
	const location = useLocation();

	const sidemenuExpanded = useAppSelector(state => state.core.sidemenu.expanded);
	const sidebarCompacted = useAppSelector(state => state.core.sidebar.compacted);

	const { instance: msal } = useMsal();

	const onToggleHandler = useCallback(() => {
		dispatch(isDesktop ? toggleSidebar() : toggleSidemenu());
	}, [dispatch, isDesktop]);

	useLayoutEffect(() => {
		if (isDesktop) {
			dispatch(toggleSidemenu(false));
		}
	}, [dispatch, isDesktop]);

	useEffect(() => {
		dispatch(toggleSidemenu(false));

		// We intentionally *only* want to do something side-effectful
		// here when the location changes, i.e. we're navigating away.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	const onSignOutHandler = useCallback(() => {
		msal.logout();
	}, [msal]);

	return (
		<div
			id={sidebarId}
			className="kko-sidebar"
			aria-hidden={!isDesktop ? !sidemenuExpanded : undefined}
			data-compact={isDesktop ? sidebarCompacted : undefined}
		>
			<div className="kko-sidebar__header">
				<Link className="kko-sidebar__logo" to="/">
					<img src={logo} alt="CACAO logo"></img>
				</Link>
				<Button
					className="kko-sidebar__toggle"
					icon={{ only: "dock-fill" }}
					label={"Sidebar navigation collapse toggle"}
					priority="tertiary"
					aria-pressed={sidebarCompacted}
					onClick={onToggleHandler}
				/>
			</div>

			<nav className="kko-sidebar__nav">
				<ul>
					{sideMenu.map(menuItem => {
						// Can't use the built-in behavior for active state tracking since some routes
						// point deeper than the first segment, but we still want to match everything
						// that matches the first segment as active.
						const end = menuItem.path.indexOf("/", 1);
						const segment = menuItem.path.slice(0, end < 1 ? undefined : end);
						const pattern = segment === "/" ? segment : `${segment}/*`;
						const isActive = matchPath(pattern, location.pathname);
						const icon = isActive ? menuItem.iconSelected : menuItem.icon;

						return (
							<li key={menuItem.path}>
								<Link
									className={classNames("kko-nav-item", { "active": isActive })}
									to={menuItem.path}
									aria-current={isActive ? "page" : undefined}
								>
									<Icon
										className="kko-nav-item__icon"
										icon={icon ?? ""}
									/>
									<span className="kko-nav-item__text">
										{t(menuItem.translationKey)}
									</span>
									{/* TODO: Activate the badge element below when notifications are ready */}
									{/*
											<span className="kko-number-badge">
												<i aria-hidden="true">23</i>
												<span role="status">You have 7 unread messages</span>
											</span>
									*/}
								</Link>
							</li>
						);
					})}
				</ul>
				<div className="kko-sidebar__secondary-nav">
					<AccountItems />
				</div>
			</nav>
			<div className="kko-sidebar__footer">
				<ul className="kko-action-list">
					<li>
						<button
							type="button"
							className="kko-action-list-item"
							data-appearance="danger"
							onClick={onSignOutHandler}
						>
							{t("kko:general.profile-menu.links.log-out")}
						</button>
					</li>
				</ul>
			</div>
		</div >
	)
}

export default SideMenu;
