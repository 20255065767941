import { FC, useMemo } from "react";
import { DateTimeFormatOptions, formatRangeDatetimeAsParts } from "../utils/formatUtils";
import { Datetime } from "./Datetime";

type DatetimeRangeProps = {
	start: string | null | undefined;
	end: string | null | undefined;
	locale: string;
	formatOptions?: DateTimeFormatOptions;
}

export const DatetimeRange: FC<DatetimeRangeProps> = ({ start, end, locale: culture, formatOptions = { dateStyle: "long", timeStyle: "long" } }) => {

	const [startAsDate, endAsDate] = useMemo(() => {
		return [
			!!start ? new Date(start) : null,
			!!end ? new Date(end) : null,
		]
	}, [start, end]);

	const formattedValuesAsDateRangeParts = useMemo(() => {
		let parts = startAsDate && endAsDate
			? formatRangeDatetimeAsParts(startAsDate, endAsDate, culture, formatOptions)
			: [];
		//remove the timezone and second parts and the : prefix for seconds
		if (parts.length > 0) {
			const firstTimeMinutePartIndex = parts.findIndex(p => p.type === "minute");
			const firstTimeTimzonePartIndex = parts.findIndex(p => p.type === "timeZoneName");

			const secondTimeMinutePartIndex = parts.findLastIndex(p => p.type === "minute");

			parts = parts.slice(0, firstTimeMinutePartIndex + 1).concat(parts.slice(firstTimeTimzonePartIndex + 1, secondTimeMinutePartIndex + 1));
		}
		return parts;
	}, [startAsDate, endAsDate, culture, formatOptions]);

	if ((!start || !startAsDate) && (!end || !endAsDate)) {
		return null;
	}

	return <>
		{formatRangeDatetimeAsParts.length > 0
			? <span>{formattedValuesAsDateRangeParts.map(part => part.value).join("")}</span>
			: startAsDate
				? <Datetime
					value={start}
					culture={culture}
					formatOptions={formatOptions}
				/>
				: <Datetime
					value={end}
					culture={culture}
					formatOptions={formatOptions}
				/>
		}
	</>;
}