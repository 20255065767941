import { DependencyList, ReactNode, useCallback, useEffect, useMemo, useReducer } from "react";
import { Deferred } from "../utils";
import { useTeardown } from "./use-teardown";

/**
 * A child template to execute for the `useAsync` hook.
 * @param props
 * The props to pass to the template.
 * @param api
 * The imperative API of the hook, which allows resolving
 * or rejecting an awaited `call` operation.
 */
export type UseAsyncTemplate<T, P extends {} = {}> = (props: P, api: UseAsyncApi<T>) => ReactNode;

/**
 * The imperative API of the `useAsync` hook.
 */
export type UseAsyncApi<T> = {
	/**
	 * Resolves the promise returned by the `call` of the hook.
	 * @param value
	 * The value with which to resolve the promise.
	 */
	resolve: (value: T) => void;

	/**
	 * Rejects the promise returned by the `call` of the hook.
	 * @param reason
	 * The reason with which to reject the promise.
	 */
	reject: (reason?: any) => void;
}

/**
 * The instance of the `useAsync` hook.
 */
type UseAsync<T, P extends {} = {}> = {
	/**
	 * Executes the `children` template passed to the `useAsync` hook
	 * with the specified `props` and allows awaiting the result.
	 * @param props
	 * The props to pass to the template.
	 * @param signal
	 * An optional abort signal that allows cancelling the asynchronous
	 * operation or UI interaction being undertaken by the end-user that
	 * the hook encapsulates.
	 * @returns 
	 * A promise representing the asynchronous operation or UI interaction
	 * being undertaken by the end-user that the hook encapsulates.
	 */
	call: (props: P, signal?: AbortSignal) => Promise<T>;

	/**
	 * Render function for the `children` passed to the `useAsync` hook.
	 */
	render: () => ReactNode;
}

type UseAsyncState<T, P extends {} = {}> = {
	deferred?: Deferred<T>;
	abort?: Deferred<T>;
	props?: P;
}

type UseAsyncAction<T, P extends {} = {}> =
	& UseAsyncState<T, P>
	& { type: "call" | "finally" };

const empty = () => null;

/**
 * Call `useAsync` at the top level of your component to allow rendering of a child
 * template that encapsulates some out-of-flow asynchronous operation or UI interaction
 * to be undertaken by the end-user in the middle of event handlers or of side effects
 * such as provided by the `useEffect` hook.
 * 
 * Its common role is to provide easy integration of interstitial popover dialogs
 * such as those that ask for the end-user's confirmation before continuing into
 * a destructive operation like deleting data.
 * 
 * @param children
 * The child template the hook should render to facilitate the out-of-flow
 * operation or UI interaction.  
 * The template is a callback taking two parameters:
 *   1. A `props` object holding properties passed to the template by the event
 *      handler or side effect making use of the hook's `call` return value.
 *   2. The `api` object holding the hook's imperative API. This consists of
 *      a `resolve` method that can be called with the expected type of result
 *      value and a `reject` method that can be called with any value to result
 *      in the `call` throwing the value as an error.
 * @param deps
 * Will cause the `children` child template to re-render when the values
 * in the list change.  
 * Pass an empty array if the template has no dependent values.
 * 
 * @returns
 * `useAsync` returns an object with exactly two members:
 *   1. `render` - which is a function to render the `children` child template
 *      into the component output. Note that the template will only be rendered
 *      when and while a `call` operation is pending.
 *   2. `call` - which is a function to call from event handlers and side effects
 *      imperatively. It can take a set of properties used to populate the `children`
 *      child template. This function is awaitable and should eventually return
 *      the expected type of result value for the out-of-flow action the end-user is
 *      to undertake.  
 *      Note that each instance of the hook can only hold **one** pending operation.
 *      If the `call` function is called again where there is already a pending
 *      operation, that prior operation is cooperatively cancelled by throwing a
 *      `DOMException` with its `name` property set to the `"AbortError"` identifier.
 *
 * @example
 * The following is a minimal example which shows the `useAsync` hook being used
 * together with a `<PromptingModal>` component that shows a binary yes/no choice
 * to the end-user to ask for confirmation before deleting an item.
 * 
 * ```tsx
 * type Prompt = { text : string }
 * type Item = { id: number, name: string }
 * 
 * const Component : FC = () => {
 *   // ...
 * 
 *   const prompt = useAsync<bool, Prompt>(({ text }, { resolve }) => (
 *     <PromptingModal
 *       text={text}
 *       onCancel={() => resolve(false)}
 *       onConfirm={() => resolve(true)}
 *     />
 *   ), []);
 * 
 *   const deleteItem = useCallback(async (item: Item) => {
 *     if (!await prompt.call(`Are you sure you want to delete '${item.name}' ?`)) {
 *       return;
 *     }
 * 
 *     // ...
 *   }, [prompt]);
 * 
 *   return (
 *     <div>
 *       <strong>Current items</strong>
 *       <ul>
 *         {items.map(item => (
 *           <li>
 *             {item.name}
 *             <button
 *               type="button"
 *               onClick={() => deleteItem(item)}
 *             >Delete</button>
 *           </li>
 *         ))}
 *       </ul>
 *       {prompt.render()}
 *     </div>
 *   );
 * }
 * ```
 */
export const useAsync = <T, P extends {} = {}>(children: UseAsyncTemplate<T, P>, deps: DependencyList): UseAsync<T, P> => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	children = useCallback(children, deps);

	const reducer = (prevState: UseAsyncState<T, P>, action: UseAsyncAction<T, P>): UseAsyncState<T, P> => {
		let { deferred } = prevState;

		switch (action.type) {
			case "call": {
				return {
					deferred: action.deferred,
					props: action.props,
					abort: deferred,
				};
			}
			case "finally":
				return Object.is(deferred, action.deferred)
					? {}
					: prevState;

			default:
				// Shouldn't exist.
				return prevState;
		}
	};

	const [state, dispatch] = useReducer(reducer, undefined, () => {
		const deferred = new Deferred<T>();
		deferred.promise.catch(() => { /* intentionally swallow */ });

		return { deferred };
	});

	useEffect(() => {
		state.abort?.reject(new DOMException("Existing call was pre-empted by new.", "AbortError"));
	}, [state.abort]);

	useTeardown(() => {
		state.abort?.reject(new DOMException("Component is unmounting.", "AbortError"));
		state.deferred?.reject(new DOMException("Component is unmounting.", "AbortError"));
	});

	const call = useCallback((props: P) => {
		const deferred = new Deferred<T>();
		deferred.promise
			.finally(() => {
				dispatch({ type: "finally", deferred });
			})
			.catch(error => {
				// Finally created a new promise, meaning
				// we need to catch and swallow abort errors
				// here again or they will leak as top-level
				// uncaught errors.
				if (!isAbortError(error)) throw error;
			});

		dispatch({ type: "call", props, deferred });
		return deferred.promise;

	}, [dispatch]);

	const render = useMemo(() => {
		const { props, deferred } = state;
		if (props == null || deferred == null) return empty;

		const api = {
			resolve: (value: T) => deferred.resolve(value),
			reject: (reason?: any) => deferred.reject(reason),
		};

		return () => children(props, api);

	}, [state, children]);

	// Memo-d as a precaution against callers passing the compound
	// object into a `useCallback` directly, without destructuring.
	return useMemo(() => ({ call, render }), [call, render]);
}


export const isAbortError = (error: any): error is DOMException =>
	error instanceof DOMException && error.name === "AbortError";