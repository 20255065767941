import i18n from "i18next";
import languageDetector from "./language-detector";

i18n
	.use(languageDetector)
	.init({
		debug: false,
		fallbackLng: false,
		returnNull: false,

		// React takes care of escaping. Letting i18n escape as well
		// leads to double-escape bugs.
		interpolation: { escapeValue: false },
		react: { useSuspense: false },
	});

export { i18n };