import { useCallback, useEffect, useMemo, useState } from "react";

/**
 * @deprecated Use `useMediaQuery` and `useMediaBreakpoint` instead.
 */
export const useScreenSize = () => {
	// NOTE: This value should be synced with $kko-page__breakpoint--tablet
	const screenSizeBreakpoint = 900;
	const mobileMatchMedia = useMemo(() => window.matchMedia(`(max-width: ${screenSizeBreakpoint - 1}px)`), [screenSizeBreakpoint]);
	const [isMobileSize, setIsMobileSize] = useState<boolean>(mobileMatchMedia.matches);

	const mobileMatchMediaChange = useCallback((e: MediaQueryListEvent) => {
		setIsMobileSize(e.matches);
	}, []);

	useEffect(() => {
		mobileMatchMedia.addEventListener("change", mobileMatchMediaChange);
		return () => mobileMatchMedia.removeEventListener("change", mobileMatchMediaChange);
	}, [mobileMatchMediaChange, mobileMatchMedia]);

	const isDesktopSize = useMemo(() => {
		return !isMobileSize;
	}, [isMobileSize]);
	return { isMobileSize, isDesktopSize };
}