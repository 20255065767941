import { FC } from "react";
import { NotificationResponseModel } from "src/apis/notification";
import { isSupportedNotification } from "../types";
import { TaskNotification } from "./TaskNotification";
import { EpisodeNotification } from "./EpisodeNotification";
import { CalendarNotification } from "./CalendarNotification";
import { DocumentNotification } from "./DocumentNotification";
import { NetworkNotification } from "./NetworkNotification";

type GeneralNotificationProps = {
	notification: NotificationResponseModel;
}

export const GeneralNotification: FC<GeneralNotificationProps> = ({ notification }) => {
	if (!isSupportedNotification(notification)) {
		console.warn("Unsupported notification kind %s.", notification.kind);
		return null;
	}

	switch (notification.kind) {
		case "CalendarAppointmentNew":
		case "CalendarAppointmentCancelled":
		case "CalendarAppointmentDataChanged":
		case "CalendarAppointmentPeriodChanged":
		case "CalendarAppointmentAttendanceAccepted":
		case "CalendarAppointmentAttendanceDeclined":
			return <CalendarNotification notification={notification} />;

		case "DocumentCreated":
		case "DocumentUpdated":
			return <DocumentNotification notification={notification} />;

		case "EpisodeCreated":
			return <EpisodeNotification notification={notification} />;

		case "TaskCreated":
		case "TaskAssigneeAdded":
		case "TaskAssigneesUpdated":
		case "TaskInformationUpdated":
		case "TaskMarkedAsCompleted":
		case "TaskReminder":
			return <TaskNotification notification={notification} />;

		case "episode-invitation-accepted":
		case "episode-invitation-declined":
		case "network-invitation-accepted":
		case "network-invitation-declined":
			return <NetworkNotification notification={notification} />;
	}
}

