import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { To, generatePath } from "react-router-dom";
import { DocumentRelatedNotification } from "../types"
import { NotificationShell } from "./NotificationShell";

type DocumentNotificationProps = {
	notification: DocumentRelatedNotification
}

export const DocumentNotification: FC<DocumentNotificationProps> = ({ notification }) => {
	const { t } = useTranslation();

	const { payload, kind } = notification;

	const title = useMemo<ReactNode>(() => {
		if (payload == null) return null;

		switch (kind) {
			case "DocumentCreated":
				return t("kko:pages.notifications.new-document-created.text", {
					episodeTitle: payload?.episodeTitle,
					documentName: payload?.documentName,
					subjectName: payload?.subjectName
				});

			case "DocumentUpdated":
				return t("kko:pages.notifications.document-updated.text", {
					episodeTitle: payload?.episodeTitle,
					documentName: payload?.documentName,
					subjectName: payload?.subjectName
				});

			default:
				return null;
		}
	}, [kind, payload, t]);

	const to = useMemo<To | undefined>(() => {
		const { documentId, episodeId } = payload;

		if (!episodeId) return undefined;

		return {
			pathname: generatePath("/episodes/details/:episodeId/documents", { episodeId }),
			search: documentId ? `?${new URLSearchParams({ documentId })}` : undefined
		} satisfies To;

	}, [payload]);

	return (
		<NotificationShell
			notification={notification}
			title={title}
			to={to}
		>
			{payload.sender?.displayName}
		</NotificationShell>
	)
};