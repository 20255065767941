import { NotificationResponseModel } from "src/apis/notification";
import { LiteralUnionSet, static_assert } from "src/common/utils";

// We don't want to accept 'any' as a type.
type NotificationResponse = Omit<NotificationResponseModel, "payload" | "kind">;

export const notificationModules = new LiteralUnionSet("Task", "Network", "Calendar", "Document", "Episode");
export type NotificationModule = LiteralUnionSet.Literal<typeof notificationModules>;

export type NotificationBadgeKind = "success" | "danger" | "notification";

export type NotificationBadge = NotificationBadgeKind | {
	kind?: NotificationBadgeKind,
	icon?: string
}

export type NotificationPayload = {
	title: string;
	episode: string;
	sender?: NotificationSender | null;
}

export type NotificationAccount = {
	accountId: string;
	userName: string;
}

export type NotificationSender = {
	accountId: string;
	displayName: string;
	profilePicture: string;
}

export type CalendarRelatedNotificationPayload = NotificationPayload & {
	module: "Calendar";
	start?: string | null;
	end?: string | null;
	organizer: NotificationAccount;
	attendeeInvitee: NotificationAccount;
}

export type DocumentRelatedNotificationPayload = NotificationPayload & {
	module: "Document";
	episodeId?: string | null;
	episodeTitle?: string | null;
	documentId?: string | null;
	documentName?: string | null;
	subjectName?: string | null;
}

export type NetworkRelatedNotificationPayload = NotificationPayload & {
	module: "Network";
	episodeId?: string | null;
}

export type TaskRelatedNotificationPayload = NotificationPayload & {
	module: "Task";
	taskId?: string | null;
	assignees?: string[] | null;
	episodeTitle?: string | null;
	episodeSubjectDisplayName?: string | null;
}

export type EpisodeRelatedNotificationPayload = NotificationPayload & {
	module: "Episode";
	episodeId: string;
	episodeTitle: string;
}

export type CalendarRelatedNotification = NotificationResponse & {
	payload: CalendarRelatedNotificationPayload;
	kind:
	| "CalendarAppointmentNew"
	| "CalendarAppointmentDataChanged"
	| "CalendarAppointmentAttendanceAccepted"
	| "CalendarAppointmentAttendanceDeclined"
	| "CalendarAppointmentCancelled"
	| "CalendarAppointmentPeriodChanged";
}

export type DocumentRelatedNotification = NotificationResponse & {
	payload: DocumentRelatedNotificationPayload;
	kind:
	| "DocumentCreated"
	| "DocumentUpdated";
}

export type NetworkRelatedNotification = NotificationResponse & {
	payload: NetworkRelatedNotificationPayload;
	kind:
	| "network-invitation-accepted"
	| "network-invitation-declined"
	| "episode-invitation-accepted"
	| "episode-invitation-declined";
}

export type TaskRelatedNotification = NotificationResponse & {
	payload: TaskRelatedNotificationPayload;
	kind:
	| "TaskCreated"
	| "TaskInformationUpdated"
	| "TaskAssigneeAdded"
	| "TaskAssigneesUpdated"
	| "TaskMarkedAsCompleted"
	| "TaskReminder";
}

export type EpisodeRelatedNotification = NotificationResponse & {
	payload: EpisodeRelatedNotificationPayload;
	kind:
	| "EpisodeCreated";
}

export type Notification =
	| TaskRelatedNotification
	| DocumentRelatedNotification
	| NetworkRelatedNotification
	| EpisodeRelatedNotification
	| CalendarRelatedNotification;

const kinds = new LiteralUnionSet(
	"CalendarAppointmentNew",
	"CalendarAppointmentDataChanged",
	"CalendarAppointmentAttendanceAccepted",
	"CalendarAppointmentAttendanceDeclined",
	"CalendarAppointmentCancelled",
	"CalendarAppointmentPeriodChanged",

	"DocumentCreated",
	"DocumentUpdated",

	"network-invitation-accepted",
	"network-invitation-declined",
	"episode-invitation-accepted",
	"episode-invitation-declined",

	"TaskCreated",
	"TaskInformationUpdated",
	"TaskAssigneeAdded",
	"TaskAssigneesUpdated",
	"TaskMarkedAsCompleted",
	"TaskReminder",

	"EpisodeCreated"
)

export const isSupportedNotification = (
	notification: NotificationResponseModel
): notification is Notification => kinds.has(notification.kind);

// NOTE:
// The following implements a small bit of security that the contents of the LiteralUnionSet
// used for the type guard, match with the actual notification kinds supported by the discriminated
// union type Notification.
type AreEqual<A, B> = A extends B ? B extends A ? true : never : never;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type _check = static_assert<AreEqual<
	LiteralUnionSet.Literal<typeof kinds>,
	Notification["kind"]
>>;
