import { RefCallback, useMemo, useRef } from "react";
import { useRefEffect } from "./use-ref-effect";
import { useEvent } from "./use-event";
import { useTeardown } from "./use-teardown";

// NOTE:
// We create a special version of the `ResizeObserverCallback` type here that omits
// the second parameter - the ResizeObserver itself.
// This is intentional, because giving access to the observer itself would allow
// messing with lifetimes that should remain under control of the hook.

interface UseResizeObserverCallback {
	/**
	 * A function called whenever an observed resize occurs.
	 * @param entries
	 * An array of {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserverEntry | ResizeObserverEntry}
	 * objects that can be used to access the new dimensions of observed elements after each change.
	 */
	(entries: readonly ResizeObserverEntry[]): void;
}

/**
 * Call `useResizeObserver` at the top level of your component to create
 * a {@linkcode https://developer.mozilla.org/docs/Web/API/ResizeObserver | ResizeObserver}
 * that can be used to observe a chosen element for size changes.
 * @param callback
 * The function called whenever an observed resize occurs.
 * @param options
 * The options used when the hook calls the {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver/observe | observe}
 * method of the `ResizeObserver` to observe an element.
 * @returns
 * A {@link https://react.dev/reference/react-dom/components/common#ref-callback ref callback}
 * function that can be used to assign the element that should be observed.
 */
export const useResizeObserver = (
	callback: UseResizeObserverCallback,
	options?: ResizeObserverOptions
): RefCallback<Element> => {
	// Automatically always call the latest version.
	callback = useEvent(callback);

	// The ResizeObserver API is not beholden to React's component lifecycle and
	// may execute its callback when the component has already unmounted. To
	// prevent such problems we explicitly track when the component has unmounted.
	const unmounted = useRef(false);

	const observer = useMemo(() => {
		// Handle the case where ResizeObserver is not defined.
		// E.g. unit test or SSR environments.
		if (!globalThis.ResizeObserver) return null;

		return new ResizeObserver(entries => {
			if (unmounted.current) return;
			callback(entries);
		});
	}, [callback]);

	useTeardown(() => { unmounted.current = true });

	return useRefEffect<Element>(element => {
		if (observer == null) return;

		observer.observe(element, options);

		return () => { observer.unobserve(element) };
	}, [observer, options]);
};
