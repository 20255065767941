type DominantRelativeTimePart = {
	readonly value: number;
	readonly unit: DominantRelativeTimeFormatUnit;
}

export type DominantRelativeTimeFormatUnit = "day" | "hour" | "minute" | "second";
export type DominantRelativeTimeFormatNumeric = "zero" | Intl.RelativeTimeFormatNumeric;

export interface DominantRelativeTimeFormatOptions extends Omit<Intl.RelativeTimeFormatOptions, "numeric"> {
	granularity?: DominantRelativeTimeFormatUnit;
	numeric?: DominantRelativeTimeFormatNumeric;
}

export class DominantRelativeTimeFormat {
	#formatter: Intl.RelativeTimeFormat;
	#zeroFormatter: Intl.RelativeTimeFormat;
	#granularity: DominantRelativeTimeFormatUnit;

	constructor(locale: string, options?: DominantRelativeTimeFormatOptions) {
		const {
			granularity = "second",
			numeric = "zero",
			...other
		} = options ?? {};

		this.#granularity = granularity;
		this.#formatter = new Intl.RelativeTimeFormat(locale, {
			...other,
			numeric: numeric === "zero" ? "always" : numeric
		});
		this.#zeroFormatter = numeric === "zero"
			? new Intl.RelativeTimeFormat(locale, { ...other, numeric: "auto" })
			: this.#formatter;
	}

	format(date: Date, relativeTo?: Date) {
		const { value, unit } = this.getDominant(date, relativeTo);

		// When value is 0 and the numeric strategy is "zero", then use the dedicated
		// formatter that passes the "auto" numeric setting. This enables rendering
		// of, e.g. "this minute" instead of "in 0 minutes" for that particular case
		// only.
		return value === 0
			? this.#zeroFormatter.format(value, unit)
			: this.#formatter.format(value, unit);
	}

	formatToParts(date: Date, relativeTo?: Date) {
		const { value, unit } = this.getDominant(date, relativeTo);

		return value === 0
			? this.#zeroFormatter.formatToParts(value, unit)
			: this.#formatter.formatToParts(value, unit);
	}

	getDominant(date: Date, relativeTo?: Date) {
		const msec = date.getTime() - (relativeTo?.getTime() ?? Date.now());

		// Relative time formatting can't go smaller than the seconds unit.
		// So compute all parts down to that.
		const seconds = Math.trunc(msec / 1000);
		const minutes = Math.trunc(seconds / 60);
		const hours = Math.trunc(minutes / 60);
		const days = Math.trunc(hours / 24);

		const candidates: readonly DominantRelativeTimePart[] = [
			{ value: days, unit: "day" },
			{ value: hours, unit: "hour" },
			{ value: minutes, unit: "minute" },
			{ value: seconds, unit: "second" }
		];

		for (const candidate of candidates) {
			if (candidate.value !== 0) return candidate;
			if (this.#granularity === candidate.unit) break;
		}

		return {
			value: 0,
			unit: this.#granularity
		};
	}
}