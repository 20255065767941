import { createSlice } from "@reduxjs/toolkit";
import { IMenuState } from "../../modules/shared/types";

const initialState: IMenuState = {
	open: true
}

export const menuSlice = createSlice({
	name: "menu",
	initialState,
	reducers: {
		openMenu: state => {
			state.open = true;
		},
		closeMenu: state => {
			state.open = false;
		},
		toggleMenu: state => {
			state.open = !state.open;
		}
	}
});

export const { openMenu, closeMenu, toggleMenu } = menuSlice.actions;
export default menuSlice.reducer;