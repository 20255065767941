export const getInitials = (name: string): string | undefined => {
	let initials;
	if (!name) {
		return;
	};
	const nameSplit = name.split(" ");
	const nameLength = nameSplit.length;
	if (nameLength > 1) {
		initials =
			nameSplit[0].substring(0, 1) +
			nameSplit[nameLength - 1].substring(0, 1);
	} else if (nameLength === 1) {
		initials = nameSplit[0].substring(0, 1);
	} else {
		return undefined;
	}
	return initials.toUpperCase();
};

