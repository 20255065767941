import { isAnyOf, createSlice } from "@reduxjs/toolkit";
import { createAppAsyncThunk } from "src/common/redux";
import {
	PreferencesService,
	PreferenceResponseModel,
	PreferredLanguageModel,
	SaveLanguagePreferenceRequestModel
} from "src/open-api/preferencesService";
import { showLoading, hideLoading, addToast, ToastType } from "./feedbackSlice";

export type Preference<T> = {
	eTag: string;
	value: T;
}

export type PreferencesState = {
	language: Preference<PreferredLanguageModel>
};

const initialState: PreferencesState = {
	language: { value: "Automatic", eTag: "" }
}

export const fetchAllPreferences = createAppAsyncThunk(
	"preferences/fetchAllPreferences",
	async () => {
		const response = await PreferencesService.getAllPreferences();
		return response.items ?? [];
	});

export const saveLanguagePreference = createAppAsyncThunk(
	"preferences/saveLanguagePreference",
	async (arg: SaveLanguagePreferenceRequestModel, { dispatch }) => {
		try {
			dispatch(showLoading());

			const response = await PreferencesService.saveLanguagePreference(arg);

			dispatch(addToast({
				titleKey: "kko:general.feedback.preferences-save-title",
				contentKey: "kko:general.feedback.preferences-save-success",
				type: ToastType.Success,
				icon: "check"
			}));

			return [response] as PreferenceResponseModel[];

		} catch (error) {
			dispatch(addToast({
				titleKey: "kko:general.feedback.preferences-save-title",
				contentKey: "kko:general.feedback.preferences-save-error",
				type: ToastType.Error
			}));

			return [];

		} finally {
			dispatch(hideLoading());
		}
	});

export const preferencesSlice = createSlice({
	name: "preferences",
	initialState,
	reducers: {},

	extraReducers(builder) {
		builder.addMatcher(isAnyOf(
			fetchAllPreferences.fulfilled,
			saveLanguagePreference.fulfilled,
		), (state, action) => {
			const preferences = action.payload;

			for (const preference of preferences) {
				switch (preference.type) {
					case "PreferredLanguage":
						state.language = {
							eTag: preference.eTag ?? "",
							value: preference.value as PreferredLanguageModel
						};
						break;
					default:
						break;
				}
			}
		});
	},
});

export default preferencesSlice.reducer;