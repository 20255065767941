import classNames from "classnames";
import { PropsWithChildren } from "react";
import {
	ImageSize,
	useGetDisplayNameByAccountIdQuery,
	useGetV1AccountsByAccountIdProfilePhotoQuery
} from "src/apis/network";
import { useLocale, createSlot, withSlots, useSlots } from "src/common/hooks";
import { isFetchBaseQueryError } from "src/common/redux";
import { Avatar, AvatarSize } from "./Avatar";

type ProfileAvatarProps = PropsWithChildren<{
	className?: classNames.Argument;
	accountId: string;
	size?: AvatarSize;
}>;

const imageSizes = new Map<AvatarSize, ImageSize>([
	["normal", "Small"],
	["large", "Large"],
	["xlarge", "Large"],
	["flexible", "Large"]
]);

const Badge = createSlot();

export const ProfileAvatar = withSlots(({
	accountId,
	children,
	size = "normal",
	...props
}: ProfileAvatarProps) => {
	const locale = useLocale();

	const { data: name } = useGetDisplayNameByAccountIdQuery({
		"Accept-Language": locale,
		accountId: accountId,
	}, {
		// Coerces a 404 to a positive result
		selectFromResult: ({ data, error, isSuccess, isError, ...other }) => {
			if (isFetchBaseQueryError(error) && error.status === 404) {
				data = undefined;
				error = undefined;
				isError = false;
				isSuccess = true;
			}

			return { data, error, isSuccess, isError, ...other };
		}
	});

	const { data: image } = useGetV1AccountsByAccountIdProfilePhotoQuery({
		"Accept-Language": locale,
		accountId: accountId,
		imageSize: imageSizes.get(size)
	}, {
		// Coerces a 404 to a positive result
		selectFromResult: ({ data, error, isSuccess, isError, ...other }) => {
			if (isFetchBaseQueryError(error) && error.status === 404) {
				data = undefined;
				error = undefined;
				isError = false;
				isSuccess = true;
			}

			return { data, error, isSuccess, isError, ...other };
		}
	});

	const { badge } = useSlots(children, { badge: Badge });

	return (
		<Avatar
			{...props}
			name={name?.displayName ?? ""}
			image={image}
			size={size}
		>
			{badge && (<Avatar.Badge>{badge}</Avatar.Badge>)}
		</Avatar>
	);
}, { Badge });
