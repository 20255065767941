import classNames from "classnames";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { Icon } from "../Icon";
import { ButtonPriority, ButtonSize } from "./Button";

type Props = {
	/**
	 * Sets the icon for the pill button.
	 */
	icon: string | { only: string };

	/**
	 * Sets the label text for the pill button.
	 */
	label: string;

	/**
	 * Configures the visual priority level of the button.  
	 * Can be one of `"primary"`; `"secondary"`; or `"tertiary"`.  
	 * Defaults to `"secondary"` when not specified.
	 */
	priority?: ButtonPriority;

	/**
	 * Configures the size variant of the button.  
	 * Can be one of `"medium"`; `"large"; or `"xlarge"`.  
	 * Defaults to `"medium"` when not specified.
	 */
	size?: ButtonSize;
};

type PillButtonProps = Props
	& Omit<ComponentPropsWithoutRef<"button">, "children" | "slot">;

type AnchorPillButtonProps = Props
	& Omit<ComponentPropsWithoutRef<"a">, "children" | "slot">
	& Pick<ComponentPropsWithoutRef<"button">, "disabled">;

const PillButton = ({
	className,
	icon,
	label,
	priority,
	size,
	...props
}: PillButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	// Counter to regular HTML buttons it is more convenient
	// to default to the "button" type instead of the "submit"
	// type.
	props.type ??= "button";
	props["aria-label"] ??= label;
	props.title ??= label;

	className = classNames("kko-button kko-button--pill", className, {
		"kko-button--primary": priority === "primary",
		"kko-button--secondary": priority === "secondary",
		"kko-button--tertiary": priority === "tertiary",
		"kko-button--large": size === "large",
		"kko-button--xlarge": size === "xlarge",
	});

	const iconObj = typeof icon === "string"
		? { only: icon }
		: icon;

	return <button
		ref={ref}
		className={className}
		{...props}
	>
		<Icon icon={iconObj.only} />
	</button>
};

const AnchorPillButton = ({
	className,
	disabled,
	icon,
	label,
	priority,
	size,
	...props
}: AnchorPillButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
	// Anchor elements can't normally be disabled like buttons can.
	// But ARIA does allow them to be announced as disabled with the "aria-disabled" attribute.
	// To then actually disable their link behavior, we have to remove the "href" attribute.
	// However, doing so also removes the intrinsic "link" role and we have to add it explicitly.
	if (disabled) {
		props["aria-disabled"] = "true";
		props.href = undefined;
		props.role ??= "link";

		if (!/\blink\b/i.test(props.role)) {
			props.role = `${props.role} link`.trimStart();
		}
	}

	props["aria-label"] ??= label;
	props.title ??= label;

	className = classNames("kko-button kko-button--pill", className, {
		"kko-button--primary": priority === "primary",
		"kko-button--secondary": priority === "secondary",
		"kko-button--tertiary": priority === "tertiary",
		"kko-button--large": size === "large",
		"kko-button--xlarge": size === "xlarge",
	});

	const iconObj = typeof icon === "string"
		? { only: icon }
		: icon;

	return <a
		ref={ref}
		className={className}
		{...props}
	>
		<Icon icon={iconObj.only} />
	</a>
};

const Overload = forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	AnchorPillButtonProps | PillButtonProps
>((props, ref) => isAnchor(props)
	? AnchorPillButton(props, ref as ForwardedRef<HTMLAnchorElement>)
	: PillButton(props, ref as ForwardedRef<HTMLButtonElement>)
);
Overload.displayName = "PillButton";

export { Overload as PillButton };


function isAnchor(value: PillButtonProps | AnchorPillButtonProps): value is AnchorPillButtonProps {
	return "href" in value && !!value.href;
};
