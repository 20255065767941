import { DependencyList, EffectCallback, RefCallback, useCallback, useRef } from "react";

export type RefEffectCallback<T> = (instance: T) => ReturnType<EffectCallback>;
export type AsyncRefEffectCallback<T> = (instance: T, signal: AbortSignal) => Promise<void> | void;

/**
 * Call `useRefEffect` at the top level of your component to declare a function
 * that contains imperative, possibly effectful code to be run whenever the
 * {@link https://react.dev/reference/react-dom/components/common#ref-callback ref callback}
 * function returned by the hook is called - i.e. , is assigned a value.
 * @param effect
 * Imperative function that receives the value of the ref callback function
 * returned by the hook. It may optionally return a _cleanup_ function.  
 * After each re-render that changes the ref value or the optional dependencies,
 * React will first run the cleanup function (if you provided it) with the
 * old values, before running the effect with the new values. React will also
 * run the cleanup function when the component unmounts.
 * @param deps
 * If present, `effect` will activate also when the values in the list change.
 * @returns
 * A ref callback function that can be used to execute the effect.
 */
export function useRefEffect<T = Element>(
	effect: RefEffectCallback<T>,
	deps?: DependencyList
): RefCallback<T> {
	const cleanup = useRef<ReturnType<EffectCallback> | null>(null);

	return useCallback<RefCallback<T>>(value => {
		cleanup.current?.();

		if (value == null) {
			cleanup.current = null;
			return;
		}

		cleanup.current = effect(value) ?? null;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps ?? []);
}

/**
 * Call `useAsyncRefEffect` at the top level of your component to declare a function
 * that contains imperative, possibly effectful code to be run asynchronously whenever the
 * {@link https://react.dev/reference/react-dom/components/common#ref-callback ref callback}
 * function returned by the hook is called - i.e. , is assigned a value.
 * @param effect
 * Imperative, possibly asynchronous function that receives the value of the ref callback
 * function returned by the hook and 
 * an {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal | AbortSignal}
 * to allow subscribing cleanup logic and cooperative cancellation of effectful code
 * that is still in-progress.
 * 
 * After each re-render that changes the ref value or the optional dependencies,
 * React will first abort the signal, allowing subscribed cleanup logic to run with
 * the old values, before running the effect with the new values. React will also
 * abort the signal when the component unmounts.
 * 
 * **⚠️ CAUTION:**  
 * Take care to check the signal's state after each `await`-ed operation inside
 * your asynchronous function and halt further execution accordingly if the signal
 * was aborted. Failing to do so may result in accidentally applying effectful code
 * based on outdated component state.
 * 
 * @param deps
 * If present, `effect` will activate also when the values in the list change.
 */
export function useAsyncRefEffect<T = Element>(
	effect: AsyncRefEffectCallback<T>,
	deps?: DependencyList
): RefCallback<T> {
	return useRefEffect(element => {
		const controller = new AbortController();
		const result = effect(element, controller.signal);

		// If the effect is in fact asynchronous and returns a Promise;
		// and it throws an error, then the following catches it and logs it,
		// preventing an Uncaught Error.
		Promise
			.resolve(result)
			.catch(console.error);

		return () => { controller.abort() };
	}, deps);
}