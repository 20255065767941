import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type CoreState = {
	readonly sidebar: SidebarState;
	readonly sidemenu: SidemenuState;
	readonly searchbox: SearchboxState;
}

type SidebarState = {
	readonly compacted: boolean;
};

type SidemenuState = {
	readonly expanded: boolean;
}

type SearchboxState = {
	readonly expanded: boolean;
}

const initialState: CoreState = {
	sidebar: { compacted: false },
	sidemenu: { expanded: false },
	searchbox: { expanded: false },
};

export const slice = createSlice({
	name: "core",
	initialState,
	reducers: {
		/**
		 * Toggles the compacted state of the desktop sidebar or explicitly
		 * sets it to compacted or normal state.
		 */
		toggleSidebar: (state, action: PayloadAction<boolean | undefined>) => {
			state.sidebar.compacted = action.payload ?? !state.sidebar.compacted;
		},

		/**
		 * Toggles the expanded state of the mobile side menu or explicitly
		 * sets it to expanded or collapsed state.  
		 * Where the side menu is expanded when the mutually exclusive mobile
		 * search box is expanded, the search box is collapsed.
		 */
		toggleSidemenu: (state, action: PayloadAction<boolean | undefined>) => {
			const value = action.payload ?? !state.sidemenu.expanded;
			state.sidemenu.expanded = value;

			if (value) {
				state.searchbox.expanded = false;
			}
		},

		/**
		 * Toggles the expanded state of the mobile search box or explicitly
		 * sets it to expanded or collapsed state.  
		 * Where the search box is expanded when the mutually exclusive mobile
		 * side menu is expanded, the side menu is collapsed.
		 */
		toggleSearchbox: (state, action: PayloadAction<boolean | undefined>) => {
			const value = action.payload ?? !state.searchbox.expanded;
			state.searchbox.expanded = value;

			if (value) {
				state.sidemenu.expanded = false;
			}
		},
	}
});

export const {
	toggleSidebar,
	toggleSidemenu,
	toggleSearchbox
} = slice.actions;