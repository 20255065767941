import { Dispatch, Reducer, ReducerAction, ReducerState, useRef } from "react";
import { Listen, ReducerListener, useListeningReducer } from "./use-listening-reducer";

type ControlledReducerOptions<TState extends any, TAction extends any, TProps extends {}> = {
	/**
	 * The controlled properties of the state.
	 * Updates to these will be dispatched as a `PropsAction`.
	 */
	props: TProps;

	/**
	 * Initializes the reducer by taking the initial controlled
	 * properties and completing the initial state.
	 * @param props 
	 * The controlled properties of the state.
	 * @returns
	 * The completed initial reducer state.
	 */
	initializer: (props: TProps) => TState,

	/**
	 * The reducer that updates the state.
	 */
	reducer: Reducer<TState, TAction | PropsAction<TProps>>;
}

/**
 * A reducer action payload indicating that one or more of the
 * controlled properties of a `ControlledReducer` have changed.
 */
export type PropsAction<TProps extends {}> = {
	/**
	 * The action type.  
	 * This is always `"PropsUpdate"` for a `PropsAction`.
	 */
	type: "PropsUpdate",

	/** The new properties. */
	props: TProps,

	/** The old properties. */
	oldProps: TProps,

	/** The names of the properties that have changed. */
	changed: Set<(keyof TProps)>
}

/**
 * Call `useControlledReducer at the top level of your component to manage its state
 * with a {@link https://react.dev/learn/extracting-state-logic-into-a-reducer | reducer}
 * that can take updates from {@link https://react.dev/learn/sharing-state-between-components#controlled-and-uncontrolled-components | controlled component}
 * property values.
 * @param options
 * Options that configure the reducer.
 * @returns
 * The reducer's current value.
 */
export function useControlledReducer<
	TState extends any,
	TAction extends any,
	TProps extends {}
>({ reducer, props, initializer }: ControlledReducerOptions<TState, TAction, TProps>
): [ReducerState<typeof reducer>, Dispatch<ReducerAction<typeof reducer>>, Listen<ReducerListener<typeof reducer>>] {
	// Create the original reducer
	const [state, dispatch, listen] = useListeningReducer(
		reducer,
		props,
		initializer
	);

	const ref = useRef({ oldProps: props });

	// Accumulate the changes we found in the props.
	// Then mark the new props as the current props.
	const { oldProps } = ref.current;
	const changed = new Set<keyof TProps>([
		...Object.keys(oldProps),
		...Object.keys(props)
	] as (keyof TProps)[]);

	for (const key of [...changed]) {
		if (Object.is(oldProps[key], props[key])) {
			changed.delete(key);
		}
	}

	ref.current.oldProps = props;

	// If we have any changes, we will dispatch them into the reducer now.
	if (changed.size) {
		const action = { type: "PropsUpdate" as const, changed, props, oldProps };
		dispatch(action);
	}

	return [state, dispatch, listen];
}
