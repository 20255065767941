import classNames from "classnames";
import { memo } from "react";

type Props = {
	/**
	 * Configures the icon glyph to render.
	 */
	icon: string;

	/**
	 * Configures the size of the icon.
	 */
	size?: IconSize;

	/**
	 * Configures whether to center the icon wrt
	 * any surrounding text.
	 */
	center?: boolean;

	className?: string;
};

export type IconSize = "small" | "medium" | "large";

const sizes = new Map<IconSize, string>([
	["small", "s"],
	["medium", "m"],
	["large", "l"]
]);

export const Icon = memo(({ icon, size, center, className }: Props) => {
	let rootClassName = "kko-icon";

	if (icon.startsWith("png:")) {
		icon = icon.slice(4);
		rootClassName = "kko-icon-png";
	}

	return <i
		className={classNames(rootClassName, className)}
		aria-hidden="true"
		data-icon={icon}
		data-size={size ? sizes.get(size) : undefined}
		data-valign={center ? "center" : undefined}
	/>
});
