import { MsalProvider } from "@azure/msal-react";
import i18n from "i18next";
import { useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { setAccountName } from "src/redux/slices/profileSlice";
import { env } from "src/env";
import { instance as msal, policies, verifyAccountFlow } from "./common/auth";
import { routes } from "./routes";
import { Loader } from "./modules/shared/components/Loader";
import { OperationFeedback } from "./modules/shared/components/OperationFeedback";
import { isAbortError, useAsyncEffect } from "./common/hooks";
import { showLoading, hideLoading } from "./redux/slices/feedbackSlice";
import { useAppDispatch, useAppSelector } from "./common/redux";
import { initAPIs } from "./open-api";
import { api } from "./apis/localization";

// Initialize legacy openapi-codegen API service clients.
initAPIs();

const router = createBrowserRouter(routes, {
	basename: new URL(env.REACT_APP_BASE_PATH ?? "", "https://-").pathname
});

export const App = () => {
	const dispatch = useAppDispatch();
	const isLoading = useAppSelector(state => state.feedback.isLoading);
	const [haveTranslations, setHaveTranslations] = useState(false);

	useAsyncEffect(async signal => {
		dispatch(showLoading());

		const resultEn = dispatch(api.endpoints.getV1ResourcesAll.initiate({ "Accept-Language": "en-US" }));
		const resultNl = dispatch(api.endpoints.getV1ResourcesAll.initiate({ "Accept-Language": "nl-NL" }));

		try {
			const [en, nl] = await Promise.all([
				resultEn.unwrap(),
				resultNl.unwrap(),
			]);

			i18n.addResources("en-US", "kko", en);
			i18n.addResources("nl-NL", "kko", nl);

			setHaveTranslations(true);
		} catch (error) {
			if (!isAbortError(signal)) {
				console.error("Error while getting resources", error);
			}
		} finally {
			resultEn.unsubscribe();
			resultNl.unsubscribe();
			dispatch(hideLoading());
		}
	}, [dispatch]);

	useAsyncEffect(async () => {
		const authResult = await msal.handleRedirectPromise();

		let account = authResult?.account ?? msal.getAllAccounts()[0];

		// If we have one of the valid policies we will use the auth result account
		// as the active account. Otherwise, we need to find the account that corresponds
		// to one of the valid policies.
		if (!verifyAccountFlow(account, [policies.signIn, policies.redeemInvite])) {
			account = msal
				.getAllAccounts()
				.filter(account => verifyAccountFlow(account, [policies.signIn, policies.redeemInvite]))[0];
		}

		if (account == null) {
			msal.setActiveAccount(null);
			dispatch(setAccountName(undefined));
		} else {
			msal.setActiveAccount(account);
			dispatch(setAccountName(account.name));
		}

	}, [dispatch]);

	return (
		<>
			{haveTranslations && (
				<MsalProvider instance={msal}>
					<RouterProvider router={router} />
				</MsalProvider>
			)}
			{isLoading && <Loader />}
			<OperationFeedback />
		</>
	);
}
