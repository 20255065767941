import { KeyboardEventHandler, useCallback, useMemo } from "react";
import { LiteralUnionSet } from "../../utils";
import { PropsAction, useControlledReducer } from "../use-controlled-reducer";

const keyboardKeys = new LiteralUnionSet("ArrowDown", "ArrowUp", "Home", "End", "Escape", "Enter", "Space");
type KeyboardKey = LiteralUnionSet.Literal<typeof keyboardKeys>;

type UserKeyboardAction = {
	type: "Keyboard",
	key: KeyboardKey,
};

type UserOptionAction<TOption> = {
	type: "FocusOption" | "SelectOption",
	option: TOption
}

type AriaGridState<TOption extends object> = Readonly<{
	options: TOption[];
	selected?: TOption;
}>;

export type AriaGridProps<TOption extends object> = Readonly<{
	options: TOption[];
	selected?: TOption;
}>;

export type GridProps<TOption extends object> = Readonly<{
	onKeyDown: KeyboardEventHandler;
	tabIndex: number;
	onClick: (e: TOption) => void;
}>;

export function useAriaGrid<T extends object>(props: AriaGridProps<T>,
	onChange?: (value: T | undefined) => void,
	is: (a: T | undefined, b: T | undefined) => boolean = Object.is) {

	const keyboardReducer = useCallback((prevState: AriaGridState<T>, action: UserKeyboardAction): AriaGridState<T> => {
		const { options, selected } = prevState;

		// If we don't have any options, keyboard interaction shouldn't be able
		// to accomplish anything.
		if (!options.length) return prevState;

		switch (action.key) {
			case "ArrowDown": {
				if (!selected)
					return { ...prevState, selected: options[0] };

				const index = options.findIndex((item: T) => is(item, selected)) + 1;
				return (index < options.length)
					? { ...prevState, selected: options[index] }
					: prevState;
			}

			case "ArrowUp": {
				if (!selected)
					return { ...prevState, selected: options[0] };

				const index = options.findIndex((item: T) => is(item, selected)) - 1;
				return (index !== -1)
					? { ...prevState, selected: options[Math.max(0, index)] }
					: prevState;
			}

			case "Home": {
				return { ...prevState, selected: options[0] }
			}

			case "End": {
				return { ...prevState, selected: options[options.length - 1] }
			}

			default:
				return prevState;
		}
	}, [is]);

	const optionReducer = useCallback((prevState: AriaGridState<T>, action: UserOptionAction<T>): AriaGridState<T> => {
		const option = prevState.options.find(option => is(option, action.option));

		switch (action.type) {
			case "SelectOption":
				return is(prevState.selected, option)
					? prevState
					: { ...prevState, selected: option };
			default:
				return prevState;
		}
	}, [is]);

	const reducer = useCallback((
		prevState: AriaGridState<T>,
		action: UserKeyboardAction | UserOptionAction<T> | PropsAction<AriaGridProps<T>>
	): AriaGridState<T> => {

		switch (action.type) {
			case "Keyboard":
				return keyboardReducer(prevState, action);
			case "SelectOption":
				return optionReducer(prevState, action);
			default:
				return prevState;
		}
	}, [keyboardReducer, optionReducer]);

	const [state, dispatch, listen] = useControlledReducer({
		props,
		reducer,
		initializer: props => ({
			...props,
			selected: undefined,
			options: props?.options
		}),
	});

	const onKeyDown: KeyboardEventHandler<HTMLElement> = useCallback(event => {
		if (event.defaultPrevented) return;

		if (keyboardKeys.has(event.key)) {
			event.preventDefault();
			dispatch({ type: "Keyboard", key: event.key });
		}
	}, [dispatch]);

	listen((state, prevState, action) => {
		if (!onChange) return;
		if (!prevState || !action || action.type === "PropsUpdate") return;
		if (is(prevState.selected, state.selected)) return;

		onChange(state.selected);
	});

	const onClick = useCallback((item: T) => {
		dispatch({ type: "SelectOption", option: item });
	}, [dispatch]);

	const grid: GridProps<T> = useMemo(() => ({
		onKeyDown: onKeyDown,
		tabIndex: 0,
		onClick: onClick
	}), [onKeyDown, onClick]);

	return { state, grid };
}

