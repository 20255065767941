import { blobToDataURL, DataURL } from "src/common/utils";
import { api, GetV1AccountsByAccountIdProfilePhotoArgs } from "./api.generated";

const updatedApi = api.injectEndpoints({
	// We have to replace the generated `/v1/accounts/::accountId/profile/photo` endpoint.
	overrideExisting: true,

	endpoints: build => ({
		getV1AccountsByAccountIdProfilePhoto: build.query<
			GetV1AccountsByAccountIdProfilePhotoApiResponse,
			GetV1AccountsByAccountIdProfilePhotoArgs
		>({
			query: (queryArg) => ({
				url: `/v1/accounts/${queryArg.accountId}/profile/photo`,
				headers: { "Accept-Language": queryArg["Accept-Language"] },
				params: { imageSize: queryArg.imageSize },
				validateStatus: response => {
					// Accepts a 404 Not Found status as valid.
					return response.status < 400 || response.status === 404;
				},
				responseHandler: async response => {
					const { status } = response;

					// Both 204 and 404 needs to be treated as a success state
					// with no result
					if (status === 204 || status === 404) return undefined;

					if (!response.ok) throw new Error();

					// Anything going into the Redux store has to be serializable.
					// Blobs aren't. So we take a detour and turn them into data
					// URIs.
					// This is less than ideal, but the only viable alternative
					// would be to use `URL.createObjectURL` and store those.
					// However, this has the downside of _leaking_ created URLs
					// unless they are explicitly revoked with `URL.revokeObjectURL`.
					// Doing that means tying into the RTK Query cache lifetime
					// management with a lot of manual wizarding.
					// Plus- if ever we decide to persist the Redux state, so that
					// for instance the application can survive an accidental F5 to
					// refresh, those stored object URLs would also no longer be
					// valid.
					const blob = await response.blob();
					return await blobToDataURL(blob);
				}
			})
		})
	})
});

const enhancedApi = updatedApi.enhanceEndpoints({
	addTagTypes: ["Contact", "Profile", "ProfilePhoto", "ReceivedInvite", "SentInvite"],
	endpoints: {
		streamAllAddressBookEntries: {
			providesTags: [{ type: "Contact", id: "LIST" }]
		},
		getAddressBookEntryById: {
			providesTags: (response, error, args) => [{ type: "Contact", id: args.addressBookEntryId }]
		},
		createAddressBookEntry: {
			invalidatesTags: [{ type: "Contact", id: "LIST" }]
		},
		deleteAddressBookEntry: {
			invalidatesTags: (response, error, args) => [
				{ type: "Contact", id: args.addressBookEntryId },
				{ type: "Contact", id: "LIST" }
			]
		},
		updateAddressBookEntry: {
			invalidatesTags: (response, error, args) => [
				{ type: "Contact", id: args.updateAddressBookEntryRequestModel.addressBookEntryId },
				{ type: "Contact", id: "LIST" }
			]
		},
		getProfileByAccountId: {
			providesTags: (response, error, args) => [
				{ type: "Profile", id: args.accountId }
			]
		},
		getProfileByCurrentUserAccountId: {
			providesTags: (response, error, args) => response
				? [{ type: "Profile", id: response.accountId }]
				: [{ type: "Profile", id: "CURRENT" }] // if we get an error we still want to be able to invalidate
		},
		updateProfile: {
			invalidatesTags: (response, error, args) => [
				{ type: "Profile", id: args.accountId },
				{ type: "Profile", id: "CURRENT" } // invalidate CURRENT if it exists (in an error state)
			]
		},
		getV1AccountsByAccountIdProfilePhoto: {
			providesTags: (response, error, args) => [
				{ type: "ProfilePhoto", id: args.accountId }
			]
		},
		putV1AccountsByAccountIdProfilePhoto: {
			invalidatesTags: (response, error, args) => [
				{ type: "ProfilePhoto", id: args.accountId },
				{ type: "Profile", id: args.accountId }
			]
		},
		deleteV1AccountsByAccountIdProfilePhoto: {
			invalidatesTags: (response, error, args) => [
				{ type: "ProfilePhoto", id: args.accountId },
				{ type: "Profile", id: args.accountId }
			]
		},

		getReceivedNetworkInvitations: {
			providesTags: [{ type: "ReceivedInvite", id: "LIST" }]
		},
		acceptNetworkInvitation: {
			invalidatesTags: [{ type: "ReceivedInvite", id: "LIST" }]
		},
		declineNetworkInvitation: {
			invalidatesTags: [{ type: "ReceivedInvite", id: "LIST" }]
		},

		getSentNetworkInvitations: {
			providesTags: [{ type: "SentInvite", id: "LIST" }]
		},
		createNetworkInvitationForKnownUser: {
			invalidatesTags: [{ type: "SentInvite", id: "LIST" }]
		},
		createNetworkInvitationForUnknownUser: {
			invalidatesTags: [{ type: "SentInvite", id: "LIST" }]
		},
		revokeNetworkInvitation: {
			invalidatesTags: [{ type: "SentInvite", id: "LIST" }]
		}
	}
});

// The following is a bit weird.
// We want to forward the exports of all the API schema
// types, but we _also_ want some of those types to be
// updated to reflect the overwritten endpoint.
// Luckily, it's possible to overwrite re-exports.
export * from "./api.generated";

export type GetV1AccountsByAccountIdProfilePhotoApiResponse =
	| /** status 200 Success */ DataURL
	| /** status 204 No Content */ undefined
	| /** status 404 Not Found */ undefined;

export const {
	useGetV1AccountsByAccountIdProfilePhotoQuery,
	useLazyGetV1AccountsByAccountIdProfilePhotoQuery
} = enhancedApi;
export { enhancedApi as api };
