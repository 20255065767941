import { ComponentPropsWithoutRef, FC, forwardRef, useMemo } from "react"
import { useLocale } from "src/common/hooks";
import {
	DominantRelativeTimeFormat,
	DominantRelativeTimeFormatNumeric,
	DominantRelativeTimeFormatUnit,
	toLocalISOString
} from "src/common/utils";

type Props = Omit<ComponentPropsWithoutRef<"time">,
	| "children"
	| "dateTime"
	| "slot"
> & {
	dateTime: Date | string;
	relativeTo?: Date | string;
	granularity?: DominantRelativeTimeFormatUnit;
	numeric?: DominantRelativeTimeFormatNumeric;
	timeStyle?: Intl.RelativeTimeFormatStyle;
}

export const RelativeTime: FC<Props> = forwardRef<HTMLTimeElement, Props>(({
	dateTime,
	relativeTo,
	numeric,
	timeStyle,
	granularity = "minute",
	...props
}, ref) => {
	const locale = useLocale();

	const dateTimeDate = useMemo(() => {
		return typeof dateTime === "string"
			? new Date(dateTime)
			: dateTime
	}, [dateTime]);

	const relativeToDate = useMemo(() => {
		return typeof relativeTo === "string"
			? new Date(relativeTo)
			: relativeTo;
	}, [relativeTo]);

	const formatter = useMemo(() => {
		const usedLocale = locale === "en-US" ? "en-NL" : locale;

		try {
			return new DominantRelativeTimeFormat(usedLocale, {
				granularity,
				numeric,
				style: timeStyle
			});
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}, [granularity, locale, numeric, timeStyle]);

	const formatted = useMemo(() => {
		const relativeTo = relativeToDate ?? new Date();

		if (!formatter
			|| isNaN(+dateTimeDate)
			|| (isNaN(+relativeTo))
		) return null;

		return formatter.format(dateTimeDate, relativeTo);
	}, [dateTimeDate, formatter, relativeToDate]);


	if (!formatted) return null;

	return (
		<time
			{...props}
			ref={ref}
			dateTime={toLocalISOString(dateTimeDate)}
		>{formatted}</time>
	);
});