import { useMsal } from "@azure/msal-react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
	useGetProfileByAccountIdQuery,
	useGetV1AccountsByAccountIdProfilePhotoQuery
} from "src/apis/network";
import { useAriaMenu, useLocale } from "src/common/hooks";
import { isFetchBaseQueryError } from "src/common/redux";
import { Avatar } from "src/modules/shared/components/avatars";
import { usePlatformAccount } from "src/modules/shared/hooks";

type MenuItemAppearance = "normal" | "danger";
type AccountMenuCommand = "profile" | "settings" | "signout";

type AccountMenuItem = {
	appearance?: MenuItemAppearance;
	label: string;
	command: AccountMenuCommand;
};

export const AccountMenu: FC = () => {
	const { t } = useTranslation("kko", { keyPrefix: "core.header.account-menu" });
	const locale = useLocale();
	const navigate = useNavigate();

	const { instance: msal } = useMsal();

	const { accountName: fallbackName = "", accountId } = usePlatformAccount();

	// TODO:
	// Should update to `useGetMinimalProfileByAccountIdQuery` once it's been extended
	// to include organization and job role.
	const { data: profile } = useGetProfileByAccountIdQuery(accountId ? {
		"Accept-Language": locale,
		accountId
	} : skipToken);

	const { data: photo } = useGetV1AccountsByAccountIdProfilePhotoQuery(accountId ? {
		"Accept-Language": locale,
		imageSize: "Large",
		accountId,
	} : skipToken, {
		// Coerces a 404 to a positive result
		selectFromResult: ({ data, error, isSuccess, isError, ...other }) => {
			if (isFetchBaseQueryError(error) && error.status === 404) {
				data = undefined;
				error = undefined;
				isError = false;
				isSuccess = true;
			}

			return { data, error, isSuccess, isError, ...other };
		}
	});

	const menuItems = useMemo<readonly AccountMenuItem[]>(() => {
		return [{
			command: "profile",
			label: t("menuitems.profile")
		}, {
			command: "settings",
			label: t("menuitems.settings")
		}, {
			command: "signout",
			label: t("menuitems.sign-out"),
			appearance: "danger"
		}]
	}, [t]);

	const { name, role, organization } = useMemo(() => ({
		name: profile?.name.displayName ?? fallbackName,
		role: profile?.jobRole?.value,
		organization: profile?.organization?.value,
	}), [profile, fallbackName]);

	const label = useMemo(() => {
		const context = [];
		if (name) context.push("name");
		if (role) context.push("role");
		if (organization) context.push("organization");

		return t("label", {
			name,
			role,
			organization,
			context: context.join("+")
		});
	}, [name, role, organization, t]);

	const onSelectedHandler = useCallback((value: AccountMenuItem | undefined) => {
		if (!value) return;

		switch (value.command) {
			case "profile":
				navigate("/account/profile");
				break;
			case "settings":
				navigate("/account/settings");
				break;
			case "signout":
				msal.logout();
				break;
		}
	}, [msal, navigate]);

	const {
		state,
		menuButtonProps,
		menuProps,
		getMenuItemProps,
		isActiveMenuItem
	} = useAriaMenu({
		menuItems,
		is: (a, b) => a?.command === b?.command,
		onSelected: onSelectedHandler
	});

	return (
		<div className="kko-header__profile">
			<div
				className="kko-dropdown-list"
				data-expanded={state.expanded}
			>
				<button
					{...menuButtonProps}
					className="kko-person-summary kko-dropdown-list__trigger"
					aria-label={label}
					title={label}
				>
					<Avatar image={photo} name={name} />
					<div className="kko-person-summary__content">
						<div className="kko-person-summary__name">
							{name}
						</div>
						<div className="kko-person-summary__title">
							{role}
							{!!(role && organization) && <br />}
							{organization}
						</div>
					</div>
				</button>
				<div className="kko-dropdown-list__menu-container">
					<ul
						{...menuProps}
						className="kko-dropdown-list__menu"
						aria-label={label}
					>
						{state.menuItems.map(menuItem => (
							<li
								key={menuItem.command}
								role="none"
							>
								<span
									{...getMenuItemProps(menuItem)}
									className="kko-action-list-item"
									data-active={isActiveMenuItem(menuItem) ? "" : undefined}
									data-appearance={menuItem.appearance === "normal" ? undefined : menuItem.appearance}
								>
									<span>{menuItem.label}</span>
								</span>
							</li>
						))}
					</ul>
				</div>
			</div>
		</div>
	);
};