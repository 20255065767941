import type { RouteObject } from "react-router-dom";

export const routes: RouteObject[] = [{
	path: "terms-and-conditions",
	async lazy() {
		const { TermsAndConditions } = await import("./pages");
		return { element: <TermsAndConditions locale="en-US" /> };
	}
}, {
	path: "algemene-voorwaarden",
	async lazy() {
		const { TermsAndConditions } = await import("./pages");
		return { element: <TermsAndConditions locale="nl-NL" /> };
	}
}];