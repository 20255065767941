import { forwardRef } from "react";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { toggleChats } from "src/redux/slices/chatsSlice";
import { api as chatApi } from "src/apis/chat";
import { useUnreadMessages } from "../hooks/use-unread-messages";

export const ChatsPillIndicator = forwardRef<HTMLDivElement>((props, ref) => {

	const { unreadMessages } = useUnreadMessages();
	const dispatch = useAppDispatch();
	const show = () => {
		// we want to fetch fresh data when we open the chat thread
		!chatsOpen && dispatch(chatApi.util.invalidateTags(["ChatThread"]));
		dispatch(toggleChats(undefined));
	}

	const chatsOpen = useAppSelector(state => state.chat.show);
	return (
		<div
			ref={ref}
			className="kko-header-pill-notification"
			onClick={() => show()}
		>
			{unreadMessages ? <div className="kko-number-badge">
				<i aria-hidden="true">{unreadMessages}</i>
				<span role="status">{`You have ${unreadMessages} unread messages`}</span>
			</div> : undefined}
			<button className="kko-button kko-button--pill kko-button--xlarge" data-appearance={chatsOpen ? "active" : undefined}>
				<i className="kko-icon" data-icon={chatsOpen ? "chat-fill" : "chat"}></i>
			</button>
		</div>
	)
});

