import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ChatsState } from "../types";

const initialState: ChatsState = {
	show: false,
	focusedThreadId: undefined,
	unreadMessagesCount: {},
	lastMessageDate: undefined
};

export const chatSlice = createSlice({
	name: "chat",
	initialState,
	reducers: {
		toggleChats: (state, payload: PayloadAction<string | undefined>) => {
			state.show = !state.show;
			state.focusedThreadId = payload.payload;
		},
		focusChat: (state, payload: PayloadAction<string | undefined>) => {
			state.focusedThreadId = payload.payload;
		},
		hideChats: (state) => {
			state.show = false;
			state.focusedThreadId = undefined;
		},
		setUnreadMessagesCount: (state, action: PayloadAction<{ unreadMessagesCount: number, threadId: string }>) => {
			state.unreadMessagesCount[action.payload.threadId] = action.payload.unreadMessagesCount;
		},
		setLastMessageDate: (state, action: PayloadAction<string | undefined>) => {
			state.lastMessageDate = action.payload;
		},
	}
});

export const {
	toggleChats,
	focusChat,
	hideChats,
	setUnreadMessagesCount,
	setLastMessageDate
} = chatSlice.actions;
export default chatSlice.reducer;