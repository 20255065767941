export default interface ICacaoSideMenu {
	readonly path: string;
	readonly icon?: string;
	readonly iconSelected?: string;
	readonly translationKey: string
}

export const sideMenu: readonly ICacaoSideMenu[] = [{
	path: "/",
	icon: "home",
	iconSelected: "home-fill",
	translationKey: "kko:general.menu.links.home"
},
{
	path: "/network/contacts",
	icon: "user",
	iconSelected: "user-fill",
	translationKey: "kko:general.menu.links.address-book"
},
{
	path: "/calendar",
	icon: "calendar",
	iconSelected: "calendar-fill",
	translationKey: "kko:general.menu.links.calendar"
},
{
	path: "/tasks",
	icon: "clipboard-check",
	iconSelected: "clipboard-check-fill",
	translationKey: "kko:general.menu.links.tasks"
},
{
	path: "/episodes/summary",
	icon: "description",
	iconSelected: "description-fill",
	translationKey: "kko:general.menu.links.episodes"
},
];
