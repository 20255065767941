import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { To, generatePath } from "react-router-dom";
import { useLocale } from "src/common/hooks";
import { DatetimeRange } from "src/modules/shared/components/DatetimeRange";
import { toISODateString } from "src/common/utils";
import { CalendarRelatedNotification, NotificationBadge } from "../types"
import { NotificationShell } from "./NotificationShell";

type CalendarNotificationProps = {
	notification: CalendarRelatedNotification
}

export const CalendarNotification: FC<CalendarNotificationProps> = ({ notification }) => {
	const { t } = useTranslation();
	const locale = useLocale();

	const { payload, kind } = notification;

	const title = useMemo<ReactNode>(() => {
		switch (kind) {
			case "CalendarAppointmentNew":
				return t("kko:pages.notifications.new-calendar-appointment.invited-text");

			case "CalendarAppointmentCancelled":
				return t("kko:pages.calendar.cancelled-appointment-message");

			case "CalendarAppointmentDataChanged":
				return t("kko:pages.notifications.calendar-appointment-changed.invited-text");

			case "CalendarAppointmentPeriodChanged":
				return t("kko:pages.calendar.rescheduled-appointment-message");

			case "CalendarAppointmentAttendanceAccepted":
				return t("kko:pages.notifications.changed-calendar-appointment.accepted");

			case "CalendarAppointmentAttendanceDeclined":
				return t("kko:pages.notifications.changed-calendar-appointment.declined");

			default:
				return null;
		}
	}, [kind, t]);

	const badge = useMemo<NotificationBadge | undefined>(() => {
		switch (kind) {
			case "CalendarAppointmentCancelled":
			case "CalendarAppointmentAttendanceDeclined":
				return "danger";
			case "CalendarAppointmentAttendanceAccepted":
				return "success";
			default:
				return undefined;
		}
	}, [kind]);

	const cancelled = kind === "CalendarAppointmentCancelled";

	const to = useMemo<To>(() => {
		const date = payload.start && !cancelled
			? new Date(payload.start)
			: undefined;

		return date && !isNaN(+date)
			? generatePath("/calendar/:date", { date: toISODateString(date) })
			: "/calendar"
	}, [cancelled, payload.start]);

	return (
		<NotificationShell
			notification={notification}
			badge={badge}
			title={title}
			to={to}
		>
			<span className={classNames({ "kko-text--strike-accented": cancelled })}>
				{payload.organizer?.userName}
				<span aria-hidden="true"> · </span>
				{payload.title}
				<br />
				<DatetimeRange
					locale={locale}
					start={payload.start}
					end={payload.end}
				/>
			</span>
		</NotificationShell>
	)
};