import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationActionModel } from "src/open-api/notificationService";
import { request } from "src/open-api/calendarService/core/request";
import { OpenAPI } from "src/open-api/calendarService";
import { useLocale } from "src/common/hooks";
import { useAppDispatch } from "src/common/redux";
import { ToastType, addToast } from "src/redux/slices/feedbackSlice";
import { Button } from "src/modules/shared/components/buttons/Button";

interface NotificationActionsProps {
	actions: readonly NotificationActionModel[];
	refetch?: () => void;
}

export const NotificationActions: FC<NotificationActionsProps> = ({ actions, refetch }) => {
	const { t } = useTranslation();
	const locale = useLocale();

	const dispatch = useAppDispatch();

	const [localUsedActions, setLocalUsedActions] = useState<NotificationActionModel[]>([]);

	const executeAction = useCallback(async (action: NotificationActionModel) => {
		try {
			await request(OpenAPI, {
				url: action.relativePath ?? "",
				method: action.verb as "GET" | "PATCH",
				headers: { "Accept-Language": locale },
			});

			dispatch(addToast({
				icon: "check",
				type: ToastType.Success,
				titleKey: "kko:general.feedback.notification-action-success-title",
				contentKey: "kko:general.feedback.notification-action-success-content"
			}));

			setLocalUsedActions([...localUsedActions, action]);
			refetch && refetch();

		} catch {
			dispatch(addToast({
				type: ToastType.Error,
				titleKey: "kko:general.feedback.notification-action-error-title",
				contentKey: "kko:general.feedback.notification-action-error-content"
			}));
		}
	}, [dispatch, localUsedActions, locale, refetch]);

	const usedActions = useMemo(() => {
		return localUsedActions.concat(actions.filter(action => action.isUsed));
	}, [actions, localUsedActions]);

	return actions.map((action, index) => (
		<Button
			key={index}
			label={t(`kko:pages.notification.action.kind.${action.kind}`)}
			priority={index === 0 ? "primary" : "secondary"}
			disabled={!!usedActions.length}
			onClick={() => executeAction(action)}
		/>
	));
}


