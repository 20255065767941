import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    upsertEmoticonReaction: build.mutation<
      UpsertEmoticonReactionApiResponse,
      UpsertEmoticonReactionArgs
    >({
      query: (queryArg) => ({
        url: `/v1/threads/${queryArg.chatThreadId}/messages/${queryArg.chatMessageId}/emoticon-reaction`,
        method: "PATCH",
        headers: { "X-Chat-Access-Token": queryArg["X-Chat-Access-Token"] },
        params: { emoticon: queryArg.emoticon },
      }),
    }),
    removeEmoticonReaction: build.mutation<
      RemoveEmoticonReactionApiResponse,
      RemoveEmoticonReactionArgs
    >({
      query: (queryArg) => ({
        url: `/v1/threads/${queryArg.chatThreadId}/messages/${queryArg.chatMessageId}/emoticon-reaction`,
        method: "DELETE",
        headers: { "X-Chat-Access-Token": queryArg["X-Chat-Access-Token"] },
      }),
    }),
    createOneOnOneChatThread: build.mutation<
      CreateOneOnOneChatThreadApiResponse,
      CreateOneOnOneChatThreadArgs
    >({
      query: (queryArg) => ({
        url: `/v1/threads/one-on-one`,
        method: "POST",
        headers: { "X-Chat-Access-Token": queryArg["X-Chat-Access-Token"] },
        params: { accountToChatWith: queryArg.accountToChatWith },
      }),
    }),
    findOneOnOneChatThread: build.mutation<
      FindOneOnOneChatThreadApiResponse,
      FindOneOnOneChatThreadArgs
    >({
      query: (queryArg) => ({
        url: `/v1/threads/find-one-on-one-chat-thread`,
        method: "POST",
        headers: { "X-Chat-Access-Token": queryArg["X-Chat-Access-Token"] },
        params: { accountToChatWith: queryArg.accountToChatWith },
      }),
    }),
    getChatThreads: build.query<GetChatThreadsApiResponse, GetChatThreadsArgs>({
      query: (queryArg) => ({
        url: `/v1/threads`,
        headers: { "X-Chat-Access-Token": queryArg["X-Chat-Access-Token"] },
      }),
    }),
    getChatById: build.query<GetChatByIdApiResponse, GetChatByIdArgs>({
      query: (queryArg) => ({
        url: `/v1/threads/${queryArg.chatId}`,
        headers: { "X-Chat-Access-Token": queryArg["X-Chat-Access-Token"] },
      }),
    }),
    getCommunicationUserIdentity: build.query<
      GetCommunicationUserIdentityApiResponse,
      GetCommunicationUserIdentityArgs
    >({
      query: () => ({ url: `/v1/communication-user/user-identity` }),
    }),
    getCommunicationAccessToken: build.query<
      GetCommunicationAccessTokenApiResponse,
      GetCommunicationAccessTokenArgs
    >({
      query: (queryArg) => ({
        url: `/v1/communication-user/access-token`,
        params: { userIdentity: queryArg.userIdentity },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type UpsertEmoticonReactionApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type UpsertEmoticonReactionArgs = {
  chatThreadId: string;
  chatMessageId: string;
  emoticon?: string;
  /** Additional authorization token for the chat API */
  "X-Chat-Access-Token": string;
};
export type RemoveEmoticonReactionApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type RemoveEmoticonReactionArgs = {
  chatThreadId: string;
  chatMessageId: string;
  /** Additional authorization token for the chat API */
  "X-Chat-Access-Token": string;
};
export type CreateOneOnOneChatThreadApiResponse =
  /** status 201 Created */ CreateOneOnOneChatThreadResponseModel;
export type CreateOneOnOneChatThreadArgs = {
  accountToChatWith: string;
  /** Additional authorization token for the chat API */
  "X-Chat-Access-Token": string;
};
export type FindOneOnOneChatThreadApiResponse =
  /** status 201 Created */ FindOneOnOneChatThreadResponseModel;
export type FindOneOnOneChatThreadArgs = {
  accountToChatWith: string;
  /** Additional authorization token for the chat API */
  "X-Chat-Access-Token": string;
};
export type GetChatThreadsApiResponse =
  /** status 200 Success */ GetChatThreadResponseModel;
export type GetChatThreadsArgs = {
  /** Additional authorization token for the chat API */
  "X-Chat-Access-Token": string;
};
export type GetChatByIdApiResponse =
  /** status 200 Success */ GetChatResponseModel;
export type GetChatByIdArgs = {
  chatId: string;
  /** Additional authorization token for the chat API */
  "X-Chat-Access-Token": string;
};
export type GetCommunicationUserIdentityApiResponse =
  /** status 200 Success */ GetCommunicationUserIdentityResult;
export type GetCommunicationUserIdentityArgs = void;
export type GetCommunicationAccessTokenApiResponse =
  /** status 200 Success */ GetCommunicationAccessTokenResult;
export type GetCommunicationAccessTokenArgs = {
  userIdentity?: string;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type CreateOneOnOneChatThreadResponseModel = {
  /** The id of the newly created chat thread. */
  id: string;
};
export type ChatThreadKind = 0 | 1;
export type ChatThreadParticipantResponseModel = {
  /** The account id of the chat thread participant. */
  accountId: string;
  /** The external chat participant id. */
  chatParticipantId?: string | null;
};
export type ChatThreadResponseModel = {
  /** The chat thread id. */
  id: string;
  /** The external chat thread id. */
  externalChatThreadId: string;
  /** The id of the episode associated with the chat thread. */
  episodeId?: string | null;
  /** The date and time the last message in the chat thread was received. */
  lastMessageReceivedOn?: string | null;
  kind: ChatThreadKind;
  /** The chat thread participants. */
  participants: ChatThreadParticipantResponseModel[];
};
export type FindOneOnOneChatThreadResponseModel = {
  chatThread?: ChatThreadResponseModel;
};
export type GetChatThreadResponseModel = {
  /** The chat threads. */
  items: ChatThreadResponseModel[];
};
export type GetChatResponseModel = {
  chatThread: ChatThreadResponseModel;
};
export type GetCommunicationUserIdentityResult = {
  /** The communication user identity. */
  communicationUserIdentity: string;
};
export type GetCommunicationAccessTokenResult = {
  /** The communication access token. */
  communicationAccessToken: string;
  /** The date time offset when the token expires. */
  expiresOn: string;
};
export const {
  useUpsertEmoticonReactionMutation,
  useRemoveEmoticonReactionMutation,
  useCreateOneOnOneChatThreadMutation,
  useFindOneOnOneChatThreadMutation,
  useGetChatThreadsQuery,
  useGetChatByIdQuery,
  useGetCommunicationUserIdentityQuery,
  useGetCommunicationAccessTokenQuery,
} = injectedRtkApi;
