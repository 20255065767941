import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    textSearch: build.query<TextSearchApiResponse, TextSearchArgs>({
      query: (queryArg) => ({
        url: `/v1/search`,
        method: "POST",
        body: queryArg.searchRequestModel,
      }),
    }),
    getStatistics: build.query<GetStatisticsApiResponse, GetStatisticsArgs>({
      query: () => ({ url: `/v1/search/stats` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type TextSearchApiResponse =
  /** status 201 Created */ SearchResponseModel;
export type TextSearchArgs = {
  searchRequestModel: SearchRequestModel;
};
export type GetStatisticsApiResponse =
  /** status 200 Success */ SearchStatisticsModel;
export type GetStatisticsArgs = void;
export type SearchDocumentKindModel =
  | "AddressBookEntry"
  | "CalendarEvent"
  | "Document"
  | "Episode"
  | "Task";
export type SearchDocumentModelBase = {
  $type: string;
  /** The id of the document. */
  id: string;
  /** The parent episode of the document */
  parentId?: string | null;
  kind: SearchDocumentKindModel;
  /** The title of the document. */
  title: string;
};
export type AddressBookEntryModel = {
  $type: "AddressBookEntryModel";
} & SearchDocumentModelBase;
export type CalendarEventModel = {
  $type: "CalendarEventModel";
} & SearchDocumentModelBase & {
    /** The id of the calendar to which the event belongs. */
    calendarId: string;
    /** The start date of the calendar event. */
    startDate: string;
  };
export type DocumentModel = {
  $type: "DocumentModel";
} & SearchDocumentModelBase;
export type EpisodeModel = {
  $type: "EpisodeModel";
} & SearchDocumentModelBase;
export type TaskModel = {
  $type: "TaskModel";
} & SearchDocumentModelBase;
export type FilterModel = {
  /** The value of the filter */
  value: string;
  /** The number of documents which match the filter */
  documentCount: number;
};
export type SearchResponseModel = {
  /** The search result documents */
  searchResults: (
    | AddressBookEntryModel
    | CalendarEventModel
    | DocumentModel
    | EpisodeModel
    | TaskModel
  )[];
  /** The filters which can be used to refine the search */
  filters: {
    [key: string]: FilterModel[];
  };
  /** The page number of the results */
  page: number;
  /** The number of results per page */
  pageSize: number;
  /** The total number of results */
  totalResults: number;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type SearchRequestModel = {
  /** The term to search on */
  searchTerm: string;
  /** The page number of the results */
  page?: number;
  /** The number of results per page */
  pageSize?: number;
  /** The object types to filter on */
  objectTypeFilters?: SearchDocumentKindModel[] | null;
  /** The episode to filter on */
  episodeIdFilter?: string | null;
};
export type SearchStatisticsModel = {
  /** The total number of documents available */
  documentCount?: number;
  /** The number of shards available */
  numberOfShards?: number;
  /** Per kind of document, the number of documents available */
  kinds?: {
    [key: string]: number;
  } | null;
};
export const { useTextSearchQuery, useGetStatisticsQuery } = injectedRtkApi;
