import { ComponentProps, FocusEventHandler, KeyboardEventHandler, MouseEventHandler, useCallback, useId, useMemo } from "react";
import { Unbinder, bindEventListener } from "src/common/dom";
import { LiteralUnionSet } from "../../utils";
import { PropsAction, useControlledReducer } from "../use-controlled-reducer";
import { useMappedIds } from "../use-mappped-ids";
import { useRefEffect } from "../use-ref-effect";

const KeyboardKeys = new LiteralUnionSet("ArrowDown", "ArrowUp", "Home", "End", "Escape", "Enter", " ");
type KeyboardKey = LiteralUnionSet.Literal<typeof KeyboardKeys>;

/**
 * An equality comparison function.
 * @template T The type to compare for equality.
 */
interface EqualityComparison<T> {
	/**
	 * Compares two values for equality.
	 * @param a The first value to compare.
	 * @param b The second value to compare.
	 * @returns `true` when `a` and `b` should be considered equal; otherwise, `false`.
	 */
	(a: T | undefined, b: T | undefined): boolean;
}

type UserKeyboardAction = {
	type: "Keyboard";
	key: KeyboardKey;
	alt?: boolean;
};

type UserMenuItemAction<TMenuItem> = {
	type: "FocusMenuItem" | "SelectMenuItem";
	menuItem: TMenuItem;
}

type UserMenuAction = {
	type: "FocusOut" | "ToggleList";
}

type CompositeAction<TMenuItem extends object> =
	| UserKeyboardAction
	| UserMenuAction
	| UserMenuItemAction<TMenuItem>
	| PropsAction<AriaMenuControlledProps<TMenuItem>>;

type AriaMenuState<TMenuItem extends object> = Readonly<{
	menuItems: readonly TMenuItem[];
	expanded: boolean;
	active?: TMenuItem;
}>;

export type AriaMenuProps<TMenuItem extends object> = Readonly<{
	menuItems: readonly TMenuItem[];

	/**
	 * An equality comparison function used to compare menu item
	 * elements. Replaces the use of `Object.is` when specified.
	 *
	 * This property binds on first render and is not changed with
	 * subsequent renders. The function used must either be pure or
	 * it must have dependencies which do not change.
	 */
	is?: EqualityComparison<TMenuItem>;

	/**
	 * A callback function that is executed when a menu item
	 * is selected.
	 * @param menuItem The selected menu item.
	 */
	onSelected?: (menuItem: TMenuItem | undefined) => boolean | null | undefined | void;
}>;

type AriaMenuControlledProps<TMenuItem extends object> =
	Omit<AriaMenuProps<TMenuItem>, "is" | "onSelected">;

type MenuButtonProps = Omit<Readonly<
	& Required<Pick<ComponentProps<"var">,
		| "role"
		| "onClick"
		| "onKeyDown"
		| "onBlur"
		| "aria-controls"
		| "aria-expanded"
	>>
	& Pick<ComponentProps<"var">, "aria-activedescendant">
>, never>;

type MenuProps = Omit<Readonly<
	& Required<Pick<ComponentProps<"var">,
		| "id"
		| "role"
		| "hidden"
	>>
	& {
		ref: <T extends HTMLElement>(element: T | null) => void
	}
>, never>;

type Booleanish = boolean | "true" | "false";
type MenuItemProps = Omit<Readonly<
	& Required<Pick<ComponentProps<"var">,
		| "id"
		| "role"
		| "onPointerDownCapture"
		| "onPointerEnter"
	>>
	& {
		"data-active"?: Booleanish
	}
>, never>;

type AriaMenuReturn<TMenuItem extends object> = Readonly<{
	state: Readonly<AriaMenuState<TMenuItem>>;
	menuButtonProps: MenuButtonProps;
	menuProps: MenuProps;
	getMenuItemProps: (menuItem: TMenuItem) => MenuItemProps;
	isActiveMenuItem: (menuItem: TMenuItem) => boolean;
}>;

export function useAriaMenu<TMenuItem extends object>({
	is = Object.is,
	onSelected,
	...props
}: AriaMenuProps<TMenuItem>): AriaMenuReturn<TMenuItem> {
	// The 'is' property can be set once at first render and
	// after this point is fixed. The function should be pure.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	is = useCallback(is, []);

	const propsReducer = useCallback((
		prevState: AriaMenuState<TMenuItem>,
		action: PropsAction<AriaMenuControlledProps<TMenuItem>>
	) => {
		let { menuItems, expanded, active } = prevState;

		if (action.changed.has("menuItems")) {
			menuItems = action.props.menuItems;
			expanded = !!menuItems.length && expanded;
			active = active && menuItems.find(option => is(active, option));
		}

		return {
			...prevState,
			expanded,
			menuItems,
			active
		};
	}, [is]);

	const keyboardReducer = useCallback((
		prevState: AriaMenuState<TMenuItem>,
		action: UserKeyboardAction
	): AriaMenuState<TMenuItem> => {
		const { menuItems, expanded, active } = prevState;

		// If we don't have any menuItems, keyboard interaction shouldn't be able
		// to accomplish anything.
		if (!menuItems.length) return prevState;

		switch (action.key) {
			case "ArrowDown": {
				if (!expanded)
					return { ...prevState, expanded: true };

				if (action.alt)
					return prevState;

				if (active == null)
					return { ...prevState, active: menuItems[0] };

				const index = menuItems.findIndex(item => is(item, active)) + 1;
				return (index < menuItems.length)
					? { ...prevState, active: menuItems[index] }
					: prevState;
			}

			case "ArrowUp": {
				if (!expanded)
					return { ...prevState, expanded: true, active: menuItems[0] }

				if (action.alt)
					return { ...prevState, expanded: false, active: undefined };

				if (active == null)
					return { ...prevState, active: menuItems[0] }

				const index = menuItems.findIndex(item => is(item, active)) - 1;
				return (index !== -1)
					? { ...prevState, active: menuItems[Math.max(0, index)] }
					: prevState;
			}

			case "Home":
				return { ...prevState, expanded: true, active: menuItems[0] };

			case "End":
				return { ...prevState, expanded: true, active: menuItems.at(-1) };

			case "Escape":
				return expanded
					? { ...prevState, expanded: false, active: undefined }
					: prevState;

			case "Enter":
			case " ":
				return expanded
					? { ...prevState, expanded: false, active: undefined }
					: { ...prevState, expanded: true };

			default:
				return prevState;
		}
	}, [is]);

	const focusOutReducer = useCallback((prevState: AriaMenuState<TMenuItem>): AriaMenuState<TMenuItem> => {
		const { expanded } = prevState;
		return expanded
			? { ...prevState, expanded: false, active: undefined }
			: prevState;
	}, []);

	const menuItemReducer = useCallback((
		prevState: AriaMenuState<TMenuItem>,
		action: UserMenuItemAction<TMenuItem>
	): AriaMenuState<TMenuItem> => {
		const option = prevState.menuItems.find(option => is(option, action.menuItem));

		switch (action.type) {
			case "FocusMenuItem":
				return { ...prevState, active: option };
			case "SelectMenuItem":
				return { ...prevState, expanded: false, active: undefined };
			default:
				return prevState;
		}
	}, [is]);

	const reducer = useCallback((
		prevState: AriaMenuState<TMenuItem>,
		action: CompositeAction<TMenuItem>
	): AriaMenuState<TMenuItem> => {
		switch (action.type) {
			case "FocusOut":
				return focusOutReducer(prevState);
			case "Keyboard":
				return keyboardReducer(prevState, action);
			case "ToggleList":
				return { ...prevState, expanded: !prevState.expanded, active: undefined };
			case "FocusMenuItem":
			case "SelectMenuItem":
				return menuItemReducer(prevState, action);
			case "PropsUpdate":
				return propsReducer(prevState, action);
			default:
				return prevState;
		}
	}, [focusOutReducer, keyboardReducer, menuItemReducer, propsReducer]);

	const [state, dispatch] = useControlledReducer({
		props,
		reducer,
		initializer: props => ({
			...props,
			expanded: false,
			active: undefined
		}),
	});

	const onKeyDown: KeyboardEventHandler<HTMLElement> = useCallback(event => {
		if (event.defaultPrevented) return;
		if (!KeyboardKeys.has(event.key)) return;

		event.preventDefault();

		const selected = (event.key === " " || event.key === "Enter")
			? state.active
			: null;

		if (selected) {
			try {
				const ok = onSelected?.(selected);
				if (ok === false) return;
			} catch {
				return
			}
		}

		dispatch({ type: "Keyboard", key: event.key, alt: event.altKey });

	}, [state.active, dispatch, onSelected]);

	const onBlur: FocusEventHandler<HTMLElement> = useCallback(event => {
		dispatch({ type: "FocusOut" });
	}, [dispatch]);

	const onButtonClick: MouseEventHandler<HTMLElement> = useCallback(() => {
		dispatch({ type: "ToggleList" });
	}, [dispatch]);

	const menuId = useId();
	const createId = useMappedIds();

	const createMenuItemId = useCallback((option: TMenuItem) => {
		// If we don't use `Object.is` object identity, then always
		// try to map back to the current state list for the authorative
		// option instance.
		if (is !== Object.is) {
			option = state.menuItems.find(x => is(x, option)) ?? option;
		}

		return createId(option);
	}, [is, createId, state.menuItems]);

	const menuButtonProps: MenuButtonProps = useMemo(() => {
		return {
			role: "button",
			"aria-haspopup": "menu",
			"aria-controls": menuId,
			"aria-expanded": state.expanded,
			"aria-activedescendant": state.active ? createMenuItemId(state.active) : undefined,
			onClick: onButtonClick,
			onBlur,
			onKeyDown
		};
	}, [menuId, state, createMenuItemId, onButtonClick, onBlur, onKeyDown]);

	const menuRef = useRefEffect(element => {
		const unbinders: Unbinder[] = [];

		unbinders.push(bindEventListener(element, "touchstart", event => {
			event.preventDefault();
			event.stopImmediatePropagation();
		}, { passive: false }));

		unbinders.push(bindEventListener(element, "pointerdown", event => {
			event.preventDefault();
			event.stopImmediatePropagation();
		}, { passive: false }));

		unbinders.push(bindEventListener(element, "mousedown", event => {
			event.preventDefault();
			event.stopImmediatePropagation();
		}, { passive: false }));

		return () => {
			for (const unbinder of unbinders) { unbinder() }
		}
	});

	const menuProps: MenuProps = useMemo(() => ({
		id: menuId,
		ref: menuRef,
		role: "menu",
		hidden: !state.expanded,
	}), [menuId, menuRef, state.expanded]);

	const getMenuItemProps = useCallback((menuItem: TMenuItem): MenuItemProps => {
		return {
			id: createMenuItemId(menuItem),
			role: "menuitem",
			onPointerEnter: () => dispatch({ type: "FocusMenuItem", menuItem }),
			onPointerDownCapture: () => {
				// Executes at least one event loop tick later, after the event has gone
				// through its bubbling phase, where it will be cancelled and stopped by
				// the native listeners that are registered with passive:false.
				setTimeout(() => {
					try {
						const ok = onSelected?.(menuItem);
						if (ok === false) return;
					} catch {
						return;
					}

					dispatch({ type: "SelectMenuItem", menuItem });
				}, 0);
			}
		};
	}, [dispatch, onSelected, createMenuItemId]);

	const isActiveMenuItem = useCallback((menuItem: TMenuItem) => {
		return !!(state.active && is(state.active, menuItem));
	}, [is, state]);

	return {
		state,
		menuButtonProps,
		menuProps,
		getMenuItemProps,
		isActiveMenuItem
	};
};
