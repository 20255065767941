import { FC, PropsWithChildren, useMemo } from "react";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin, AppInsightsContext, AppInsightsErrorBoundary, withAITracking } from "@microsoft/applicationinsights-react-js";
import { env } from "src/env";

type Props = PropsWithChildren<{}>;

export const TelemetryProvider: FC<Props> = ({ children }) => {
	const reactPlugin = useMemo(() => {

		const applicationName = env.REACT_APP_AI_CLOUD_ROLE_NAME;
		//Setup AI for web
		const reactPlugin = new ReactPlugin();
		const appInsights = new ApplicationInsights({
			config: {
				instrumentationKey: env.REACT_APP_AI_INSTRUMENTATION_KEY,
				disableFetchTracking: true,
				enableAutoRouteTracking: true,
				extensions: [reactPlugin]
			}
		});

		appInsights.loadAppInsights();
		appInsights.addTelemetryInitializer(telemetryItem => {
			telemetryItem.tags = telemetryItem.tags || [];
			telemetryItem.tags.push({ "ai.cloud.role": applicationName });
		})

		return reactPlugin;
	}, []);

	const AppWithAITracking = withAITracking(
		reactPlugin,
		() => <>{children}</>,
		"AppWithAITracking",
		"kko-contents"
	);

	return (
		<AppInsightsContext.Provider value={reactPlugin} >
			<AppInsightsErrorBoundary appInsights={reactPlugin} onError={() => <></>}>
				<AppWithAITracking />
			</AppInsightsErrorBoundary>
		</AppInsightsContext.Provider >
	);
}