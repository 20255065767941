import { forwardRef, useCallback, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTranslation } from "react-i18next";
import { env } from "src/env";
import { ChatThreadResponseModel, useGetChatThreadsQuery, useGetCommunicationUserIdentityQuery, useGetCommunicationAccessTokenQuery, useGetChatByIdQuery } from "src/apis/chat";
import { usePlatformAccount } from "src/modules/shared/hooks";
import { useLocalStorage, useLocale, useMediaBreakpoint, useMediaQuery } from "src/common/hooks";
import { useGetDisplayNameByAccountIdQuery, useGetV1AccountsByAccountIdProfilePhotoQuery } from "src/apis/network";
import { useAppSelector } from "src/common/redux";
import { Icon } from "src/modules/shared/components/Icon";
import { NavTabBar } from "../components/NavTabBar";
import { ChatThread } from "../components/ChatThread";
import { ChatContainer } from "../components/ChatContainer";

export const ChatsOverview = forwardRef<HTMLDivElement>((props, ref) => {

	const { t } = useTranslation();
	const locale = useLocale();
	const focusedThreadId = useAppSelector(state => state.chat.focusedThreadId);

	const [selectedTab, setSelectedTab] = useState<"one-on-one" | "episode">("one-on-one");
	const [pinnedChatIds] = useLocalStorage<string[]>("pinnedChatIds", [], "all");

	const { data: userIdentityResponse } = useGetCommunicationUserIdentityQuery();
	const { data: chatAccessTokenResponse } = useGetCommunicationAccessTokenQuery(userIdentityResponse?.communicationUserIdentity ? {
		userIdentity: userIdentityResponse.communicationUserIdentity
	} : skipToken);

	const { data: { items: chatThreads } = {} } = useGetChatThreadsQuery(chatAccessTokenResponse?.communicationAccessToken ? {
		"X-Chat-Access-Token": chatAccessTokenResponse.communicationAccessToken
	} : skipToken);

	const { data: { chatThread: focusedChatThread } = {} } = useGetChatByIdQuery(chatAccessTokenResponse?.communicationAccessToken && focusedThreadId ? {
		"X-Chat-Access-Token": chatAccessTokenResponse.communicationAccessToken,
		chatId: focusedThreadId
	} : skipToken);

	const { accountId } = usePlatformAccount();

	const otherParticipant = useCallback((chatThread?: ChatThreadResponseModel) => {
		return chatThread?.participants?.find(p => p.accountId !== accountId);
	}, [accountId]);

	const onChangeTab = (tab: "one-on-one" | "episode") => {
		setSelectedTab(tab);
	}

	/**
	 * Sorts a list of chat threads by lastMessageReceivedOn DESC
	 */
	const sortChats = useCallback((chatThreads: ChatThreadResponseModel[]) => {
		return chatThreads.sort((a, b) => {
			if (!a.lastMessageReceivedOn) {
				return -1;
			}
			if (!b.lastMessageReceivedOn) {
				return -1;
			}
			var c = Date.parse(a.lastMessageReceivedOn);
			var d = Date.parse(b.lastMessageReceivedOn);
			return d - c;
		});
	}, []);

	const pinnedChatThreadsInTab = useMemo(() => {
		const threads = chatThreads?.filter(c => !!c.episodeId === (selectedTab === "episode"));
		if (!threads) {
			return [];
		}
		return sortChats(threads.filter(t => pinnedChatIds.includes(t.externalChatThreadId)));
	}, [selectedTab, chatThreads, pinnedChatIds, sortChats]);

	const unpinnedChatThreadsInTab = useMemo(() => {
		const threads = chatThreads?.filter(c => !!c.episodeId === (selectedTab === "episode"));
		if (!threads) {
			return [];
		}
		return sortChats(threads.filter(t => !pinnedChatIds.includes(t.externalChatThreadId)));
	}, [selectedTab, chatThreads, pinnedChatIds, sortChats]);

	const { data: otherParticipantImageUrl } = useGetV1AccountsByAccountIdProfilePhotoQuery(otherParticipant(focusedChatThread)?.accountId ?
		{
			accountId: otherParticipant(focusedChatThread)?.accountId!,
			"Accept-Language": locale
		} : skipToken);

	const { data: displayNameResponse } = useGetDisplayNameByAccountIdQuery(otherParticipant(focusedChatThread)?.accountId ?
		{
			accountId: otherParticipant(focusedChatThread)?.accountId!,
			"Accept-Language": locale
		} : skipToken);

	const breakpoint = useMediaBreakpoint("tablet");
	const isDesktop = useMediaQuery(`(min-width :  ${breakpoint})`);

	const side = useMemo(() => (
		(isDesktop || !focusedThreadId) && <div className="kko-chat-container__sidebar">
			<div className="kko-chat-container__sidebar__nav">
				<NavTabBar
					className="mb-16"
					selected={selectedTab}
					onChangeTab={onChangeTab}
				/>
			</div>
			<div className="kko-chat-container__sidebar__list">
				{![...pinnedChatThreadsInTab, ...unpinnedChatThreadsInTab]?.length &&
					<div className="py-16 px-24">{t("kko:pages.chats.no-chats-available")}</div>
				}
				{!!pinnedChatThreadsInTab?.length && <div className="kko-chat-container__sidebar__section">
					<div>
						<Icon icon="pin" />
						<span>{t("kko:pages.chats.chats-list-pinned-subtitle")}</span>
					</div>
					<ul>
						{pinnedChatThreadsInTab?.map(chatThread =>
							<li><ChatThread chatThread={chatThread} /></li>
						)}
					</ul>
				</div>
				}
				{!!unpinnedChatThreadsInTab?.length && <div className="kko-chat-container__sidebar__section">
					<div>
						<Icon icon="chat" />
						<span>{selectedTab === "one-on-one" ? t("kko:pages.chats.chats-list-unpinned-subtitle") : t("kko:pages.chats.channels-list-unpinned-subtitle")}</span>
					</div>
					<ul>
						{unpinnedChatThreadsInTab?.map(chatThread =>
							<li><ChatThread chatThread={chatThread} /></li>
						)}
					</ul>
				</div>
				}
			</div>
		</div>
	), [focusedThreadId, isDesktop, pinnedChatThreadsInTab, selectedTab, t, unpinnedChatThreadsInTab]);

	const main = useMemo(() => (
		<div className="kko-chat-container__main">
			{(userIdentityResponse?.communicationUserIdentity && chatAccessTokenResponse?.communicationAccessToken && focusedChatThread && focusedThreadId) ?
				<ChatContainer
					userIdentifier={userIdentityResponse?.communicationUserIdentity}
					token={chatAccessTokenResponse?.communicationAccessToken}
					displayName={displayNameResponse?.displayName || ""}
					endpointUrl={env.REACT_APP_COMMUNICATION_SERVICE_ENDPOINT}
					threadId={focusedChatThread?.externalChatThreadId}
					topic={false}
					imageUrl={otherParticipantImageUrl + ""}
				/> : <div className="kko-address-book__motivator">
					<span>{t("kko:pages.chats.select-chat.motivator")}</span>
				</div>
			}
		</div>
	), [chatAccessTokenResponse?.communicationAccessToken, displayNameResponse?.displayName, focusedChatThread, focusedThreadId, otherParticipantImageUrl, t, userIdentityResponse?.communicationUserIdentity]);

	return (
		<div
			ref={ref}
			className="kko-chat-container"
			data-expanded={focusedThreadId !== undefined ? "true" : undefined}
		>
			{side}
			{main}
		</div >
	)
});