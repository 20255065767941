import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    isActive: build.query<IsActiveApiResponse, IsActiveArgs>({
      query: (queryArg) => ({
        url: `/v1/Invitations/IsActive`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { identifier: queryArg.identifier },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type IsActiveApiResponse =
  /** status 200 Success */ InvitationStatusResponseModel;
export type IsActiveArgs = {
  /** The identifier of the invitation that is being checked. */
  identifier?: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type InvitationStatusResponseModel = {
  /** The flag that specifies if the invitation is active. */
  isActive: boolean;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export const { useIsActiveQuery } = injectedRtkApi;
