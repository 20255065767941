import { useTranslation } from "react-i18next";
import { useAppDispatch } from "src/common/redux";
import { ToastType, addToast } from "src/redux/slices/feedbackSlice";

type CopyFn = (
	text: string,
	successfulToastTitle?: string,
	successfulToastMessage?: string,
	errorToastTitle?: string,
	errorToastMessage?: string
) => Promise<boolean> // Return success

/**
 * Hook that handles the values capied in clipboard and shows success/error toasts.
 * @returns the value copied in clipboard and the method to copy to clipboard.
 */
export function useCopyToClipboard(): [CopyFn] {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();

	const copy: CopyFn = async (text, successfulToastTitle?, successfulToastMessage?, errorToastTitle?, errorToastMessage?) => {
		if (!navigator?.clipboard) {
			if (errorToastMessage) {
				dispatch(addToast({
					titleKey: t(errorToastTitle!),
					contentKey: t(errorToastMessage),
					type: ToastType.Error
				}));
			}
			return false;
		}

		// Try to save to clipboard then save it in the state if worked
		try {
			await navigator.clipboard.writeText(text);
			if (successfulToastMessage) {
				dispatch(addToast({
					titleKey: t(successfulToastTitle!),
					contentKey: t(successfulToastMessage),
					type: ToastType.Success,
					icon: "check"
				}));
			}
			return true;
		} catch (error) {
			if (errorToastMessage) {
				dispatch(addToast({
					titleKey: t(errorToastTitle!),
					contentKey: t(errorToastMessage),
					type: ToastType.Error
				}));
			}
			return false;
		}
	}

	return [copy];
}