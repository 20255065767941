export type DataURL<
	Type extends string = string,
	SubType extends string = string
> = `data:${Type}/${SubType};${string}`;

export const blobToDataURL = (blob: Blob, signal?: AbortSignal): Promise<DataURL> => {
	return new Promise<DataURL>((resolve, reject) => {
		const reader = new FileReader();

		reader.onloadend = () => resolve(reader.result as DataURL);
		reader.onabort = () => reject(signal?.reason ?? reader.error);
		reader.onerror = () => reject(reader.error);
		reader.readAsDataURL(blob);

		signal?.addEventListener("abort", () => reader.abort());
	});
}

export const dataURLToBlob = async (dataURL: DataURL, signal?: AbortSignal): Promise<Blob> => {
	const response = await fetch(dataURL, { signal });
	return await response.blob();
};