import classNames from "classnames";
import { PropsWithChildren } from "react";
import { createSlot, useSlots, withSlots } from "src/common/hooks";
import { getInitials } from "../../utils/getInitials";
import Image from "../Image";

export type AvatarSize = "normal" | "large" | "xlarge" | "flexible";

type AvatarProps = PropsWithChildren<{
	className?: classNames.Argument;
	image?: string;
	name: string;
	size?: AvatarSize;
}>

const Badge = createSlot();

export const Avatar = withSlots(({
	children,
	className,
	image,
	name,
	size = "normal"
}: AvatarProps) => {
	const { badge } = useSlots(children, { badge: Badge });

	const classArg: classNames.Argument = {
		[`kko-avatar--${size}`]: size !== "normal"
	};

	return (
		<div
			className={classNames("kko-avatar", classArg, className)}
			role="presentation"
			aria-hidden="true"
		>
			{image
				? <Image src={image} />
				: <span>{getInitials(name)}</span>
			}
			{badge}
		</div>
	);
}, { Badge });
