import { useState } from "react";
import { bindEventListener } from "src/common/dom";
import { useRefEffect } from "src/common/hooks";

/**
 * A hook that offers a boolean toggle that incorporates the light dismissal
 * UI interaction pattern.
 * 
 * Note that this _only_ incorporates the pointer-based dismissal by clicking or
 * tapping outside the element. It does not implement keyboard dismissal by hitting
 * the Escape key, as that is typically associated with other concerns as well -
 * such as restoring keyboard focus to the opener element, etc.
 * @see https://html.spec.whatwg.org/multipage/popover.html#popover-light-dismiss
 * @param initialState Initial state of the toggle.
 * @returns A stateful toggle value, a function to update it, and a `RefCallback`
 * to which to assign the element that should define the scope of the light dismissal
 * pattern.
 * 
 * @deprecated
 * Use the `useAriaMenu` or `useAriaCombobox` hook to build foldout menus instead.
 */
export function useLightDismissToggle(initialState: boolean | (() => boolean) = false) {
	const [toggle, setToggle] = useState(initialState);

	const setRef = useRefEffect<HTMLElement>(element => {
		if (!toggle) return;

		return bindEventListener(element.ownerDocument, "pointerdown", event => {
			if (!element.contains(event.target as Node)) {
				setToggle(false);
			}
		}, { capture: true, passive: true });


	}, [toggle]);

	return [toggle, setToggle, setRef] as const;
}
