import { FC, Children, ReactNode, isValidElement, PropsWithChildren } from "react";


type SlotProps<P = unknown> = keyof P extends never
	? { children?: ReactNode }
	: { children?: ReactNode | ((props: P) => ReactNode) };

/**
 * A slot component usable with the `useSlots` hook.
 */
export type Slot<P = unknown> = FC<SlotProps<P>>

/**
 * Defines a unique `Slot` component.
 * Slots can be given their own props to create parametrized slots.
 * 
 * This function defines an actual component type and should be called _outside_
 * of the component that needs a slot.
 * 
 * @returns The slot component.
 */
export function createSlot<P = unknown>(): Slot<P> {
	const Slot: Slot<P> = () => null;
	return Slot;
}

/**
 * Assigns unique slot components to a `FunctionComponent` as properties,
 * following the common practice of compound components.
 * @param Component The component to assign slot components.
 * @param slots A map of names to slot components.
 * @returns The component with its typing augmented.
 * 
 * @example
 * ```tsx
 * const Header = createSlot();
 * const Footer = createSlot();
 * 
 * const Card = withSlots(({ children }) => {
 *   // ...
 * }, { Header, Footer });
 * 
 * // ...
 * 
 * <Card>
 *   <Card.Header>header text</Card.Header>
 *   <Card.Footer>footer text</Card.Footer>
 *   content text
 * </Card>
 * ```
 */
export function withSlots<
	P extends PropsWithChildren,
	Slots extends { [key: string]: Slot<any> | Slot<unknown> }
>(
	Component: FC<P>,
	slots: Slots
): FC<P> & { [key in keyof Slots]: Slots[key] } {
	// Need to use as any here. We know the return type will be correct.
	return Object.assign(Component, slots) as any;
}

/**
 * Pulls slot components out of the component's children and makes their child content
 * available.
 * 
 * @param children The `children` of the component.
 * @param slots A map of names to slot components.
 * @returns A map of names to the children of each slot.
 */
export function useSlots<Slots extends { [key: string]: Slot<any> | Slot<unknown> }>(
	children: ReactNode,
	slots: Slots
): { [key in keyof Slots]?: Slots[key] extends Slot<infer SP> ? SlotProps<SP>["children"] : never } {
	const map = new Map<Slot<any>, string[]>();

	for (const [name, type] of Object.entries(slots)) {
		const names = getOrAdd(map, type, () => []);
		names.push(name);
	}

	const content = Children
		.toArray(children)
		.reduce((obj, child) => {
			if (!isValidElement(child)) return obj;

			const names = map.get(child.type as any);
			if (!names) return obj;

			const props = child.props as SlotProps;

			for (const name of names) {
				obj[name] = props.children;
			}

			return obj;
		}, {} as any);

	return content;
};

function getOrAdd<TKey, TValue>(map: Map<TKey, TValue>, key: TKey, fn: () => TValue): TValue {
	return map.get(key) ?? (() => {
		const value = fn();
		map.set(key, value);
		return value;
	})();
}