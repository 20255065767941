import { Key, RefCallback, useCallback, useMemo, useRef, useState } from "react";
import { KeyRef, useKeyRefsEffect } from "./use-key-refs";
import { useEvent } from "./use-event";
import { useTeardown } from "./use-teardown";


// NOTE:
// We create a special version of the `IntersectionObserverCallback` type here that omits
// the second parameter - the ResizeObserver itself.
// This is intentional, because giving access to the observer itself would allow
// messing with lifetimes that should remain under control of the hook.

interface UseIntersectionObserverCallback {
	/**
	 * A function called whenever an intersection crosses one of the observed thresholds.
	 * @param entries
	 * A map of keys identifying elements to {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserverEntry | IntersectionObserverEntry}
	 * objects that can be used to access information on the intersection of those element with the intersecting root.
	 */
	(entries: ReadonlyMap<Key, IntersectionObserverEntry>): void;
}

/**
 * Configures the `useIntersectionObserver` hook.
 */
type UseIntersectionObserverOptions = {
	/**
	 * Whether to use an intersecting root other than the document viewport as the
	 * {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/root| root}.  
	 *
	 * Defaults to `false` when not specified.  
	 * When set to `true` the second return value of the `useIntersectionObserver`
	 * hook can be used to assign the root element.
	 */
	useRoot?: boolean;

	/**
	 * The {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/rootMargin | rootMargin}
	 * of the observer.
	 */
	rootMargin?: string;

	/**
	 * The {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/thresholds | thresholds}
	 * of the observer.
	 */
	thresholds?: number | number[];
}

/**
 * Call `useIntersectionObserver` at the top level of your component to create
 * an {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver | IntersectionObserver}
 * that can be used to observe chosen elements intersecting with a common intersecting
 * root element.
 * @param callback
 * The function called whenever an intersection crosses one of the observed thresholds.
 * @param options
 * Options that configure the `IntersectionObserver`.
 * @returns
 * `useIntersectionObserver` returns an array tuple with exactly two values:
 * 1. A {@linkcode KeyRef<Element>} function that can be used to create keyed
 *    callback-style refs to assign elements that should be observed for intersection.
 * 2. A {@linkcode RefCallback<Element>} ref callback function that can be used to
 *    assign the `root` element serving as intersecting root.
 */
export const useIntersectionObserver = (
	callback: UseIntersectionObserverCallback,
	{ useRoot, rootMargin, thresholds: threshold }: UseIntersectionObserverOptions = {}
): [KeyRef<Element>, RefCallback<Element>] => {
	// Automatically always call the latest version.
	callback = useEvent(callback);

	// The IntersectionObserver API is not beholden to React's component lifecycle and
	// may execute its callback when the component has already unmounted. To
	// prevent such problems we explicitly track when the component has unmounted.
	const unmounted = useRef(false);

	const inverseRef = useRef(new Map<Element, Key>());
	const [root, setRoot] = useState<Element | null>(null);

	const observer = useMemo(() => {
		const { current: inverse } = inverseRef;
		if (useRoot && !root) return undefined;

		return new IntersectionObserver(entries => {
			if (unmounted.current) return;

			const mappedEntries = new Map<Key, IntersectionObserverEntry>();
			for (const entry of entries) {
				const key = inverse.get(entry.target);
				if (!key) continue;

				mappedEntries.set(key, entry);
			}

			callback(mappedEntries);
		}, {
			root: root ?? undefined,
			rootMargin,
			threshold
		});
	}, [callback, root, rootMargin, threshold, useRoot]);

	useTeardown(() => { unmounted.current = true });

	const rootRef = useCallback<RefCallback<Element>>(element => {
		setRoot(element);
	}, []);

	const observeKeyRef = useKeyRefsEffect((element, key) => {
		if (!observer) return;

		const { current: inverse } = inverseRef;

		observer.observe(element);
		inverse.set(element, key);

		return () => {
			observer.unobserve(element);
			inverse.delete(element);
		}
	}, [observer]);

	return [observeKeyRef, rootRef];
}