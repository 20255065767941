import { useCallback, useEffect, useMemo, useRef } from "react";
import { Location, useLocation } from "react-router-dom";

export const useLocationChange = () => {
	const location = useLocation();
	const isFirst = useRef(true);
	const listeners = useMemo(() => new Set<(location: Location<any>) => void>(), []);

	const listen = useCallback((listener: (location: Location<any>) => void) => {
		listeners.add(listener);
		return () => {
			listeners.delete(listener);
		}
	}, [listeners]);

	useEffect(() => {
		if (isFirst.current) {
			isFirst.current = false;
			return;
		}

		for (const listener of listeners) {
			listener(location);
		}
	}, [listeners, location]);

	return { listen } as const;
}