import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllTasks: build.query<GetAllTasksApiResponse, GetAllTasksArgs>({
      query: (queryArg) => ({
        url: `/v1/tasks`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: {
          Status: queryArg.status,
          Priority: queryArg.priority,
          DueDateTimeFrom: queryArg.dueDateTimeFrom,
          DueDateTimeTo: queryArg.dueDateTimeTo,
          OnlyDirectlyInvolved: queryArg.onlyDirectlyInvolved,
          Sorting: queryArg.sorting,
          EpisodeIds: queryArg.episodeIds,
        },
      }),
    }),
    createTask: build.mutation<CreateTaskApiResponse, CreateTaskArgs>({
      query: (queryArg) => ({
        url: `/v1/tasks`,
        method: "POST",
        body: queryArg.createTaskRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getTaskDetailsById: build.query<
      GetTaskDetailsByIdApiResponse,
      GetTaskDetailsByIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/tasks/${queryArg.taskId}/details`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    updateTaskByOwner: build.mutation<
      UpdateTaskByOwnerApiResponse,
      UpdateTaskByOwnerArgs
    >({
      query: (queryArg) => ({
        url: `/v1/tasks/${queryArg.taskId}`,
        method: "PATCH",
        body: queryArg.updateTaskByOwnerRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    deleteTask: build.mutation<DeleteTaskApiResponse, DeleteTaskArgs>({
      query: (queryArg) => ({
        url: `/v1/tasks/${queryArg.taskId}`,
        method: "DELETE",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    updateTaskByAssignee: build.mutation<
      UpdateTaskByAssigneeApiResponse,
      UpdateTaskByAssigneeArgs
    >({
      query: (queryArg) => ({
        url: `/v1/tasks/${queryArg.taskId}/limited`,
        method: "PATCH",
        body: queryArg.updateTaskByAssigneeRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    markCompletionStatus: build.mutation<
      MarkCompletionStatusApiResponse,
      MarkCompletionStatusArgs
    >({
      query: (queryArg) => ({
        url: `/v1/tasks/${queryArg.taskId}/completion-status`,
        method: "PATCH",
        body: queryArg.markCompletionStatusRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetAllTasksApiResponse =
  /** status 200 Success */ GetAllTasksResponseModel;
export type GetAllTasksArgs = {
  /** Gets or sets the status of the tasks to filter by. */
  status?: TaskStatusRequestModel[];
  /** Gets or sets the priority of the tasks to filter by. */
  priority?: TaskPriorityRequestModel[];
  /** Gets or sets the start due date and time to filter tasks. */
  dueDateTimeFrom?: string;
  /** Gets or sets the end due date and time to filter tasks. */
  dueDateTimeTo?: string;
  /** Gets or sets a value indicating whether only tasks in which the user is directly involved should be retrieved. */
  onlyDirectlyInvolved?: boolean;
  /** Gets or sets the sorting criteria for the tasks. */
  sorting?: TaskSortingRequestModel;
  /** Gets or sets the IDs of the episodes to filter tasks by. */
  episodeIds?: string[];
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type CreateTaskApiResponse =
  /** status 201 Created */ CreateTaskResponseModel;
export type CreateTaskArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The request model containing task details. */
  createTaskRequestModel: CreateTaskRequestModel;
};
export type GetTaskDetailsByIdApiResponse =
  /** status 200 Success */ GetTaskDetailsByIdResponseModel;
export type GetTaskDetailsByIdArgs = {
  /** The unique ID of the task to retrieve. */
  taskId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type UpdateTaskByOwnerApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type UpdateTaskByOwnerArgs = {
  /** The unique ID of the task to update. */
  taskId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The request model containing the updated task details. */
  updateTaskByOwnerRequestModel: UpdateTaskByOwnerRequestModel;
};
export type DeleteTaskApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type DeleteTaskArgs = {
  /** The unique identifier of the task to delete. */
  taskId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type UpdateTaskByAssigneeApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type UpdateTaskByAssigneeArgs = {
  /** The unique ID of the task to update. */
  taskId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The request model containing task assignees. */
  updateTaskByAssigneeRequestModel: UpdateTaskByAssigneeRequestModel;
};
export type MarkCompletionStatusApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type MarkCompletionStatusArgs = {
  /** The unique identifier of the task. */
  taskId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The request model containing the completion status. */
  markCompletionStatusRequestModel: MarkCompletionStatusRequestModel;
};
export type TaskPriorityResponseModel = "Low" | "Medium" | "High";
export type LinkedObjectResponseModelBase = {
  $type: string;
};
export type ProfileDisplayInformationResponseModel = {
  /** Gets the unique identifier of the user's account. */
  accountId: string;
  /** Gets the display name associated with the user's profile. */
  displayName: string;
  /** Indicates whether the user has a profile photo. */
  hasPhoto: boolean;
};
export type DocumentDisplayInformationResponseModel = {
  /** Gets the unique identifier of the document. */
  id: string;
  /** Gets the name of the document. */
  name: string;
  /** Gets the size of the document in decimal format. */
  size: number;
  /** Gets the document type. */
  documentType: string;
};
export type LinkedEpisodeResponseModel = {
  $type: "LinkedEpisodeResponseModel";
} & LinkedObjectResponseModelBase & {
    /** Gets the unique identifier of the linked episode. */
    id: string;
    /** Gets the title of the linked episode. */
    title: string;
    subject: ProfileDisplayInformationResponseModel;
    document?: DocumentDisplayInformationResponseModel;
  };
export type TaskStatusResponseModel = "Active" | "Completed" | "Deleted";
export type TaskResponseModel = {
  /** Gets the unique identifier of the task. */
  id: string;
  /** Gets the title of the task. */
  title: string;
  priority: TaskPriorityResponseModel;
  /** Gets the due date and time of the task. */
  dueDateTime?: string | null;
  /** Gets the linked object associated with the task. */
  linkedObject?: LinkedEpisodeResponseModel | null;
  /** Gets a value indicating whether the task is private. */
  isPrivate: boolean;
  /** Gets a value indicating whether the task is overdue. */
  isOverdue: boolean;
  status: TaskStatusResponseModel;
};
export type GetAllTasksResponseModel = {
  /** Gets or sets the collection of task items. */
  items: TaskResponseModel[];
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type TaskStatusRequestModel = "Active" | "Completed" | "Deleted";
export type TaskPriorityRequestModel = "Low" | "Medium" | "High";
export type TaskSortingRequestModel =
  | "DueDateTimeOldestToNewest"
  | "DueDateTimeNewestToOldest"
  | "PriorityHighToLow"
  | "PriorityLowToHigh"
  | "ClientLastNameAtoZ"
  | "ClientLastNameZtoA";
export type CreateTaskResponseModel = {
  /** The id of the created task. */
  id: string;
};
export type TaskReminderNoticeRequestModel =
  | "FifteenMinutesBefore"
  | "SixtyMinutesBefore"
  | "OneDayBefore"
  | "TwoDaysBefore"
  | "FiveDaysBefore"
  | "OneWeekBefore"
  | "TwoWeeksBefore"
  | "FourWeeksBefore";
export type LinkedObjectRequestModelBase = {
  $type: string;
};
export type LinkedEpisodeRequestModel = {
  $type: "LinkedEpisodeRequestModel";
} & LinkedObjectRequestModelBase & {
    /** The id of the episode to link. */
    id: string;
    /** The relevant episode document id. */
    documentId?: string | null;
  };
export type CreateTaskRequestModel = {
  /** Gets or initializes the title of the task. The title is required and must be between 1 and 50 characters long. */
  title: string;
  /** Gets or initializes the due date and time of the task. It is optional and can be `null`. */
  dueDateTime?: string | null;
  priority: TaskPriorityRequestModel;
  reminderNotice?: TaskReminderNoticeRequestModel;
  /** Gets or initializes the description of the task, optional, up to 500 characters. */
  description?: string | null;
  /** Gets or initializes details about the linked object, optional. */
  linkedObject?: LinkedEpisodeRequestModel | null;
  /** Gets or initializes the assignees of the task. For public tasks, at least one and no more than 5 assignees are allowed. For private tasks, assignees are not allowed. */
  assignees: string[];
  /** Indicates whether the task is private, required. Affects validation of other properties. */
  isPrivate: boolean;
};
export type TaskReminderNoticeResponseModel =
  | "FifteenMinutesBefore"
  | "SixtyMinutesBefore"
  | "OneDayBefore"
  | "TwoDaysBefore"
  | "FiveDaysBefore"
  | "OneWeekBefore"
  | "TwoWeeksBefore"
  | "FourWeeksBefore";
export type GetTaskDetailsByIdResponseModel = {
  /** Gets the unique identifier of the task. */
  id: string;
  /** Gets the title of the task. */
  title: string;
  priority: TaskPriorityResponseModel;
  /** Gets the due date and time of the task, if applicable. */
  dueDateTime?: string | null;
  /** Gets the description of the task, if available. */
  description?: string | null;
  /** Gets information about a linked object, if associated with the task. */
  linkedObject?: LinkedEpisodeResponseModel | null;
  /** Gets a value indicating whether the task is private. */
  isPrivate: boolean;
  /** Gets a value indicating whether the task is overdue. */
  isOverdue: boolean;
  status: TaskStatusResponseModel;
  creator: ProfileDisplayInformationResponseModel;
  /** Gets a collection of display information about assignees of the task. */
  assignees: ProfileDisplayInformationResponseModel[];
  /** Gets the date and time when the reminder for the task triggers. */
  reminderTriggersAt?: string | null;
  reminderNotice?: TaskReminderNoticeResponseModel;
  /** Gets the date and time when the task was created. */
  createdAt: string;
};
export type UpdateTaskByOwnerRequestModel = {
  /** Gets or initializes the title of the task. The title is required and must be between 1 and 50 characters long. */
  title: string;
  /** Gets or initializes the due date and time of the task. It is optional and can be `null`. */
  dueDateTime?: string | null;
  priority: TaskPriorityRequestModel;
  reminderNotice?: TaskReminderNoticeRequestModel;
  /** Gets or initializes the description of the task, optional, up to 500 characters. */
  description?: string | null;
  /** Gets or initializes details about the linked object, optional. */
  linkedObject?: LinkedEpisodeRequestModel | null;
  /** Gets or initializes the assignees of the task. For public tasks, at least one and no more than 5 assignees are allowed. For private tasks, assignees are not allowed. */
  assignees: string[];
  /** Indicates whether the task is private, required. Affects validation of other properties. */
  isPrivate: boolean;
  /** The completion status of the task. */
  isCompleted?: boolean;
};
export type UpdateTaskByAssigneeRequestModel = {
  /** The completion status of the task. */
  isCompleted?: boolean;
  /** Gets or initializes the assignees of the task. For public tasks, at least one and no more than 5 assignees are allowed. For private tasks, assignees are not allowed. */
  assignees: string[];
};
export type CompletionStatusRequestModel = "Completed" | "Incomplete";
export type MarkCompletionStatusRequestModel = {
  status: CompletionStatusRequestModel;
};
export const {
  useGetAllTasksQuery,
  useCreateTaskMutation,
  useGetTaskDetailsByIdQuery,
  useUpdateTaskByOwnerMutation,
  useDeleteTaskMutation,
  useUpdateTaskByAssigneeMutation,
  useMarkCompletionStatusMutation,
} = injectedRtkApi;
