import { CalendarGranularity } from "./types";

type Expand = "past" | "future" | "both";

/**
 * Converts a date and calendar granularity to a range defined by
 * a start and end date for fetching events.
 * @param date The date
 * @param granularity The granularity
 * @param expand The directions into which to expand the range.
 * Can be one of `"past"`, `"future"`, or `"both"` and defaults
 * to `"both"` when not specified.
 * @returns 
 * An object with `startDate` and `endDate` properties
 * that represent the range.
 */
export const toDateRangeForEvents = (
	date: Date,
	granularity: CalendarGranularity,
	expand: Expand = "both"
) => {
	switch (granularity) {
		case "day": {
			// Days should use a -1/+2 days to account for date-crossing
			// events and the fact that the end of the range is exclusive.
			const startDate = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate() - 1
			);

			const endDate = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate() + 2
			);

			return {
				startDate: expand !== "future" ? startDate : date,
				endDate: expand !== "past" ? endDate : date
			};
		}

		case "week":
		case "work-week": {
			const shift = (date.getDay() + 6) % 7;

			// Back to the start of the week, and then back one date
			// further to account for date-crossing events.
			const startDate = expand !== "future"
				? new Date(
					date.getFullYear(),
					date.getMonth(),
					date.getDate() - shift - 1
				)
				: new Date(
					date.getFullYear(),
					date.getMonth(),
					date.getDate()
				);

			// Advance by one week to the monday ahead because of
			// exclusive end of range, and then one day further to
			// to account for date-crossing events.
			const endDate = new Date(
				date.getFullYear(),
				date.getMonth(),
				date.getDate() - shift + 8
			);

			return {
				startDate: expand !== "future" ? startDate : date,
				endDate: expand !== "past" ? endDate : date
			};
		}

		case "month": {
			// Back to the first of the month, and then back one more
			// day to account for date-crossing events.
			const startDate = new Date(
				date.getFullYear(),
				date.getMonth(),
				0
			);

			// First day of the next month because of exclusive end of range,
			// and then one day more to account for date-crossing events.
			const endDate = new Date(
				date.getFullYear(),
				date.getMonth() + 1,
				2
			);

			return {
				startDate: expand !== "future" ? startDate : date,
				endDate: expand !== "past" ? endDate : date
			};
		}
	}
}