import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createAddressBookEntry: build.mutation<
      CreateAddressBookEntryApiResponse,
      CreateAddressBookEntryArgs
    >({
      query: (queryArg) => ({
        url: `/v1/address-book-entries`,
        method: "POST",
        body: queryArg.createAddressBookEntryRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    updateAddressBookEntry: build.mutation<
      UpdateAddressBookEntryApiResponse,
      UpdateAddressBookEntryArgs
    >({
      query: (queryArg) => ({
        url: `/v1/address-book-entries`,
        method: "PUT",
        body: queryArg.updateAddressBookEntryRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    streamAllAddressBookEntries: build.query<
      StreamAllAddressBookEntriesApiResponse,
      StreamAllAddressBookEntriesArgs
    >({
      query: (queryArg) => ({
        url: `/v1/address-book-entries`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { linkedAccountsOnly: queryArg.linkedAccountsOnly },
      }),
    }),
    deleteAddressBookEntry: build.mutation<
      DeleteAddressBookEntryApiResponse,
      DeleteAddressBookEntryArgs
    >({
      query: (queryArg) => ({
        url: `/v1/address-book-entries/${queryArg.addressBookEntryId}`,
        method: "DELETE",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getAddressBookEntryById: build.query<
      GetAddressBookEntryByIdApiResponse,
      GetAddressBookEntryByIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/address-book-entries/${queryArg.addressBookEntryId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    createNetworkInvitationForKnownUser: build.mutation<
      CreateNetworkInvitationForKnownUserApiResponse,
      CreateNetworkInvitationForKnownUserArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/known-user`,
        method: "POST",
        body: queryArg.createNetworkInvitationForKnownUserRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    createNetworkInvitationForUnknownUser: build.mutation<
      CreateNetworkInvitationForUnknownUserApiResponse,
      CreateNetworkInvitationForUnknownUserArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/unknown-user`,
        method: "POST",
        body: queryArg.createNetworkInvitationForUnknownUserRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    acceptNetworkInvitation: build.mutation<
      AcceptNetworkInvitationApiResponse,
      AcceptNetworkInvitationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.networkInvitationId}/accept`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    declineNetworkInvitation: build.mutation<
      DeclineNetworkInvitationApiResponse,
      DeclineNetworkInvitationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.networkInvitationId}/decline`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getReceivedNetworkInvitations: build.query<
      GetReceivedNetworkInvitationsApiResponse,
      GetReceivedNetworkInvitationsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/received`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getSentNetworkInvitations: build.query<
      GetSentNetworkInvitationsApiResponse,
      GetSentNetworkInvitationsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/sent`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    revokeNetworkInvitation: build.mutation<
      RevokeNetworkInvitationApiResponse,
      RevokeNetworkInvitationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.networkInvitationId}/revoke`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getNetworkInvitationById: build.query<
      GetNetworkInvitationByIdApiResponse,
      GetNetworkInvitationByIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.networkInvitationId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getV1AccountsByAccountIdProfilePhoto: build.query<
      GetV1AccountsByAccountIdProfilePhotoApiResponse,
      GetV1AccountsByAccountIdProfilePhotoArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.accountId}/profile/photo`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { imageSize: queryArg.imageSize },
      }),
    }),
    putV1AccountsByAccountIdProfilePhoto: build.mutation<
      PutV1AccountsByAccountIdProfilePhotoApiResponse,
      PutV1AccountsByAccountIdProfilePhotoArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.accountId}/profile/photo`,
        method: "PUT",
        body: queryArg.body,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    deleteV1AccountsByAccountIdProfilePhoto: build.mutation<
      DeleteV1AccountsByAccountIdProfilePhotoApiResponse,
      DeleteV1AccountsByAccountIdProfilePhotoArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.accountId}/profile/photo`,
        method: "DELETE",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getProfileByCurrentUserAccountId: build.query<
      GetProfileByCurrentUserAccountIdApiResponse,
      GetProfileByCurrentUserAccountIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/current/profile`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { maxVisibility: queryArg.maxVisibility },
      }),
    }),
    getProfileByAccountId: build.query<
      GetProfileByAccountIdApiResponse,
      GetProfileByAccountIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.accountId}/profile`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { maxVisibility: queryArg.maxVisibility },
      }),
    }),
    updateProfile: build.mutation<UpdateProfileApiResponse, UpdateProfileArgs>({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.accountId}/profile`,
        method: "PUT",
        body: queryArg.updateProfileRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getMinimalProfileByAccountId: build.query<
      GetMinimalProfileByAccountIdApiResponse,
      GetMinimalProfileByAccountIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.accountId}/minimal-profile`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { maxVisibility: queryArg.maxVisibility },
      }),
    }),
    getDisplayNameByAccountId: build.query<
      GetDisplayNameByAccountIdApiResponse,
      GetDisplayNameByAccountIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.accountId}/display-name`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getMultiProfileNameByAccountIds: build.query<
      GetMultiProfileNameByAccountIdsApiResponse,
      GetMultiProfileNameByAccountIdsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/profiles/names`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { accountIds: queryArg.accountIds },
      }),
    }),
    getMultipleDisplayNamesByAccountIds: build.query<
      GetMultipleDisplayNamesByAccountIdsApiResponse,
      GetMultipleDisplayNamesByAccountIdsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/display-names`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { accountIds: queryArg.accountIds },
      }),
    }),
    getProfilesByEpisodeId: build.query<
      GetProfilesByEpisodeIdApiResponse,
      GetProfilesByEpisodeIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/accounts/${queryArg.episodeId}/profiles`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type CreateAddressBookEntryApiResponse =
  /** status 201 Created */ CreateAddressBookEntryResponseModel;
export type CreateAddressBookEntryArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The address book entry that needs to be created */
  createAddressBookEntryRequestModel: CreateAddressBookEntryRequestModel;
};
export type UpdateAddressBookEntryApiResponse = /** status 201 Created */
  | void
  | /** status 204 No Content */ void;
export type UpdateAddressBookEntryArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The address book entry that needs to be updated. */
  updateAddressBookEntryRequestModel: UpdateAddressBookEntryRequestModel;
};
export type StreamAllAddressBookEntriesApiResponse =
  /** status 200 Success */ GetAllAddressBookEntriesResponseModel;
export type StreamAllAddressBookEntriesArgs = {
  linkedAccountsOnly?: boolean;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type DeleteAddressBookEntryApiResponse =
  /** status 202 Accepted */ NoContentResult | /** status 204 No Content */ void;
export type DeleteAddressBookEntryArgs = {
  /** The id of the address book entry which needs to be deleted. */
  addressBookEntryId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetAddressBookEntryByIdApiResponse =
  /** status 200 Success */ GetAddressBookEntryByIdResponseModel;
export type GetAddressBookEntryByIdArgs = {
  /** The id of the address book entry that needs to be retrieved. */
  addressBookEntryId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type CreateNetworkInvitationForKnownUserApiResponse =
  /** status 201 Created */ CreateNetworkInvitationResponseModel;
export type CreateNetworkInvitationForKnownUserArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  createNetworkInvitationForKnownUserRequestModel: CreateNetworkInvitationForKnownUserRequestModel;
};
export type CreateNetworkInvitationForUnknownUserApiResponse =
  /** status 201 Created */ CreateNetworkInvitationResponseModel;
export type CreateNetworkInvitationForUnknownUserArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  createNetworkInvitationForUnknownUserRequestModel: CreateNetworkInvitationForUnknownUserRequestModel;
};
export type AcceptNetworkInvitationApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type AcceptNetworkInvitationArgs = {
  /** The ID of the invite that needs to be accepted. */
  networkInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type DeclineNetworkInvitationApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type DeclineNetworkInvitationArgs = {
  /** The ID of the invite that needs to be declined. */
  networkInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetReceivedNetworkInvitationsApiResponse =
  /** status 200 Success */ GetReceivedNetworkInvitationsResponseModel;
export type GetReceivedNetworkInvitationsArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetSentNetworkInvitationsApiResponse =
  /** status 200 Success */ GetSentNetworkInvitationsResponseModel;
export type GetSentNetworkInvitationsArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type RevokeNetworkInvitationApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type RevokeNetworkInvitationArgs = {
  /** The ID of the invite that needs to be revoked. */
  networkInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetNetworkInvitationByIdApiResponse =
  /** status 200 Success */ GetNetworkInvitationByIdResponseModel;
export type GetNetworkInvitationByIdArgs = {
  /** The ID of the network invitation. */
  networkInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetV1AccountsByAccountIdProfilePhotoApiResponse =
  /** status 200 Success */ Blob;
export type GetV1AccountsByAccountIdProfilePhotoArgs = {
  /** The account id for which the profile picture should be returned. */
  accountId: string;
  /** The size of the image to be returned. */
  imageSize?: ImageSize;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type PutV1AccountsByAccountIdProfilePhotoApiResponse =
  /** status 201 Created */ void | /** status 204 No Content */ void;
export type PutV1AccountsByAccountIdProfilePhotoArgs = {
  /** The account id for which the profile picture should be uploaded. */
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The binary picture payload. */
  body: Blob;
};
export type DeleteV1AccountsByAccountIdProfilePhotoApiResponse =
  /** status 202 Accepted */ void | /** status 204 No Content */ void;
export type DeleteV1AccountsByAccountIdProfilePhotoArgs = {
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetProfileByCurrentUserAccountIdApiResponse =
  /** status 200 Success */ GetProfileByCurrentUserAccountIdResponseModel;
export type GetProfileByCurrentUserAccountIdArgs = {
  /** The maximum visibility to return. */
  maxVisibility?: Visibility;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetProfileByAccountIdApiResponse =
  /** status 200 Success */ GetProfileByAccountIdResponseModel;
export type GetProfileByAccountIdArgs = {
  /** The account id of the profile that needs to be retrieved. */
  accountId: string;
  /** The maximum visibility to return. */
  maxVisibility?: Visibility;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type UpdateProfileApiResponse = /** status 201 Created */
  | void
  | /** status 204 No Content */ void;
export type UpdateProfileArgs = {
  /** The account id of the profile that needs to be updated. */
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The profile that needs to be updated. */
  updateProfileRequestModel: UpdateProfileRequestModel;
};
export type GetMinimalProfileByAccountIdApiResponse =
  /** status 200 Success */ GetMinimalProfileByAccountIdResponseModel;
export type GetMinimalProfileByAccountIdArgs = {
  /** The account id of the profile that needs to be retrieved. */
  accountId: string;
  /** The maximum visibility to return. */
  maxVisibility?: Visibility;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetDisplayNameByAccountIdApiResponse =
  /** status 200 Success */ GetProfileDisplayNameByAccountIdResponseModel;
export type GetDisplayNameByAccountIdArgs = {
  /** The unique identifier of the account. */
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetMultiProfileNameByAccountIdsApiResponse =
  /** status 200 Success */ GetMultiProfileNameByAccountIdsResponseModel;
export type GetMultiProfileNameByAccountIdsArgs = {
  /** The account ids of the users. */
  accountIds?: string[];
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetMultipleDisplayNamesByAccountIdsApiResponse =
  /** status 200 Success */ GetMultipleDisplayNamesByAccountIdsResponseModel;
export type GetMultipleDisplayNamesByAccountIdsArgs = {
  /** The unique identifiers of the accounts. */
  accountIds?: string[];
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetProfilesByEpisodeIdApiResponse =
  /** status 200 Success */ GetProfilesByEpisodeIdResponseModel;
export type GetProfilesByEpisodeIdArgs = {
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type NameResponseModel = {
  /** The first name of the person */
  firstName: string;
  /** The infix of the person's name */
  infix?: string | null;
  /** The last name of the person */
  lastName: string;
  /** The name to display for the person */
  displayName: string;
};
export type GenderResponseModel =
  | "Unknown"
  | "Male"
  | "Female"
  | "Undifferentiated";
export type EmailAddressResponseModel = {
  /** The type of the e-mail address */
  kind: string;
  /** The e-mail address */
  value: string;
};
export type PhoneNumberResponseModel = {
  /** The type of phone number */
  kind: string;
  /** The actual phone number */
  value: string;
};
export type AddressResponseModel = {
  /** The type of the address */
  kind: string;
  /** The postal code of the address */
  postalCode?: string | null;
  /** The street of the address */
  street?: string | null;
  /** The house number of the address */
  houseNumber?: string | null;
  /** A letter following the house number as assigned by the municipal authorities */
  houseNumberLetter?: string | null;
  /** The letters or signs needed to locate the mailbox, in addition to the house number and letter. */
  houseNumberAddition?: string | null;
  /** The city of the address */
  city?: string | null;
  /** The country of the address */
  country?: string | null;
};
export type ProfileResponseModel = {
  /** Gets the unique identifier of the user account. */
  accountId: string;
  name: NameResponseModel;
  /** Gets or inits the dateOfBirth information of the user profile. */
  dateOfBirth?: string | null;
  gender?: GenderResponseModel;
  /** Gets or inits the organization information of the user profile. */
  organization?: string | null;
  /** Gets or inits the job role information of the user profile. */
  jobRole?: string | null;
  /** Gets or inits the biography information of the user profile. */
  biography?: string | null;
  /** Gets or inits the photo information of the user profile. */
  photoInfo?: string | null;
  /** Gets the collection of email addresses associated with the user profile. */
  emailAddresses: EmailAddressResponseModel[];
  /** Gets the collection of phone numbers associated with the user profile. */
  phoneNumbers: PhoneNumberResponseModel[];
  /** Gets the collection of addresses associated with the user profile. */
  addresses: AddressResponseModel[];
};
export type CreateAddressBookEntryResponseModel = {
  /** The id of the entry */
  id: string;
  /** Gets the creation date and time of the entity in UTC. */
  createdAt: string;
  name: NameResponseModel;
  /** The date of birth of the person represented by the entry */
  dateOfBirth?: string | null;
  gender: GenderResponseModel;
  /** The organization of the person represented by the entry */
  organization?: string | null;
  /** The job role of the person represented by the entry */
  jobRole?: string | null;
  /** The biography of the person represented by the entry */
  biography?: string | null;
  /** The e-mail addresses of the person represented by the entry */
  emailAddresses: EmailAddressResponseModel[];
  /** The phone numbers of the person represented by the entry */
  phoneNumbers: PhoneNumberResponseModel[];
  /** The addresses of the person represented by the entry */
  addresses: AddressResponseModel[];
  linkedProfile?: ProfileResponseModel;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type NameModel = {
  /** The first name */
  firstName: string;
  /** The infix */
  infix?: string | null;
  /** The last name */
  lastName: string;
};
export type GenderRequestModel =
  | "Unknown"
  | "Male"
  | "Female"
  | "Undifferentiated";
export type EmailModel = {
  /** The type of the e-mail address */
  type: string;
  /** The e-mail address */
  emailAddress: string;
};
export type PhoneModel = {
  /** The type of phone number */
  type: string;
  /** The actual phone number */
  phoneNumber: string;
};
export type AddressModel = {
  /** The type of the address */
  type: string;
  /** The postal code of the address */
  postalCode?: string | null;
  /** The street of the address */
  street?: string | null;
  /** The housenumber of the address */
  houseNumber?: string | null;
  /** A letter following the house number as assigned by the municipal authorities */
  houseNumberLetter?: string | null;
  /** The letters or signs needed to locate the mailbox, in addition to the house number and letter. */
  houseNumberAddition?: string | null;
  /** The city of the address */
  city?: string | null;
  /** The country of the address */
  country?: string | null;
};
export type CreateAddressBookEntryRequestModel = {
  name: NameModel;
  /** The date of birth of the person represented by the entry */
  dateOfBirth?: string | null;
  gender: GenderRequestModel;
  /** The organization of the person represented by the entry */
  organization?: string | null;
  /** The job role of the person represented by the entry */
  jobRole?: string | null;
  /** The biography of the person represented by the entry */
  biography?: string | null;
  /** The e-mail addresses of the person represented by the entry */
  emailAddresses: EmailModel[];
  /** The phone numbers of the person represented by the entry */
  phoneNumbers: PhoneModel[];
  /** The addresses of the person represented by the entry */
  addresses?: AddressModel[] | null;
};
export type UpdateAddressBookEntryRequestModel = {
  /** The id of the address book entry */
  addressBookEntryId: string;
  name: NameModel;
  /** The date of birth of the person represented by the entry */
  dateOfBirth?: string | null;
  gender: GenderRequestModel;
  /** The organization of the person represented by the entry */
  organization?: string | null;
  /** The job role of the person represented by the entry */
  jobRole?: string | null;
  /** The biography of the person represented by the entry */
  biography?: string | null;
  /** The e-mail addresses of the person represented by the entry */
  emailAddresses?: EmailModel[] | null;
  /** The phone numbers of the person represented by the entry */
  phoneNumbers?: PhoneModel[] | null;
  /** The addresses of the person represented by the entry */
  addresses?: AddressModel[] | null;
};
export type AddressBookEntryResponseModel = {
  /** The id of the entry */
  id: string;
  /** Gets the creation date and time of the entity in UTC. */
  createdAt: string;
  name: NameResponseModel;
  /** The date of birth of the person represented by the entry */
  dateOfBirth?: string | null;
  gender: GenderResponseModel;
  /** The organization of the person represented by the entry */
  organization?: string | null;
  /** The job role of the person represented by the entry */
  jobRole?: string | null;
  /** The biography of the person represented by the entry */
  biography?: string | null;
  /** The e-mail addresses of the person represented by the entry */
  emailAddresses: EmailAddressResponseModel[];
  /** The phone numbers of the person represented by the entry */
  phoneNumbers: PhoneNumberResponseModel[];
  /** The addresses of the person represented by the entry */
  addresses: AddressResponseModel[];
  linkedProfile?: ProfileResponseModel;
};
export type GetAllAddressBookEntriesResponseModel = {
  /** the address book entries */
  items: AddressBookEntryResponseModel[];
};
export type NoContentResult = {
  statusCode?: number;
};
export type GetAddressBookEntryByIdResponseModel = {
  /** The id of the entry */
  id: string;
  /** Gets the creation date and time of the entity in UTC. */
  createdAt: string;
  name: NameResponseModel;
  /** The date of birth of the person represented by the entry */
  dateOfBirth?: string | null;
  gender: GenderResponseModel;
  /** The organization of the person represented by the entry */
  organization?: string | null;
  /** The job role of the person represented by the entry */
  jobRole?: string | null;
  /** The biography of the person represented by the entry */
  biography?: string | null;
  /** The e-mail addresses of the person represented by the entry */
  emailAddresses: EmailAddressResponseModel[];
  /** The phone numbers of the person represented by the entry */
  phoneNumbers: PhoneNumberResponseModel[];
  /** The addresses of the person represented by the entry */
  addresses: AddressResponseModel[];
  linkedProfile?: ProfileResponseModel;
};
export type CreateNetworkInvitationResponseModel = {
  /** Gets or sets the ID of the created invitation. */
  id: string;
};
export type CreateNetworkInvitationForKnownUserRequestModel = {
  /** Gets or sets the account ID of the person that received the invite. */
  inviteeAccountId: string;
  /** Gets or sets the address book entry ID of the invitee. */
  addressBookEntryId: string;
};
export type CreateNetworkInvitationForUnknownUserRequestModel = {
  /** Gets or initializes the email address of the user to be invited. */
  emailAddress: string;
  /** Gets or initializes the phone number of the user to be invited. */
  phoneNumber: string;
  /** Gets or sets the address book entry ID of the invitee. */
  addressBookEntryId: string;
};
export type InviteeResponseModel = object;
export type KnownInviteeResponseModel = InviteeResponseModel & {
  /** Gets or sets the account ID of the known invitee. */
  accountId: string;
};
export type UnknownInviteeResponseModel = InviteeResponseModel & {
  /** Gets or sets the email address of the anonymous invitee. */
  emailAddress: string;
  /** Gets or sets the phone number of the anonymous invitee. */
  phoneNumber: string;
};
export type NetworkInvitationResponseModel = {
  /** Gets the ID of the entity. */
  id: string;
  /** Gets the creation date and time of the entity in UTC. */
  createdAt: string;
  /** Gets or sets the Etag of the entity. */
  etag: string;
  /** Gets the account id of the person that initiated the invite. */
  inviterAccountId: string;
  /** Gets the status of the invite. */
  status: string;
  /** Gets the invitee. */
  invitee: KnownInviteeResponseModel | UnknownInviteeResponseModel;
  /** Gets the creation date and time the invitation will expire in UTC. */
  expiresAt: string;
  /** Gets the date and time the invitation was settled in UTC. */
  settledAt?: string | null;
  /** Gets the date and time the entity was modified in UTC. */
  modifiedAt: string;
  /** Gets a value indicating whether the network invitation has expired. */
  isExpired: boolean;
  /** Gets the unique identifier for the corresponding address book entry that this invitation was initiated from. */
  addressBookEntryId: string;
};
export type GetReceivedNetworkInvitationsResponseModel = {
  /** Gets or sets the list of received network invitations. */
  items: NetworkInvitationResponseModel[];
};
export type GetSentNetworkInvitationsResponseModel = {
  /** Gets or sets the list of sent network invitations. */
  items: NetworkInvitationResponseModel[];
};
export type GetNetworkInvitationByIdResponseModel = {
  /** Gets the ID of the entity. */
  id: string;
  /** Gets the creation date and time of the entity in UTC. */
  createdAt: string;
  /** Gets or sets the Etag of the entity. */
  etag: string;
  /** Gets the account id of the person that initiated the invite. */
  inviterAccountId: string;
  /** Gets the status of the invite. */
  status: string;
  /** Gets the invitee. */
  invitee: KnownInviteeResponseModel | UnknownInviteeResponseModel;
  /** Gets the creation date and time the invitation will expire in UTC. */
  expiresAt: string;
  /** Gets the date and time the invitation was settled in UTC. */
  settledAt?: string | null;
  /** Gets the date and time the entity was modified in UTC. */
  modifiedAt: string;
  /** Gets a value indicating whether the network invitation has expired. */
  isExpired: boolean;
  /** Gets the unique identifier for the corresponding address book entry that this invitation was initiated from. */
  addressBookEntryId: string;
};
export type ImageSize = "Small" | "Large";
export type VisibilityResponseModel = "Private" | "Network" | "Public";
export type DateOfBirthWithVisibilityResponseModel = {
  /** Gets the value of the user's dateOfBirth in DateOnly format. */
  value: string;
  visibility: VisibilityResponseModel;
};
export type GenderWithVisibilityResponseModel = {
  value: GenderResponseModel;
  visibility: VisibilityResponseModel;
};
export type OrganizationWithVisibilityResponseModel = {
  /** Gets the value of the user's organization. */
  value: string;
  visibility: VisibilityResponseModel;
};
export type JobRoleWithVisibilityResponseModel = {
  /** Gets the value of the user's job role. */
  value: string;
  visibility: VisibilityResponseModel;
};
export type BiographyWithVisibilityResponseModel = {
  /** Gets the value of the user's biography. */
  value: string;
  visibility: VisibilityResponseModel;
};
export type PhotoInfoResponseModel = {
  /** Gets the value of the photo information. */
  value: string;
};
export type EmailAddressWithVisibilityResponseModel = {
  /** Gets the kind or type of the email address. */
  kind: string;
  /** Gets the value of the email address. */
  value: string;
  visibility: VisibilityResponseModel;
};
export type PhoneNumberWithVisibilityResponseModel = {
  /** Gets the kind or type of the phone number. */
  kind: string;
  /** Gets the value of the phone number. */
  value: string;
  visibility: VisibilityResponseModel;
};
export type AddressWithVisibilityResponseModel = {
  /** Gets the kind or type of the address. */
  kind: string;
  /** Gets the postal code of the address (optional). */
  postalCode?: string | null;
  /** Gets the street name of the address (optional). */
  street?: string | null;
  /** Gets the house number of the address (optional). */
  houseNumber?: string | null;
  /** Gets the house number letter or character of the address (optional). */
  houseNumberLetter?: string | null;
  /** Gets the house number addition of the address (optional). */
  houseNumberAddition?: string | null;
  /** Gets the city of the address (optional). */
  city?: string | null;
  /** Gets the country of the address (optional). */
  country?: string | null;
  visibility: VisibilityResponseModel;
};
export type GetProfileByCurrentUserAccountIdResponseModel = {
  /** Gets the unique identifier of the user account. */
  accountId: string;
  name: NameResponseModel;
  dateOfBirth?: DateOfBirthWithVisibilityResponseModel;
  gender?: GenderWithVisibilityResponseModel;
  organization?: OrganizationWithVisibilityResponseModel;
  jobRole?: JobRoleWithVisibilityResponseModel;
  biography?: BiographyWithVisibilityResponseModel;
  photoInfo?: PhotoInfoResponseModel;
  /** Gets the collection of email addresses associated with the user profile. */
  emailAddresses: EmailAddressWithVisibilityResponseModel[];
  /** Gets the collection of phone numbers associated with the user profile. */
  phoneNumbers: PhoneNumberWithVisibilityResponseModel[];
  /** Gets the collection of addresses associated with the user profile. */
  addresses: AddressWithVisibilityResponseModel[];
};
export type Visibility = "Private" | "Network" | "Public";
export type GetProfileByAccountIdResponseModel = {
  /** Gets the unique identifier of the user account. */
  accountId: string;
  name: NameResponseModel;
  dateOfBirth?: DateOfBirthWithVisibilityResponseModel;
  gender?: GenderWithVisibilityResponseModel;
  organization?: OrganizationWithVisibilityResponseModel;
  jobRole?: JobRoleWithVisibilityResponseModel;
  biography?: BiographyWithVisibilityResponseModel;
  photoInfo?: PhotoInfoResponseModel;
  /** Gets the collection of email addresses associated with the user profile. */
  emailAddresses: EmailAddressWithVisibilityResponseModel[];
  /** Gets the collection of phone numbers associated with the user profile. */
  phoneNumbers: PhoneNumberWithVisibilityResponseModel[];
  /** Gets the collection of addresses associated with the user profile. */
  addresses: AddressWithVisibilityResponseModel[];
};
export type NameRequestModel = {
  /** Gets the first name of the user. */
  firstName: string;
  /** Gets the infix (middle name or initial) of the user (optional). */
  infix?: string | null;
  /** Gets the last name of the user. */
  lastName: string;
};
export type VisibilityRequestModel = "Private" | "Network" | "Public";
export type DateOfBirthWithVisibilityRequestModel = {
  /** Gets the value of the user's dateOfBirth in DateOnly format. */
  value: string;
  visibility: VisibilityRequestModel;
};
export type GenderWithVisibilityRequestModel = {
  value: GenderRequestModel;
  visibility?: VisibilityRequestModel;
};
export type OrganizationWithVisibilityRequestModel = {
  /** Gets the value of the user's organization. */
  value: string;
  visibility: VisibilityRequestModel;
};
export type JobRoleWithVisibilityRequestModel = {
  /** Gets the value of the user's job role. */
  value: string;
  visibility: VisibilityRequestModel;
};
export type BiographyWithVisibilityRequestModel = {
  /** Gets the value of the user's biography. */
  value: string;
  visibility: VisibilityRequestModel;
};
export type PhotoInfoRequestModel = {
  /** Gets the value of the photo information. */
  value: string;
};
export type EmailAddressWithVisibilityRequestModel = {
  /** Gets the kind or type of the email address. */
  kind: string;
  /** Gets the value of the email address. */
  value: string;
  visibility: VisibilityRequestModel;
};
export type PhoneNumberWithVisibilityRequestModel = {
  /** Gets the kind or type of the phone number. */
  kind: string;
  /** Gets the value of the phone number. */
  value: string;
  visibility: VisibilityRequestModel;
};
export type AddressWithVisibilityRequestModel = {
  /** Gets the kind or type of the address. */
  kind: string;
  /** Gets the postal code of the address (optional). */
  postalCode?: string | null;
  /** Gets the street name of the address (optional). */
  street?: string | null;
  /** Gets the house number of the address (optional). */
  houseNumber?: string | null;
  /** Gets the house number letter or character of the address (optional). */
  houseNumberLetter?: string | null;
  /** Gets the house number addition of the address (optional). */
  houseNumberAddition?: string | null;
  /** Gets the city of the address (optional). */
  city?: string | null;
  /** Gets the country of the address (optional). */
  country?: string | null;
  visibility: VisibilityRequestModel;
};
export type UpdateProfileRequestModel = {
  name: NameRequestModel;
  dateOfBirth?: DateOfBirthWithVisibilityRequestModel;
  gender?: GenderWithVisibilityRequestModel;
  organization?: OrganizationWithVisibilityRequestModel;
  jobRole?: JobRoleWithVisibilityRequestModel;
  biography?: BiographyWithVisibilityRequestModel;
  photoInfo?: PhotoInfoRequestModel;
  /** Gets the collection of email addresses associated with the user profile. */
  emailAddresses: EmailAddressWithVisibilityRequestModel[];
  /** Gets the collection of phone numbers associated with the user profile. */
  phoneNumbers: PhoneNumberWithVisibilityRequestModel[];
  /** Gets the collection of addresses associated with the user profile. */
  addresses?: AddressWithVisibilityRequestModel[] | null;
};
export type GetMinimalProfileByAccountIdResponseModel = {
  /** Gets or sets the account ID of the user profile. */
  accountId: string;
  /** Gets or sets the display name of the user profile. */
  displayName: string;
  /** Gets the photo information of the user profile. */
  photoInfo?: string | null;
};
export type GetProfileDisplayNameByAccountIdResponseModel = {
  /** Gets or sets the display name of the user profile. */
  displayName: string;
};
export type ProfileNameAccountIdResponseModel = {
  /** Gets or sets the account ID of the user profile. */
  accountId: string;
  /** The first name */
  firstName: string;
  /** The infix */
  infix?: string | null;
  /** The last name */
  lastName: string;
  /** The name to display */
  displayName: string;
};
export type GetMultiProfileNameByAccountIdsResponseModel = {
  /** The profile names */
  items: ProfileNameAccountIdResponseModel[];
};
export type ProfileDisplayNameResponseModel = {
  /** Gets or sets the account ID of the user profile. */
  accountId: string;
  /** Gets or sets the display name of the user profile. */
  displayName: string;
};
export type GetMultipleDisplayNamesByAccountIdsResponseModel = {
  /** Gets or sets the display name of the user profile. */
  items: ProfileDisplayNameResponseModel[];
};
export type GetProfilesByEpisodeIdResponseModel = {
  /** The profiles of the episode members */
  items: ProfileResponseModel[];
};
export const {
  useCreateAddressBookEntryMutation,
  useUpdateAddressBookEntryMutation,
  useStreamAllAddressBookEntriesQuery,
  useDeleteAddressBookEntryMutation,
  useGetAddressBookEntryByIdQuery,
  useCreateNetworkInvitationForKnownUserMutation,
  useCreateNetworkInvitationForUnknownUserMutation,
  useAcceptNetworkInvitationMutation,
  useDeclineNetworkInvitationMutation,
  useGetReceivedNetworkInvitationsQuery,
  useGetSentNetworkInvitationsQuery,
  useRevokeNetworkInvitationMutation,
  useGetNetworkInvitationByIdQuery,
  useGetV1AccountsByAccountIdProfilePhotoQuery,
  usePutV1AccountsByAccountIdProfilePhotoMutation,
  useDeleteV1AccountsByAccountIdProfilePhotoMutation,
  useGetProfileByCurrentUserAccountIdQuery,
  useGetProfileByAccountIdQuery,
  useUpdateProfileMutation,
  useGetMinimalProfileByAccountIdQuery,
  useGetDisplayNameByAccountIdQuery,
  useGetMultiProfileNameByAccountIdsQuery,
  useGetMultipleDisplayNamesByAccountIdsQuery,
  useGetProfilesByEpisodeIdQuery,
} = injectedRtkApi;
