import { FC, useCallback, useMemo, useState } from "react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useTranslation } from "react-i18next";
import { useChatClient } from "@azure/communication-react";
import { ChatMessage } from "@azure/communication-chat";
import { ChatThreadResponseModel } from "src/apis/chat";
import { useGetEpisodeQuery } from "src/apis/episode";
import { Avatar } from "src/modules/shared/components/avatars";
import { usePlatformAccount, useProfileDetails } from "src/modules/shared/hooks";
import { useAsyncEffect, useLocale } from "src/common/hooks";
import { useGetDisplayNameByAccountIdQuery, useGetV1AccountsByAccountIdProfilePhotoQuery } from "src/apis/network";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { focusChat, setUnreadMessagesCount } from "src/redux/slices/chatsSlice";
import { Datetime } from "src/modules/shared/components/Datetime";

interface ChatThreadProps {
	chatThread: ChatThreadResponseModel,
}

export const ChatThread: FC<ChatThreadProps> = ({ chatThread }: ChatThreadProps) => {
	const { t } = useTranslation();
	const locale = useLocale();
	const dispatch = useAppDispatch();
	const focusedThreadId = useAppSelector(state => state.chat.focusedThreadId);
	const lastMesageDate = useAppSelector(state => state.chat.lastMessageDate);

	const [lastMessage, setLastMessage] = useState<ChatMessage | undefined>(undefined);

	const unreadMessagesCount = useAppSelector(state => state.chat.unreadMessagesCount);

	const noOfUnreadMessages = useMemo(() => {
		return unreadMessagesCount[chatThread.externalChatThreadId];
	}, [chatThread, unreadMessagesCount])

	const statefulChatClient = useChatClient();

	const chatThreadClient = useMemo(() => {
		if (!statefulChatClient) {
			return undefined;
		}
		return statefulChatClient.getChatThreadClient(chatThread.externalChatThreadId);
	}, [statefulChatClient, chatThread?.externalChatThreadId]);

	useAsyncEffect(async () => {
		if (chatThreadClient) {
			setLastMessage(undefined);
			for await (const message of chatThreadClient.listMessages({ maxPageSize: 1 })) {
				if (message?.content?.message) {
					setLastMessage(message);
					break;
				}
			}
		}
	}, [chatThreadClient, lastMesageDate]);

	const { accountId } = usePlatformAccount();

	const otherParticipant = useMemo(() => {
		return chatThread?.participants?.find(p => p.accountId !== accountId);
	}, [chatThread, accountId]);

	const isCurrentlySelected = useMemo(() => {
		return chatThread?.id && focusedThreadId === chatThread.id;
	}, [focusedThreadId, chatThread?.id]);

	const [, otherParticipantImage, otherParticipantDisplayName] = useProfileDetails({ accountId: otherParticipant?.accountId });

	const { data: episode } = useGetEpisodeQuery(chatThread?.episodeId ? {
		"Accept-Language": locale,
		episodeId: chatThread?.episodeId
	} : skipToken);
	const { data: episodeSubjectName } = useGetDisplayNameByAccountIdQuery(episode?.subjectAccountId ? { accountId: episode.subjectAccountId, "Accept-Language": locale } : skipToken);

	const { currentData: episodeSubjectImageUrl } = useGetV1AccountsByAccountIdProfilePhotoQuery((episode?.subjectAccountId ? {
		"Accept-Language": locale,
		accountId: episode.subjectAccountId
	} : skipToken));

	const side = useMemo(() => (<div className="kko-chat-summary__side">
		<Avatar
			name={chatThread?.episodeId && episodeSubjectName ? episodeSubjectName.displayName : otherParticipantDisplayName}
			image={chatThread?.episodeId ? episodeSubjectImageUrl : otherParticipantImage}
		/>
	</div>), [episodeSubjectName, otherParticipantDisplayName, otherParticipantImage, chatThread?.episodeId, episodeSubjectImageUrl]);
	const title = useMemo(() => (
		<div className="kko-chat-summary__title">
			<strong>
				{chatThread?.episodeId && episodeSubjectName?.displayName ? episodeSubjectName.displayName : otherParticipantDisplayName}
				{chatThread?.episodeId && episode?.title ?
					<a className="kko-person-tag__name" href="/">{" " + episode.title}</a> : <></>}
			</strong>
			<Datetime
				value={chatThread.lastMessageReceivedOn}
				culture={locale}
				timeStyle="regular"
			/>
		</div>
	), [chatThread?.lastMessageReceivedOn, chatThread?.episodeId, episode?.title, otherParticipantDisplayName, episodeSubjectName?.displayName, locale]);

	const subtitle = useMemo(() => (
		<div className="kko-chat-summary__subtitle">
			<span>{lastMessage?.content?.message}</span>
			{!!noOfUnreadMessages && <div className="kko-number-badge"><i aria-hidden="true">{noOfUnreadMessages <= 99 ? noOfUnreadMessages : "99+"}</i><span role="status"></span></div>}
		</div>
	), [lastMessage, noOfUnreadMessages]);

	const onClick = useCallback(async () => {
		dispatch(focusChat(chatThread.id));
		if (chatThreadClient && chatThreadClient?.threadId) {
			dispatch(setUnreadMessagesCount({ threadId: chatThreadClient?.threadId, unreadMessagesCount: 0 }));
			// fetches messages and caches them
			// eslint-disable-next-line
			for await (const message of chatThreadClient.listMessages().byPage()) {

			}
		}
	}, [chatThread?.id, dispatch, chatThreadClient]);

	if (!chatThread?.episodeId && !chatThread?.lastMessageReceivedOn && chatThread?.id !== focusedThreadId) {
		// one-on-one chats should be shown only if they have a last message
		return;
	}

	return (
		<div
			className="kko-chat-summary"
			aria-current={isCurrentlySelected ? "true" : undefined}
			onClick={onClick}
		>
			{side}
			<div className="kko-chat-summary__content">
				{title}
				{subtitle}
			</div>
			<button
				type="button"
				className="kko-chat-summary__default-action"
			>{t("kko:pages.chats.commands.card.view")}</button>
		</div>
	)

}
