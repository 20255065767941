import { useSessionStorage } from "src/common/hooks/use-storage";
import { SortDirection } from "../utils/sort/constants";

export type SortState<T> = {
	readonly column: T;
	readonly direction: SortDirection;
}

export type SortOptions = {
	readonly storageKey: string;
}

export type AriaSort = "ascending" | "descending" | "none";

export const useSortedColumns = <T>(
	initialState: SortState<T> | (() => SortState<T>),
	options: SortOptions
) => {
	const [sortState, setSortState] = useSessionStorage<SortState<T>>(
		options.storageKey,
		initialState
	);

	const toggleSortColumn = (column: T) => {
		setSortState(prevState => {
			const direction: SortDirection = column === prevState.column
				? (prevState.direction === "ascending" ? "descending" : "ascending")
				: "ascending";

			return { column, direction };
		});
	}

	const getAriaSort = (sortColumn: T): AriaSort => {
		return sortColumn !== sortState.column
			? "none"
			: sortState.direction;
	}

	return { sortState, toggleSortColumn, getAriaSort } as const;
}