export const reactions = [{
	icon: "emoji-heart",
	label: "Love",
	command: "❤️",
	inline: true
},
{
	icon: "emoji-thumbs-up",
	label: "Like",
	command: "👍",
	inline: true
},
{
	icon: "emoji-pray",
	label: "Pray",
	command: "🙏🏻",
	inline: true
},
{
	icon: "emoji-cry",
	label: "Cry",
	command: "😢",
	inline: true
},
{
	icon: "emoji-lightbulb",
	label: "Idea",
	command: "💡",
	inline: true
}]
