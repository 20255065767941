import { useCallback, useMemo, useSyncExternalStore } from "react"

/**
 * Call `useMediaQuery` at the top level of your component to determine whether
 * the {@link https://developer.mozilla.org/en-US/docs/Web/API/Document | document}
 * matches a {@link https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_media_queries/Using_media_queries | media query}
 * string, and trigger a re-render when the matching state changes.
 *
 * @param query The media query string.
 * @returns `true` if the document matches the media query; otherwise, `false`.
 *
 * @example
 * ```tsx
 * const MyComponent : FC = () => {
 *   const isSmallScreen = useMediaQuery("(max-width:400px)");
 *   const isLandScape = useMediaQuery("(orientation:landscape)");
 * 
 *   // ...
 * };
 * ```
 */
export const useMediaQuery = (query: string | null | undefined) => {
	const mql = useMemo(() => {
		// While we know this will exist in browsers, it might not exist
		// under e.g. unit testing frameworks that use JSDOM.
		return typeof globalThis.matchMedia === "function"
			? globalThis.matchMedia(query ?? "")
			: null;
	}, [query]);

	const subscribe = useCallback((onStoreChange: () => void) => {
		if (!mql) return () => { };

		const listener = () => onStoreChange();
		mql.addEventListener("change", listener);

		return () => mql.removeEventListener("change", listener);
	}, [mql]);

	const getSnapshot = useCallback(() => {
		// We know this will exist for browser-side getSnapshot, but it might
		// not exist in a 'browser' environment such as for JSDOM unit tests.
		return mql?.matches ?? false;
	}, [mql]);

	return useSyncExternalStore(subscribe, getSnapshot);
}

/**
 * Call `useMediaBreakpoint` at the top level of your component to obtain the value
 * of a {@link https://developer.mozilla.org/en-US/docs/Learn/CSS/CSS_layout/Media_queries#how_to_choose_breakpoints | media breakpoint}
 * defined via a {@link https://developer.mozilla.org/en-US/docs/Web/CSS/--* | CSS variable}
 * following the naming convention of `--<prefix>-breakpoint--<name>` that is applied to the
 * root element of the {@link https://developer.mozilla.org/en-US/docs/Web/API/Document | document}.
 * 
 * This hook is used together with the `useMediaQuery` hook to set breakpoints that are
 * directly taken from the application's style sheets, such that they can be centrally managed
 * and updated from one authoritative place in the application.
 * 
 * @param name The breakpoint name.
 * @param prefix A prefix to apply to the variable name. Defaults to `kko`.
 * @returns The breakpoint value.
 * 
 * @example
 * The following shows how to use a breakpoint defined in CSS
 * for tablet devices to treat anything at or below it as a tablet
 * device.
 * 
 * ```tsx
 * const Component: FC = () => {
 *   const breakpoint = useMediaBreakpoint("tablet");
 *   const isTablet = useMediaQuery(`(max-width: ${breakpoint})`);
 * 
 *   // ...
 * };
 * ```
 */
export const useMediaBreakpoint = (name: string, prefix: string = "kko") => {
	const breakpoint = useMemo(() => {
		if (typeof globalThis.getComputedStyle !== "function") return undefined;

		const style = globalThis.getComputedStyle(document.documentElement);
		return style.getPropertyValue(`--${prefix}-breakpoint--${name}`) || undefined;
	}, [name, prefix]);

	// `useSyncExternalStore` is used as a safeguard to ensure we only
	// run this code on clients and can't run it under any type of SSR
	// scenario.
	const subscribe = useCallback(() => () => { }, []);

	const getSnapshot = useCallback(() => breakpoint, [breakpoint]);

	return useSyncExternalStore(subscribe, getSnapshot);
}
