import { useReducer } from "react";

type ListReducerAction<S> =
	| { type: "add", payload: S }
	| { type: "remove", payload: S }
	| { type: "clear" };

type AddValueStateAction<S> = (value: S) => void;
type RemoveValueStateAction<S> = (value: S) => void;
type ClearStateAction = () => void;

type ListStateActions<S> = {
	add: AddValueStateAction<S>;
	remove: RemoveValueStateAction<S>;
	clear: ClearStateAction;
}

/**
 * Call `useListState` at the top level of your component to return
 * a stateful list of values, and functions to add values; remove values;
 * or clear the list.
 * @param initialState
 * The values you want the list to have initially. This argument is
 * ignored after the initial render.
 * 
 * If you pass a function as `initialState`, it will be treated as
 * an _initializer_ function. This function should be pure. React will
 * call your initializer function when initializing the component, and
 * store its return value as the initial state.
 * @param valuesEqual
 * An equality comparison function used to determine if a value is
 * present on the list, when adding or removing values.
 * When not specified the default `Object.is` implementation is used.
 * @returns
 * `useListState` returns an array tuple with exactly 2 items:
 * 1. The current list state.  
 *    During initial render it will match the `initialState` you have passed.
 * 2. An object with three function members that each let you update the list
 *    state, and trigger a re-render:
 *    1. `add` - which adds a value to the list, if not yet present.
 *    2. `remove` - which removes a value from the list, if present.
 *    3. `clear` - which clears the list.
 */
export const useListState = <S>(
	initialState: S[] | (() => S[]),
	valuesEqual: ((a: S, b: S) => boolean) = Object.is
): [readonly S[], ListStateActions<S>] => {
	const initializer = () => isThunk(initialState)
		? initialState()
		: initialState;

	const reducer = (state: S[], action: ListReducerAction<S>): S[] => {
		switch (action.type) {
			case "add":
				return (state.some(item => valuesEqual(item, action.payload)))
					? state
					: [...state, action.payload];
			case "remove":
				const remaining = state.filter(item => !valuesEqual(item, action.payload));
				return remaining.length === state.length
					? state
					: remaining;
			case "clear":
				return (state.length === 0)
					? state
					: [];
			default:
				return state;
		}
	}

	const [state, dispatch] = useReducer(reducer, null, initializer);

	const add: AddValueStateAction<S> = (value: S) =>
		dispatch({ type: "add", payload: value });

	const remove: RemoveValueStateAction<S> = (value: S) =>
		dispatch({ type: "remove", payload: value });

	const clear: ClearStateAction = () =>
		dispatch({ type: "clear" });

	return [state, { add, remove, clear }] as const;
}

function isThunk<T>(value: (() => T[]) | unknown): value is (() => T[]) {
	return typeof value === "function";
}