/**
 * Manages a `Promise` that can be externally settled.
 */
export class Deferred<T> {
	#promise: Promise<T>;
	#resolve!: (value: T | PromiseLike<T>) => void;
	#reject!: (reason?: any) => void;

	/**
	 * Creates a new `Deferred`.
	 */
	constructor() {
		this.#promise = new Promise((resolve, reject) => {
			this.#resolve = resolve;
			this.#reject = reject;
		});
	}

	/**
	 * Resolves the promise managed by this `Deferred`.
	 * @param value
	 * The value with which to resolve the promise.
	 */
	resolve(value: T) {
		this.#resolve(value);
	}

	/**
	 * Rejects the promise managed by this `Deferred`.
	 * @param reason
	 * The reason with which to reject the promise.
	 */
	reject(reason?: any) {
		this.#reject(reason);
	}

	/**
	 * The promise managed by this `Deferred`.
	 */
	get promise() {
		return this.#promise;
	}
}