import { skipToken } from "@reduxjs/toolkit/query/react";
import { FC } from "react";
import { useGetV1AccountsByAccountIdProfilePhotoQuery } from "src/apis/network";
import { useLocale } from "src/common/hooks";
import { Avatar } from "src/modules/shared/components/avatars";
import { Icon } from "src/modules/shared/components/Icon";
import { NotificationBadge, NotificationModule, NotificationSender } from "../types";

type NotificationAvatarProps = {
	module?: NotificationModule;
	sender?: NotificationSender | null;
	badge?: NotificationBadge;
}

const moduleIcons = new Map<NotificationModule, string>([
	["Calendar", "calendar"],
	["Document", "description-fill"],
	["Episode", "description-fill"],
	["Network", "user-fill"],
	["Task", "clipboard-check-fill"]
]) as ReadonlyMap<NotificationModule, string>;

const fallbackIcon = "bell-fill";

export const NotificationAside: FC<NotificationAvatarProps> = ({
	module,
	sender,
	badge
}) => {
	const locale = useLocale();

	const { currentData: image } = useGetV1AccountsByAccountIdProfilePhotoQuery(sender ? {
		"Accept-Language": locale,
		accountId: sender.accountId,
		imageSize: "Large"
	} : skipToken);

	const badgeKind = (typeof badge === "object" ? badge.kind : badge) ?? "notification";
	const badgeIcon = typeof badge === "object" ? badge.icon : undefined;

	const moduleIcon = (module && moduleIcons.get(module));

	return (
		<div className="kko-newnotification__side">
			{sender
				? (
					<Avatar
						className="kko-avatar--flexible"
						name={sender?.displayName ?? ""}
						image={image}
					/>
				)
				: (
					<Icon
						className="kko-avatar--flexible"
						icon={moduleIcon ?? fallbackIcon}
					/>
				)
			}
			{!!(badgeIcon || (sender && moduleIcon)) && (
				<div
					className="kko-newnotification__badge"
					data-appearance={badgeKind}
				>
					<Icon icon={badgeIcon ?? moduleIcon ?? fallbackIcon} />
				</div>
			)}
		</div>
	);
};