import { useInsertionEffect, useRef } from "react";

type EqualityComparison<T> = (newValue: T, oldValue: T) => boolean;

/**
 * Call `useStable` at the top level of your component to ensure a value is
 * stable for React's built-in `Object.is` reference equality, where actual
 * equality needs to be defined via a custom equality comparison.  
 *
 * The hook uses provided custom equality comparison to compare an incoming new
 * value to the old value the hook was previously called with, and where they are
 * the same it will return the previous value rather than the new value.
 * This keeps the value stable for reference equality.
 *
 * @param value The value to stabilize.
 * @param is The custom equality comparison to use.
 * @returns Where the passed in `value` was equal to the previous value according
 * to the `is` comparison, the previous value; otherwise the passed in `value`.
 * 
 * @example
 * ```ts
 * type Props = { date : Date };
 * 
 * const MyComponent : FC<Props> = ({ date }) => {
 *   date = useStable(date, (newValue, oldValue) => {
 *     return newValue.getTime() === oldValue.getTime()
 *   });
 * 
 *   const formatted = useMemo(() => {
 *     const formatter = new Intl.DateTimeFormat("en", { dateStyle : "long" });
 *     return formatter.format(date);
 *   }, [date]);
 * 
 *   // ...
 * };
 * ```
 */
export const useStable = <T>(value: T, is: EqualityComparison<T> = Object.is): T => {
	const ref = useRef(value);

	// If the new value is equal to the previous value,
	// throw out the new value and replace it with the previous
	// value.
	if (is(ref.current, value)) { value = ref.current }

	// Assign the new/previous value back to the ref from an insertion
	// effect, which is safe for concurrent mode. This is effectively
	// a no-op if we swapped back to the previous value.
	useInsertionEffect(() => { ref.current = value });

	return value;
}