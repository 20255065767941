import { skipToken } from "@reduxjs/toolkit/dist/query/react";
import { FC, ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { To, generatePath } from "react-router-dom";
import { useGetEpisodeDisplayInformationQuery } from "src/apis/episode";
import { useLocale } from "src/common/hooks";
import { useGetDisplayNameByAccountIdQuery } from "src/apis/network";
import { EpisodeRelatedNotification } from "../types"
import { NotificationShell } from "./NotificationShell";

type EpisodeNotificationProps = {
	notification: EpisodeRelatedNotification
}

export const EpisodeNotification: FC<EpisodeNotificationProps> = ({ notification }) => {
	const { t } = useTranslation();
	const locale = useLocale();

	const { payload, kind } = notification;

	const { currentData: episode } = useGetEpisodeDisplayInformationQuery((payload.episodeId
		? { "Accept-Language": locale, episodeId: payload.episodeId }
		: skipToken
	), { refetchOnMountOrArgChange: true });

	const { currentData: subjectName } = useGetDisplayNameByAccountIdQuery((episode?.subjectAccountId
		? { "Accept-Language": locale, accountId: episode.subjectAccountId }
		: skipToken
	), { refetchOnMountOrArgChange: true });

	const title = useMemo<ReactNode>(() => {
		switch (kind) {
			case "EpisodeCreated":
				return t("kko:pages.notifications.new-episode-created.text");

			default:
				return null;
		}
	}, [kind, t])

	const to = useMemo<To | undefined>(() => {
		return payload.episodeId
			? generatePath("/episodes/details/:episodeId/information", { episodeId: payload.episodeId })
			: undefined;
	}, [payload.episodeId]);

	return (
		<NotificationShell
			notification={notification}
			title={title}
			to={to}
		>
			{payload.sender?.displayName}
			{!!(episode?.title && subjectName?.displayName) && (
				<>
					{!!payload.sender?.displayName && <span aria-hidden="true"> · </span>}
					{`${episode.title} (${subjectName.displayName})`}
				</>
			)}
		</NotificationShell>
	)
};