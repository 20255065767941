import { skipToken } from "@reduxjs/toolkit/query/react";
import { useMemo } from "react";
import { useGetChatThreadsQuery, useGetCommunicationAccessTokenQuery, useGetCommunicationUserIdentityQuery } from "src/apis/chat";
import { useAppSelector } from "src/common/redux";

export function useUnreadMessages() {

	const { data: userIdentityResponse } = useGetCommunicationUserIdentityQuery();
	const { data: chatAccessTokenResponse } = useGetCommunicationAccessTokenQuery(userIdentityResponse?.communicationUserIdentity ? {
		userIdentity: userIdentityResponse.communicationUserIdentity
	} : skipToken);
	const { data: { items: chatThreads } = {} } = useGetChatThreadsQuery(chatAccessTokenResponse?.communicationAccessToken ? {
		"X-Chat-Access-Token": chatAccessTokenResponse.communicationAccessToken
	} : skipToken);


	const unreadMessagesCount = useAppSelector(state => state.chat.unreadMessagesCount);
	const oneOnOneThreadIds = useMemo(() => {
		return chatThreads?.filter(c => !c.episodeId).map(c => c.externalChatThreadId);
	}, [chatThreads]);
	const channelThreadIds = useMemo(() => {
		return chatThreads?.filter(c => c.episodeId).map(c => c.externalChatThreadId);
	}, [chatThreads]);

	const unreadOneOnOneMessages = useMemo(() => {
		let unreadCount = 0;
		if (!oneOnOneThreadIds?.length) {
			return 0;
		}
		for (const [threadId, count] of Object.entries(unreadMessagesCount)) {
			if (oneOnOneThreadIds.includes(threadId)) {
				unreadCount = unreadCount + count;
			}
		}
		return unreadCount <= 99 ? unreadCount : "99+";
	}, [oneOnOneThreadIds, unreadMessagesCount]);

	const unreadChannelMessages = useMemo(() => {
		let unreadCount = 0;
		if (!channelThreadIds?.length) {
			return 0;
		}
		for (const [threadId, count] of Object.entries(unreadMessagesCount)) {
			if (channelThreadIds.includes(threadId)) {
				unreadCount = unreadCount + count;
			}
		}
		return unreadCount <= 99 ? unreadCount : "99+";
	}, [channelThreadIds, unreadMessagesCount]);

	const unreadMessages = useMemo(() => {
		let count = 0;
		for (const unread of Object.values(unreadMessagesCount)) {
			count = count + unread;
		}
		return count <= 99 ? count : "99+";
	}, [unreadMessagesCount]);

	return { unreadOneOnOneMessages, unreadChannelMessages, unreadMessages } as const;
}