import { FC, useId } from "react";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useLocale } from "src/common/hooks";
import { useGetDisplayNameByAccountIdQuery } from "src/apis/network";
import { ProfileAvatar } from "src/modules/shared/components/avatars";
import { AccountIdReaction } from "../types";

interface ChatAccountReactionProps {
	accountIdReaction: AccountIdReaction
}

export const ChatAccountReaction: FC<ChatAccountReactionProps> = ({ accountIdReaction }: ChatAccountReactionProps) => {

	const labelId = useId();
	const locale = useLocale();

	const { accountId } = accountIdReaction;
	const { data: displayNameResponse } = useGetDisplayNameByAccountIdQuery(accountId
		? { "Accept-Language": locale, accountId }
		: skipToken
	);

	if (!accountIdReaction?.accountId || !displayNameResponse?.displayName) {
		return null;
	}

	return (
		<div className="kko-chat-reaction-person">
			<div className="kko-person-summary">
				<ProfileAvatar size="large" accountId={accountIdReaction.accountId} />
				<span className="kko-person-summary__content">
					<strong id={labelId} className="kko-person-summary__name">{displayNameResponse.displayName}</strong>
				</span>
			</div>
			<span className="kko-chat-reaction-person__emoji">
				{accountIdReaction.reaction}
			</span>
		</div>
	)
}