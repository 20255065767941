import { AccountInfo } from "@azure/msal-browser";
import { Policy } from "./config";

export const getClaim = <T>(account: AccountInfo | null, claimType: string) => {
	const claims = account?.idTokenClaims;
	if (!claims || !Object.prototype.hasOwnProperty.call(claims, claimType)) return undefined;

	return claims[claimType] as T;
}

export const getAccountFlow = (account: AccountInfo | null) => {
	return getClaim<string>(account, "tfp") ?? getClaim<string>(account, "acr");
}

export const verifyAccountFlow = (account: AccountInfo | null, validPolicies: Policy[]) => {
	const activePolicy = getAccountFlow(account)?.toUpperCase();
	if (!activePolicy) return false;

	for (const policy of validPolicies) {
		if (policy.name.toUpperCase() === activePolicy)
			return true;
	}

	return false;
}