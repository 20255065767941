import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createEpisodeInvitationRequest: build.mutation<
      CreateEpisodeInvitationRequestApiResponse,
      CreateEpisodeInvitationRequestArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/invitation-requests`,
        method: "POST",
        body: queryArg.createEpisodeInvitationRequestRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getPendingInvitationRequests: build.query<
      GetPendingInvitationRequestsApiResponse,
      GetPendingInvitationRequestsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/invitation-requests`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    acceptEpisodeInvitationRequest: build.mutation<
      AcceptEpisodeInvitationRequestApiResponse,
      AcceptEpisodeInvitationRequestArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/invitation-requests/${queryArg.episodeInvitationRequestId}/accept`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    declineEpisodeInvitationRequest: build.mutation<
      DeclineEpisodeInvitationRequestApiResponse,
      DeclineEpisodeInvitationRequestArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/invitation-requests/${queryArg.episodeInvitationRequestId}/decline`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    createEpisodeInvitationForKnownUser: build.mutation<
      CreateEpisodeInvitationForKnownUserApiResponse,
      CreateEpisodeInvitationForKnownUserArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/known-user`,
        method: "POST",
        body: queryArg.createEpisodeInvitationForKnownUserRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    createEpisodeInvitationForUnknownUser: build.mutation<
      CreateEpisodeInvitationForUnknownUserApiResponse,
      CreateEpisodeInvitationForUnknownUserArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/unknown-user`,
        method: "POST",
        body: queryArg.createEpisodeInvitationForUnknownUserRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    acceptEpisodeInvitation: build.mutation<
      AcceptEpisodeInvitationApiResponse,
      AcceptEpisodeInvitationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.episodeInvitationId}/accept`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    declineEpisodeInvitation: build.mutation<
      DeclineEpisodeInvitationApiResponse,
      DeclineEpisodeInvitationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.episodeInvitationId}/decline`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getReceivedEpisodeInvitations: build.query<
      GetReceivedEpisodeInvitationsApiResponse,
      GetReceivedEpisodeInvitationsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/Received`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getSentEpisodeInvitations: build.query<
      GetSentEpisodeInvitationsApiResponse,
      GetSentEpisodeInvitationsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/Sent`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    revokeEpisodeInvitation: build.mutation<
      RevokeEpisodeInvitationApiResponse,
      RevokeEpisodeInvitationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.episodeInvitationId}/revoke`,
        method: "PATCH",
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getEpisodeInvitationById: build.query<
      GetEpisodeInvitationByIdApiResponse,
      GetEpisodeInvitationByIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/invitations/${queryArg.episodeInvitationId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getPendingEpisodeInvitationsByEpisodeId: build.query<
      GetPendingEpisodeInvitationsByEpisodeIdApiResponse,
      GetPendingEpisodeInvitationsByEpisodeIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/invitations`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    postV1EpisodeMemberships: build.mutation<
      PostV1EpisodeMembershipsApiResponse,
      PostV1EpisodeMembershipsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/EpisodeMemberships`,
        method: "POST",
        body: queryArg.createEpisodeMembershipRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getV1EpisodeMemberships: build.query<
      GetV1EpisodeMembershipsApiResponse,
      GetV1EpisodeMembershipsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/EpisodeMemberships`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
        params: { episodeId: queryArg.episodeId },
      }),
    }),
    getV1EpisodeMembershipsAccountByAccountId: build.query<
      GetV1EpisodeMembershipsAccountByAccountIdApiResponse,
      GetV1EpisodeMembershipsAccountByAccountIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/EpisodeMemberships/account/${queryArg.accountId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getAllEpisodes: build.query<GetAllEpisodesApiResponse, GetAllEpisodesArgs>({
      query: (queryArg) => ({
        url: `/v1/episodes`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    createEpisode: build.mutation<CreateEpisodeApiResponse, CreateEpisodeArgs>({
      query: (queryArg) => ({
        url: `/v1/episodes`,
        method: "POST",
        body: queryArg.createEpisodeRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getEpisode: build.query<GetEpisodeApiResponse, GetEpisodeArgs>({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    updateEpisode: build.mutation<UpdateEpisodeApiResponse, UpdateEpisodeArgs>({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}`,
        method: "PUT",
        body: queryArg.updateEpisodeRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getEpisodeDisplayInformation: build.query<
      GetEpisodeDisplayInformationApiResponse,
      GetEpisodeDisplayInformationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/episodes/${queryArg.episodeId}/display-information`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    acceptEpisodeInvitationInternally: build.mutation<
      AcceptEpisodeInvitationInternallyApiResponse,
      AcceptEpisodeInvitationInternallyArgs
    >({
      query: (queryArg) => ({
        url: `/v1/internal/invitations/Accept`,
        method: "PATCH",
        body: queryArg.acceptEpisodeInvitationInternallyRequestModel,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getReplicationData: build.query<
      GetReplicationDataApiResponse,
      GetReplicationDataArgs
    >({
      query: (queryArg) => ({
        url: `/v1/internal/episodes/replication-data`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getEpisodeByIdInternally: build.query<
      GetEpisodeByIdInternallyApiResponse,
      GetEpisodeByIdInternallyArgs
    >({
      query: (queryArg) => ({
        url: `/v1/internal/episodes/${queryArg.episodeId}`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getInvolvedEpisodesByAccountId: build.query<
      GetInvolvedEpisodesByAccountIdApiResponse,
      GetInvolvedEpisodesByAccountIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/internal/accounts/${queryArg.accountId}/involved-episodes`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
    getAllEpisodeIds: build.query<
      GetAllEpisodeIdsApiResponse,
      GetAllEpisodeIdsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/internal/episodes`,
        headers: { "Accept-Language": queryArg["Accept-Language"] },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type CreateEpisodeInvitationRequestApiResponse =
  /** status 201 Created */ void;
export type CreateEpisodeInvitationRequestArgs = {
  /** The ID of the episode to create the invitation request for. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The model that contains the information required to create the invitation request. */
  createEpisodeInvitationRequestRequestModel: CreateEpisodeInvitationRequestRequestModel;
};
export type GetPendingInvitationRequestsApiResponse =
  /** status 200 Success */ GetPendingInvitationRequestsResponseModel;
export type GetPendingInvitationRequestsArgs = {
  /** The ID of the episode to get the pending invitation requests for. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type AcceptEpisodeInvitationRequestApiResponse =
  /** status 202 Accepted */ void | /** status 204 No Content */ void;
export type AcceptEpisodeInvitationRequestArgs = {
  /** The ID of the episode that the invitation request is for. */
  episodeId: string;
  /** The ID of the invitation request to accept. */
  episodeInvitationRequestId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type DeclineEpisodeInvitationRequestApiResponse =
  /** status 202 Accepted */ void | /** status 204 No Content */ void;
export type DeclineEpisodeInvitationRequestArgs = {
  /** The ID of the episode that the invitation request is for. */
  episodeId: string;
  /** The ID of the invitation request to decline. */
  episodeInvitationRequestId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type CreateEpisodeInvitationForKnownUserApiResponse =
  /** status 201 Created */ CreateEpisodeInvitationResponseModel;
export type CreateEpisodeInvitationForKnownUserArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  createEpisodeInvitationForKnownUserRequestModel: CreateEpisodeInvitationForKnownUserRequestModel;
};
export type CreateEpisodeInvitationForUnknownUserApiResponse =
  /** status 201 Created */ CreateEpisodeInvitationResponseModel;
export type CreateEpisodeInvitationForUnknownUserArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  createEpisodeInvitationForUnknownUserRequestModel: CreateEpisodeInvitationForUnknownUserRequestModel;
};
export type AcceptEpisodeInvitationApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type AcceptEpisodeInvitationArgs = {
  /** The request model. */
  episodeInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type DeclineEpisodeInvitationApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type DeclineEpisodeInvitationArgs = {
  /** The ID of the invite that needs to be declined. */
  episodeInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetReceivedEpisodeInvitationsApiResponse =
  /** status 200 Success */ GetReceivedEpisodeInvitationsResponseModel;
export type GetReceivedEpisodeInvitationsArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetSentEpisodeInvitationsApiResponse =
  /** status 200 Success */ GetSentEpisodeInvitationsResponseModel;
export type GetSentEpisodeInvitationsArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type RevokeEpisodeInvitationApiResponse = /** status 202 Accepted */
  | void
  | /** status 204 No Content */ void;
export type RevokeEpisodeInvitationArgs = {
  /** The ID of the invite that needs to be revoked. */
  episodeInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetEpisodeInvitationByIdApiResponse =
  /** status 200 Success */ GetEpisodeInvitationByIdResponseModel;
export type GetEpisodeInvitationByIdArgs = {
  /** The ID of the episode invitation. */
  episodeInvitationId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetPendingEpisodeInvitationsByEpisodeIdApiResponse =
  /** status 200 Success */ GetPendingEpisodeInvitationsByEpisodeIdResponseModel;
export type GetPendingEpisodeInvitationsByEpisodeIdArgs = {
  /** The ID of the episode. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type PostV1EpisodeMembershipsApiResponse =
  /** status 201 Created */ CreateEpisodeMembershipResponseModel;
export type PostV1EpisodeMembershipsArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The request model containing the necessary data to create the episode member. */
  createEpisodeMembershipRequestModel: CreateEpisodeMembershipRequestModel;
};
export type GetV1EpisodeMembershipsApiResponse =
  /** status 200 Success */ GetEpisodeMembersResponse;
export type GetV1EpisodeMembershipsArgs = {
  /** The id of the episode. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetV1EpisodeMembershipsAccountByAccountIdApiResponse =
  /** status 200 Success */ GetEpisodeIdsForAccountResponseModel;
export type GetV1EpisodeMembershipsAccountByAccountIdArgs = {
  /** The account for which the episodes are returned */
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetAllEpisodesApiResponse =
  /** status 200 Success */ GetAllEpisodesResponseModel;
export type GetAllEpisodesArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type CreateEpisodeApiResponse =
  /** status 201 Created */ CreateEpisodeResponseModel;
export type CreateEpisodeArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The episode that needs to be created. */
  createEpisodeRequestModel: CreateEpisodeRequestModel;
};
export type GetEpisodeApiResponse =
  /** status 200 Success */ GetEpisodeResponseModel;
export type GetEpisodeArgs = {
  /** The id of the episode that needs to be retrieved. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type UpdateEpisodeApiResponse = /** status 201 Created */
  | void
  | /** status 204 No Content */ void;
export type UpdateEpisodeArgs = {
  /** The ID of the episode to update */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The episode that needs to be updated. */
  updateEpisodeRequestModel: UpdateEpisodeRequestModel;
};
export type GetEpisodeDisplayInformationApiResponse =
  /** status 200 Success */ GetEpisodeDisplayInformationResponseModel;
export type GetEpisodeDisplayInformationArgs = {
  /** The id of the episode */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type AcceptEpisodeInvitationInternallyApiResponse =
  /** status 202 Accepted */ void | /** status 204 No Content */ void;
export type AcceptEpisodeInvitationInternallyArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
  /** The request model. */
  acceptEpisodeInvitationInternallyRequestModel: AcceptEpisodeInvitationInternallyRequestModel;
};
export type GetReplicationDataApiResponse =
  /** status 200 Success */ EpisodeReplicationDataInternalResponseModel[];
export type GetReplicationDataArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetEpisodeByIdInternallyApiResponse =
  /** status 200 Success */ GetEpisodeByIdInternalResponseModel;
export type GetEpisodeByIdInternallyArgs = {
  /** The unique identifier of the episode. */
  episodeId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetInvolvedEpisodesByAccountIdApiResponse =
  /** status 200 Success */ GetInvolvedEpisodesByAccountIdInternalResponseModel;
export type GetInvolvedEpisodesByAccountIdArgs = {
  /** The unique identifier of the account for which involved episodes are being requested. */
  accountId: string;
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type GetAllEpisodeIdsApiResponse = /** status 200 Success */ string[];
export type GetAllEpisodeIdsArgs = {
  /** The culture code used for the request. */
  "Accept-Language": string;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type KnownInviteeRequestModel = {
  /** The account id of the person that invitation is intended for. */
  accountId: string;
};
export type CreateEpisodeInvitationRequestRequestModel = {
  /** The unique identifier for the corresponding address book entry that this invitation was initiated from. */
  addressBookEntryId: string;
  invitee: KnownInviteeRequestModel;
  /** The message to include with the invitation. */
  message?: string | null;
};
export type EpisodeInvitationRequestResponseModel = {
  /** The account id of the person that initiated the invite. */
  inviterAccountId: string;
  /** The reason for the invitation. */
  message: string;
  /** The display name of the person that is the invite is intended for. */
  inviteeDisplayName: string;
  /** The episode id that is the invite is intended for. */
  episodeId: string;
  /** The unique identifier for the invitation request. */
  id: string;
  /** The date and time that the invitation request was created. */
  createdAt: string;
};
export type GetPendingInvitationRequestsResponseModel = {
  /** The pending episode invitation requests. */
  items: EpisodeInvitationRequestResponseModel[];
};
export type CreateEpisodeInvitationResponseModel = {
  /** Gets or sets the ID of the created invitation. */
  id: string;
};
export type CreateEpisodeInvitationForKnownUserRequestModel = {
  /** The message to include with the invitation. */
  message?: string | null;
  /** Gets or sets the account ID of the person that received the invite. */
  inviteeAccountId: string;
  /** Gets or sets the ID of the episode the invitation is for. */
  episodeId: string;
  /** Gets or sets the address book entry ID of the invitee. */
  addressBookEntryId: string;
};
export type CreateEpisodeInvitationForUnknownUserRequestModel = {
  /** The message to include with the invitation. */
  message?: string | null;
  /** Gets or initializes the email address of the user to be invited. */
  emailAddress: string;
  /** Gets or initializes the phone number of the user to be invited. */
  phoneNumber: string;
  /** Gets or sets the ID of the episode the invitation is for. */
  episodeId: string;
  /** Gets or sets the address book entry ID of the invitee. */
  addressBookEntryId: string;
};
export type InviteeResponseModel = object;
export type KnownInviteeResponseModel = InviteeResponseModel & {
  /** Gets or sets the account ID of the known invitee. */
  accountId: string;
};
export type UnknownInviteeResponseModel = InviteeResponseModel & {
  /** Gets or sets the email address of the anonymous invitee. */
  emailAddress: string;
  /** Gets or sets the phone number of the anonymous invitee. */
  phoneNumber: string;
};
export type EpisodeInvitationResponseModel = {
  /** Gets the ID of the entity. */
  id: string;
  /** Gets the creation date and time of the entity in UTC. */
  createdAt: string;
  /** Gets or sets the Etag of the entity. */
  etag: string;
  /** Gets the account id of the person that initiated the invite. */
  inviterAccountId: string;
  /** Gets the status of the invite. */
  status: string;
  /** Gets the invitee. */
  invitee: KnownInviteeResponseModel | UnknownInviteeResponseModel;
  /** Gets the creation date and time the invitation will expire in UTC. */
  expiresAt: string;
  /** Gets the date and time the invitation was settled in UTC. */
  settledAt?: string | null;
  /** Gets the date and time the entity was modified in UTC. */
  modifiedAt: string;
  /** Gets a value indicating whether the episode invitation has expired. */
  isExpired: boolean;
  /** The episode id that is the invite is intended for. */
  episodeId: string;
  /** Gets the unique identifier for the corresponding address book entry that this invitation was initiated from. */
  addressBookEntryId: string;
  /** The message written by the inviter to the invitee. */
  message?: string | null;
};
export type GetReceivedEpisodeInvitationsResponseModel = {
  /** Gets or sets the list of received episode invitations. */
  items: EpisodeInvitationResponseModel[];
};
export type GetSentEpisodeInvitationsResponseModel = {
  /** Gets or sets the list of sent episode invitations. */
  items: EpisodeInvitationResponseModel[];
};
export type GetEpisodeInvitationByIdResponseModel = {
  /** Gets the ID of the entity. */
  id: string;
  /** Gets the creation date and time of the entity in UTC. */
  createdAt: string;
  /** Gets or sets the Etag of the entity. */
  etag: string;
  /** Gets the account id of the person that initiated the invite. */
  inviterAccountId: string;
  /** Gets the status of the invite. */
  status: string;
  /** Gets the invitee. */
  invitee: KnownInviteeResponseModel | UnknownInviteeResponseModel;
  /** Gets the creation date and time the invitation will expire in UTC. */
  expiresAt: string;
  /** Gets the date and time the invitation was settled in UTC. */
  settledAt?: string | null;
  /** Gets the date and time the entity was modified in UTC. */
  modifiedAt: string;
  /** Gets a value indicating whether the episode invitation has expired. */
  isExpired: boolean;
  /** The episode id that is the invite is intended for. */
  episodeId: string;
  /** Gets the unique identifier for the corresponding address book entry that this invitation was initiated from. */
  addressBookEntryId: string;
  /** The message written by the inviter to the invitee. */
  message?: string | null;
};
export type GetPendingEpisodeInvitationsByEpisodeIdResponseModel = {
  /** The list of pending episode invitations. */
  items: EpisodeInvitationResponseModel[];
};
export type CreateEpisodeMembershipResponseModel = {
  /** Gets or sets the inviter account ID. */
  inviterAccountId: string;
  /** Gets or sets the response ID. */
  id: string;
  /** Gets or sets the account ID. */
  accountId: string;
  /** Gets or sets the episode ID. */
  episodeId: string;
};
export type CreateEpisodeMembershipRequestModel = {
  /** Gets or sets the account ID of the inviter. */
  inviterAccountId: string;
  /** Gets or sets the account ID of the member. */
  accountId: string;
  /** Gets or sets the ID of the episode that the member is associated with. */
  episodeId: string;
};
export type EpisodeMembershipResponseModel = {
  /** Gets or sets the account ID of the member. */
  accountId: string;
  /** Gets or sets the account ID of the inviter. */
  inviterAccountId: string;
};
export type GetEpisodeMembersResponse = {
  /** The list of episode members with their account ids */
  items: EpisodeMembershipResponseModel[];
};
export type GetEpisodeIdsForAccountResponseModel = {
  /** The episodes */
  episodeIds?: string[] | null;
};
export type EpisodeStatusResponseModel = 0;
export type AccessRightModel = 0;
export type EpisodeResponseModel = {
  /** Gets the id of the episode. */
  id: string;
  /** Gets the account id of the subject of the episode. */
  subjectAccountId: string;
  /** Gets the name of the subject of the episode. */
  subjectDisplayName: string;
  /** Gets the account id of the owner of the episode */
  ownerAccountId: string;
  /** Gets the name of the owner of the episode. */
  ownerDisplayName: string;
  /** Gets the title of the episode. */
  title: string;
  /** Gets the description of the episode. */
  description?: string | null;
  /** Gets the creation date for the episode. */
  creationDate?: string;
  /** Gets the Etag used to make sure changes to an episodes are not overwritten by accident. */
  etag?: string | null;
  status?: EpisodeStatusResponseModel;
  defaultDocumentsAccessRight?: AccessRightModel;
  /** The IDs of the episode members */
  members?: string[] | null;
};
export type GetAllEpisodesResponseModel = {
  /** Gets the list of the episode response models. */
  items?: EpisodeResponseModel[] | null;
};
export type CreateEpisodeResponseModel = {
  /** The ID of the newly created episode */
  id: string;
};
export type CreateEpisodeRequestModel = {
  /** Gets or sets the subject account ID associated with the episode. */
  subjectAccountId: string;
  /** The title of the episode */
  title: string;
  /** The description of the episode */
  description?: string | null;
};
export type GetEpisodeResponseModel = {
  /** Gets the id of the episode. */
  id: string;
  /** Gets the account id of the subject of the episode. */
  subjectAccountId: string;
  /** Gets the name of the subject of the episode. */
  subjectDisplayName: string;
  /** Gets the account id of the owner of the episode */
  ownerAccountId: string;
  /** Gets the name of the owner of the episode. */
  ownerDisplayName: string;
  /** Gets the title of the episode. */
  title: string;
  /** Gets the description of the episode. */
  description?: string | null;
  /** Gets the creation date for the episode. */
  creationDate?: string;
  /** Gets the Etag used to make sure changes to an episodes are not overwritten by accident. */
  etag?: string | null;
  status?: EpisodeStatusResponseModel;
  defaultDocumentsAccessRight?: AccessRightModel;
  /** The IDs of the episode members */
  members?: string[] | null;
};
export type UpdateEpisodeRequestModel = {
  /** The title of the episode */
  title: string;
  /** The description of the episode */
  description?: string | null;
  /** The etag value for the episode db data */
  etag: string;
};
export type GetEpisodeDisplayInformationResponseModel = {
  /** The title of the episode. */
  title: string;
  /** The account id of episode's subject */
  subjectAccountId: string;
};
export type AcceptEpisodeInvitationInternallyRequestModel = {
  episodeInvitationId: string;
  inviteeAccountId: string;
};
export type EpisodeStatusInternalResponseModel = 0;
export type EpisodeReplicationDataInternalResponseModel = {
  episodeId: string;
  createdAt: string;
  modifiedAt: string;
  title: string;
  subjectAccountId: string;
  ownerAccountId: string;
  members: string[];
  status: EpisodeStatusInternalResponseModel;
};
export type GetEpisodeByIdInternalResponseModel = {
  title: string;
  description: string;
  subjectAccountId: string;
  ownerAccountId: string;
  members: string[];
  status: EpisodeStatusInternalResponseModel;
  createdAt: string;
  modifiedAt: string;
};
export type EpisodeInternalResponseModel = {
  id: string;
  subjectAccountId: string;
  title: string;
};
export type GetInvolvedEpisodesByAccountIdInternalResponseModel = {
  items: EpisodeInternalResponseModel[];
};
export const {
  useCreateEpisodeInvitationRequestMutation,
  useGetPendingInvitationRequestsQuery,
  useAcceptEpisodeInvitationRequestMutation,
  useDeclineEpisodeInvitationRequestMutation,
  useCreateEpisodeInvitationForKnownUserMutation,
  useCreateEpisodeInvitationForUnknownUserMutation,
  useAcceptEpisodeInvitationMutation,
  useDeclineEpisodeInvitationMutation,
  useGetReceivedEpisodeInvitationsQuery,
  useGetSentEpisodeInvitationsQuery,
  useRevokeEpisodeInvitationMutation,
  useGetEpisodeInvitationByIdQuery,
  useGetPendingEpisodeInvitationsByEpisodeIdQuery,
  usePostV1EpisodeMembershipsMutation,
  useGetV1EpisodeMembershipsQuery,
  useGetV1EpisodeMembershipsAccountByAccountIdQuery,
  useGetAllEpisodesQuery,
  useCreateEpisodeMutation,
  useGetEpisodeQuery,
  useUpdateEpisodeMutation,
  useGetEpisodeDisplayInformationQuery,
  useAcceptEpisodeInvitationInternallyMutation,
  useGetReplicationDataQuery,
  useGetEpisodeByIdInternallyQuery,
  useGetInvolvedEpisodesByAccountIdQuery,
  useGetAllEpisodeIdsQuery,
} = injectedRtkApi;
