import { forwardRef, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { hideNotifications, toggleNotifications } from "src/redux/slices/notificationsSlice";
import { PillButton } from "src/modules/shared/components/buttons";
import { useGetAllQuery } from "src/apis/notification";
import { useLocationChange } from "src/modules/shared/hooks";


export const NotificationsPillIndicator = forwardRef<HTMLDivElement>((props, ref) => {
	const { t } = useTranslation();

	const notificationsOpen = useAppSelector(state => state.notification.show);
	const dispatch = useAppDispatch();

	// When the end-user navigates to other routes,
	// always close the notifications panel.
	const { listen } = useLocationChange();
	useEffect(() => {
		return listen(() => {
			dispatch(hideNotifications());
		});
	}, [dispatch, listen]);

	const { data: unread } = useGetAllQuery({}, {
		pollingInterval: notificationsOpen ? undefined : 10e3,
		selectFromResult: ({ data }) => ({
			// Discard notifications that don't have IDs.
			// We can't handle them correctly.
			data: data?.items
				? data.items.filter(item => !!item.id && !item.isRead).length
				: 0,
		})
	});

	const onShowHandler = useCallback(() => {
		dispatch(toggleNotifications());
	}, [dispatch]);

	const label = t("kko:pages.notifications.pill.view");
	const status = unread ? t("kko:pages.notifications.unread.message", { count: unread }) : "";
	const title = unread ? `${label}\n${status}` : label;

	return (
		<div
			ref={ref}
			role="group"
			className="kko-header-pill-notification"
			aria-label={t("kko:pages.notifications.pill.title")}
		>
			{!!unread && (
				<div className="kko-number-badge">
					<i aria-hidden="true">{unread > 99 ? "99+" : unread}</i>
					<span role="status">{status}</span>
				</div>
			)}
			<PillButton
				aria-pressed={notificationsOpen}
				icon={notificationsOpen ? "bell-fill" : "bell"}
				label={label}
				title={title}
				size="xlarge"
				onClick={onShowHandler}
			/>
		</div>
	)
});

