import { FC, useMemo } from "react";
import { DateTimeFormatOptions, formatDatetimeAsParts } from "../utils/formatUtils";

type DatetimeProps = {
	value: string | null | undefined;
	culture: string;
	formatOptions?: DateTimeFormatOptions;
	timeStyle?: "small" | "regular"
}
export const Datetime: FC<DatetimeProps> = ({ value, culture, formatOptions = { dateStyle: "medium", timeStyle: "short" }, timeStyle = "small" }) => {
	const valueAsDate = useMemo(() => {
		return !!value ? new Date(value) : null;
	}, [value]);

	const formattedValueAsDateParts = useMemo(() => {
		return valueAsDate ? formatDatetimeAsParts(valueAsDate, culture, formatOptions) : [];
	}, [valueAsDate, culture, formatOptions]);

	if (!value || !valueAsDate) {
		return null;
	}

	return <time dateTime={valueAsDate.toISOString()}>
		{getDateStringFromParts(formattedValueAsDateParts)}
		{
			timeStyle === "small" ?
				<small>{getTimeStringFromParts(formattedValueAsDateParts)}</small> :
				<>{getTimeStringFromParts(formattedValueAsDateParts)}</>
		}
	</time>;
}

const findDatePart = (p: Intl.DateTimeFormatPart) => p.type === "year" || p.type === "month" || p.type === "day";
const findMinuteTimePart = (p: Intl.DateTimeFormatPart) => p.type === "minute";

const getDateStringFromParts = (parts: Intl.DateTimeFormatPart[]): string => {
	const dateParts = parts.slice(0, parts.findLastIndex(findDatePart) + 1).map(p => p.value);
	return dateParts.join("");
}

const getTimeStringFromParts = (parts: Intl.DateTimeFormatPart[]): string => {
	const timeParts = parts.slice(parts.findLastIndex(findDatePart) + 1, parts.findLastIndex(findMinuteTimePart) + 1).map(p => p.value);
	return timeParts.join("");
}