/**
 * Parses the date-only portion of an ISO 8601 string representation of a date
 * into a `Date` instance at 00:00 hrs in the local timezone.
 * @param value
 * @returns The parsed `Date` instance, or `undefined` if parsing failed.
 */
export const parseLocalISODate = (value?: string | null): Date | undefined => {
	if (!value) return undefined;

	// Ensure we only retain the date in cases where a time is also specified.
	value = value.split("T")[0];

	// Failing to parse a date means its `valueOf()` returns `NaN`.
	// We use that to return a much easier to understand `undefined` instead.
	const date = new Date(value);
	if (isNaN(+date)) return undefined;

	// Parsing an ISO 8601 date-only string parses it as UTC. So we reverse the timezone
	// offset into local time to obtain the date at midnight 00:00 local time.
	date.setTime(date.getTime() + date.getTimezoneOffset() * 60e3);
	return date;
};

/**
 * Converts a `Date` instance to a date-only ISO 8601 string representation.
 * @param date The date to convert.
 * @returns The data-only ISO string representation.
 */
export const toISODateString = (date: Date): string => {
	// To safely obtain an ISO 8601 date-only string representation:
	// 1. Project the given Date from local time into UTC and erase the time.
	// 2. Use Date.prototype.toISOString to obtain the full ISO string.
	// 3. Drop the time portion of the generated string.

	date = new Date(Date.UTC(
		date.getFullYear(),
		date.getMonth(),
		date.getDate()
	));

	const serialized = date.toISOString();
	return serialized.slice(0, serialized.indexOf("T"));
}

/**
 * Converts a `Date` instance to its ISO 8601 string representation while retaining
 * the local timezone offset.  
 * Note that this is different from `Date.prototype.toISOString` which will always
 * convert to and format in UTC.
 * @param date The date to convert.
 * @returns The ISO string representation in the local timezone offset.
 */
export const toLocalISOString = (date?: Date | null) => {
	if (date == null || isNaN(+date)) return "";

	// The Swedish locale formats as YYYY-MM-DD HH:mm:ss which is extremely close to the ISO timestamp
	// format.
	// All it needs is:
	// - adding fractional milliseconds and replacing the fraction separator ',' with '.';
	// - adding a long timezone offset and removing the " GMT" infix; and
	// - when no timezone offset is present, adding a Z

	let hasOffset = false;

	return date
		.toLocaleString("sv", {
			timeZoneName: "longOffset",
			year: "numeric",
			month: "numeric",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			fractionalSecondDigits: 3
		})
		.replace(",", ".")
		.replace(" GMT", () => { hasOffset = true; return ""; })
		.replace(" ", "T") + (hasOffset ? "" : "Z");
}

export const today = (includeHour?: boolean) => {
	const now = new Date();

	return new Date(
		now.getFullYear(),
		now.getMonth(),
		now.getDate(),
		includeHour ? now.getHours() : 0
	);
};
