import { FC } from "react";

type ImageProps = {
	src?: string;
	alt?: string;
}

export const Image: FC<ImageProps> = ({ src, alt }) => {

	if (!src) return null;

	return (
		<img src={src} alt={alt ?? ""} />
	);
}

// TODO: Remove default export
export default Image;