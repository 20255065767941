import { DependencyList, useCallback, useMemo, useRef } from "react";
import { useBlocker } from "react-router-dom";
import { UseAsyncTemplate, isAbortError, useAsync } from "./use-async";
import { useAsyncEffect } from "./use-async-effect";

/**
 * Call `useBlockerAsync` at the top level of your component to prevent the user
 * from navigating away from the current location using navigation facilitated
 * by React Router.  
 * Uses the {@linkcode useAsync} hook internally to have the user confirm or
 * cancel the navigation through an out-of-flow asynchronous operation or UI
 * interaction.
 * 
 * @param children
 * The child template the hook should render to facilitate the out-of-flow
 * operation or UI interaction.  
 * The template is a callback taking two parameters:
 *   1. A `props` object holding properties passed to the template by the event
 *      handler or side effect making use of the hook's `block` return value.
 *   2. The `api` object holding the hook's imperative API. This consists of
 *      a `resolve` method that can be called with the boolean result value and
 *      a `reject` method that can be called with any value to result in the
 *      `block` throwing the value as an error.
 * @param deps
 * Will cause the `children` child template to re-render when the values
 * in the list change.  
 * Pass an empty array if the template has no dependent values.
 * @returns
 * `useBlockerAsync` returns an object with exactly three members:
 *   1. `render` - which is a function to render the `children` child template
 *      into the component output. Note that the template will only be rendered
 *      when and when a navigation is blocked and pending confirmation.
 *   2. `block` - which is a function to call from event handlers and side effects
 *      imperatively to raise the blocking condition. It can take a set of properties
 *      used to populate the `children` child template.  
 *      Note that each instance of the hook can only hold **one** pending confirmation.
 *      If the user attempts to navigate a second time when there is already a pending
 *      confirmation, that prior confirmation is replaced by the new one.
 *   3. `unblock` - which is a function to call from event handlers and side effects
 *      imperatively to lower the blocking condition programmatically.
 */
export const useBlockerAsync = <P extends {} = {}>(children: UseAsyncTemplate<boolean, P>, deps: DependencyList) => {
	const blockedWith = useRef<P | null>(null);
	const blocker = useBlocker(({ currentLocation, nextLocation }) => {
		return !!blockedWith.current && (false
			|| currentLocation.pathname !== nextLocation.pathname
			|| currentLocation.search !== nextLocation.search
		);
	});

	const { call, render } = useAsync(children, deps);

	useAsyncEffect(async signal => {
		if (blocker.state !== "blocked" || !blockedWith.current) return;

		try {
			const result = await call(blockedWith.current, signal);
			if (signal.aborted) return;

			if (result) {
				blocker.proceed();
			} else {
				blocker.reset();
			}
		} catch (error) {
			if (!isAbortError(error)) throw error;
		}
	}, [blocker.state]);

	const block = useCallback((props: P) => { blockedWith.current = props }, []);
	const unblock = useCallback(() => { blockedWith.current = null }, []);

	return useMemo(() => ({ block, unblock, render }), [block, render, unblock]);
};