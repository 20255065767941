import { skipToken } from "@reduxjs/toolkit/query/react";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useGetProfileByAccountIdQuery, useGetV1AccountsByAccountIdProfilePhotoQuery } from "src/apis/network";
import { useLocale } from "src/common/hooks";
import { isFetchBaseQueryError } from "src/common/redux";
import { Icon } from "src/modules/shared/components/Icon";
import { Avatar } from "src/modules/shared/components/avatars";
import { usePlatformAccount } from "src/modules/shared/hooks";


export const AccountItems: FC = () => {
	const { t } = useTranslation("kko", { keyPrefix: "core.header.account-menu" });
	const locale = useLocale();

	const { accountName: fallbackName = "", accountId } = usePlatformAccount();

	// TODO:
	// Should update to `useGetMinimalProfileByAccountIdQuery` once it's been extended
	// to include organization and job role.
	const { data: profile } = useGetProfileByAccountIdQuery(accountId ? {
		"Accept-Language": locale,
		accountId
	} : skipToken);

	const { data: photo } = useGetV1AccountsByAccountIdProfilePhotoQuery(profile ? {
		"Accept-Language": locale,
		accountId: profile?.accountId,
		imageSize: "Large"
	} : skipToken, {
		// Coerces a 404 to a positive result
		selectFromResult: ({ data, error, isSuccess, isError, ...other }) => {
			if (isFetchBaseQueryError(error) && error.status === 404) {
				data = undefined;
				error = undefined;
				isError = false;
				isSuccess = true;
			}

			return { data, error, isSuccess, isError, ...other };
		}
	});

	const { name, role, organization } = useMemo(() => ({
		name: profile?.name.displayName ?? fallbackName,
		role: profile?.jobRole?.value,
		organization: profile?.organization?.value,
	}), [profile, fallbackName]);

	const label = useMemo(() => {
		const context = [];
		if (name) context.push("name");
		if (role) context.push("role");
		if (organization) context.push("organization");

		return t("label", {
			name,
			role,
			organization,
			context: context.join("+")
		});
	}, [name, role, organization, t]);


	return (
		<>
			<ul className="kko-action-list" aria-label={label}>
				<li>
					<NavLink
						className="kko-action-list-item"
						to="/account/profile"
						aria-label={t("menuitems.profile")}
					>
						<div
							className="kko-person-summary"
							aria-hidden="true"
						>
							<Avatar image={photo} name={name} />
							<div className="kko-person-summary__content">
								<div className="kko-person-summary__name">
									{name}
								</div>
								<div className="kko-person-summary__title">
									{role}
									{!!(role && organization) && <br />}
									{organization}
								</div>
							</div>
						</div>
						<Icon icon="arrow-right" />
					</NavLink>
				</li>
				<li>
					<NavLink className="kko-action-list-item" to="/account/settings">
						{t("menuitems.settings")}
					</NavLink>
				</li>
			</ul>
		</>
	)
}