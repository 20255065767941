import { FC, useCallback } from "react";
import { useAriaMenu } from "src/common/hooks";
import { Icon } from "src/modules/shared/components/Icon";
import { Button, ButtonIcon } from "src/modules/shared/components/buttons/Button";

type MenuAlignment = "left" | "right";
type MenuItemAppearance = "normal" | "danger";

export type ChatContextMenuItem<C extends string = string> = {
	inline?: boolean;
	appearance?: MenuItemAppearance;
	icon?: string;
	label: string;
	disabled?: boolean;
	command: C;
	active?: boolean;

};

type ChatContextMenuProps = {
	align?: MenuAlignment;
	icon?: string | ButtonIcon;
	label: string;
	menuItems: readonly ChatContextMenuItem[];
	onCommand?: (command: string) => void
}

export const ChatContextMenu: FC<ChatContextMenuProps> = ({
	align = "right",
	icon = "menu-dots",
	label,
	menuItems,
	onCommand
}) => {
	const onSelectedHandler = useCallback((menuItem: ChatContextMenuItem | undefined) => {
		if (menuItem?.disabled) return false;

		const command = menuItem?.command;
		if (!command) return;

		onCommand?.(command);
	}, [onCommand]);

	const {
		state,
		menuButtonProps,
		menuProps,
		getMenuItemProps,
		isActiveMenuItem
	} = useAriaMenu({
		menuItems,
		is: (a, b) => a?.command === b?.command,
		onSelected: onSelectedHandler
	});

	return (
		<div
			className="kko-dropdown-list"
			data-align={align}
			data-expanded={state.expanded ? "true" : "false"}
		>
			<Button
				{...menuButtonProps}
				className="kko-dropdown-list__trigger"
				priority="tertiary"
				icon={icon}
				label={label}
			/>
			<div className="kko-dropdown-list__menu-container">
				<ul
					{...menuProps}
					className="kko-dropdown-list__menu"
					aria-label={label}
				>
					{state.menuItems.some(menuItems => menuItems.inline) && (
						<ul className="kko-dropdown-list__submenu" role="none">
							<li role="none">
								{state.menuItems.filter(menuItems => menuItems.inline).map(menuItem => (
									<span
										{...getMenuItemProps(menuItem)}
										key={menuItem.command}
										className="kko-action-list-item"
										aria-disabled={menuItem.disabled}
										data-active={menuItem.active || isActiveMenuItem(menuItem) ? "" : undefined}
										data-appearance={menuItem.appearance === "normal" ? undefined : menuItem.appearance}
									>
										{menuItem.command}
										<span>{menuItem.label}</span>
									</span>
								))}
							</li>
						</ul>
					)}
					{state.menuItems.filter(menuItem => !menuItem.inline).map(menuItem => (
						<li
							key={menuItem.command}
							role="group"
						>
							<span
								{...getMenuItemProps(menuItem)}
								className="kko-action-list-item"
								aria-disabled={menuItem.disabled}
								data-active={isActiveMenuItem(menuItem) ? "" : undefined}
								data-appearance={menuItem.appearance === "normal" ? undefined : menuItem.appearance}
							>
								{menuItem.icon && <Icon
									icon={menuItem.icon}
									size="medium"
								/>}
								<span>{menuItem.label}</span>
							</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
};