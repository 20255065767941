/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ImageSize } from '../models/ImageSize';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProfilePhotosService {

    /**
     * Retrieves the image for the specified account.
     * @param accountId The account id for which the profile picture should be returned.
     * @param acceptLanguage The culture code used for the request.
     * @param imageSize The size of the image to be returned.
     * @returns binary Success
     * @throws ApiError
     */
    public static getV1AccountsProfilePhoto(
        accountId: string,
        acceptLanguage: string,
        imageSize?: ImageSize,
    ): CancelablePromise<Blob> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/v1/accounts/{accountId}/profile/photo',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            query: {
                'imageSize': imageSize,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Uploads the profile picture for the logged in user.
     * @param accountId The account id for which the profile picture should be uploaded.
     * @param acceptLanguage The culture code used for the request.
     * @param requestBody The binary picture payload.
     * @returns any Created
     * @throws ApiError
     */
    public static putV1AccountsProfilePhoto(
        accountId: string,
        acceptLanguage: string,
        requestBody?: Blob,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/v1/accounts/{accountId}/profile/photo',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            body: requestBody,
            mediaType: 'image/jpeg',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

    /**
     * Deletes a profile picture.
     * @param accountId
     * @param acceptLanguage The culture code used for the request.
     * @returns any Accepted
     * @throws ApiError
     */
    public static deleteV1AccountsProfilePhoto(
        accountId: string,
        acceptLanguage: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/v1/accounts/{accountId}/profile/photo',
            path: {
                'accountId': accountId,
            },
            headers: {
                'Accept-Language': acceptLanguage,
            },
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                500: `Server Error`,
            },
        });
    }

}
