import { skipToken } from "@reduxjs/toolkit/query/react";
import {
	ChatThreadClientProvider,
	CompositeLocale,
	FluentThemeProvider,
	useChatClient
} from "@azure/communication-react";
import { PartialTheme, Theme } from "@fluentui/react";
import { useMemo, FC, useCallback } from "react";
import { useAppSelector } from "src/common/redux";
import { ProfileAvatar } from "src/modules/shared/components/avatars";
import { useGetChatByIdQuery, useGetCommunicationAccessTokenQuery, useGetCommunicationUserIdentityQuery } from "src/apis/chat";
import { ChatComponents } from "./ChatComponents";

export type ContainerProps = {
	/** UserIdentifier is of type CommunicationUserIdentifier see below how to construct it from a string input */
	userIdentifier: string;
	token: string;
	displayName: string;
	imageUrl: string | undefined;
	endpointUrl: string;
	threadId: string;
	fluentTheme?: PartialTheme | Theme;
	rtl?: boolean;
	errorBar?: boolean;
	participants?: boolean;
	topic?: boolean;
	locale?: CompositeLocale;
	formFactor?: "desktop" | "mobile";
};

export const ChatContainer: FC<ContainerProps> = (props: ContainerProps) => {

	const focusedThreadId = useAppSelector(state => state.chat.focusedThreadId);
	const { data: userIdentityResponse } = useGetCommunicationUserIdentityQuery();
	const { data: chatAccessTokenResponse } = useGetCommunicationAccessTokenQuery(userIdentityResponse?.communicationUserIdentity ? {
		userIdentity: userIdentityResponse.communicationUserIdentity
	} : skipToken);
	const { data: chatThreadResponse } = useGetChatByIdQuery(focusedThreadId && chatAccessTokenResponse?.communicationAccessToken ? {
		chatId: focusedThreadId,
		"X-Chat-Access-Token": chatAccessTokenResponse?.communicationAccessToken
	} : skipToken);

	const accountIdByAcsId = useCallback((acsId: string) => {
		if (!chatThreadResponse?.chatThread?.participants?.length) {
			return undefined;
		}
		return chatThreadResponse.chatThread.participants.find(p => p.chatParticipantId === acsId)?.accountId;
	}, [chatThreadResponse?.chatThread?.participants]);

	const onFetchAvatar = (userId: string | undefined) => {
		if (!userId) {
			return <></>;
		}
		const accountId = accountIdByAcsId(userId);
		if (!accountId) {
			return <></>;
		}
		return <ProfileAvatar accountId={accountId} />
	}

	const statefulChatClient = useChatClient();

	const chatThreadClient = useMemo(() => {
		if (!statefulChatClient) {
			return undefined;
		}
		const threadClient = statefulChatClient.getChatThreadClient(props.threadId);
		return threadClient;
	}, [props.threadId, statefulChatClient]);

	return (chatThreadClient && <div className="kko-chat-conversation">
		<FluentThemeProvider>
			<ChatThreadClientProvider chatThreadClient={chatThreadClient}>
				<ChatComponents
					currentUserIdentifier={props.userIdentifier}
					onFetchAvatar={onFetchAvatar}
				/>
			</ChatThreadClientProvider>
		</FluentThemeProvider>
	</div>
	);
};
