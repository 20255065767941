import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { i18n } from "src/common/i18n";
import { createAppAsyncThunk } from "src/common/redux";
import {
	ProfilesService,
	ProfilePhotosService,
	UpdateProfileRequestModel,
	GetProfileByAccountIdResponseModel
} from "src/open-api/networkService";
import { ProfileState } from "../../modules/shared/types";
import { showLoading as showGlobalLoading, hideLoading as hideGlobalLoading, addToast, ToastType } from "./feedbackSlice";

const initialState: ProfileState = {
	accountName: undefined,
	profile: undefined,
	isLoading: false,
	image: undefined,
	otherProfiles: {},
	otherImages: {}
}

type FetchProfileArgs = {
	id?: string
};

export const fetchProfile = createAppAsyncThunk(
	"profile/fetchProfile",
	async (arg: FetchProfileArgs, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showGlobalLoading());

			const profile = arg.id
				? await ProfilesService.getProfileByAccountId(arg.id, locale)
				: await ProfilesService.getProfileByCurrentUserAccountId(locale);

			dispatch(arg.id
				? setOtherProfile({ profile, id: arg.id })
				: dispatch(setProfile(profile))
			);

			// Type information on binary responses is wrong and makes a mess of things.
			// Stripping it is the cleanest way to work around it. It goes directly into `URL.createObjectURL` anyway.
			// NOTE:
			// We use profile.accountId here, since for the getProfileByCurrentUserAccountId call we didn't pass an arg.id
			if (profile) {
				const image: any = await ProfilePhotosService.getV1AccountsProfilePhoto(profile.accountId, locale, "Large");
				const imageUrl = URL.createObjectURL(image);

				dispatch(arg.id
					? setOtherImage({ profileImage: imageUrl, id: arg.id })
					: setImage(imageUrl)
				);
			}

			return profile;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});


type FetchProfilesArgs = {
	ids: string[]
};

export const fetchProfiles = createAppAsyncThunk(
	"profile/fetchProfiles",
	async (arg: FetchProfilesArgs | undefined, { dispatch }) => {
		const locale = i18n.language;

		try {
			const accountIds = arg?.ids ?? [];
			const profiles = await Promise.all(accountIds.map(accountId =>
				ProfilesService.getProfileByAccountId(accountId, locale)
			));

			for (const profile of profiles) {
				dispatch(setOtherProfile({ profile, id: profile.accountId }))

				// fetch profile images without explicitly waiting for them
				if (profile.photoInfo == null) continue;

				ProfilePhotosService.getV1AccountsProfilePhoto(profile.accountId, locale, "Small")
					.then((imageBlob: any) => {
						const imageUrl = window.URL.createObjectURL(imageBlob);
						dispatch(setOtherImage({ profileImage: imageUrl, id: profile.accountId! }));
					})
					.catch((error) => { console.error("Error for image fetch", error) });
			}

		} finally {
		}
	});

type SaveProfileArgs = {
	accountId: string,
	profile: UpdateProfileRequestModel,
	isImageChanged: boolean,
	imageValue: Blob
};

export const saveProfile = createAppAsyncThunk(
	"profile/saveProfile",
	async ({ accountId, profile, isImageChanged, imageValue }: SaveProfileArgs, { dispatch }) => {
		const locale = i18n.language;

		try {
			dispatch(showLoading());

			let savedProfile = await ProfilesService.updateProfile(accountId, locale, profile);

			dispatch(setProfile(savedProfile));

			if (isImageChanged) {
				if (!imageValue) {
					await ProfilePhotosService.deleteV1AccountsProfilePhoto(accountId, locale);
					dispatch(setImage(""));
				} else {
					// image was changed
					await ProfilePhotosService.putV1AccountsProfilePhoto(accountId, locale, imageValue);
					const imageUrl = window.URL.createObjectURL(imageValue);
					dispatch(setImage(imageUrl));
				}

				savedProfile = await ProfilesService.getProfileByAccountId(accountId, locale);
				dispatch(setProfile(savedProfile));
			}

			dispatch(addToast({
				titleKey: "kko:general.feedback.profile-save-title",
				contentKey: "kko:general.feedback.profile-save-success",
				type: ToastType.Success,
				icon: "check"
			}));

			return savedProfile;

		} catch (error) {
			dispatch(addToast({
				titleKey: "kko:general.feedback.profile-save-title",
				contentKey: "kko:general.feedback.profile-save-error",
				type: ToastType.Error
			}));

		} finally {
			dispatch(hideLoading());
		}

		return profile;
	});

export const profileSlice = createSlice({
	name: "profile",
	initialState,
	reducers: {
		setProfile: (state, action: PayloadAction<GetProfileByAccountIdResponseModel | undefined>) => {
			state.profile = action.payload;
		},
		setOtherProfile: (state, action: PayloadAction<{ profile: GetProfileByAccountIdResponseModel | undefined, id: string }>) => {
			state.otherProfiles[action.payload.id] = action.payload.profile;
		},
		setAccountName: (state, action: PayloadAction<string | undefined>) => {
			state.accountName = action.payload;
		},
		setImage: (state, action: PayloadAction<string | undefined>) => {
			state.image = action.payload;
		},
		setOtherImage: (state, action: PayloadAction<{ profileImage: string | undefined, id: string }>) => {
			state.otherImages[action.payload.id] = action.payload.profileImage;
		},
		showLoading: state => {
			state.isLoading = true;
		},
		hideLoading: state => {
			state.isLoading = false;
		},
	}
});

export const { setProfile, showLoading, hideLoading, setAccountName, setImage, setOtherProfile, setOtherImage } = profileSlice.actions;
export default profileSlice.reducer;