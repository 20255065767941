import { MouseEventHandler, forwardRef, useCallback } from "react";
import { RelativeRoutingType, To, useHref, useLinkClickHandler } from "react-router-dom";
import { AnchorButtonProps, Button } from "./Button"


type NavButtonProps = Omit<AnchorButtonProps, "href"> & {
	preventScrollReset?: boolean;
	relative?: RelativeRoutingType;
	replace?: boolean;
	state?: any;
	to: To;
};

export const NavButton = forwardRef<HTMLAnchorElement, NavButtonProps>(({
	children,
	disabled,
	preventScrollReset,
	relative,
	replace = false,
	state,
	target,
	to,
	onClick,
	...props
}, ref) => {
	const href = useHref(to, { relative });
	const onRoute = useLinkClickHandler(to, {
		preventScrollReset,
		relative,
		replace,
		state,
		target
	});

	const onClickHandler = useCallback<MouseEventHandler<HTMLAnchorElement>>(event => {
		try {
			onClick?.(event);
		} finally {
			if (!event.defaultPrevented && !disabled) { onRoute(event) }
		}
	}, [disabled, onClick, onRoute]);

	return <Button
		ref={ref}
		href={href}
		disabled={disabled}
		target={target}
		onClick={onClickHandler}
		{...props as AnchorButtonProps}
	>
		{children}
	</Button>
});