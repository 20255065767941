import type { DependencyList } from "react";
import { useEffect } from "react";

/**
 * A potentially asynchronous effect callback function that receives 
 * an {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal | AbortSignal}
 * to subscribe cleanup logic to run.
 */
export type AsyncEffectCallback = (signal: AbortSignal) => void | Promise<void>;

/**
 * Call `useAyncEffect` at the top level of your component to declare
 * a function that contains imperative, possibly effectful code to be run
 * asynchronously.
 * @param effect
 * Imperative, possibly asynchronous function that receives
 * an {@linkcode https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal | AbortSignal}
 * to allow subscribing cleanup logic and cooperative cancellation of effectful code
 * that is still in-progress.
 * 
 * After each re-render, React will first abort the signal, allowing subscribed cleanup
 * logic to run with the old values, before running the effect with the new values.
 * React will also abort the signal when the component unmounts.
 * 
 * **⚠️ CAUTION:**  
 * Take care to check the signal's state after each `await`-ed operation inside
 * your asynchronous function and halt further execution accordingly if the signal
 * was aborted. Failing to do so may result in accidentally applying effectful code
 * based on outdated component state.
 * 
 * @param deps
 * If present, `effect` will only activate if the values in the list change.  
 * If not present, `effect` will activate on each re-render.
 * 
 * To run `effect` only on the first render after mounting your component,
 * pass an empty array.
 * 
 * @example
 * The following fetches some data on mount from somwhere, passing
 * the signal to e.g. cooperatively cancel a request. It only sets
 * that data if the effectful code wasn't cancelled yet by the
 * component unmounting.
 * ```tsx
 * const Component: FC = () => {
 *   const [data, setData] = useState<Data>();
 *
 *   useAsyncEffect(signal => {
 *     const data = await fetchSomeStuff({ ... }, signal);
 *     if (signal.aborted) return;
 * 
 *     setData(data);
 *   }, []);
 * };
 * ```
 * 
 * @example
 * The following asynchronously subscribes a handler dependent on an
 * `id` value and awaits for an unsubscribe function to be returned.
 * It unsubscribe immediately when the signal was already aborted by
 * the time the subscription finished registering, and otherwise
 * listens for the signal to be aborted.
 * ```tsx
 * const Component: FC = () => {
 * 
 *   useAsyncEffect(signal => {
 *     const unsubscribe = await subscribeAsync(() => {
 *       if (signal.aborted) return;
 *       // ... handler code which uses `id` ...
 *     }, signal);
 * 
 *     if (signal.aborted) {
 *       unsubscribe();
 *       return;
 *     }
 * 
 *     signal.addEventListener("abort", () => {
 *       unsubscribe();
 *     }, { once : true });
 *   }, [id]);
 * };
 * ```
 */
export function useAsyncEffect(
	effect: AsyncEffectCallback,
	deps?: DependencyList
): void {
	useEffect(() => {
		const controller = new AbortController();
		const result = effect(controller.signal);

		// If the effect is in fact asynchronous and returns a Promise;
		// and it throws an error, then the following catches it and logs it,
		// preventing an Uncaught Error.
		Promise
			.resolve(result)
			.catch(console.error);

		return () => { controller.abort() };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, deps);
}