import classNames from "classnames";
import { ComponentPropsWithoutRef, ForwardedRef, forwardRef } from "react";
import { Icon } from "../Icon";
import { ButtonPriority } from "./Button";

export type ActionButtonIcon =
	| { only: string, center?: never }
	| { only?: never, center: string };

type Props = {
	/**
	 * Sets the icon for the action button.
	 */
	icon: string | ActionButtonIcon;

	/**
	 * Sets the label text for the action button.
	 */
	label: string;

	/**
	 * Configures the visual priority level of the button.  
	 * Can be one of `"primary"`; `"secondary"`; or `"tertiary"`.  
	 * Defaults to `"secondary"` when not specified.
	 */
	priority?: ButtonPriority;
};

type ActionButtonProps = Props
	& Omit<ComponentPropsWithoutRef<"button">, "children" | "slot">;

type AnchorActionButtonProps = Props
	& Omit<ComponentPropsWithoutRef<"a">, "children" | "slot">
	& Pick<ComponentPropsWithoutRef<"button">, "disabled">;

const ActionButton = ({
	className,
	icon,
	label,
	priority = "secondary",
	...props
}: ActionButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
	// Counter to regular HTML buttons it is more convenient
	// to default to the "button" type instead of the "submit"
	// type.
	props.type ??= "button";

	const iconObj = typeof icon === "string"
		? { center: icon } satisfies ActionButtonIcon
		: icon;

	if (iconObj.only) {
		props["aria-label"] ??= label;
		props["title"] ??= label;
	}

	className = classNames("kko-button kko-button--action", className, {
		"kko-button--primary": priority === "primary",
		"kko-button--secondary": priority === "secondary",
		"kko-button--tertiary": priority === "tertiary",
		"kko-button--icon-only": !!iconObj.only
	});

	return <button
		ref={ref}
		className={className}
		{...props}
	>
		<Icon icon={iconObj.center ?? iconObj.only} />
		{!iconObj.only && <span className="kko-button__text">{label}</span>}
	</button>
};

const AnchorActionButton = ({
	className,
	disabled,
	icon,
	label,
	priority = "secondary",
	...props
}: AnchorActionButtonProps, ref: ForwardedRef<HTMLAnchorElement>) => {
	// Anchor elements can't normally be disabled like buttons can.
	// But ARIA does allow them to be announced as disabled with the "aria-disabled" attribute.
	// To then actually disable their link behavior, we have to remove the "href" attribute.
	// However, doing so also removes the intrinsic "link" role and we have to add it explicitly.
	if (disabled) {
		props["aria-disabled"] = "true";
		props.href = undefined;
		props.role ??= "link";

		if (!/\blink\b/i.test(props.role)) {
			props.role = `${props.role} link`.trimStart();
		}
	}

	const iconObj = typeof icon === "string"
		? { center: icon } satisfies ActionButtonIcon
		: icon;

	if (iconObj.only) {
		props["aria-label"] ??= label;
		props["title"] ??= label;
	}

	className = classNames("kko-button kko-button--action", className, {
		"kko-button--primary": priority === "primary",
		"kko-button--secondary": priority === "secondary",
		"kko-button--tertiary": priority === "tertiary",
		"kko-button--icon-only": !!iconObj.only
	});

	return <a
		ref={ref}
		className={className}
		{...props}
	>
		<Icon icon={iconObj.center ?? iconObj.only} />
		{!iconObj.only && <span className="kko-button__text">{label}</span>}
	</a>
};

const Overload = forwardRef<
	HTMLAnchorElement | HTMLButtonElement,
	AnchorActionButtonProps | ActionButtonProps
>((props, ref) => isAnchor(props)
	? AnchorActionButton(props, ref as ForwardedRef<HTMLAnchorElement>)
	: ActionButton(props, ref as ForwardedRef<HTMLButtonElement>)
);
Overload.displayName = "ActionButton";

export { Overload as ActionButton };

function isAnchor(value: ActionButtonProps | AnchorActionButtonProps): value is AnchorActionButtonProps {
	return "href" in value && !!value.href;
};
