import { i18nLanguageCodes } from "./constants";
export type DateTimeFormatOptions = Pick<Intl.DateTimeFormatOptions, "dateStyle" | "timeStyle">;

export const formatFileSizeShort = (fileSize: number, languageCode: string): string => {
	const formatInfo = getFileSizeFormatInfo(fileSize, getFormatCulture(languageCode), "short");
	return formatInfo.formatter.format(formatInfo.value);
}

export const formatFileSizeLong = (fileSize: number, languageCode: string): string => {
	const formatInfo = getFileSizeFormatInfo(fileSize, getFormatCulture(languageCode), "long");
	return formatInfo.formatter.format(formatInfo.value);
}

export const formatDatetimeAsParts = (datetime: Date, languageCode: string, options: DateTimeFormatOptions): Intl.DateTimeFormatPart[] => {
	const formatter = getDatetimeFormatter(getFormatCulture(languageCode), options);
	return formatter.formatToParts(datetime);
}

export const formatDateAsParts = (date: Date, languageCode: string): Intl.DateTimeFormatPart[] => {
	const formatter = getDateFormatter(getFormatCulture(languageCode));
	return formatter.formatToParts(date);
}

export const formatRangeDatetimeAsParts = (start: Date, end: Date, languageCode: string, options: DateTimeFormatOptions): Intl.DateTimeFormatPart[] => {
	const formatter = getDatetimeFormatter(getFormatCulture(languageCode), options);
	return formatter.formatRangeToParts(start, end);
}

const getFormatCulture = (culture: string): string => {
	switch (culture) {
		case i18nLanguageCodes.English:
			return "en-NL";
		case i18nLanguageCodes.Dutch:
			return "nl-NL";
		default:
			return "en-NL";
	}
}

const dateTimeFormattersCache = new Map<string, Intl.DateTimeFormat>();
const dateFormatterCache = new Map<string, Intl.DateTimeFormat>();
const fileSizeFormattersCache = new Map<string, Intl.NumberFormat>();

const getDatetimeFormatter = (culture: string, { dateStyle = "long", timeStyle = "long" }: DateTimeFormatOptions) => {

	const cacheKey = `${culture}-${dateStyle}-${timeStyle}`;
	let formatter = dateTimeFormattersCache.get(cacheKey);
	if (!formatter) {
		formatter = new Intl.DateTimeFormat(culture, {
			// dateStyle: includeWeekDay ? "full" : "long",
			// timeStyle: "long",
			dateStyle: dateStyle,
			timeStyle: timeStyle,
			hour12: false
		});
		dateTimeFormattersCache.set(cacheKey, formatter);
	}

	return formatter;
}

// create a new cache for ProfileDate
const getDateFormatter = (culture: string) => {

	let formatter = dateFormatterCache.get(culture);
	if (!formatter) {
		formatter = new Intl.DateTimeFormat(culture, {
			day: "2-digit",
			month: "2-digit",
			year: "numeric",
		});
		dateFormatterCache.set(culture, formatter);
	}

	return formatter;
}

const getFileSizeFormatInfo = (fileSize: number, culture: string, unitDisplay: "short" | "long"): { value: number, formatter: Intl.NumberFormat } => {
	// The thresholds are 1000 multiples and not 1024 multiples because the Intl formatter sees them
	const kilobyteThreshold = 1000;
	const megabyteThreshold = 1000 * 1000;
	const { unit, value } = ((value) => {
		if (value < kilobyteThreshold) return { unit: "byte", value };
		if (value < megabyteThreshold) return { unit: "kilobyte", value: value / kilobyteThreshold };
		return { unit: "megabyte", value: value / megabyteThreshold };
	})(fileSize);

	const cacheKey = `${culture}-${unitDisplay}-${unit}`;
	let formatter = fileSizeFormattersCache.get(cacheKey);
	if (!formatter) {
		formatter = new Intl.NumberFormat(culture, {
			style: "unit",
			unit,
			unitDisplay,
			maximumFractionDigits: 0,
			minimumFractionDigits: 0
		});
		fileSizeFormattersCache.set(cacheKey, formatter);
	}
	return {
		formatter,
		value
	};
}