import { FC, useCallback } from "react";
import { useAriaMenu } from "src/common/hooks";
import { Button, ButtonIcon } from "./buttons";
import { Icon } from "./Icon";

type MenuAlignment = "left" | "right";
type MenuItemAppearance = "normal" | "danger";

export type ContextMenuItem<C extends string = string> = {
	appearance?: MenuItemAppearance;
	icon?: string;
	label: string;
	disabled?: boolean;
	command: C;
};

type ContextMenuProps = {
	align?: MenuAlignment;
	icon?: string | ButtonIcon;
	label: string;
	menuItems: readonly ContextMenuItem[];
	onCommand?: (command: string) => void
}

export const ContextMenu: FC<ContextMenuProps> = ({
	align = "right",
	icon = "menu-dots",
	label,
	menuItems,
	onCommand
}) => {
	const onSelectedHandler = useCallback((menuItem: ContextMenuItem | undefined) => {
		if (menuItem?.disabled) return false;

		const command = menuItem?.command;
		if (!command) return;

		onCommand?.(command);
	}, [onCommand]);

	const {
		state,
		menuButtonProps,
		menuProps,
		getMenuItemProps,
		isActiveMenuItem
	} = useAriaMenu({
		menuItems,
		is: (a, b) => a?.command === b?.command,
		onSelected: onSelectedHandler
	});

	return (
		<div
			className="kko-dropdown-list"
			data-align={align}
			data-expanded={state.expanded ? "true" : "false"}
		>
			<Button
				{...menuButtonProps}
				className="kko-dropdown-list__trigger"
				priority="tertiary"
				icon={icon}
				label={label}
			/>
			<div className="kko-dropdown-list__menu-container">
				<ul
					{...menuProps}
					className="kko-dropdown-list__menu"
					aria-label={label}
				>
					{state.menuItems.map(menuItem => (
						<li
							key={menuItem.command}
							role="none"
						>
							<span
								{...getMenuItemProps(menuItem)}
								className="kko-action-list-item"
								aria-disabled={menuItem.disabled}
								data-active={isActiveMenuItem(menuItem) ? "" : undefined}
								data-appearance={menuItem.appearance === "normal" ? undefined : menuItem.appearance}
							>
								{menuItem.icon && <Icon icon={menuItem.icon} size="small" />}
								<span>{menuItem.label}</span>
							</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
};