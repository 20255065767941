import type { FC } from "react";
import { useTranslation } from "react-i18next";
import { Toast, ToastContainer } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "src/common/redux";
import { removeToast, ToastItemData, ToastType } from "src/redux/slices/feedbackSlice";



export const OperationFeedback: FC = () => {
	const { t } = useTranslation();
	const dispatch = useAppDispatch();
	const toasts = useAppSelector(state => state.feedback.toasts);

	const toastClose = (toast: ToastItemData) => {
		dispatch(removeToast(toast));
	}

	return <ToastContainer
		className="kko-toast-container"
		position="bottom-end"
	>
		{toasts.map(toast => (
			<Toast
				key={toast.key}
				show
				animation
				autohide={true}
				delay={7000}
				bg={toast.type === ToastType.Error ? "danger" : "success"}
				onClose={() => toastClose(toast)}
			>
				<Toast.Header closeButton={true}>
					<div className="kko-toast__heading">
						{(toast.icon || toast.type === ToastType.Error) &&
							<i className="kko-icon" data-icon={toast.icon}></i>
						}
						<span>{t(toast.titleKey)}</span>
					</div>
				</Toast.Header>
				<Toast.Body>
					<div className="kko-toast__content">
						{t(toast.contentKey, { context: toast.contentFormatData })}
					</div>
					<div className="kko-toast__footer">
						{toast.actions?.map(action => (
							<div className="toast-footer">
								<button className="kko-button kko-button--tertiary">
									<span className="kko-button__text">{action.name}</span>
								</button>
							</div>
						))}
					</div>
				</Toast.Body>
			</Toast>))}
	</ToastContainer>;
}