import { api } from "./base";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getV1DocumentsByDocumentId: build.query<
      GetV1DocumentsByDocumentIdApiResponse,
      GetV1DocumentsByDocumentIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}`,
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
        },
      }),
    }),
    deleteV1DocumentsByDocumentId: build.mutation<
      DeleteV1DocumentsByDocumentIdApiResponse,
      DeleteV1DocumentsByDocumentIdArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}`,
        method: "DELETE",
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
        },
      }),
    }),
    getV1DocumentsByDocumentIdContent: build.query<
      GetV1DocumentsByDocumentIdContentApiResponse,
      GetV1DocumentsByDocumentIdContentArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/content`,
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
          version: queryArg.version,
        },
      }),
    }),
    getV1Documents: build.query<GetV1DocumentsApiResponse, GetV1DocumentsArgs>({
      query: (queryArg) => ({
        url: `/v1/documents`,
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
          documentStatus: queryArg.documentStatus,
        },
      }),
    }),
    postV1Documents: build.mutation<
      PostV1DocumentsApiResponse,
      PostV1DocumentsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents`,
        method: "POST",
        body: queryArg.body,
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
          title: queryArg.title,
          permission: queryArg.permission,
        },
      }),
    }),
    getV1DocumentsByDocumentIdVersions: build.query<
      GetV1DocumentsByDocumentIdVersionsApiResponse,
      GetV1DocumentsByDocumentIdVersionsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/versions`,
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
        },
      }),
    }),
    restoreFromTrash: build.mutation<
      RestoreFromTrashApiResponse,
      RestoreFromTrashArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/restore`,
        method: "POST",
        body: queryArg.restoreDocumentFromTrashRequestModel,
      }),
    }),
    restoreToVersion: build.mutation<
      RestoreToVersionApiResponse,
      RestoreToVersionArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/versions/${queryArg.version}/restore`,
        method: "POST",
        body: queryArg.restoreDocumentToVersionRequestModel,
      }),
    }),
    patchV1DocumentsByDocumentIdName: build.mutation<
      PatchV1DocumentsByDocumentIdNameApiResponse,
      PatchV1DocumentsByDocumentIdNameArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/name`,
        method: "PATCH",
        body: queryArg.renameDocumentRequestModel,
      }),
    }),
    getV1DocumentsExists: build.query<
      GetV1DocumentsExistsApiResponse,
      GetV1DocumentsExistsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/exists`,
        params: {
          title: queryArg.title,
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
        },
      }),
    }),
    getV1DocumentsByDocumentIdAccessrights: build.query<
      GetV1DocumentsByDocumentIdAccessrightsApiResponse,
      GetV1DocumentsByDocumentIdAccessrightsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/accessrights`,
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
        },
      }),
    }),
    patchV1DocumentsByDocumentIdAccessrights: build.mutation<
      PatchV1DocumentsByDocumentIdAccessrightsApiResponse,
      PatchV1DocumentsByDocumentIdAccessrightsArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/accessrights`,
        method: "PATCH",
        body: queryArg.updateAccessRightsForDocumentModel,
      }),
    }),
    patchV1DocumentsByDocumentIdPrivate: build.mutation<
      PatchV1DocumentsByDocumentIdPrivateApiResponse,
      PatchV1DocumentsByDocumentIdPrivateArgs
    >({
      query: (queryArg) => ({
        url: `/v1/documents/${queryArg.documentId}/private`,
        method: "PATCH",
        params: {
          objectId: queryArg.objectId,
          objectType: queryArg.objectType,
        },
      }),
    }),
    getV1InternalDocumentsByDocumentIdDisplayInformation: build.query<
      GetV1InternalDocumentsByDocumentIdDisplayInformationApiResponse,
      GetV1InternalDocumentsByDocumentIdDisplayInformationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/internal/documents/${queryArg.documentId}/display-information`,
      }),
    }),
    getV1InternalDocumentsByDocumentIdObjectInformation: build.query<
      GetV1InternalDocumentsByDocumentIdObjectInformationApiResponse,
      GetV1InternalDocumentsByDocumentIdObjectInformationArgs
    >({
      query: (queryArg) => ({
        url: `/v1/internal/documents/${queryArg.documentId}/object-information`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetV1DocumentsByDocumentIdApiResponse =
  /** status 200 Success */ GetDocumentResponse;
export type GetV1DocumentsByDocumentIdArgs = {
  /** The identifier of the document. */
  documentId: string;
  /** The object id to which the document belongs to. */
  objectId: string;
  /** The object type to which the document belongs to. */
  objectType: ObjectTypeModel;
};
export type DeleteV1DocumentsByDocumentIdApiResponse =
  /** status 202 Accepted */ DeleteDocumentReponse | /** status 204 No Content */ void;
export type DeleteV1DocumentsByDocumentIdArgs = {
  /** The id of the document that is being deleted. */
  documentId: string;
  /** The id of the object for which the documents should be retrieved. */
  objectId: string;
  /** The type of the object for which the documents should be retrieved. */
  objectType?: ObjectTypeModel;
};
export type GetV1DocumentsByDocumentIdContentApiResponse =
  /** status 200 Success */ Blob;
export type GetV1DocumentsByDocumentIdContentArgs = {
  /** The identifier of the document. */
  documentId: string;
  /** The object id to which the document belongs to. */
  objectId: string;
  /** The object type to which the document belongs to. */
  objectType: ObjectTypeModel;
  /** The version of the document that needs to be retrieved. */
  version?: number;
};
export type GetV1DocumentsApiResponse =
  /** status 200 Success */ GetDocumentsResponse;
export type GetV1DocumentsArgs = {
  /** The id of the object for which the documents should be retrieved. */
  objectId: string;
  /** The type of the object for which the documents should be retrieved. */
  objectType: ObjectTypeModel;
  /** The status of the documents that should be retrieved */
  documentStatus?: DocumentStatusModel;
};
export type PostV1DocumentsApiResponse =
  /** status 201 Created */ CreateDocumentResponse;
export type PostV1DocumentsArgs = {
  /** The id of the object for which the document is created. */
  objectId: string;
  /** The object type for which the document is created. */
  objectType: ObjectTypeModel;
  /** The title of the document. */
  title: string;
  /** The initial permissions that will be assigned to the document. */
  permission?: InitialPermissionModel;
  body: {
    file?: Blob;
  };
};
export type GetV1DocumentsByDocumentIdVersionsApiResponse =
  /** status 200 Success */ GetVersionsResponseModel;
export type GetV1DocumentsByDocumentIdVersionsArgs = {
  /** The ID of the document for which versions are retrieved. */
  documentId: string;
  /** The ID of the related object associated with the document. */
  objectId: string;
  /** The type of the related object. */
  objectType: ObjectTypeModel;
};
export type RestoreFromTrashApiResponse = /** status 201 Created */ void;
export type RestoreFromTrashArgs = {
  /** The ID of the document to be restored from the trash. */
  documentId: string;
  /** The request model containing the restoration details. */
  restoreDocumentFromTrashRequestModel: RestoreDocumentFromTrashRequestModel;
};
export type RestoreToVersionApiResponse = /** status 201 Created */ void;
export type RestoreToVersionArgs = {
  /** The ID of the document to be restored to a specific version. */
  documentId: string;
  /** The version number to which the document should be restored. */
  version: number;
  /** The request model containing the restoration details. */
  restoreDocumentToVersionRequestModel: RestoreDocumentToVersionRequestModel;
};
export type PatchV1DocumentsByDocumentIdNameApiResponse =
  /** status 202 Accepted */ void | /** status 204 No Content */ void;
export type PatchV1DocumentsByDocumentIdNameArgs = {
  documentId: string;
  /** The object that provides information about the document that needs to be renamed. */
  renameDocumentRequestModel: RenameDocumentRequestModel;
};
export type GetV1DocumentsExistsApiResponse =
  /** status 200 Success */ DocumentExistsResponse;
export type GetV1DocumentsExistsArgs = {
  /** The title of the document that is being checked. */
  title: string;
  /** The id of the object for which the documents should be retrieved. */
  objectId: string;
  /** The type of the object for which the documents should be retrieved. */
  objectType: ObjectTypeModel;
};
export type GetV1DocumentsByDocumentIdAccessrightsApiResponse =
  /** status 200 Success */ GetAccessRightsResponseModel;
export type GetV1DocumentsByDocumentIdAccessrightsArgs = {
  /** The id of the document for which the access rights are retrieved. */
  documentId: string;
  /** The id of the object to which the document belongs to. */
  objectId: string;
  /** The type of the object to which the document belongs to. */
  objectType?: ObjectTypeModel;
};
export type PatchV1DocumentsByDocumentIdAccessrightsApiResponse =
  /** status 202 Accepted */ void | /** status 204 No Content */ void;
export type PatchV1DocumentsByDocumentIdAccessrightsArgs = {
  /** The id of the document for which the access rights are updated. */
  documentId: string;
  /** The object that provides details about the access rights that need to be updated. */
  updateAccessRightsForDocumentModel: UpdateAccessRightsForDocumentModel;
};
export type PatchV1DocumentsByDocumentIdPrivateApiResponse =
  /** status 202 Accepted */ TogglePrivateStatusResponse | /** status 204 No Content */ void;
export type PatchV1DocumentsByDocumentIdPrivateArgs = {
  /** The id of the document for which private flag is being toggled. */
  documentId: string;
  /** The id of the object to which the document belongs to. */
  objectId: string;
  /** The type of the object to which the document belongs to. */
  objectType: ObjectTypeModel;
};
export type GetV1InternalDocumentsByDocumentIdDisplayInformationApiResponse =
  /** status 200 Success */ GetDocumentDisplayInformationInternalResponseModel;
export type GetV1InternalDocumentsByDocumentIdDisplayInformationArgs = {
  /** The identifier of the document. */
  documentId: string;
};
export type GetV1InternalDocumentsByDocumentIdObjectInformationApiResponse =
  /** status 200 Success */ GetDocumentObjectInformationInternalResponseModel;
export type GetV1InternalDocumentsByDocumentIdObjectInformationArgs = {
  /** The identifier of the document. */
  documentId: string;
};
export type DocumentTypeModel =
  | "Word"
  | "Pdf"
  | "Text"
  | "Excel"
  | "PowerPoint"
  | "Jpeg"
  | "Png"
  | "Other";
export type DocumentStatusModel = "Active" | "Deleted";
export type PermissionEnumModel =
  | "Read"
  | "Edit"
  | "Delete"
  | "Restore"
  | "ReadPermissions"
  | "EditPermissions"
  | "Download"
  | "CreateTask";
export type GetDocumentResponse = {
  /** Gets the id of the document. */
  id?: string;
  /** Gets the unique id of the document. */
  uniqueIdentifier?: string | null;
  /** Gets the parent id of the document. */
  parentId?: string | null;
  /** Gets the flag that specifies if the current document is private or not. */
  isPrivate?: boolean;
  /** Gets the latest version number for the document. */
  latestVersion?: number;
  /** Gets the name of the document. */
  name?: string | null;
  /** Gets the extension for the document. */
  extension?: string | null;
  documentType?: DocumentTypeModel;
  /** Gets the account id of the user that created the document. */
  creatorAccountId?: string;
  /** Gets the creation date and time. */
  createdDateTime?: string;
  /** Gets the account id of the user that last modified the document. */
  lastModifiedAccountId?: string;
  /** Gets the last modified date and time. */
  lastModifiedDateTime?: string;
  /** Gets the size of the document in bytes. */
  size?: number;
  /** The date when the document was soft deleted */
  deletedDate?: string | null;
  /** The id of the account the did the soft delete */
  deletedByAccountId?: string | null;
  status?: DocumentStatusModel;
  /** Returns the e-tag for the document. */
  eTag?: string | null;
  /** The list of permissions that the current user has for the document. */
  permissions?: PermissionEnumModel[] | null;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type ObjectTypeModel = "Episode";
export type DeleteDocumentReponse = {
  /** Indicates the success of the delete operation */
  success?: boolean;
};
export type DocumentModel = {
  /** Gets the id of the document. */
  id?: string;
  /** Gets the unique id of the document. */
  uniqueIdentifier?: string | null;
  /** Gets the parent id of the document. */
  parentId?: string | null;
  /** Gets the flag that specifies if the current document is private or not. */
  isPrivate?: boolean;
  /** Gets the latest version number for the document. */
  latestVersion?: number;
  /** Gets the name of the document. */
  name?: string | null;
  /** Gets the extension for the document. */
  extension?: string | null;
  documentType?: DocumentTypeModel;
  /** Gets the account id of the user that created the document. */
  creatorAccountId?: string;
  /** Gets the creation date and time. */
  createdDateTime?: string;
  /** Gets the account id of the user that last modified the document. */
  lastModifiedAccountId?: string;
  /** Gets the last modified date and time. */
  lastModifiedDateTime?: string;
  /** Gets the size of the document in bytes. */
  size?: number;
  /** The date when the document was soft deleted */
  deletedDate?: string | null;
  /** The id of the account the did the soft delete */
  deletedByAccountId?: string | null;
  status?: DocumentStatusModel;
  /** Returns the e-tag for the document. */
  eTag?: string | null;
  /** The list of permissions that the current user has for the document. */
  permissions?: PermissionEnumModel[] | null;
};
export type GetDocumentsResponse = {
  /** Gets the list of documents. */
  items?: DocumentModel[] | null;
};
export type CreateDocumentResponse = {
  /** Gets the id of the document that was just created. */
  documentId: string;
  /** Gets the version of the document. */
  version: number;
};
export type InitialPermissionModel =
  | "AllParentMembersHaveViewPermission"
  | "AllParentMembersHaveEditPermission"
  | "Private";
export type VersionResponseModel = {
  /** Gets the number for the current version. */
  versionNumber: number;
  /** Gets the account id of the user that created the document. */
  creatorAccountId: string;
  /** Gets the creation date and time. */
  createdAt: string;
};
export type GetVersionsResponseModel = {
  /** Gets the collection of document versions */
  items: VersionResponseModel[];
};
export type RestoreDocumentFromTrashRequestModel = {
  /** The id of the object to which the document belongs to. */
  objectId: string;
  objectType: ObjectTypeModel;
};
export type RestoreDocumentToVersionRequestModel = {
  /** The id of the object to which the document belongs to. */
  objectId: string;
  objectType: ObjectTypeModel;
};
export type RenameDocumentRequestModel = {
  /** The id of the document that needs to be renamed. */
  documentId: string;
  /** The new name of the document. */
  newName: string;
  /** The id of the object to which the document belongs to. */
  objectId: string;
  objectType: ObjectTypeModel;
};
export type DocumentExistsResponse = {
  /** The flag that specifies if the document already exists. */
  isDuplicate?: boolean;
  /** Retrieves the alternative title to use for upload in case the document shouldn't be overwritten. */
  alternativeTitle?: string | null;
  /** The flag that specifies if the current user can overwrite the existing document. */
  canOverwrite?: boolean;
};
export type AccessRightsLevelModel =
  | "NoAccess"
  | "Reader"
  | "Contributor"
  | "Owner";
export type AccessRightsModel = {
  /** The id of the user for which the current access right is assigned to. */
  userId: string;
  accessRightsLevel?: AccessRightsLevelModel;
};
export type GetAccessRightsResponseModel = {
  /** Indicates if the document is private. */
  isPrivate: boolean;
  /** Gets the list of user access rights for the current document. */
  userAccessRights: AccessRightsModel[];
};
export type UpdateAccessRightsForDocumentModel = {
  /** The object id to which the document belongs to. */
  objectId?: string;
  objectType?: ObjectTypeModel;
  /** The list of access rights for the users. */
  userAccessRights?: AccessRightsModel[] | null;
};
export type TogglePrivateStatusResponse = {
  /** The new private status of the document. */
  isPrivate: boolean;
};
export type GetDocumentDisplayInformationInternalResponseModel = {
  id: string;
  name: string;
  size: number;
  documentType: string;
};
export type ObjectTypeInternalResponseModel = "Episode";
export type GetDocumentObjectInformationInternalResponseModel = {
  id: string;
  objectId: string;
  objectType: ObjectTypeInternalResponseModel;
};
export const {
  useGetV1DocumentsByDocumentIdQuery,
  useDeleteV1DocumentsByDocumentIdMutation,
  useGetV1DocumentsByDocumentIdContentQuery,
  useGetV1DocumentsQuery,
  usePostV1DocumentsMutation,
  useGetV1DocumentsByDocumentIdVersionsQuery,
  useRestoreFromTrashMutation,
  useRestoreToVersionMutation,
  usePatchV1DocumentsByDocumentIdNameMutation,
  useGetV1DocumentsExistsQuery,
  useGetV1DocumentsByDocumentIdAccessrightsQuery,
  usePatchV1DocumentsByDocumentIdAccessrightsMutation,
  usePatchV1DocumentsByDocumentIdPrivateMutation,
  useGetV1InternalDocumentsByDocumentIdDisplayInformationQuery,
  useGetV1InternalDocumentsByDocumentIdObjectInformationQuery,
} = injectedRtkApi;
