import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, createAppAsyncThunk } from "src/common/redux";
import { DocumentStatusModel, DocumentModel, DocumentsService, ObjectTypeModel, InitialPermissionModel } from "src/open-api/documentService";
import { parseCollaboraDiscoveryXml } from "src/modules/shared/utils/files";
import { env } from "src/env";
import { DocumentsState } from "../types";
import { addToast, ToastType, showLoading as showGlobalLoading, hideLoading as hideGlobalLoading } from "./feedbackSlice";

const initialState: DocumentsState = {
	isLoading: false,
	documents: {},
	parentObject: null,
	currentDocument: null,
	currentDocumentId: null,
	collaboraIframeBaseUrls: null
}

type ExistsDocumentArgs = {
	episodeId: string,
	title: string
};

export const existsDocument = createAppAsyncThunk(
	"documents/existsDocument",
	async ({ episodeId, title }: ExistsDocumentArgs, { dispatch }) => {
		try {
			dispatch(showGlobalLoading());
			const result = await DocumentsService.getV1DocumentsExists(title, episodeId, "Episode");
			return result;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});

type AddNewDocumentArgs = {
	episodeId: string,
	file: File,
	title: string,
	permissionModel: InitialPermissionModel
};

export const addNewDocument = createAppAsyncThunk(
	"documents/addNewDocument",
	async ({ episodeId, file, title, permissionModel }: AddNewDocumentArgs, { dispatch }) => {
		try {
			dispatch(showGlobalLoading());

			await DocumentsService.postV1Documents(episodeId, "Episode", title, permissionModel, { file });

			dispatch(addToast({
				titleKey: "kko:general.feedback.add-document-title",
				contentKey: "kko:general.feedback.add-document-success",
				type: ToastType.Success,
				icon: "check"
			}));

			dispatch(getDocuments({
				objectId: episodeId,
				objectType: "Episode",
				status: "Active"
			}));

		} finally {
			dispatch(hideGlobalLoading());
		}
	});

type GetDocumentsArgs = {
	objectId: string,
	objectType: ObjectTypeModel,
	status: DocumentStatusModel
};

export const getDocuments = createAppAsyncThunk(
	"documents/getDocuments",
	async ({ objectId, objectType, status }: GetDocumentsArgs, { dispatch, getState }) => {
		try {
			dispatch(showGlobalLoading());
			dispatch(setParentObject({ id: objectId, type: objectType }));

			const result = await DocumentsService.getV1Documents1(objectId, objectType, status);
			const documents = result.items ?? [];
			const state = getState();

			// check if the parent object id/type used for the fetch of documents is the last object id/type stored in state for this operation
			if (state.document.parentObject?.id !== objectId || state.document.parentObject.type !== objectType) {
				return [];
			}

			dispatch(setDocuments({ items: documents, status }));
			return documents;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});

type DeleteDocumentArgs = {
	documentId: string,
	episodeId: string
}

export const deleteDocument = createAppAsyncThunk(
	"documents/deleteDocument",
	async ({ documentId, episodeId }: DeleteDocumentArgs, { dispatch }) => {
		try {
			dispatch(showGlobalLoading());

			await DocumentsService.deleteV1Documents(documentId, episodeId);

			dispatch(addToast({
				titleKey: "kko:general.feedback.delete-document-title",
				contentKey: "kko:general.feedback.delete-document-success",
				type: ToastType.Success,
				icon: "check"
			}));

			dispatch(getDocuments({
				objectId: episodeId,
				status: "Active",
				objectType: "Episode"
			}));

		} catch {
			dispatch(addToast({
				titleKey: "kko:general.feedback.delete-document-error-title",
				contentKey: "kko:general.feedback.delete-document-error",
				type: ToastType.Error
			}));

		} finally {
			dispatch(hideGlobalLoading());
		}
	});

type getDocumentRequest = {
	documentId: string;
	objectId: string;
	objectType: ObjectTypeModel;
}
export const getDocument = createAppAsyncThunk(
	"documents/getDocument",
	async ({ documentId, objectId, objectType }: getDocumentRequest, thunkApi) => {
		try {
			thunkApi.dispatch(showGlobalLoading());
			const result = await DocumentsService.getV1Documents(documentId, objectId, objectType);
			return result;
		}
		finally {
			thunkApi.dispatch(hideGlobalLoading());
		}
	});

type RestoreDocumentRequest = {
	documentId: string,
	objectId: string,
	objectTypeModel: ObjectTypeModel;
	isUpload?: boolean;
};

export const restoreDocument = createAppAsyncThunk(
	"documents/restore",
	async ({ documentId, objectId, objectTypeModel, isUpload }: RestoreDocumentRequest, thunkApi) => {
		try {
			thunkApi.dispatch(showGlobalLoading());

			await DocumentsService.restoreFromTrash(documentId, {
				objectId: objectId,
				objectType: objectTypeModel
			});

			thunkApi.dispatch(getDocuments({
				objectId: objectId,
				objectType: objectTypeModel,
				status: "Deleted"
			}));

			// successful document restore message
			// set the corresponding message, for restore/upload
			thunkApi.dispatch(addToast({
				titleKey: isUpload
					? "kko:pages.document.list.deleted.successful-upload-toast-title"
					: "kko:pages.document.list.deleted.successful-restore-toast-title",
				contentKey: isUpload
					? "kko:pages.document.list.deleted.successful-upload-toast-msg"
					: "kko:pages.document.list.deleted.successful-restore-toast-msg",
				type: ToastType.Success,
				icon: "check"
			}));

		} catch {
			// failed document restore message
			thunkApi.dispatch(addToast({
				titleKey: isUpload
					? "kko:pages.document.list.deleted.failed-upload-toast-title"
					: "kko:pages.document.list.deleted.failed-restore-toast-title",
				contentKey: isUpload
					? "kko:pages.document.list.deleted.failed-upload-toast-msg"
					: "kko:pages.document.list.deleted.failed-restore-toast-msg",
				type: ToastType.Error
			}));

		} finally {
			thunkApi.dispatch(hideGlobalLoading());
		}
	});

export const getCollaboraIframeBaseUrls = createAppAsyncThunk(
	"documents/getCollaboraIframeBaseUrls",
	async (_, { dispatch, getState }) => {
		try {
			// When we already have the Collabora iframe url srcs in the state,
			// there is no point in retrieving them again.
			const current = getState().document.collaboraIframeBaseUrls;
			if (current) return current;

			dispatch(showGlobalLoading());

			const url = new URL("/hosting/discovery", new URL(env.REACT_APP_COLLABORA_SERVER_URL));
			const response = await fetch(url).then(response => response.text());

			const baseUrls = parseCollaboraDiscoveryXml(response);
			return baseUrls;

		} finally {
			dispatch(hideGlobalLoading());
		}
	});


export const documentSlice = createSlice({
	name: "document",
	initialState,
	reducers: {
		showLoading: state => {
			state.isLoading = true;
		},
		hideLoading: state => {
			state.isLoading = false;
		},
		setDocuments: (state, payload: PayloadAction<{ items: DocumentModel[], status: DocumentStatusModel }>) => {
			state.documents[payload.payload.status] = payload.payload.items;
		},
		setParentObject: (state, payload: PayloadAction<{ id: string, type: ObjectTypeModel }>) => {
			state.parentObject = payload.payload;
		},
		setCurrentDocumentId: (state, payload: PayloadAction<string>) => {
			state.currentDocumentId = payload.payload;
		},
		setCurrentDocument: (state, payload: PayloadAction<DocumentModel>) => {
			state.currentDocument = payload.payload;
		}
	},
	extraReducers(builder) {
		builder.addCase(getDocument.pending, (state, action) => {
			// save the current requested document id in the state for later to see if the response is for the last request
			state.currentDocumentId = action.meta.arg.documentId;
		});
		builder.addCase(getDocument.fulfilled, (state, action) => {
			// check if the document id used for the fetch of document is the last document id stored in state for this operation
			if (state.currentDocumentId === action.meta.arg.documentId) {
				state.currentDocument = action.payload;
			}
		});
		builder.addCase(getCollaboraIframeBaseUrls.fulfilled, (state, action) => {
			state.collaboraIframeBaseUrls = action.payload;
		});
	}
});

export const activeDocuments = createSelector(
	(state: RootState) => state.document.documents,
	(documents) => documents["Active"] ?? []
);

export const deletedDocuments = createSelector(
	(state: RootState) => state.document.documents,
	(documents) => documents["Deleted"] ?? []
);

export const { showLoading, hideLoading, setDocuments, setParentObject } = documentSlice.actions;
export default documentSlice.reducer;